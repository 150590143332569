import React, { useEffect, useState } from 'react';

const RegistrationConfirmModal = (props) => {
    
    return (
        <div className="manage-column">
            <div className='modal-heading'>
               <span>{props.heading}</span> 
            </div>
            <div className="delete-columns">
                <p>{props.text}</p>
            </div>
            <div className="column-buttons">
                <button
                    className="apply"
                    onClick={props.onClick}
                >
                    Okay
                </button>
            </div>
        </div>
    );
};

export default RegistrationConfirmModal;
