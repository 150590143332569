import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Import from '../../../common/import/Import';

const ImportAlumniPage = () => {
    return (
        <MainLayout>
            <Import type={'alumni'} />
        </MainLayout>
    );
};

export default ImportAlumniPage;
