import { range } from './Utils';

export const Months = {
    0: '',
    1: 'Jan',
    2: 'Feb',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
};

export const MonthOptions = range(1, 12).map((month) => {
    return {
        key: month,
        text: Months[month],
    };
});
