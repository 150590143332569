import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import ListBatchPage from '../views/main/Batch/ListBatchPage';
import AddBatchPage from '../views/main/Batch/AddBatchPage';
import ListNotificationPage from '../views/main/Notifications/ListNotificationPage';

function NotificationRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/list`} exact component={ListNotificationPage} />
        </SwitchWithNotFound>
    );
}

export default NotificationRoutes;
