import React from 'react';
import ChatHeader from './components/ChatHeader';
import Conversation from './components/Conversation';
import ConversationInputTool from './components/ConversationInputTool';

const Chat = () => {
    return (
        <div className="converstion-chat">
            <ChatHeader />
            <div className="messages-conversation">
                <Conversation />
                <ConversationInputTool />
            </div>
        </div>
    );
};
export default Chat;
