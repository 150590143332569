import React, { useState, useEffect } from 'react';

const PersonalInfoModal = ({
    onCancel,
    updatedPersonalInformation,
    onUpdate,
}) => {
    const [input, setInput] = useState({
        phone: updatedPersonalInformation,
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        let fields = [
            {
                label: 'Phone',
                key: 'phone',
                required: true,
                regex:
                    /^(\+\d{1,3}[- ]?)?\d{10}$/ ||
                    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const handlesubmit = () => {
        if (!validate()) {
            return;
        }
        onUpdate({ ...input });
    };

    return (
        <div className="edit-profile-modal">
            <div className="modal-heading">
                <h4>Personal Information</h4>
                <div onClick={onCancel}>
                    <img
                        src="../../../../images/edit-close.svg"
                        alt="CloseIcon"
                    />
                </div>
            </div>
            
            <div className="edit-form">
                {/* <div className='form-group'>
                    <label>Email</label>
                    <input type='text' name='email' placeholder='Email' />
                </div> */}
                <div className="form-group">
                    <label>Phone Number</label>
                    <div
                        className={
                            errors.phone
                                ? 'input-with-label-error'
                                : 'input-with-label'
                        }
                    >
                        <input
                            type="text"
                            name="phone"
                            onChange={handleChange}
                            placeholder="Phone Number"
                            value={input.phone}
                        />
                        <span>{errors.phone}</span>
                    </div>
                </div>
                <div className="form-buttons">
                    <button className="cancel" onClick={onCancel}>
                        No, cancel
                    </button>
                    <button className="save" onClick={handlesubmit}>
                        Yes, confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfoModal;
