import React, { useEffect, useState } from 'react';
import AddPost from './components/AddPost';
import PostSorting from './components/PostSorting';
import PostCard from './PostCard';
import Api from '../../../../../../common/api/api';
import PollCard from './PollCard';
import Initialising from '../../../../../../common/controls/Initialising';

const Feed = () => {
    const [feed, setFeed] = useState({ posts: [] });
    const [pollFeed, setPollFeed] = useState({ polls2: [] });
    const [pollFeedByVotes, setPollFeedByVotes] = useState({ polls1: [] });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Api.getInstance()
            .getFeed()
            .then((response) => {
                setFeed(response);
            })
            .then(console.log)
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Initialising />;
    }

    if (feed.posts.length === 0 && pollFeed.polls2.length === 0) {
        return (
            <>
                <div className='welcome-msg'>
                    <h1>Welcome to Almacircle!</h1>
                    <p>We&apos;re happy to have you on-board!</p>
                </div>
            </>
        );
    }
    
    return (
        <>
            {/* <PostSorting /> */}
            {feed.posts.map((post) => {
                if(post.contentType === 'post')
                {
                return <PostCard post={post} key={`feed-post-${post.id}`} />;
                }
                if(post.contentType === 'poll')
                {
                return <PollCard poll={post} clickable={true} key={`feed-post-${post.id}`} />;
                }
            })}
        </>
    );
};

const HomeMiddleItems = () => {
    return (
        <div className="home-middle-col">
            {/* <AddPost /> */}
            <Feed />
        </div>
    );
};

export default HomeMiddleItems;
