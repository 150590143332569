import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Table from '../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 34,
    },
};

const ListAlumniPage = () => {
    const history = useHistory();
    return (
        <MainLayout>
            <Table
                allowImport={true}
                allowExport={true}
                initialsField={'name'}
                type={'alumni'}
                tableName={'Alumni'}
                deleteIcon={true}
                showAdvancedSearch={true}
                name={'Alumni'}
                usePagination={true}
                onItemClick={(item) => {
                    history.push(`/alumni/${item.id}/edit`);
                }}
            />
        </MainLayout>
    );
};

export default ListAlumniPage;
