import React from 'react';
import { Route } from 'react-router-dom';

import ListAlumniPage from '../views/main/Alumni/ListAlumniPage';
import ImportAlumniPage from '../views/main/Alumni/ImportAlumniPage';
import AddAlumniPage from '../views/main/Alumni/AddAlumniPage';
import AlumniPage from '../views/main/Alumni/AlumniPage';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import Unsubscribe from '../views/main/Unsubscribed/Unsubscribe';
import UnsubscribedSuccessful from '../views/main/Unsubscribed/UnsubscribedSuccessful';
import ImportExcelSheet from '../views/main/Dashboard/ImportExcelSheet';

function AlumniRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/`} exact component={AlumniPage} />
            <Route path={`${url}/add`} exact component={AddAlumniPage} />
            <Route path={`${url}/:id/edit`} exact component={AddAlumniPage} />
            <Route path={`${url}/import`} exact component={ImportAlumniPage} />
            <Route path={`${url}/importExcel`} exact component={ImportExcelSheet} />

            <Route path={`${url}/list`} exact component={ListAlumniPage} />
            <Route path={`${url}/unsubscribe`} exact component={Unsubscribe} />
            <Route path={`${url}/unsubscribesuccessful`} exact component={UnsubscribedSuccessful} />
        </SwitchWithNotFound>
    );
}

export default AlumniRoutes;
