import React from 'react';
import ActionSection from './components/ActionSection';
import CardHeaderSection from './components/CardHeaderSection';
import MediaSection from './components/MediaSection';

const PostCard = ({
    post: {
        description,
        user,
        timestamp_relative,
        images,
        option,
        question,
        ...post
    },
    showActions = true,
}) => {
    return (
        <div className="card">
            <CardHeaderSection
                user={user}
                timestamp_relative={timestamp_relative}
            />
            <MediaSection description={description} images={images} option={option} poll={null} question={question}/>
            {showActions === true && <ActionSection post={post} />}
        </div>
    );
};

export default PostCard;
