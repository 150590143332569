import React, { useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';

// Hook
function useOnClickOutside(ref, buttonRef, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (
                    !ref.current ||
                    ref.current.contains(event.target) ||
                    (buttonRef && buttonRef.current.contains(event.target))
                ) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler],
    );
}

const PostSorting = () => {

    const [showDrop, setShowDrop] = useState(false);

    // OutsideClicked Event
    const ref = useRef();
    const buttonRef = useRef();
    useOnClickOutside(ref, buttonRef, () => setShowDrop(false));

    return (
        <div className="sorting-section">
            <div className="sorting">
                <img src="../../../../../../images/FilterLine.svg" alt="Line" />
                <span>Sort by</span>
                <div className="sorting-dropdown" ref={buttonRef} onClick={() => setShowDrop(!showDrop)}>
                    <span>Recent</span>
                    <img
                        src="../../../../../../images/drop-icon.svg"
                        alt="Line"
                    />

                    {/* UserDrop Down */}
                    {showDrop &&
                        <div ref={ref} className='sorting-drop'>
                            <ul>
                                <li>
                                    <Link >
                                        <span>Most Liked</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link>
                                        <span>Recent</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PostSorting;
