import React from 'react';
import { connect } from 'react-redux';
import Api from '../../../../../../../common/api/api';

const ProfileSection = ({ alumni }) => {
    return (
        <div>
            <div className="user-info-col">
                <div className="pic-card">
                    <img
                        src={
                            Api.getInstance().getImageUrl(alumni.image) ||
                            '../../../../../../images/new-default-icon.png'
                        }
                        alt="ProfilePic"
                    />
                </div>
                <div className="profile-col">
                    <span>{alumni.name}</span>
                    <p>{alumni.batch_name || '-'}</p>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    alumni: state.app.alumni,
});

export default connect(mapStateToProps)(ProfileSection);
