import React from 'react';
import Api from '../../../../../../../common/api/api';

const CardHeaderSection = ({ user, timestamp_relative }) => {
    return (
        <div className="card-header">
            <div className="card-header-col-left">
                <img
                    src={
                        Api.getInstance().getImageUrl(user.image) ||
                        '../../../../../../images/new-default-icon.png'
                    }
                    alt="Logo"
                    width='36'
                    height='36'
                />
                <div className="post-info">
                    <h4 style={{marginBottom:0}}>{user.name}</h4>
                    {/* <span>43.796 Talking</span> */}
                    <div className="date-info">
                        <span>
                            {timestamp_relative}
                            {/* • */}
                        </span>
                        {/*<img
                            src="../../../../../../images/Vector.svg"
                            alt="Vector"
                        />*/}
                    </div>
                </div>
            </div>
            {/* <div className="card-header-col-right">
                <img src="../../../../../../images/options.svg" alt="Options" />
            </div> */}
        </div>
    );
};

export default CardHeaderSection;
