import React from 'react';
import AsideLinks from './components/AsideLinks';
import ProfileSection from './components/ProfileSection';

const HomeLeftItems = () => {
    return (
        <div className="home-left-col">
            <ProfileSection />
            <AsideLinks />
        </div>
    );
};

export default HomeLeftItems;
