import React from 'react';
import { connect } from 'react-redux';
import AuthLayout from '../../../layouts/auth/AuthLayout';
import AccountSetPassword from '../../AlumniFrontEnd/Main/MyAccount/components/subcomponents/AccountSetPassword';

const SetChangePassword = ({ user, title, ...prpos }) => {
    return (
        <AuthLayout>
            <div className="login-heading">
                <span className="ms-fontSize-24 ms-fontWeight-bold darkColor fontFamily">
                Set Password
                </span>
            </div>
            <AccountSetPassword required={user.email?false:true} />
        </AuthLayout>
    );
};

const mapStateToProps = (state) => ({
    user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchLogin: (data) =>
        dispatch({
            type: 'AUTH_LOGIN',
            data: data,
        }),
    dispatchLogout: () =>
        dispatch({
            type: 'AUTH_LOGOUT',
        }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetChangePassword);
