import React from 'react';

const Conversation = () => {
    return (
        <div className="inner-messages">
            {/* Reply Messages  box */}
            <div className="messages-reply-body">
                <div className="receive-messages">
                    <div className="calout-intial">
                        <img
                            src="../../../../../../images/mike.svg"
                            alt="Intial"
                        />
                    </div>
                    <div className="receive-conversation-display">
                        <div className="calout-boxes">
                            <h4>Mike Mazowski </h4>
                            <span>
                                Hello guys, we have discussed about post-corona
                                vacation plan and our decision is to go to Bali.
                                We will have a very big party after this corona
                                ends! These are some images about our
                                destination
                            </span>
                            <p>16.04</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sent Messages box */}
            <div className="messages-body">
                <div className="sent-messages">
                    <div className="conversation-display">
                        <div className="calout-boxes">
                            <span>
                                That’s very nice place! you guys made a very
                                good decision. Can’t wait to go on vacation!
                            </span>
                            <p>16.04</p>
                        </div>
                        <div className="calout-intial">
                            <img
                                src="../../../../../../images/user-intial.svg"
                                alt="Intial"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Reply Messages  box */}
            <div className="messages-reply-body">
                <div className="receive-messages">
                    <div className="calout-intial">
                        <img
                            src="../../../../../../images/mike.svg"
                            alt="Intial"
                        />
                    </div>
                    <div className="receive-conversation-display">
                        <div className="calout-boxes">
                            <h4>Mike Mazowski </h4>
                            <span>
                                Hello guys, we have discussed about post-corona
                                vacation plan and our decision is to go to Bali.
                                We will have a very big party after this corona
                                ends! These are some images about our
                                destination
                            </span>
                            <p>16.04</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sent Messages box */}
            <div className="messages-body">
                <div className="sent-messages">
                    <div className="conversation-display">
                        <div className="calout-boxes">
                            <span>
                                That’s very nice place! you guys made a very
                                good decision. Can’t wait to go on vacation!
                            </span>
                            <p>16.04</p>
                        </div>
                        <div className="calout-intial">
                            <img
                                src="../../../../../../images/user-intial.svg"
                                alt="Intial"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Reply Messages  box */}
            <div className="messages-reply-body">
                <div className="receive-messages">
                    <div className="calout-intial">
                        <img
                            src="../../../../../../images/mike.svg"
                            alt="Intial"
                        />
                    </div>
                    <div className="receive-conversation-display">
                        <div className="calout-boxes">
                            <h4>Mike Mazowski </h4>
                            <span>
                                Hello guys, we have discussed about post-corona
                                vacation plan and our decision is to go to Bali.
                                We will have a very big party after this corona
                                ends! These are some images about our
                                destination
                            </span>
                            <p>16.04</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sent Messages box */}
            <div className="messages-body">
                <div className="sent-messages">
                    <div className="conversation-display">
                        <div className="calout-boxes">
                            <span>
                                That’s very nice place! you guys made a very
                                good decision. Can’t wait to go on vacation!
                            </span>
                            <p>16.04</p>
                        </div>
                        <div className="calout-intial">
                            <img
                                src="../../../../../../images/user-intial.svg"
                                alt="Intial"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Reply Messages  box */}
            <div className="messages-reply-body">
                <div className="receive-messages">
                    <div className="calout-intial">
                        <img
                            src="../../../../../../images/mike.svg"
                            alt="Intial"
                        />
                    </div>
                    <div className="receive-conversation-display">
                        <div className="calout-boxes">
                            <h4>Mike Mazowski </h4>
                            <span>
                                Hello guys, we have discussed about post-corona
                                vacation plan and our decision is to go to Bali.
                                We will have a very big party after this corona
                                ends! These are some images about our
                                destination
                            </span>
                            <p>16.04</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sent Messages box */}
            <div className="messages-body">
                <div className="sent-messages">
                    <div className="conversation-display">
                        <div className="calout-boxes">
                            <span>
                                That’s very nice place! you guys made a very
                                good decision. Can’t wait to go on vacation!
                            </span>
                            <p>16.04</p>
                        </div>
                        <div className="calout-intial">
                            <img
                                src="../../../../../../images/user-intial.svg"
                                alt="Intial"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Reply Messages  box */}
            <div className="messages-reply-body">
                <div className="receive-messages">
                    <div className="calout-intial">
                        <img
                            src="../../../../../../images/mike.svg"
                            alt="Intial"
                        />
                    </div>
                    <div className="receive-conversation-display">
                        <div className="calout-boxes">
                            <h4>Mike Mazowski </h4>
                            <span>
                                Hello guys, we have discussed about post-corona
                                vacation plan and our decision is to go to Bali.
                                We will have a very big party after this corona
                                ends! These are some images about our
                                destination
                            </span>
                            <p>16.04</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sent Messages box */}
            <div className="messages-body">
                <div className="sent-messages">
                    <div className="conversation-display">
                        <div className="calout-boxes">
                            <span>
                                That’s very nice place! you guys made a very
                                good decision. Can’t wait to go on vacation!
                            </span>
                            <p>16.04</p>
                        </div>
                        <div className="calout-intial">
                            <img
                                src="../../../../../../images/user-intial.svg"
                                alt="Intial"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Conversation;
