import React from 'react';
import MainLayout from '../../AlumniFrontEnd/Components/Layout/MainLayout';
import FileUpload from './FileUpload';

const ImportExcelSheet = () => {
  return (
    <div>
     <MainLayout>
        <FileUpload />
     </MainLayout>
    </div>
  )
}

export default ImportExcelSheet
