import React from 'react';
import SetChangePassword from '../../../main/AccountSettings/SetChangePassword';

const UpdatePassword = () => {
    return (
        <SetChangePassword />
    );
};

export default UpdatePassword;
