import * as React from 'react';
import { Checkbox, Stack } from '@fluentui/react';

// Used to add spacing between example checkboxes
const stackTokens = { childrenGap: 10 };

const CheckboxButtons = ({ options, value, onChange, name }) => {
    const handleChange = (option) => {
        let val = [...value];
        if (val.indexOf(option.value) > -1) {
            val.splice(val.indexOf(option.value), 1);
        } else {
            val.push(option.value);
        }
        onChange({ target: { name, value: val } });
    };

    return (
        <Stack tokens={stackTokens}>
            <div className="forms-checkboxes">
                <div className="check-group">
                    {options.map((option) => {
                        return (
                            // <Checkbox
                            //     key={`${name}-option-${option.value}`}
                            //     checked={value.indexOf(option.value) > -1}
                            //     label={option.label}
                            //     name={option.label}
                            //     onChange={() => {
                            //         handleChange(option);
                            //     }}
                            // />
                            <label key={`${name}-option-${option.value}`}>
                                <input
                                    checked={value.indexOf(option.value) > -1}
                                    label={option.label}
                                    name={option.label}
                                    onChange={() => {
                                        handleChange(option);
                                    }}
                                    type="checkbox"
                                />
                                <span>{option.label}</span>
                            </label>
                        );
                    })}
                </div>
            </div>
        </Stack>
    );
};

export default CheckboxButtons;
