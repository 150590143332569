import * as React from 'react';
import { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import InputWithLabel from './controls/InputWithLabel';
import ButtonWithLoader from './controls/ButtonWithLoader';
import Api from './api/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Initialising from './controls/Initialising';
import UpdateModal from './modals/UpdateModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const stackNewDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: '#2962FF',
        color: '#ffffff',
        fontSize: 16,
        border: 'none',
        fontWeight: 'normal',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px',
    },
};

const SettingsAccordianTabs = ({ school_id }) => {
    const [user, setUser] = useState({});
    const [uploading, setUploading] = useState(false);
    const [initialising, setInitialising] = useState(true);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [val, setVal] = useState(Array([]));
    const [senderVal, setSenderVal] = useState(Array([]));
    const [emailErrors, setEmailErrors] = useState([]);
    const [transactionalEmailErrors, setTransactionalEmailErrors] = useState(
        [],
    );
    const [senderEmailErrors, setSenderEmailErrors] = useState([]);
    const [duplicateSenderError, setDuplicateSenderError] = useState('');
    const [duplicateReceiverError, setDuplicateReceiverError] = useState('');

    useEffect(() => {
        if (!school_id) {
            return;
        }
        Api.getInstance()
            .getSchool(school_id)
            .then((response) => {
                console.log('response is');
                console.log(response);
                setUser(response);
                const inputdata = [...val];
                for (
                    let index = 0;
                    index < response.receiver_email.length;
                    index++
                ) {
                    inputdata[index] = response.receiver_email[index];
                    setVal(inputdata);
                    setInput((i) => ({
                        ...i,
                        receiver_email: inputdata,
                    }));
                }
                const senderInputdata = [...senderVal];
                for (
                    let index = 0;
                    index < response.sender_email.length;
                    index++
                ) {
                    senderInputdata[index] = response.sender_email[index];
                    setSenderVal(senderInputdata);
                    setInput((i) => ({
                        ...i,
                        sender_email: senderInputdata,
                    }));
                }
            })

            .catch(() => {})
            .finally(() => {
                setInitialising(false);
            });
    }, [school_id]);

    const [input, setInput] = useState({
        receiver_email: [],
        sender_email: [],
        sender_name: '',
    });

    const [transactionalInput, setTransactionalInput] = useState({
        transactional_receiver_email: '',
        transactional_sender_email: '',
        transactional_sender_name: '',
    });

    useEffect(() => {
        setInput({
            receiver_email: user.receiver_email,
            sender_email: user.sender_email,
            sender_name: user.sender_name,
        });
    }, [user]);

    useEffect(() => {
        setTransactionalInput({
            transactional_receiver_email: user.transactional_receiver_email,
            transactional_sender_email: user.transactional_sender_email,
            transactional_sender_name: user.transactional_sender_name,
        });
    }, [user]);

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const validateInvalidDomainsEmails = () => {
        let fields = [
            {
                label: 'Name',
                key: 'name',
                required: true,
            },
            {
                label: 'Sender Name',
                key: 'sender_name',
                required: true,
            },
            {
                label: 'Sender Email',
                key: 'sender_email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
            {
                label: 'Receiver Email',
                key: 'receiver_email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
        ];

        let emailErrors = {};
        let foundValidationError = false;
        fields.forEach((field) => {
            if (field.key === 'sender_email') {
                const inputdata = [...senderVal];
                inputdata.forEach((element, i) => {
                    let value = false;
                    user.domains.forEach((domain) => {
                        if (element.includes(domain)) {
                            value = true;
                        }
                    });
                    if (value == false) {
                        foundValidationError = true;
                        emailErrors[i] = `Invalid Domain for email ${element}`;
                    }
                });
            }
        });
        setSenderEmailErrors(emailErrors);
        return (
            Object.keys(emailErrors).length === 0 &&
            foundValidationError === false
        );
    };

    const validate = () => {
        let fields = [
            {
                label: 'Name',
                key: 'name',
                required: true,
            },
            {
                label: 'Sender Name',
                key: 'sender_name',
                required: true,
            },
            {
                label: 'Sender Email',
                key: 'sender_email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
            {
                label: 'Receiver Email',
                key: 'receiver_email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
        ];

        let e = {};
        let emailErrors = {};
        let senderEmailErrors = {};
        let foundValidationError = false;
        let foundSenderValidationError = false;
        fields.forEach((field) => {
            if (field.key === 'receiver_email') {
                const inputdata = [...val];
                inputdata.forEach((element, i) => {
                    let regex = new RegExp(field.regex);
                    if (!regex.test(element)) {
                        foundValidationError = true;
                        emailErrors[i] = `Please enter a valid ${field.label}`;
                    }
                });
            }

            if (field.key === 'sender_email') {
                const inputdata = [...senderVal];
                inputdata.forEach((element, i) => {
                    let regex = new RegExp(field.regex);
                    if (!regex.test(element)) {
                        foundSenderValidationError = true;
                        senderEmailErrors[
                            i
                        ] = `Please enter a valid ${field.label}`;
                    }
                });
            }

            if (field.required && input[field.key] === '') {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });

        setDuplicateSenderError('');
        setDuplicateReceiverError('');
        setErrors(e);
        setEmailErrors(emailErrors);
        setSenderEmailErrors(senderEmailErrors);
        if (new Set(senderVal).size !== senderVal.length) {
            setDuplicateSenderError('You have entered duplicate sender emails');
            return false;
        }
        if (new Set(val).size !== val.length) {
            setDuplicateReceiverError(
                'You have entered duplicate reply to emails',
            );
            return false;
        }
        return (
            Object.keys(e).length === 0 &&
            foundValidationError === false &&
            foundSenderValidationError === false
        );
    };

    const updateChanges = () => {
        window.location.reload();
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

        // if (!validateInvalidDomainsEmails()) {
        //     return;
        // }
        setLoading(true);

        Api.getInstance()
            .updateSchoolWithEmailCampaign(school_id, input)
            .then((response) => {
                toggleHideDialog();
            })
            .catch((e) => {
                if (e.fields) {
                    setErrors(e.fields);
                } else {
                    alert(e.error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTransactionalSubmit = () => {
        if (!validateTransactional()) {
            return;
        }

        Api.getInstance()
            .updateSchoolWithTransactional(school_id, transactionalInput)
            .then((response) => {
                toggleHideDialog();
            })
            .catch((e) => {
                if (e.fields) {
                    setErrors(e.fields);
                } else {
                    alert(e.error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const validateTransactional = () => {
        let fields = [
            {
                label: 'Transactional Name',
                key: 'transactional_sender_name',
                required: true,
            },
            {
                label: 'Transactional Sender Email',
                key: 'transactional_sender_email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
            {
                label: 'Transactional Receiver Email',
                key: 'transactional_receiver_email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.required && transactionalInput[field.key] === '') {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && transactionalInput[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(transactionalInput[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });

        setTransactionalEmailErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const handleTransactionalChange = (e) => {
        let i = { ...transactionalInput };
        i[e.target.name] = e.target.value;
        setTransactionalInput(i);
    };

    const handleInputChange = (onChangeValue, i) => {
        const inputdata = [...val];

        inputdata[i] = onChangeValue.target.value;
        setVal(inputdata);

        setInput((i) => ({
            ...i,
            receiver_email: inputdata,
        }));
    };

    const handleSenderInputChange = (onChangeValue, i) => {
        const inputdata = [...senderVal];
        inputdata[i] = onChangeValue.target.value;
        setSenderVal(inputdata);

        setInput((i) => ({
            ...i,
            sender_email: inputdata,
        }));
    };

    const handleAdd = () => {
        const indexes = [...val, []];
        setVal(indexes);

        const errors = [...emailErrors, []];
        setEmailErrors(errors);
    };

    const removeOption = (i) => {
        const deleteVal = [...val];
        {
            i != 0 ? deleteVal.splice(i, 1) : '';
        }
        setVal(deleteVal);
        setInput((i) => ({
            ...i,
            receiver_email: deleteVal,
        }));
    };

    const handleSenderAdd = () => {
        const indexes = [...senderVal, []];
        setSenderVal(indexes);
        const errors = [...senderEmailErrors, []];
        setSenderEmailErrors(errors);
    };

    const removeSenderOption = (i) => {
        const deleteVal = [...senderVal];
        {
            i != 0 ? deleteVal.splice(i, 1) : '';
        }
        setSenderVal(deleteVal);
        setInput((i) => ({
            ...i,
            sender_email: deleteVal,
        }));
    };

    if (initialising) {
        return <Initialising />;
    }

    return (
        <>
            <div className="user-tabs" style={{ marginTop: 0 }}>
                <h4 className="change-password">Email Settings</h4>

                <Stack>
                    <div className="add-user-form">
                        <div className="form-group current pr">
                            <InputWithLabel
                                errorMessage={errors.sender_name}
                                type="text"
                                value={input.sender_name}
                                onChange={handleChange}
                                label="Sender Name"
                                name="sender_name"
                            />
                        </div>
                        <div className="form-group current pl"></div>

                        <div
                            className="form-group current pr"
                            style={{ height: 'auto' }}
                        >
                            {Object.keys(senderVal).map((key, i) => {
                                return (
                                    <>
                                        <InputWithLabel
                                            key={i}
                                            type="text"
                                            onChange={(e) =>
                                                handleSenderInputChange(e, i)
                                            }
                                            errorMessage={
                                                senderEmailErrors[i] != ''
                                                    ? senderEmailErrors[i]
                                                    : ''
                                            }
                                            label="Sender Email"
                                            name="sender_email"
                                            value={senderVal[key]}
                                            className={
                                                senderEmailErrors[i]
                                                    ? 'drop-error actual-height'
                                                    : ''
                                            }
                                        />
                                        {i != 0 ? (
                                            <button
                                                onClick={() =>
                                                    removeSenderOption(i)
                                                }
                                                className="remove-option"
                                            >
                                                Remove
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                        <br />
                                        <br />
                                    </>
                                );
                            })}
                            <div
                                className="image-add-another-button"
                                onClick={handleSenderAdd}
                            >
                                <a>
                                    <img src="../../../../images/plus-icon.svg" />
                                </a>
                                <span className="add-another-button">
                                    Add Another
                                </span>
                            </div>
                            <p className="duplicate-error-email">
                                {duplicateSenderError}
                            </p>
                        </div>

                        <div
                            className="form-group current pl"
                            style={{ height: 'auto' }}
                        >
                            {Object.keys(val).map((key, i) => {
                                return (
                                    <>
                                        <InputWithLabel
                                            key={i}
                                            type="text"
                                            onChange={(e) =>
                                                handleInputChange(e, i)
                                            }
                                            errorMessage={
                                                emailErrors[i] != ''
                                                    ? emailErrors[i]
                                                    : ''
                                            }
                                            label="Reply To"
                                            name="receiver_email"
                                            value={val[key]}
                                            className={
                                                emailErrors[i]
                                                    ? 'drop-error actual-height'
                                                    : ''
                                            }
                                        />
                                        {i != 0 ? (
                                            <button
                                                onClick={() => removeOption(i)}
                                                className="remove-option"
                                            >
                                                Remove
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                        <br />
                                        <br />
                                    </>
                                );
                            })}
                            <div
                                className="image-add-another-button"
                                onClick={handleAdd}
                            >
                                <a>
                                    <img src="../../../../images/plus-icon.svg" />
                                </a>
                                <span className="add-another-button">
                                    Add Another
                                </span>
                            </div>
                            <p className="duplicate-error-email">
                                {duplicateReceiverError}
                            </p>
                        </div>
                    </div>
                </Stack>
                <Stack styles={stackNewStyles}>
                    {/* <div className="next-button">
                    <button
                        style={{ width: 80 }}
                    >
                        <span>Update</span>
                    </button>
                </div> */}
                    <ButtonWithLoader
                        onClick={handleSubmit}
                        name="Update"
                        loading={loading}
                    />
                </Stack>

                {/* </Pivot> */}

                <Dialog
                    hidden={hideDialog}
                    onDismiss={toggleHideDialog}
                    styles={dialogNewStyles}
                >
                    <UpdateModal
                        text={'Setting has been updated successfully.'}
                        onClick={updateChanges}
                    />
                </Dialog>
            </div>
            <div className="user-tabs" style={{ marginTop: 0 }}>
                <h4 className="change-password">
                    Transactional Email Settings
                </h4>
                <div className="add-user-form">
                    <div className="form-group current pr">
                        <InputWithLabel
                            errorMessage={
                                transactionalEmailErrors.transactional_sender_name
                            }
                            type="text"
                            value={transactionalInput.transactional_sender_name}
                            onChange={handleTransactionalChange}
                            label="Transactional Sender Name"
                            name="transactional_sender_name"
                        />
                    </div>
                    <div className="form-group current pl">
                        <InputWithLabel
                            errorMessage={
                                transactionalEmailErrors.transactional_sender_email
                            }
                            type="text"
                            value={
                                transactionalInput.transactional_sender_email
                            }
                            onChange={handleTransactionalChange}
                            label="Transactional Sender Email"
                            name="transactional_sender_email"
                        />
                    </div>
                    <div className="form-group current pr">
                        <InputWithLabel
                            errorMessage={
                                transactionalEmailErrors.transactional_receiver_email
                            }
                            type="text"
                            value={
                                transactionalInput.transactional_receiver_email
                            }
                            onChange={handleTransactionalChange}
                            label="Transactional Receiver Name"
                            name="transactional_receiver_email"
                        />
                    </div>
                    <Stack styles={stackNewStyles}>
                        {/* <div className="next-button">
                    <button
                        style={{ width: 80 }}
                    >
                        <span>Update</span>
                    </button>
                </div> */}
                        <ButtonWithLoader
                            onClick={handleTransactionalSubmit}
                            name="Update"
                        />
                    </Stack>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    school_id: state.app.school_id,
});

export default withRouter(connect(mapStateToProps)(SettingsAccordianTabs));
