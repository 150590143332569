import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react';
import { useEffect, useState } from 'react';

const ListSortPage = ({ onChange }) => {
    const options = [
        { key: 0, text: 'Pending' },
        { key: 1, text: 'Solved' },
    ];

    return (
            <Dropdown 
                className ={'hello'}
                placeholder="Sort by"
                options={options}
                onChange={(e, item) => {
                                onChange({
                                    target: {
                                        value: item.key,
                                        name: 'sort',
                                    },
                                });
                            }}
                name="sort"
            />
    );
};

export default ListSortPage;
