import React, { useEffect, useState } from 'react';

const ListErrorModal = (props) => {
    const [values, setValues] = useState([{}]);
    useEffect(() => {
        setValues(props.text);
    }, []);

    console.log("avlues are");
    console.log(values);

    return (
        <div className="manage-column">
            <div className="modal-icons">
                <div>
                    <img src="../../../images/error-icon.svg"></img>
                </div>
                <div className="cross-icon">
                    <img
                        onClick={props.onClick}
                        src="../../../images/cross-icon.svg"
                    ></img>
                </div>
            </div>
            <div className="modal-heading">
                <span>{values.length >= 2 ? `${values.length} Errors Found`:'Error Found'}</span>
            </div>
            <div className="delete-columns-List-Modal">
                {values.length > 1 ? <>{values.map((value,index) => {
                    if(value[2] === 'does not exist')
                    {
                         return (
                        <p key={index}>{++index}. <b>{value[0]}</b> <b>{value[1]}</b> {value[2]}</p>
                        );
                     
                    }
                    else if(value[2] === 'is Empty')
                    {
                         return (
                        <p key={index}>{++index}. Row <b>{value[0]}</b> {value[2]}</p>
                        );
                       
                    }
                    else{
                         return (<p key={index}>{++index}. In Row <b>{value[0]}</b>, <b>{value[1]}</b> {value[2]}</p>);
                    }
                 })}</>:<>
                  {values.map((value,index) => {
                    if(value[2] === 'does not exist')
                    {
                         return (
                        <p key={index}><b>{value[0]}</b> <b>{value[1]}</b> {value[2]}</p>
                        );
                    }
                    else if(value[2] === 'Empty')
                    {
                         return (<p key={index}>Row <b>{value[0]}</b> is {value[2]}</p>);
                     
                    }
                    else
                    {
                        return (<p key={index}>Row <b>{value[0]},</b> <b>{value[1]}</b> {value[2]}</p>);
                    }
                 })}</>}
            </div>
            <div className="column-buttons">
                <button className="delete" onClick={props.onClick}>
                    Okay, Got it
                </button>
            </div>
        </div>
    );
};

export default ListErrorModal;
