import React, { useEffect, useState } from 'react';
import EditProfileSection from './Components/EditProfileSection';
import EducationDetails from './Components/EducationDetails';
import EmploymentHistory from './Components/EmploymentHistory';
import PersonalInformation from './Components/PersonalInformation';
import Api from '../../../../../../common/api/api';
import Initialising from '../../../../../../common/controls/Initialising';
import SocialLink from './Components/SocialLink';

const EditMiddle = () => {
    const [profile, setProfile] = useState(null);


    const loadData = (showLoading = false) => {
        if (showLoading) {
            setProfile(null);
        }
        Api.getInstance()
            .getAlumniProfile()
            .then((response) => {
                setProfile(response);
            })
            .catch(console.log);
    };

    useEffect(() => {
        loadData();
    }, []);

    if (!profile) {
        return <Initialising />;
    }
    return (
        <div className="home-middle-col">
            
            <EditProfileSection profile={profile} />
            <PersonalInformation profile={profile} refresh={loadData}/>
            <EducationDetails
                education={profile.education}
                refresh={loadData}
            />
            <EmploymentHistory
                refresh={loadData}
                work_experience={profile.work_experience}
            />
             <SocialLink socialLink={profile.socialLink} refresh={loadData}/>
            
        </div>
    );
};

export default EditMiddle;
