import React, { useEffect, useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import Api from '../api/api';
import { useHistory } from 'react-router-dom';
import Initialising from '../controls/Initialising';
import ErrorModal from '../modals/ErrorModal';
import UpdateModal from '../modals/UpdateModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import ConfirmModal from '../modals/ConfirmModal';
import ListErrorModal from '../modals/ListErrorModal';
import xtype from 'xtypejs';

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px',
    },
};

const checkboxsyle = {
    display: 'flex',
    float: 'left',
    width: '25%',
    overflowWrap: 'break-word',
};

const checkboxLabel = {
    marginLeft: 5,
};

const Import = ({ type, name }) => {
    const [confirmation, setConfirmation] = useState(false);
    const [importedRows, setImportedRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);
    const [hideDialog3, { toggle: toggleHideDialog3 }] = useBoolean(true);
    const [confirmModalText, setConfirmModalText] = useState('');
    const [errorArrayText, setErrorArrayText] = useState([{}]);
    const [errorText, setErrorText] = useState('');
    const [duplicateField, setDuplicateField] = useState('');
    const [modalText, setModalText] = useState('');
    const [fields, setFields] = useState([]);
    const [excelFields, setExcelFields] = useState([]);
    const [isChecked, setIsChecked] = useState(true);

    const history = useHistory();

    const updateChanges = () => {
        toggleHideDialog();
        history.push(`/${type}/list`);
    };

    const confirmModalChanges = () => {
        handleImport();
        toggleHideDialog2();
    };

    const cancelImport = () => {
        toggleHideDialog2();
        history.push(`/${type}/list`);
    };

    const handleChange = (event) => {
        setIsChecked(event.target.value);
    };

    const handleImport = () => {
        setLoading(true);
        Api.getInstance()
            .importData(type, importedRows, excelFields, isChecked)
            .then((response) => {
                setModalText(
                    `You have successfully imported ${response.imported} records`,
                );
                toggleHideDialog();
            })
            .catch((e) => {
                if (xtype(e.error) === 'multi_elem_array' || xtype(e.error) === 'single_elem_array' ) {
                    console.log(e.error);
                    setErrorArrayText(e.error);
                    toggleHideDialog3();
                } else {
                    console.log(e.error);
                    setErrorText(e.error);
                    toggleHideDialog1();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        Api.getInstance()
            .getImportFields(type)
            .then((response) => {
                setFields(response);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Initialising />;
    }

    return (
        <div className="csv-impoter-screen">
            <Importer
                chunkSize={100000} // optional, internal parsing chunk size in bytes
                assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                restartable={true} // optional, lets user choose to upload another file when import is complete
                onStart={({ file, fields }) => {
                    setExcelFields(fields);
                    // optional, invoked when user has mapped columns and started import
                }}
                processChunk={async (rows) => {
                    // required, receives a list of parsed objects based on defined fields and user column mapping;
                    // may be called several times if file is large
                    // (if this callback returns a promise, the widget will wait for it before parsing more data)
                    setImportedRows((v) => [...v, ...rows]);
                }}
                onComplete={({ file, fields }) => {
                    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                    setConfirmModalText(
                        `You are about to import ${importedRows.length} alumni's.`,
                    );
                    toggleHideDialog2();
                }}
                onClose={() => {
                    // optional, invoked when import is done and user clicked "Finish"
                    // (if this is not specified, the widget lets the user upload another file)
                }}
            >
                {fields.map((field) => {
                    return (
                        <ImporterField
                            key={field.key}
                            optional={!field.required}
                            name={field.key}
                            label={field.name}
                        />
                    );
                })}
            </Importer>

            <input
                type="checkbox"
                name="override"
                onClick={(e) => {
                    handleChange({
                        target: {
                            value: e.target.checked,
                            name: 'override',
                        },
                    });
                }}
                // checked={isChecked}
                value={isChecked}
                defaultChecked={true}
            />

            <span>Override Existing Data</span>

            <div className="upload-sample-csv">
                <span
                    onClick={() => {
                        window.location.href = '/Sample.csv';
                    }}
                >
                    Download sample csv
                </span>
            </div>

            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <UpdateModal text={modalText} onClick={updateChanges} />
            </Dialog>
            <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={updateChanges} />
            </Dialog>

            <Dialog
                hidden={hideDialog2}
                onDismiss={toggleHideDialog2}
                styles={dialogNewStyles}
            >
                <ConfirmModal
                    heading="Confirm"
                    text={confirmModalText}
                    onClick={confirmModalChanges}
                    onCancel={cancelImport}
                    buttonText={"Okay, Import it"}
                />
            </Dialog>
            <Dialog
                hidden={hideDialog3}
                onDismiss={toggleHideDialog3}
                styles={dialogNewStyles}
            >
                <ListErrorModal
                    text={errorArrayText}
                    onClick={updateChanges}
                />
            </Dialog>
        </div>
    );
};

export default Import;
