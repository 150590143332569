import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../views/AlumniFrontEnd/auth/Login/Login';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import Home from '../views/AlumniFrontEnd/Main/Home/Home';
import EditProfile from '../views/AlumniFrontEnd/Main/EditProfile/EditProfile';
import Messages from '../views/AlumniFrontEnd/Main/Messages/Messages';
import AllBatchMembers from '../views/AlumniFrontEnd/Main/BatchMembers/AllBatchMembers';
import MyAccount from '../views/AlumniFrontEnd/Main/MyAccount/MyAccount';
import OtherProfile from '../views/AlumniFrontEnd/Main/OtherProfile/OtherProfile';
import UpdatePassword from '../views/AlumniFrontEnd/Main/MyAccount/SetPassword';
import ContactUs from '../views/AlumniFrontEnd/Main/ContactUs/ContactUs';

function UserRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/login`} exact>
                <Login />
            </Route>
            <Route path={`${url}/home`} exact>
                <Home />
            </Route>
            <Route path={`${url}/profile`} exact>
                <EditProfile />
            </Route>
            <Route path={`${url}/messages`} exact>
                <Messages />
            </Route>
            <Route path={`${url}/batchmember`} exact>
                <AllBatchMembers />
            </Route>
            <Route path={`${url}/account`} exact>
                <MyAccount />
            </Route>
            <Route path={`${url}/updatePassword`} exact>
                <UpdatePassword />
            </Route>
             <Route path={`${url}/contactUs`} exact>
                <ContactUs />
            </Route>
            <Route path={`${url}/:id/profile`} exact component={OtherProfile} />
        </SwitchWithNotFound>
    );
}

export default UserRoutes;
