import React from 'react';
import DefaultScreen from '../../../common/Default/DefaultScreen';
import MainLayout from '../../../layouts/main/MainLayout';
import ListOfPost from './components/ListOfPost';
import Table from '../../../common/table/TableV3';

const ContentPage = () => {
    return (
        <MainLayout>
            {/* <DefaultScreen
                icon="../../../../images/content.svg"
                Heading="Content Management"
                Description="Content Management System lets you approve or reject posts by users. As an admin, you can view posts by users before any
                                other user can. If you feel a user post is inappropriate or violating the guidelines of your community"
                textbtn="Create New Post"
                textbtn2="Settings"
            /> */}
            {/* <Table
                        type={'post'}
                        name={'Post'}
                        tableName={'Posts'}
                        deleteIcon={true}
                        onItemClick={(item) => {
                            history.push(`/post/${item.id}`);
                        }}
                        onLikeItemClick={(item) => {
                            history.push(`/post/${item.id}/list`);
                        }}
                    />*/}
            <ListOfPost /> 
        </MainLayout>
    );
};

export default ContentPage;
