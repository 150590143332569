import React from 'react';
import classNames from 'classnames';
import AsideList from '../../../../../../../layouts/main/Aside/components/AsideList';

const AsideLinks = (props) => {
    const getClassNames = (defaultClass, tabname) => {
        let active = false;
        let url = window.location.pathname;

        if (tabname === 'home') {
            active = url === '/user' || url.includes('/user/home');
        }
        if (tabname === 'MyProfile') {
            active = url.includes('/user/profile');
        }
        if (tabname === 'MyAccount') {
            active = url.includes('/user/account');
        }
        if (tabname === 'MemberBatch') {
            active = url.includes('/user/batchmember');
        }
         if (tabname === 'ContactUs') {
            active = url.includes('/user/contactUs');
        }
        if (tabname === 'logout') {
            active = url.includes('/auth/login');
        }

        return classNames(defaultClass, { active: active });
    };

    return (
        <div className="links-card">
            <ul>
                <li className={getClassNames('nav-item', 'home')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../../../images/icons/home-active.svg'
                        }
                        inactive={
                            '../../../../../../images/icons/home-inactive.svg'
                        }
                        url="/user/home"
                        tabname="Home"
                    />
                </li>
                
                <li className={getClassNames('nav-item', 'MyProfile')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../../../images/icons/alumni-active.svg'
                        }
                        inactive={
                            '../../../../../../images/icons/alumni-inactive 1.svg'
                        }
                        url="/user/profile"
                        tabname="My Profile"
                    />
                </li>
                <li className={getClassNames('nav-item', 'MyAccount')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../../../images/icons/account-active.svg'
                        }
                        inactive={
                            '../../../../../../images/icons/account-inactive.svg'
                        }
                        url="/user/account"
                        tabname="My Account"
                    />
                </li>
                <li className={getClassNames('nav-item', 'MemberBatch')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../../../images/icons/member-active.svg'
                        }
                        inactive={
                            '../../../../../../images/icons/member-inactive.svg'
                        }
                        url="/user/batchmember"
                        tabname="Your Batchmates"
                    />
                </li>
                 <li className={getClassNames('nav-item', 'ContactUs')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../../../images/icons/contact-us-active.svg'
                        }
                        inactive={
                            '../../../../../../images/icons/contact-us.svg'
                        }
                        url="/user/contactUs"
                        tabname="Contact Us"
                    />
                </li>
                <li className={getClassNames('nav-item', 'logout')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../../../images/icons/logout-active.svg'
                        }
                        inactive={
                            '../../../../../../images/icons/logout-inactive.svg'
                        }
                        url="/auth/login"
                        tabname="Logout"
                    />
                </li>
            </ul>
        </div>
    );
};

export default AsideLinks;
