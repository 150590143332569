import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Pivot, PivotItem } from '@fluentui/react';
import Post from './Post';
import Table from '../../../../common/table/TableV3';
import { useHistory } from 'react-router-dom';
/*
// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        paddingTop: 50,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 50,
        float: 'left',
    },
};

const stackPivotmainStyles = {
    root: {
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomStyle: 'dashed',
        borderBottomColor: '#E0E0E0',
    },
};

const ListOfPost = () => {
    const history = useHistory();

    return (
        <Stack styles={stackStyles}>
            <Pivot
                aria-label="Basic Pivot Example"
                styles={stackPivotmainStyles}
            >
                <PivotItem
                    headerText="All Posts"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'Noticeboard',
                    }}
                >
                    <Table
                        type={'post'}
                        name={'Post'}
                        tableName={'Posts'}
                        deleteIcon={true}
                        onItemClick={(item) => {
                            history.push(`/post/${item.id}`);
                        }}
                        onLikeItemClick={(item) => {
                            history.push(`/post/${item.id}/list`);
                        }}
                    />
                </PivotItem>
                <PivotItem headerText="Active Posts">
                    <span
                        className="ms-fontSize-14 ms-fontWeight-regular"
                        style={{
                            color: '#424242',
                            display: 'block',
                            marginTop: 14,
                        }}
                    >
                        Create new user for the alumni portal. After creating
                        new user , you will be prompted to edit/update
                        additional information for that user
                    </span>
                </PivotItem>
            </Pivot>
        </Stack>
    );
};
*/


const ListOfPost = () => {
    const history = useHistory();

    return (
                    <Table
                        type={'post'}
                        name={'Post'}
                        tableName={'Posts'}
                        deleteIcon={true}
                        onItemClick={(item) => {
                            history.push(`/post/${item.id}`);
                        }}
                        onLikeItemClick={(item) => {
                            history.push(`/post/${item.id}/list`);
                        }}
                    />
    );
};

export default ListOfPost;
