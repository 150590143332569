import * as React from 'react';

const UnsubscribedSuccessful = () => {
    return (
        <div className="unsubscribe-box new">
            <div className="unsubscribe-content successful">
                <div className="for-unsubscribe-mobile">
                    <span>Unsubscribe successful</span>
                    <p>
                        You have successfully unsubscribed from the email list.
                        You will no longer receive email from selected.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UnsubscribedSuccessful;
