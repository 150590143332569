import React from 'react';
import Loader from 'react-js-loader';

const Initialising = (props) => {
    return <div className='no-records-intable'>
        <Loader
            type="bubble-scale"
            bgColor={'#2962FF'}
            color={'#2962FF'}
            size={40}
            
        />
    </div>;
};

export default Initialising;
