import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Pivot, PivotItem } from '@fluentui/react';
import Table from '../../../../common/table/TableV3';
import { useHistory } from 'react-router-dom';

const ListOfPoll = () => {
    const history = useHistory();
    return (
        <Table
            type={'poll'}
            name={'Poll'}
            tableName={'Polls'}
            deleteIcon={true}
            onItemClick={(item) => {
                history.push(`/poll/${item.id}/edit`);
            }}
            onVotesClick={(item) => {
                history.push(`/poll/${item.id}/list`);
            }}
        />
    );
};

export default ListOfPoll;
