import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AddRegistrationForm from '../views/main/RegistrationForm/AddRegistrationForm';
import ListRegistrationFormPage from '../views/main/RegistrationForm/ListRegistrationFormPage';

function RegistrationFormRoutes({ match: { url } }) {
   return (
        <SwitchWithNotFound>
            <Route path={`${url}/form`} exact component={AddRegistrationForm} />
            <Route path={`${url}/list`} exact component={ListRegistrationFormPage} />
        </SwitchWithNotFound>
    );
}

export default RegistrationFormRoutes
