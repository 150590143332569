import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import SchoolSettings from '../views/main/SchoolSettings/SchoolSettings1';

function SchoolSettingsRuote({ match: { url } }) {
    return (

        <SwitchWithNotFound>
            <Route path={`${url}/SchoolSettings`} exact component={SchoolSettings} />
        </SwitchWithNotFound>
    );
}

export default SchoolSettingsRuote;
