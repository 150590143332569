import React from 'react';
import { Route } from 'react-router-dom';
import ContentPage from '../views/main/Content/ContentPage';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AddContentPage from '../views/main/Content/AddContentPage';
import ListLikePage from '../views/main/Content/components/ListLikePage';
import UnsubscribeFromAlert from '../views/main/Content/components/UnsubscribeFromPost';
import UnsubscribeFromPost from '../views/main/Content/components/UnsubscribeFromPost';

function ContentRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}`} exact>
                <ContentPage />
            </Route>
            <Route path={`${url}/add`} exact component={AddContentPage} />
            <Route path={`${url}/:id`} exact component={AddContentPage} />
            <Route path={`${url}/:id/list`} exact component={ListLikePage} />
            <Route path={`${url}/:id/:type`} exact component={UnsubscribeFromPost} />
        </SwitchWithNotFound>
    );
}

export default ContentRoutes;
