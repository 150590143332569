import React, { useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useHistory } from 'react-router-dom';
import { CSVLink, CSVDownload } from 'react-csv';
import Api from '../../../../common/api/api';
import { useEffect } from 'react';
import ButtonWithLoader from '../../../../common/controls/ButtonWithLoader';
import ExportButtonWithLoader from '../../../../common/controls/ExportButtonWithLoader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../../../redux/store';
import { Route, useParams } from 'react-router-dom';

import getSchool from '../../../../helpers/getSchool';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#EEEEEE',
        height: 71,
        paddingLeft: 32,
        paddingRight: 32,
    },
};

const stackItemStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        childrenGap: 10,
    },
};

const stackDefaultStyles = {
    root: {
        height: 36,
        borderRadius: 4,
        background: 'transparent',
        color: '#2962FF',
        fontSize: 16,
        border: 1,
        borderColor: '#2962FF',
        borderStyle: 'solid',
        marginRight: 10,
        fontWeight: 'normal',
        width: 'auto',
        paddingTop: 1,
        paddingBottom: 1,
        fontfamily: 'Calibri',
    },
};

const stackNewDefaultStyles = {
    root: {
        height: 36,
        borderRadius: 4,
        background: '#2962FF',
        color: '#ffffff',
        fontSize: 16,
        borderColor: '#2962FF',
        borderStyle: 'solid',
        borderWidth: 1,
        fontWeight: 'normal',
        width: 'auto',
        paddingTop: 1,
        paddingBottom: 1,
        fontfamily: 'Calibri',
    },
};

const myComponentStyle = {
    color: '#2962FF',
    fontSize: 16,
};

const ListHeader = ({
    name,
    type,
    tableName,
    filters,
    allowExport,
    allowImport,
    TotalCount,
    school,
}) => {
    const history = useHistory();
    const { id } = useParams(); 

    return (
        <>
            <Stack horizontal styles={stackStyles}>
                <Stack.Item grow={12}>
                    {tableName === 'Alumni' ? (
                        <>
                            <p className="ms-fontSize-20 ms-fontWeight-bold line-height24 fontFamily darkLightColor ">
                                <span>{tableName}</span>
                                <span style={myComponentStyle}>
                                    {' '}
                                    ({TotalCount})
                                </span>
                            </p>
                        </>
                    ) : (
                        <>
                            <span className="ms-fontSize-20 ms-fontWeight-bold line-height24 fontFamily darkLightColor ">
                                {tableName}
                            </span>
                        </>
                    )}
                </Stack.Item>
                <Stack.Item grow={12} styles={stackItemStyles}>
                    <div className="import-buttons">
                        {allowExport && (
                            <a
                            className='export-anchor-tag'
                                href={`${process.env.REACT_APP_BACKEND_BASE_URL}/alumni/export/csv?school=${getSchool()}&token=${store.getState().app.token}&filters=${JSON.stringify(filters)}`}>
                                <span className='export-span'>Export</span>
                            </a>
                        )}

                        {allowImport && (
                            <DefaultButton
                                text="Import"
                                styles={stackDefaultStyles}
                                onClick={() => {
                                    history.push(`/${type}/import`);
                                }}
                            />
                        )}

                    </div>


                    {name !== 'PostLike' &&
                        name !== 'TotalVotes' &&
                        name !== 'UnSubscribe' &&
                        name !== 'Option Votes' &&
                        name !== 'Registration' && 
                        name!='Poll and Post Stats' &&
                        name !== 'Contact Us' &&
                        name !== 'audit' &&
                        name != 'Failed Emails' &&
                        name != 'Clicked Emails' &&
                        name != 'Opened Emails' &&
                        name != 'Bounced' && (
                            <PrimaryButton
                                onClick={() => {
                                    history.push(`/${type}/add`);
                                }}
                                text={`Add ${name}`}
                                styles={stackNewDefaultStyles}
                            />
                        )}
                        
                        {
                        tableName === 'Failed Emails' &&(
                            <a 
                                className='export-anchor-tag'
                                href={`${process.env.REACT_APP_BACKEND_BASE_URL}/campaign/email/export/Failed/CSV/${id}?school=${getSchool()}&token=${store.getState().app.token}`}>
                                <span className='export-span'>Export</span>
                            </a>
                        )}
                        {
                        tableName === 'Clicked Emails' &&(
                            <a 
                                className='export-anchor-tag'
                                href={`${process.env.REACT_APP_BACKEND_BASE_URL}/campaign/email/export/Clicks/CSV/${id}?school=${getSchool()}&token=${store.getState().app.token}`}>
                                <span className='export-span'>Export</span>
                            </a>
                        )}
                        {
                        tableName === 'Opened Emails' &&(
                            <a 
                                className='export-anchor-tag'
                                href={`${process.env.REACT_APP_BACKEND_BASE_URL}/campaign/email/export/Opened/CSV/${id}?school=${getSchool()}&token=${store.getState().app.token}`}>
                                <span className='export-span'>Export</span>
                            </a>
                        )}
                         {
                        tableName === 'UnSubscribed Users' &&(
                            <a 
                                className='export-anchor-tag'
                                href={`${process.env.REACT_APP_BACKEND_BASE_URL}/campaign/email/export/Unsubscribes/CSV/${id}?school=${getSchool()}&token=${store.getState().app.token}`}>
                                <span className='export-span'>Export</span>
                            </a>
                        )}
                          {
                        tableName === 'Bounced Emails' &&(
                            <a 
                                className='export-anchor-tag'
                                href={`${process.env.REACT_APP_BACKEND_BASE_URL}/campaign/email/export/Bounceback/CSV/${id}?school=${getSchool()}&token=${store.getState().app.token}`}>
                                <span className='export-span'>Export</span>
                            </a>
                        )}
                </Stack.Item>
            </Stack>
        </>
    );
};

const mapStateToProps = (state) => ({
    school: state.app.school,
});

export default withRouter(connect(mapStateToProps)(ListHeader));
