import * as axios from 'axios';
import store from '../../redux/store';
import getSchool from '../../helpers/getSchool';

export default class Api {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_BACKEND_BASE_URL;
        // this.api_url = "https://dev.api.almacircle.com" ;
    }

    static getInstance() {
        return new Api();
    }

    init(authorize = true, isMultiPart = false) {
        //    this.api_token = localStorage.getItem('token');

        const headers = {
            Accept: 'application/json',
        };

        if (isMultiPart) {
            headers['Content-Type'] = 'multipart/form-data';
        }

        if (store.getState().app.token && authorize) {
            headers.Authorization = `Bearer ${store.getState().app.token}`;
        }

        headers['School'] = getSchool();

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 310000,
            headers,
        });

        return this.client;
    }

    post(url, data, authorize = true, isMultiPart = false) {
        return new Promise((resolve, reject) => {
            this.init(authorize, isMultiPart)
                .post(url, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e.response ? e.response.data : {});
                });
        });
    }

    get(url, params, authorize = true) {
        return new Promise((resolve, reject) => {
            this.init(authorize)
                .get(url, { params })
                .then((response) => {
                    if (response.status) resolve(response.data);
                })
                .catch((e) => {
                    reject(e.response ? e.response.data : {});
                });
        });
    }

    delete(url) {
        return new Promise((resolve, reject) => {
            this.init()
                .delete(url)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e.response ? e.response.data : {});
                });
        });
    }

    login(params) {
        return this.post('/users/login', params, false);
    }

    forgotPassword(params) {
        return this.post('/users/password/forgot', params, false);
    }

    resetPassword(params) {
        return this.post('/users/password/reset', params, false);
    }

    changePassword(params) {
        return this.post('/users/password/change', params, true);
    }

    setPassword(params) {
        return this.post('/users/setpassword/change', params, true);
    }

    updateUserProfile(params) {
        return this.post('/users/profile', params, true);
    }

    createAlumni(params) {
        return this.post('/alumni', { params });
    }

    createStaff(params) {
        return this.post('/staff', params);
    }

    updateAlumni(id, params) {
        return this.post(`/alumni/${id}`, params);
    }

    updateStaff(id, params) {
        return this.post(`/staff/${id}`, params);
    }

    createCategory(id, params) {
        if (id) {
            return this.post(`/category/${id}`, params);
        }
        return this.post(`/category`, params);
    }

    getCategories() {
        return this.get(`/category`);
    }

    getTemplates() {
        return this.get(`/template`);
    }

    getCategory(id) {
        return this.get(`/category/${id}`);
    }

    getCategoriesBySchool(schoolId, id) {
        return this.get(`/category/BySchool/${schoolId}/${id}`, null, false);
    }

    createBatch(id, params) {
        if (id) {
            return this.post(`/batch/${id}`, params);
        }
        return this.post('/batch', params);
    }

    createConatctUsForm(id, params) {
        if (id) {
            return this.post(`/contactUs/${id}`, params);
        }
        return this.post('/contactUs', params);
    }

    createRegistrationForm(params, school) {
        return this.post('/registration/form', { params, school });
    }

    getBatchesForRegistrationForm(school) {
        return this.post('/registration/form/batch', { school });
    }

    rejectAlumniRequest(params) {
        return this.post('/registration/form/reject', params);
    }

    solveQuery(params) {
        return this.post('/contactUs/solve', params);
    }

    checkForExistingUsername(username, school) {
        return this.post('/registration/form/username', { username, school });
    }

    checkForExistingEmail(email, school) {
        return this.post('/registration/form/email', { email, school });
    }

    approveAlumniRequest(params) {
        return this.post('/registration/form/approve', params);
    }

    createList(id, params) {
        if (id) {
            return this.post(`/lists/${id}`, params);
        }
        return this.post('/lists', params);
    }

    createPost(params) {
        if (params.id) {
            return this.post(`/post/${params.id}`, params);
        }
        return this.post('/post', params);
    }

    createPoll(params) {
        if (params.id) {
            return this.post(`/poll/${params.id}`, params);
        }
        return this.post('/poll', params);
    }

    createField(id, params) {
        if (id) {
            return this.post(`/field/${id}`, params);
        }
        return this.post('/field', params);
    }

    getBatches() {
        return this.get('/batch');
    }

    getPosts() {
        return this.get('/post');
    }

    getFeed() {
        return this.get('/feed');
    }

    savePollVotes(id, value) {
        return this.post(`/poll/pollVotes/${id}`, { value });
    }

    getSubmittedPolls(id) {
        return this.get(`/poll/submitted/${id}`);
    }

    getPollDaysLeft(id) {
        return this.get(`/poll/daysLeft/${id}`);
    }

    getPost(id) {
        return this.get(`/post/${id}`);
    }

    getPoll(id) {
        return this.get(`/poll/${id}`);
    }

    likePost(id, action) {
        return this.post(`/posts/${id}/like`, { action });
    }

    getPostComments(id) {
        return this.get(`/posts/${id}/comments`);
    }

    getBatch(id) {
        return this.get(`/batch/${id}`);
    }

    getList(id) {
        return this.get(`/lists/${id}`);
    }

    deleteItems(type, ids) {
        return this.post(`/${type}/delete`, { ids });
    }

    getFields() {
        return this.get('/field');
    }

    getField(id) {
        return this.get(`/field/${id}`);
    }

    updateCustomFields(id, params) {
        return this.post(`/alumni/${id}/custom`, params);
    }

    createEmailCampaign(campaign) {
        if (campaign.id) {
            return this.post(`/email/campaign/${campaign.id}`, campaign);
        }
        return this.post(`/email/campaign`, campaign);
    }

    createEmailTemplate(input) {
        if (input.id) {
            return this.post(`/template/${input.id}`, input);
        }
        return this.post(`/template`, input);
    }

    sendEmailCampaign(id) {
        return this.post(`/email/campaign/${id}/send`);
    }

    unSubscribe(id, campaignId, categoryId) {
        return this.post(
            `/email/campaign/unsubscribe/${id}/${campaignId}/${categoryId}`,
        );
    }

    unSubscribeFromPost(id, type) {
        return this.post(
            `/post/unsubscribe/${id}/${type}`,
        );
    }

    removeUnSubscribeUserFromPost(id, type) {
        return this.post(
            `/post/remove/unsubscribed/user/${id}/${type}`,
        );
    }

    unSubscribeFromPoll(id, type) {
        return this.post(
            `/poll/unsubscribe/${id}/${type}`,
        );
    }

    publishPost(id) {
        return this.post(`/post/${id}/publish`);
    }

    publishPoll(id) {
        return this.post(`/poll/${id}/publish`);
    }

    getEmailCampaign(id) {
        return this.get(`/email/campaign/${id}`);
    }

    getEmailTemplate(id) {
        return this.get(`/template/${id}`);
    }

    updateWorkExperience(id, params) {
        return this.post(`/alumni/${id}/work`, params);
    }

    getProfile() {
        return this.get('/users/profile');
    }

    getAdminProfile() {
        return this.get('/users/profile/admin');
    }

    getColumnDefinitions(key) {
        return this.get(`/${key}/table/columns`);
    }

    getUnSubscribeUsersColumnDefinitions(key) {
        return this.get(`/campaign/email/table/columns/UnSubscribe`);
    }

    getBouncedUsersColumnDefinitions(key) {
        return this.get(`/campaign/email/table/columns/bouncebacks`);
    }

    getFailedColumnDefinitions(key) {
        return this.get(`/campaign/email/table/columns/failed`);
    }

    getClickedColumnDefinitions(key) {
        return this.get(`/campaign/email/table/columns/clicked`);
    }

    getOpenedColumnDefinitions(key) {
        return this.get(`/campaign/email/table/columns/opened`);
    }

    getVotesForOptionColumnDefinitions(key) {
        return this.get(`/poll/table/columns/option`);
    }

    getImportFields(key) {
        return this.get(`/${key}/import/fields`);
    }

    getExportFields(key) {
        return this.get(`/${key}/export/fields`);
    }

    getExportData(key) {
        return this.get(`/${key}/export/export_csv`);
    }

    importData(key, rows, fields, checked) {
        return this.post(`/${key}/import`, { rows, fields, checked });
    }

    getRows(key, filters, limit = 0, offset = 0) {
        return this.get(`/${key}/table/rows`, { filters, limit, offset });
    }

    getRowsBasedOnStatus(key, status) {
        return this.get(`/${key}/table/rowsByStatus/${status}`);
    }

    getRowCount(key, filters) {
        return this.get(`/${key}/table/rows/count`, { filters });
    }

    getLikeRows(key, id, filters) {
        return this.get(`/${key}/table/rows/${id}`, { filters });
    }

    getTotalVotes(key, id, filters) {
        return this.get(`/${key}/table/rows/${id}`, { filters });
    }

    getUnsubscribeUsers(key, id, filters) {
        return this.get(`/${key}/table/rows/${id}/UnSubscribe`, { filters });
    }

    getBouncedUsers(key, id, filters) {
        return this.get(`/${key}/table/rows/${id}/bouncebacks`, { filters });
    }

    getFailedEmails(key, id, filters) {
        return this.get(`/${key}/table/rows/${id}/failed`, { filters });
    }

    getClickedEmails(key, id, filters) {
        return this.get(`/${key}/table/rows/${id}/clicked`, { filters });
    }

    getOpenedEmails(key, id, filters) {
        return this.get(`/${key}/table/rows/${id}/opened`, { filters });
    }
    getVotesForOption(key, id, filters) {
        return this.get(`/${key}/table/rows/${id}/option`, { filters });
    }

    getAlumniFilterOptions() {
        return this.get(`/alumni/filters`);
    }

    getAlumni(id) {
        return this.get(`/alumni/${id}`);
    }

    getAlumniProfile() {
        return this.get(`/alumni/profile`);
    }

    getBatchMates(limit = '') {
        return this.get(`/alumni/batchmates`, { limit });
    }

    getStaff(id) {
        return this.get(`/staff/${id}`);
    }

    getSchools() {
        return this.get('/schools');
    }

    getSchool(id) {
        return this.get(`/schools/${id}`);
    }

    updateSchool(id, params) {
        return this.post(`/schools/${id}`, params);
    }

    updateSchoolWithEmailCampaign(id, params) {
        return this.post(`/schools/email/${id}`, params);
    }

     updateSchoolWithTransactional(id, params) {
        return this.post(`/schools/transactional/${id}`, params);
    }

    updateEducation({ id, ...params }) {
        if (id) {
            return this.post(`/alumni/education/${id}`, params);
        }
        return this.post(`/alumni/education`, params);
    }

    updateSocialLinks(id, ...params) {
        if (id) {
            return this.post(`/alumni/socialLinks/${id}`, params);
        }
        return this.post(`/alumni/socialLinks`, params);
    }

    updatePersonalInformation({ ...params }) {
        return this.post(`/alumni/personal`, params);
    }

    deleteEducation(id) {
        return this.delete(`/alumni/education/${id}`);
    }

    updateSingleWorkExperience({ id, ...params }) {
        if (id) {
            return this.post(`/alumni/work/${id}`, params);
        }
        return this.post(`/alumni/work`, params);
    }

    deleteWorkExperience(id) {
        return this.delete(`/alumni/work/${id}`);
    }

    uploadFile(data, file, storeLocally = false) {
        const formData = new FormData();
        if (data) {
            Object.keys(data).forEach((k) => {
                formData.append(k, data[k]);
            });
        }
        formData.append('file', file);
        if (storeLocally) {
            formData.append('storeLocally', storeLocally);
        }
        return this.post('/media/upload', formData, true, true);
    }

    getImageUrl(key, storeLocally = false) {
        if (!key) {
            return null;
        }
        if (storeLocally) {
            return `${process.env.REACT_APP_BACKEND_BASE_URL}/${key}`;
        }
        return `https://app.almacircle.com/${key}`;
    }
}
