import React from 'react';

const PollProgress = (props) => {
    const { bgcolor, completed, title } = props;

    const mainContainer = {
        width: '100%',
        float: 'left'
    };

    const containerStyles = {
        display: 'flex',
        marginBottom: 5,
    };

    const fillerStyles = {
        width: '100% ',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        overFlow: 'hidden'
    };

    const labelStyles = {
        width: `${completed}%`,
        backgroundColor: `${bgcolor}`,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        minHeight:40,
        borderRadius:4
    };
    const spanstyle = {
        display:'flex',
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:0,
        marginBottom:0,
        position:'absolute'
    };
    const spancontent = {
        color: 'rgba(14, 14, 14, 0.9)',
        fontSize: 15,
        fontWeight: 'bold',
        margin:0,
        paddingLeft:10
    };

    const progressStyles = {
        color: 'rgba(14, 14, 14, 0.9)',
        fontSize: 15,
        fontWeight: 'bold',
        margin: 0,
        paddingRight: 10,
        paddingLeft: 10,
    };

   
    const progresspercentage = {
        display:'flex',
        alignItems: 'center',
        marginLeft:8
    };

    return (
        <>
            <div style={containerStyles}>
                <div style={fillerStyles}>
                    <div style={labelStyles}>
                        <div style={spanstyle}>
                            <span style={spancontent} >{title}</span>
                        </div>
                    </div>
                </div>
                <div style={progresspercentage}>
                    <p style={progressStyles}>{`${completed}%`}</p>
                </div>
            </div>

        </>
    );
};

export default PollProgress;