import * as React from 'react';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';

const RadioButtons = ({ options, onChange, value }) => {
    return (
        <ChoiceGroup
            defaultSelectedKey={value}
            options={options}
            onChange={onChange}
            required={false}
        />
    );
};

function _onChange(ev, option) {
    console.dir(option);
}

export default RadioButtons;
