import React, { useEffect, useState } from 'react';
import AuthLayout from '../../../layouts/auth/AuthLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import { Link } from 'react-router-dom';
import Api from '../../../common/api/api';
import { connect } from 'react-redux';
import DefaultInput from '../../../common/controls/DefaultInput';
import ButtonWithLoader from '../../../common/controls/ButtonWithLoader';
import ErrorModal from '../../../common/modals/ErrorModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import config from '../../../config/config';
import getSchool from '../../../helpers/getSchool';

const stackStyles = {
    root: {
        width: 280,
        flexDirection: 'column',
    },
};

const stackItemStyles = {
    root: {
        marginTop: 12,
    },
};

const btnstackItemStyles = {
    root: {
        marginTop: 12,
    },
};

const forgotstackItemStyles = {
    root: {
        marginTop: 18,
    },
};

const questionstackItemStyles = {
    root: {
        marginTop: 12,
        textAlign: 'center',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const LoginPage = ({ dispatchLogin, dispatchLogout, props }) => {
    // Password hide show
    const [passwordShown, setPasswordShown] = useState(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [errorText, setErrorText] = useState('');

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        dispatchLogout();
    }, []);

    const updateChanges = () => {
        toggleHideDialog();
    };

    const validate = () => {
        let e = {};
        if (!form.email) {
            e.email = 'email is required';
        }
        if (!form.password) {
            e.password = 'Password is required';
        }
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const [form, setForm] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleFormChange = (e) => {
        let _form = { ...form };
        _form[e.target.name] = e.target.value;
        setForm(_form);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }

        setLoading(true);
        setErrors({});

        Api.getInstance()
            .login(form)
            .then((response) => {
                dispatchLogin(response);
                Api.getInstance()
                    // get school info for default password(dp)
                    .get('/schools')
                    .then((resp) => {
                        if (
                            resp[0].type == 'alumni' &&
                            response.user.password_change == false
                        ) {
                            window.location.href = '/user/updatePassword';
                        }
                        // else if (resp[0].type == "staff") {
                        //     window.location.href = '/user/home';
                        // }
                        else {
                            window.location.href = '/auth/schools';
                        }
                    });
            })
            .catch((e) => {
                if (e.error) {
                    setErrors(e);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <AuthLayout>
            <div className="login-heading">
                <span className="ms-fontSize-24 ms-fontWeight-bold darkColor fontFamily">
                    Signin
                </span>
            </div>
            <form onSubmit={handleSubmit}>
                <Stack horizontal styles={stackStyles}>
                    <Stack.Item styles={stackItemStyles}>
                        <DefaultInput
                            onChange={handleFormChange}
                            type="email"
                            name={'email'}
                            value={form.email}
                            placeholder="Email"
                            icon="../../../../images/email-icon.svg"
                            error={errors.email ? 'Email is required' : ''}
                            className={
                                errors.email
                                    ? 'default-input error'
                                    : 'default-input'
                            }
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <DefaultInput
                            onChange={handleFormChange}
                            type={passwordShown ? 'text' : 'password'}
                            name={'password'}
                            value={form.password}
                            placeholder="Password"
                            icon="../../../../images/password-icon.svg"
                            onClick={togglePasswordVisibility}
                            error={
                                errors.password
                            }
                            righticon={
                                passwordShown
                                    ? '../../../../images/view.svg'
                                    : '../../../../images/view-hide.svg'
                            }
                            className={
                                errors.password
                                    ? 'default-input error'
                                    : 'default-input'
                            }
                        />
                    </Stack.Item>
                    <Stack.Item>
                        {errors.error && (
                            <span className="combine-error">
                                {errors.error}
                            </span>
                        )}
                    </Stack.Item>
                    <Stack.Item styles={forgotstackItemStyles}>
                        <span className="ms-fontSize-14 ms-fontWeight-regular darkColor fontFamily line-height20">
                            Forgot password?
                        </span>
                        <Link
                            to="/auth/forgot"
                            className="ms-fontSize-14 ms-fontWeight-regular blueColor fontFamily line-height20"
                            style={{ marginLeft: 3 }}
                        >
                            Click here
                        </Link>
                    </Stack.Item>

                    <Stack.Item styles={btnstackItemStyles}>
                        {/* <ButtonWithBackground
                            type={'submit'}
                            text="Sign In"
                            loading={loading}
                            onClick={handleSubmit}
                        /> */}
                        <div className="login-buton">
                            <ButtonWithLoader
                                name="Sign In"
                                loading={loading}
                                onClick={handleSubmit}
                            />
                        </div>
                    </Stack.Item>

                    <Stack.Item styles={questionstackItemStyles}>
                        <span className="ms-fontSize-14 ms-fontWeight-regular lightColor fontFamily line-height20">
                            Questions? Email us at{' '}
                        </span>
                        <a
                            href={`mailto:${config[getSchool()].supportEmail}`}
                            className="ms-fontSize-14 ms-fontWeight-regular blueColor fontFamily line-height20"
                            style={{ marginLeft: 1 }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {config[getSchool()].supportEmail}
                        </a>
                    </Stack.Item>
                </Stack>
            </form>
        </AuthLayout>
    );
};

const mapStateToProps = (state) => ({
    user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchLogin: (data) =>
        dispatch({
            type: 'AUTH_LOGIN',
            data: data,
        }),
    dispatchLogout: () =>
        dispatch({
            type: 'AUTH_LOGOUT',
        }),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
