import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import ListBatchPage from '../views/main/Batch/ListBatchPage';
import AddBatchPage from '../views/main/Batch/AddBatchPage';

function BatchRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/list`} exact component={ListBatchPage} />
            <Route path={`${url}/add`} exact component={AddBatchPage} />
            <Route path={`${url}/:id/edit`} exact component={AddBatchPage} />
        </SwitchWithNotFound>
    );
}

export default BatchRoutes;
