import React from 'react';
import MainLayout from '../../Components/Layout/MainLayout';
import BatchMemberItems from './Components/BatchMemberItems';

const AllBatchMembers = () => {
    return (
        <MainLayout>
            <BatchMemberItems />
        </MainLayout>
    );
};

export default AllBatchMembers;
