import React from 'react';
import MainLayout from '../../Components/Layout/MainLayout';
import HomeItems from './components/HomeItems';

const Home = () => {
    return (
        <MainLayout>
            <HomeItems />
        </MainLayout>
    );
};
export default Home;
