import React, { useEffect, useState } from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import Api from '../../../common/api/api';
import { useHistory, useParams } from 'react-router-dom';
import InputWithLabel from '../../../common/controls/InputWithLabel';
import Loader from 'react-js-loader';
import { DefaultButton } from '@fluentui/react/lib/Button';
import ErrorModal from '../../../common/modals/ErrorModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';

// Styles definition

const stackStyles = {
    root: {
        padding: 30,
        width: '100%',
        float: 'left',
    },
};

const stackItemStyles = {
    root: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 30,
        border: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        borderRadius: 8,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const dialogNewStyles = {
    main: {
        
        width: '100% !important',
    },
};

const AddBatchPage = () => {
    const [input, setInput] = useState({ name: '' });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [errorText, setErrorText] = useState("");

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

      const updateChanges = () => {
        toggleHideDialog();
    };

    const { id } = useParams();
    const history = useHistory();

    const validate = () => {
        let fields = [
            {
                label: window.location.host === "banyantreedel.almacircle.com" ? 'Last class studied' : 'Batch Name',
                key: 'name',
                required: true,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.required && input[field.key].trim() === '') {
                e[field.key] = `${field.label} is required`;
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);
        Api.getInstance()
            .createBatch(id, input)
            .then((response) => {
                history.push('/batch/list');
            })
            .catch((e) => {
                setErrorText(e.error);
                toggleHideDialog();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (id) {
            Api.getInstance()
                .getBatch(id)
                .then(({ custom_data, work_experience, ...response }) => {
                    setInput({ ...response });
                })
                .catch(console.log);
        }
    }, [id]);

    return (
        <MainLayout>
            <Stack styles={stackStyles}>
                <Stack.Item styles={stackItemStyles}>
                    <Stack.Item>
                        {window.location.host === "banyantreedel.almacircle.com" ? (
                                      <span className="ms-fontSize-24 ms-fontWeight-regular fontFamily line-height150 darkColor">
                                      {id ? 'Edit Last class studied' : 'Add Last class studied'}
                                  </span> ) :   <span className="ms-fontSize-24 ms-fontWeight-regular fontFamily line-height150 darkColor">
                                    {id ? 'Edit Batch' : 'Add Batch'}
                                </span>}
                    </Stack.Item>
                    <Stack.Item>
                        <Stack>
                            <div
                                className="add-user-form "
                                style={{ marginTop: 13 }}
                            >
                                <div
                                    className="form-group"
                                    style={{ width: '100%' }}
                                >
                                    <InputWithLabel
                                        //label="Batch Name"
                                        label = {window.location.host === "banyantreedel.almacircle.com" ? ('Last class studied'):'Batch'}
                                        value={input.name}
                                        onChange={handleChange}
                                        name="name"
                                        type="text"
                                        errorMessage={errors.name}
                                        required={true}
                                        estric="*"
                                    />
                                </div>

                                <Stack styles={stackNewStyles}>
                                    <DefaultButton
                                        onClick={() => {
                                            history.push('/batch/list');
                                        }}
                                        text="Cancel"
                                        styles={stackDefaultStyles}
                                    />
                                    <div className="next-button">
                                        <button
                                            loading={loading}
                                            onClick={handleSubmit}
                                            // style={{ minWidth: 90 }}
                                        >
                                            {loading ? (
                                                <Loader
                                                    type="bubble-scale"
                                                    bgColor={'#FFFFFF'}
                                                    color={'#FFFFFF'}
                                                    size={40}
                                                />
                                            ) : (
                                                <span>Save</span>
                                            )}
                                        </button>
                                    </div>
                                </Stack>
                            </div>
                        </Stack>
                    </Stack.Item>
                </Stack.Item>
            </Stack>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <ErrorModal 
                text={errorText}
                onClick={updateChanges} 
                />
            </Dialog>
        </MainLayout>
    );
};

export default AddBatchPage;
