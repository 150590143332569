import React, { useEffect } from 'react';
import Api from '../../../common/api/api';
import LoadingLayout from '../../../layouts/Loading/LoadingLayout';

const HomePage = () => {
    useEffect(() => {
        Api.getInstance()
            .getProfile()
            .then((response) => {
                if (response.school_users[0].type === 'alumni') {
                    window.location.href = '/user/home';
                } else {
                    window.location.href = '/dashboard';
                }
            })
            .catch(() => {
                window.location.href = '/auth/login';
            })
            .finally(() => {});
    }, []);

    return <LoadingLayout>Loading...</LoadingLayout>;
};

export default HomePage;
