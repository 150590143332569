import history from './history';
import configureStore from './configureStore';

// Create redux store with history
let serialisedUser = localStorage.getItem('user') || '{}';
if (serialisedUser === 'undefined') {
    serialisedUser = '{}';
}
// Create redux store with history
let serialisedAlumni = localStorage.getItem('alumni') || '{}';
if (serialisedAlumni === 'undefined') {
    serialisedAlumni = '{}';
}
// Create redux store with history
let serialisedSchool = localStorage.getItem('school') || '{}';
if (serialisedSchool === 'undefined') {
    serialisedSchool = '{}';
}
const initialState = {
    app: {
        token: localStorage.getItem('token'),
        user: JSON.parse(serialisedUser),
        alumni: JSON.parse(serialisedAlumni),
        school: JSON.parse(serialisedSchool),
        school_id: localStorage.getItem('school_id'),
    },
};
const store = configureStore(initialState, history);
export default store;
