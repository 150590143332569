import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Table from '../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 34,
    },
};

const ListBatchPage = () => {
    const history = useHistory();
    return (
        <MainLayout>
            <Table
                type={'batch'}
                // name={'Batch'}
                // tableName={'Batches'}
                name={window.location.host === "banyantreedel.almacircle.com" ? 'Last class studied' : 'Batch'}
                tableName={window.location.host === "banyantreedel.almacircle.com" ? 'Last class studied' : 'Batches'}
                deleteIcon={true}
                onItemClick={(item) => {
                    history.push(`/batch/${item.id}/edit`);
                }}
            />
        </MainLayout>
    );
};

export default ListBatchPage;
