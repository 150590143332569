import React, { useState } from 'react';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import PersonalInfoModal from '../../../../../Components/Modal/PersonalInfoModal';
import Api from '../../../../../../../common/api/api';
import InputWithLabel from '../../../../../../../common/controls/InputWithLabel';
import EducationDetailEditModal from '../../../../../Components/Modal/EducationDetailEditModal';
import SocialLinkModal from '../../../../../Components/Modal/SocialLinkModal';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '620px !important',
    },
};

const SocialLink = ({ socialLink, refresh }) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const handleChange = (e) => {};

    const onAdd = (e) => {
        setSelectedRecord({
            facebook: '',
            linkedin: '',
            twitter: '',
            instagram: '',
        });
        toggleHideDialog();
    };

    const actuallyUpdate = (socialLinks) => {
        toggleHideDialog();
        Api.getInstance()
            .updateSocialLinks(socialLink[0].id, { socialLinks })
            .then(refresh)
            .catch(console.log);
    };

    return (
        <div className="branch-member-card">
            <div className="education-heading">
                <span>Social Profile</span>
                <img
                    src="../../../../../../../images/add-new-icon.svg"
                    alt="Icon"
                    onClick={onAdd}
                />
            </div>
            {socialLink.map((item, index) => {
                return (
                    <div className="education-list" key={`${index}`}>
                        {/* Loop Div */}
                        {item.facebook.trim() !== '' && (
                            <>
                                <div className="member-list-col-new">
                                    <div className="col-left">
                                        <div className="member-info-new-details-with-icon">
                                            <img
                                                className='social-icon'
                                                src="../../../../../../../images/icons/facebook.svg"
                                                alt="Icon"
                                            />
                                            <a href={item.facebook} target="_blank" rel="noreferrer">
                                                {item.facebook}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {item.twitter.trim() !== '' && (
                            <>
                                <div className="member-list-col-new">
                                    <div className="col-left">
                                        <div className="member-info-new-details-with-icon">
                                            <img
                                                className='social-icon'
                                                src="../../../../../../../images/icons/twitter.svg"
                                                alt="Icon"
                                            />
                                           <a href={item.twitter} target="_blank" rel="noreferrer">
                                                {item.twitter}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* Loop Div */}
                        {item.linkedin.trim() !== '' && (
                            <>
                                <div className="member-list-col-new">
                                    <div className="col-left">
                                        <div className="member-info-new-details-with-icon">
                                            <img
                                                className='social-icon'
                                                src="../../../../../../../images/icons/linkedin.svg"
                                                alt="Icon"
                                            />
                                            <a href={item.linkedin} target="_blank" rel="noreferrer">
                                                {item.linkedin}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {item.instagram.trim() !== '' && (
                            <>
                                <div className="member-list-col-new">
                                    <div className="col-left">
                                        <div className="member-info-new-details-with-icon">
                                            <img
                                                className='social-icon'
                                                src="../../../../../../../images/icons/instagram.svg"
                                                alt="Icon"
                                            />
                                            <a href={item.instagram} target="_blank" rel="noreferrer">
                                                {item.instagram}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogStyles}
            >
                <SocialLinkModal
                    socialLink={socialLink}
                    onUpdate={actuallyUpdate}
                    onCancel={toggleHideDialog}
                />
            </Dialog>
        </div>
    );
};

export default SocialLink;
