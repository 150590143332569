import React, { useEffect, useState } from 'react';
import Api from '../api/api';
import { Dialog } from '@fluentui/react';
import ErrorModal from './ErrorModal';
import { useBoolean } from '@fluentui/react-hooks';
import Loader from 'react-js-loader';
import ButtonWithLoader from '../controls/ButtonWithLoader';

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const RemarksModal = (props) => {

    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);
    
    const [remarks, setRemarks] = useState('');
    const handleChange = (event) => {
        setRemarks(event.target.value);
    };

    const onReject = () => {
        
        props.item.remarks=remarks;
        setLoading(true);
        Api.getInstance()
            .rejectAlumniRequest(props.item)
            .then((response) => {
            })
            .catch((e) => {
                setErrorText(e.error);
                toggleHideDialog2();
            })
            .finally(() => {
                setLoading(false);
                window.location.reload();
            });
    };

    return (
        <div className="manage-column">
            <div className="modal-heading">
                <span>Rejection Alumni Request</span>
            </div>
            <div className="delete-columns" style={{paddingTop:15,width:'100%',float:'left'}}>
                <textarea
                    id="reason"
                    name="reason"
                    placeholder="Write your reason here.."
                    rows="4"
                    onChange={handleChange}
                    style={{width:'100%', paddingTop:10,textIndent:10}}
                />
                <div className="column-buttons">
                    <button onClick={props.onCancel} className="cancel-btn">
                        No,cancel it
                    </button>
                    <ButtonWithLoader name='Save Reason' onClick={onReject} loeading={loading} />
                </div>
            </div>
        </div>
        // {/* <Dialog
        //         hidden={hideDialog2}
        //         onDismiss={toggleHideDialog2}
        //         styles={dialogNewStyles}
        //     >
        //         <ErrorModal text={errorText} onClick={toggleHideDialog2} />
        //     </Dialog>
        // </> */}
    );
};

export default RemarksModal;
