import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../../../../common/api/api';
import Loader from 'react-js-loader';
import { useHistory } from 'react-router-dom';

const UnsubscribeFromPost = () => {
    let { id, type } = useParams();
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const handleSubmit = () => {
        setLoading(true);
        {
            Api.getInstance()
                .unSubscribeFromPost(id, type)
                .then((response) => {
                    history.push('/campaign/unsubscribedSuccessfully');
                });
        }
    };

    return (
        <div className="unsubscribe-box">
            <div className="unsubscribe-content">
                <div className="for-unsubscribe-mobile">
                    <span>Thinking of unsubscribing?</span>
                </div>
                {/* form */}
                <div className="unsubscribe-form">
                    <div className="unsubsscri-button">
                        <button onClick={handleSubmit}>Unsubscribe</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnsubscribeFromPost;
