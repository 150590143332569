import React, { useState } from 'react';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import DeleteModal from '../../../../../../../common/modals/DeleteModal';
import { Months } from '../../../../../../../common/months';
import Api from '../../../../../../../common/api/api';
import WorkExperienceDetailEditModal from '../../../../../Components/Modal/WorkExperienceDetailEditModal';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '620px !important',
    },
};

const dialogNewStyles = {
   main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px'
    },
};

const EducationDetails = ({ work_experience, refresh }) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [modalText, setModalText] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);

    const onEdit = (we) => {
        setSelectedRecord(we);
        toggleHideDialog();
    };

    const onDelete = (we) => {
        setModalText(`Experience`)
        setSelectedRecord(we);
        toggleHideDialog1();
    };

    const onAdd = (index) => {
        setSelectedRecord({
            company: '',
            position: '',
            from_month: '',
            from_year: '',
            to_month: '',
            to_year: '',
            currentlyWorking: false,
        });
        toggleHideDialog();
    };

    const actuallyDelete = () => {
        toggleHideDialog1();
        Api.getInstance()
            .deleteWorkExperience(selectedRecord.id)
            .then(refresh)
            .catch(console.log);
    };

    const actuallyUpdate = (updatedEducation) => {
        toggleHideDialog();
        Api.getInstance()
            .updateSingleWorkExperience(updatedEducation)
            .then(refresh)
            .catch(console.log);
    };

    const workExperienceToShow = work_experience.filter(
        (item) => item.from_month,
    );
    return (
        <div className="branch-member-card">
            <div className="education-heading">
                <span>Work Experience</span>
                <img
                    src="../../../../../../../images/add-new-icon.svg"
                    alt="Icon"
                    onClick={onAdd}
                />
            </div>
            <div className="education-list">
                {workExperienceToShow.length === 0 && (
                    <div className="member-list-col-new">
                        No employment history found.
                    </div>
                )}
                {/* Loop Div */}
                {workExperienceToShow.map((item, index) => {
                    return (
                        <div
                            className="member-list-col-new"
                            key={`work_experience-${index}`}
                        >
                            <div
                                className="col-left"
                                onClick={() => {
                                    onEdit(workExperienceToShow[index]);
                                }}
                            >
                                <div className="member-info-new-details">
                                    <h4>{item.company}</h4>
                                    <span>{item.position}</span>
                                    <p>
                                        {Months[item.from_month]}{' '}
                                        {item.from_year} -{' '}
                                        {item.currentlyWorking
                                            ? 'Present'
                                            : `${Months[item.to_month]} ${
                                                  item.to_year
                                              }`}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="col-right"
                                onClick={() => {
                                    onDelete(workExperienceToShow[index]);
                                }}
                            >
                                <img
                                    src="../../../../../../../images/delete-icon.svg"
                                    alt="ChatIcon"
                                />
                            </div>
                        </div>
                    );
                })}
            </div>

            {/*  Education Add/Edit Modal */}
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogStyles}
            >
                <WorkExperienceDetailEditModal
                    work_experience={selectedRecord}
                    onUpdate={actuallyUpdate}
                    onCancel={toggleHideDialog}
                />
            </Dialog>

            {/*  Education Delete Modal */}
            <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <DeleteModal
                    text={modalText}
                    onClick={actuallyDelete}
                    onCancel={toggleHideDialog1}
                />
            </Dialog>
        </div>
    );
};

export default EducationDetails;
