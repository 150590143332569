import React from 'react';

const EditProfileModal = (props) => {
    return (
        <div className="edit-profile-modal">
            <div className="modal-heading">
                <h4>Edit Profile</h4>
                <div onClick={onCancel}>
                    <img
                        src="../../../../images/edit-close.svg"
                        alt="CloseIcon"
                    />
                </div>
            </div>
            <div className="edit-form">
                <div className="form-group">
                    <label>Name</label>
                    <input type="text" name="name" placeholder="Name" />
                </div>
                <div className="form-group">
                    <label>Designation</label>
                    <input
                        type="text"
                        name="designation"
                        placeholder="Designation"
                    />
                </div>
                <div className="form-group">
                    <label>Location</label>
                    <input type="text" name="location" placeholder="Location" />
                </div>
                <div className="form-group">
                    <label>About</label>
                    <textarea placeholder="Write something about you..." />
                </div>
                <div className="form-buttons">
                    <button className="cancel">No, cancel</button>
                    <button className="save">Yes, confirm</button>
                </div>
            </div>
        </div>
    );
};

export default EditProfileModal;
