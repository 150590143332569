import * as React from 'react';
import Loader from 'react-js-loader';

export const ButtonWithLoader = (props) => {
    if (props.loading) {
        return (
            <div className="next-button">
                <button disabled={true}>
                    <Loader
                        type="bubble-scale"
                        bgColor={'#FFFFFF'}
                        color={'#FFFFFF'}
                        size={40}
                    />
                </button>
            </div>
        );
    }
    return (
        <div className="next-button">
            <button onClick={props.onClick}>
                <span>{props.name}</span>
                {props.arrow && <img src={props.arrow} alt="Arrow" />}
            </button>
        </div>
    );
};

export default ButtonWithLoader;
