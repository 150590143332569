import React from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import ListItems from './ListItems';

const AllMessagesList = () => {
    return (
        <div className="all-messages">
            <div className="messages-search-head">
                <div className="messages-search">
                    <img
                        src="../../../../../../images/search-header.svg"
                        alt="SearchIcon"
                    />
                    <input
                        className="search-input"
                        type="text"
                        placeholder="Search"
                        name="hsearch"
                    />
                </div>
            </div>

            {/* Messages List  */}
            <div className="messages-list">
                <div className="messages-tabs">
                    <Pivot aria-label="Basic Pivot Example">
                        <PivotItem
                            headerText="All chats"
                            headerButtonProps={{
                                'data-order': 1,
                                'data-title': 'Noticeboard',
                            }}
                        >
                            <div className="chat-list">
                                <ListItems />
                                <ListItems />
                                <ListItems />
                                <ListItems />
                                <ListItems />
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Personal"></PivotItem>
                        <PivotItem headerText="Groups"></PivotItem>
                    </Pivot>
                </div>
            </div>
        </div>
    );
};

export default AllMessagesList;
