import { Dropdown } from '@fluentui/react/lib/Dropdown';
import React, { useEffect, useState } from 'react';
import { MonthOptions } from '../../../../common/months';
import { YearsOptions } from '../../../../common/years';

const EducationDetailEditModal = ({ onCancel, education, onUpdate }) => {
    const [input, setInput] = useState({
        institute: '',
        course: '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setInput({ ...education });
    }, [education]);

    const validate = () => {
        let fields = [
            {
                label: 'School name',
                key: 'institute',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            {
                label: 'Course name',
                key: 'course',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            {
                label: 'From month',
                key: 'from_month',
                required: true,
            },
            {
                label: 'From year',
                key: 'from_year',
                required: true,
            },
            {
                label: 'To month',
                key: 'to_month',
                required: true,
            },
            {
                label: 'To year',
                key: 'to_year',
                required: true,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (
                field.required &&
                (input[field.key] === undefined ||
                    input[field.key] === null ||
                    input[field.key] === '')
            ) {
                if (
                    input.currentlyPursuing &&
                    ['to_month', 'to_year'].indexOf(field.key) > -1
                ) {
                    return;
                }
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        if (!input.currentlyPursuing) {
            let from = parseFloat(`${input.from_year}.${input.from_month}`);
            let to = parseFloat(`${input.to_year}.${input.to_month}`);
            if (to < from && to !== 0) {
                e.from_month = 'From cannot be after to';
                e.from_year = 'From cannot be after to';
            }
        }
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handlesubmit = () => {
        if (!validate()) {
            return;
        }
        onUpdate({ ...input });
    };

    const handleChange = e => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        console.log("mhsvd");
        console.log(e.target.value);
        setInput(i);
    };

    return (
        <div className="edit-profile-modal">
            <div className="modal-heading">
                <h4>Education Detail</h4>
                <div onClick={onCancel}>
                    <img
                        src="../../../../images/edit-close.svg"
                        alt="CloseIcon"
                    />
                </div>
            </div>
            <div className="edit-form">
                <div className="form-group">
                    <label>
                        School/College/University{' '}
                        <span className="estirc-sign">*</span>
                    </label>
                    <input
                        type="text"
                        required={true}
                        value={input.institute}
                        onChange={handleChange}
                        name="institute"
                        placeholder="Name"
                    />
                    <span className="edu-error">{errors.institute}</span>
                </div>
                <div className="form-group">
                    <label>
                        Class/Degree <span className="estirc-sign">*</span>
                    </label>
                    <input
                        type="text"
                        value={input.course}
                        onChange={handleChange}
                        name="course"
                        placeholder="Class/Degree"
                    />
                    <span className="edu-error">{errors.course}</span>
                </div>
                <div className="form-group">
                    <div className="drop-with-icon">
                        <Dropdown
                            placeholder="Select month"
                            label={'From Month'}
                            options={MonthOptions}
                            className=""
                            name="from_month"
                            selectedKey={input.from_month}
                            onChange={(e, item) => {
                                handleChange({
                                    target: {
                                        value: item.key,
                                        name: 'from_month',
                                    },
                                });
                            }}
                            required={true}
                        />
                    </div>
                    <span className="edu-error">{errors.from_month}</span>
                </div>
                <div className="form-group">
                    <div className="drop-with-icon">
                        <Dropdown
                            placeholder="Select year"
                            label={'From Year'}
                            options={YearsOptions}
                            className=""
                            name="from_year"
                            selectedKey={input.from_year}
                            onChange={(e, item) => {
                                handleChange({
                                    target: {
                                        value: item.key,
                                        name: 'from_year',
                                    },
                                });
                            }}
                            required={true}
                        />
                    </div>
                    <span className="edu-error">{errors.from_year}</span>
                </div>
                {!input.currentlyPursuing && (
                    <>
                        <div className="form-group">
                            <div className="drop-with-icon">
                                <Dropdown
                                    placeholder="Select month"
                                    label={'To Month'}
                                    options={MonthOptions}
                                    className=""
                                    name="to_month"
                                    selectedKey={input.to_month}
                                    onChange={(e, item) => {
                                        handleChange({
                                            target: {
                                                value: item.key,
                                                name: 'to_month',
                                            },
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                            <span className="edu-error">{errors.to_month}</span>
                        </div>
                        <div className="form-group">
                            <div className="drop-with-icon">
                                <Dropdown
                                    placeholder="Select year"
                                    label={'To Year'}
                                    options={YearsOptions}
                                    className=""
                                    name="to_year"
                                    selectedKey={input.to_year}
                                    onChange={(e, item) => {
                                        handleChange({
                                            target: {
                                                value: item.key,
                                                name: 'to_year',
                                            },
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                            <span className="edu-error">{errors.to_year}</span>
                        </div>
                    </>
                )}
                <div className="form-group education-checkbox">
                    <input
                        type="checkbox"
                        checked={input.currentlyPursuing}
                        onChange={(e) => {
                            handleChange({
                                target: {
                                    value: e.target.checked,
                                    name: 'currentlyPursuing',
                                },
                            });
                        }}
                        name="currentlyPursuing"
                    />
                    <label>Currently Pursuing</label>
                </div>

                <div className="form-buttons">
                    <button className="cancel" onClick={onCancel}>
                        No, cancel
                    </button>
                    <button className="save" onClick={handlesubmit}>
                        Yes, confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EducationDetailEditModal;
