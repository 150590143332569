import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import Api from './api/api';
import UserFilters from './modals/UserFilters';
import { useHistory, useParams } from 'react-router-dom';
import Initialising from './controls/Initialising';
import ButtonWithLoader from './controls/ButtonWithLoader';
import PostCard from '../views/AlumniFrontEnd/Main/Home/components/SubComponents/PostCard';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
        marginTop: 25,
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const CreateContentTabs = () => {
    const [post, setPost] = useState({
        description: '',
        type: 'text',
        images: [],
        to: { type: 'all', filters: {} },
        emailAlert: '',
    });

    const [errors, setErrors] = useState({});

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    const [selectedKey, setSelectedKey] = useState('0');
    const [initialising, setInitialising] = useState(true);

    const fileInput = useRef(null);

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setInitialising(true);
            Api.getInstance()
                .getPost(id)
                .then((response) => {
                    setPost(response);
                    setInitialising(false);
                })
                .catch(console.log);
        } else {
            setInitialising(false);
        }
    }, [id]);

    const handleChange = (e) => {
        let c = { ...post };
        c[e.target.name] = e.target.value;
        setPost(c);
    };

    const validate = () => {
        let fields = [
            [
                {
                    label: 'Description',
                    key: 'description',
                    required: true,
                },
            ],

            [],
            [],
        ];

        let e = {};
        fields[selectedKey].forEach((field) => {
            if (field.required && post[field.key] === '') {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && post[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(post[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

        setLoading(true);
        Api.getInstance()
            .createPost(post)
            .then((response) => {
                setPost(response);
                next();
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const publishPost = () => {
        setSending(true);
        Api.getInstance()
            .publishPost(post.id)
            .then((response) => {
                next();
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setSending(false);
            });
    };

    const handleBack = () => {
        if (selectedKey === '0') {
            history.push('/post');
            return;
        }
        setSelectedKey(String(parseInt(selectedKey) - 1));
    };

    const next = () => {
        if (selectedKey === '2') {
            history.push('/post');
            return;
        }
        setSelectedKey(String(parseInt(selectedKey) + 1));
    };
    const removeImage = (index) => {
        let images = post.images;
        images.splice(index, 1);
        setPost((i) => ({ ...i, images: images }));
    };
    const handleFileChange = async (e) => {
        Object.keys(e.target.files).map((k) => {
            const file = e.target.files[k];
            Api.getInstance()
                .uploadFile(
                    {
                        extension: file.name.split('.').pop(),
                        'Content-Type': file.type,
                    },
                    file,
                )
                .then(({ key }) => {
                    setPost((i) => ({ ...i, images: [...i.images, key] }));
                })
                .catch(console.log);
        });
        fileInput.current.value = '';
    };

    return (
        <div className="user-tabs">
            <Pivot aria-label="Basic Pivot Example" selectedKey={selectedKey}>
                <PivotItem
                    itemKey={'0'}
                    headerText="1. Create Your Post"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'Post',
                    }}
                >
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Create your post
                    </span>
                    {initialising && <Initialising />}
                    {!initialising && (
                        <>
                            <Stack>
                                <div className="add-user-form">
                                    <div
                                        className="form-group current pr"
                                        style={{ height: 'auto' }}
                                    >
                                        <div
                                            className={
                                                errors.description
                                                    ? 'input-with-label-error'
                                                    : 'input-with-label'
                                            }
                                        >
                                            <label>
                                                Description{' '}
                                                <span className="estirc-sign">
                                                    *
                                                </span>
                                            </label>
                                            <textarea
                                                maxLength="10000"
                                                rows={4}
                                                onChange={handleChange}
                                                name="description"
                                                value={post.description}
                                                required={true}
                                                estric="*"
                                                cols={20}
                                                style={{
                                                    height: 'auto',
                                                    resize: 'auto',
                                                }}
                                            ></textarea>
                                            <span>{errors.description}</span>
                                        </div>
                                        <div className='send-email-alert-checkbox'>
                                        <input
                                            type="checkbox"
                                            name="emailAlert"
                                            onClick={(e) => {
                                                handleChange({
                                                    target: {
                                                        value: e.target.checked,
                                                        name: 'emailAlert',
                                                    },
                                                });
                                            }}
                                             checked={post.emailAlert}
                                            value={post.emailAlert}
                                            defaultChecked={false}
                                        />

                                        <span>I would like to send an email notification for this post</span>
                                        </div>
                                        {/* <InputWithLabel
                                            errorMessage={errors.description}
                                            onChange={handleChange}
                                            label="Description"
                                            name="description"
                                            value={post.description}
                                            required={true}
                                            estric='*'
                                        /> */}
                                    </div>
                                    <div className="form-group current pl">
                                        <div className="input-with-label file-uploader">
                                            <label>Image</label>
                                            <div className="multiple-new-upload new-attchment">
                                                <input
                                                    multiple={true}
                                                    type="file"
                                                    ref={fileInput}
                                                    onChange={handleFileChange}
                                                    name="logo"
                                                    className="custom-file-input"
                                                    id="Logo"
                                                    style={{
                                                        backgroundImage: `url("${'../../../images/upload-multiple.svg'}")`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Uploaded Images display here */}
                                    <div className="uploaded-images-list">
                                        {post.images.map((image, index) => {
                                            return (
                                                <div
                                                    className="uploaded-images-col"
                                                    key={`image-${image}`}
                                                >
                                                    <img
                                                        src={Api.getInstance().getImageUrl(
                                                            image,
                                                        )}
                                                        alt=""
                                                    />

                                                    <div
                                                        className="remove-uploaded-image"
                                                        onClick={() => {
                                                            removeImage(index);
                                                        }}
                                                    >
                                                        <img
                                                            src="../../../images/edit-close.svg"
                                                            alt="Icon"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Stack>
                            <Stack styles={stackNewStyles}>
                                <DefaultButton
                                    onClick={handleBack}
                                    text="Cancel"
                                    styles={stackDefaultStyles}
                                />

                                <ButtonWithLoader
                                    onClick={handleSubmit}
                                    name="Next"
                                    arrow="../../../images/button-arrow.svg"
                                    loading={loading}
                                />
                            </Stack>
                        </>
                    )}
                </PivotItem>
                <PivotItem itemKey={'1'} headerText="2.  Select Users">
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Select users whom you want this post to be shared
                        with.This post will be visible only to these selected
                        users.
                    </span>
                    <Stack>
                        <div className="add-user-form-new">
                            <div className="mailto-text">
                                <span>Share with:</span>
                            </div>
                            <div className="choice-box">
                                <div className="radio-buton">
                                    <input
                                        type="radio"
                                        checked={post.to.type === 'all'}
                                        name="to"
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'to',
                                                    value: {
                                                        ...post.to,
                                                        type: 'all',
                                                    },
                                                },
                                            });
                                        }}
                                    />{' '}
                                    <span>All Users</span>
                                </div>
                                <div className="radio-buton">
                                    <input
                                        checked={post.to.type === 'specific'}
                                        type="radio"
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'to',
                                                    value: {
                                                        ...post.to,
                                                        type: 'specific',
                                                    },
                                                },
                                            });
                                        }}
                                        value="SpecificUsers"
                                        name="to"
                                    />{' '}
                                    <span>Specific Users</span>
                                </div>
                            </div>
                        </div>
                        <div className="Userfilters">
                            {post.to.type === 'specific' && (
                                <UserFilters
                                    showSubmitAndCancel={false}
                                    defaultFilters={post.to.filters || {}}
                                    onChange={(filters) => {
                                        handleChange({
                                            target: {
                                                name: 'to',
                                                value: {
                                                    ...post.to,
                                                    filters,
                                                },
                                            },
                                        });
                                    }}
                                />
                            )}
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={handleBack}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        <ButtonWithLoader
                            onClick={handleSubmit}
                            name="Next"
                            arrow="../../../images/button-arrow.svg"
                            loading={loading}
                        />
                    </Stack>
                </PivotItem>
                <PivotItem itemKey={'2'} headerText="3. Preview and Publish">
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Preview your post and publish it
                    </span>
                    <Stack styles={stackStyles}>
                        <div className="add-user-form">
                            <div className="">
                                <PostCard post={post} showActions={false} />
                            </div>
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={handleBack}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        <div style={{ marginRight: 10 }}>
                            <ButtonWithLoader
                                onClick={handleSubmit}
                                name="Save as Draft"
                                arrow="../../../images/button-arrow.svg"
                                loading={loading}
                            />
                        </div>
                        <div className="next-button">
                            <ButtonWithLoader
                                onClick={publishPost}
                                name="Publish Post"
                                loading={sending}
                            />
                        </div>
                    </Stack>
                </PivotItem>
            </Pivot>
        </div>
    );
};

export default CreateContentTabs;
