import React from 'react';
import ActionSection from './components/ActionSection';
import CardHeaderSection from './components/CardHeaderSection';
import MediaSection from './components/MediaSection';
import Api from '../../../../../../common/api/api';
import { useEffect,useState } from 'react';

const PollCard = ({
    poll: {
        description,
        user,
        timestamp_relative,
        question,
        ...poll
    },
    clickable,
    showActions = false,

}) => {

   const [loading, setLoading] = useState(true);
   const[daysLeft, setDaysLeft] = useState();

    useEffect(() => {
        Api.getInstance()
            .getPollDaysLeft(poll.id)
            .then((response) => {
               setDaysLeft(response);
            })
            .then(console.log)
            .finally(() => {
                setLoading(false);
            });
    },[]);

    return (

        <div className="card">
            <CardHeaderSection
                user={user}
                timestamp_relative={timestamp_relative}
            /> 
            <MediaSection description={description} clickable={clickable} images={null} option={poll.option} poll={poll} id={poll.id} daysLeft={daysLeft} question={question}/>
             {showActions === true && <ActionSection post={poll} />} 
        </div>
    );
};

export default PollCard;
