import React from 'react';
import Api from '../../../../../../../common/api/api';

const OtherProfileInfo = ({ profile: { name, batch_name, image } }) => {
    return (
        <>
            <div className="edit-card-top ">
                <div className="card-header-new">
                    <div className="card-header-col-left-new">
                        <div className="editprofile-pic">
                            <img
                                src={
                                    Api.getInstance().getImageUrl(image) ||
                                    '../../../../../../../images/new-default-icon.png'
                                }
                                alt={name}
                            />
                        </div>
                        <div className="post-info-new">
                            <h4>{name}</h4>
                            <span>{batch_name}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OtherProfileInfo;
