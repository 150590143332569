
import * as React from 'react';
import { useEffect, useState } from 'react';
import { mergeStyles, ScreenWidthMaxXLarge } from '@fluentui/react/lib/Styling';
import Api from '../api/api';
import ListFilters from '../../views/main/Alumni/components/ListFilters';
import ListHeader from '../../views/main/Alumni/components/ListHeader';
import { Link } from 'react-router-dom';
import Loader from 'react-js-loader';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import DeleteModal from '../modals/DeleteModal';
import ErrorModal from '../modals/ErrorModal';
import ReactPaginate from 'react-paginate';
import AlumniImportConfirmationModal from '../modals/AlumniImportConfirmatiomModal';
import ListSortPage from '../../views/main/ContactUs/ListSortPage';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        maxHeight: '500px !important',
        borderRadius: '4px',
    },
};
const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px',
    },
};

const exampleChildClass = mergeStyles({
    display: 'block',
    marginBottom: '10px',
});

const textFieldStyles = {
    root: { maxWidth: '300px' },
};

const NoRecordsFound = () => {
    return (
        <div className="no-records-intable">
            <img
                src="../../../images/no-result.svg"
                alt="NoData"
                style={{ width: 60 }}
            />
            <span>No Result Found</span>
            <p>We did not find any data.</p>
        </div>
    );
};
const Loading = () => {
    return (
        <div className="no-records-intable">
            <Loader
                type="bubble-scale"
                bgColor={'#2962FF'}
                color={'#2962FF'}
                size={40}
            />
        </div>
    );
};

const Table = (props) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [selectedRecord, setSelectedRecord] = useState([]);
    const [errorText, setErrorText] = useState('');
    const [modalText, setModalText] = useState('');

    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [totalRecords, setTotalRecords] = useState(0);

    const [state, updateState] = useState({
        items: [],
        _allItems: [],
        columns: [],
        filters: {},
        // id:{},
    });

    const [rows, setRows] = useState([]);

    const setState = (newState) => {
        updateState((currentState) => ({ ...currentState, ...newState }));
    };

    const offset = currentPage * limit;

    const loadData = () => {
        window.scrollTo(0, 0);
        let listKey = props.type;
        let LikeName = props.name;
        let Likeid = props.id;
        setState({ loading: true });

        if (props.name === 'UnSubscribe') {
            Api.getInstance()
                .getUnSubscribeUsersColumnDefinitions('campaign')
                .then((response) => {
                    setState({ columns: response });
                });
        }
        if (props.name === 'Bounced') {
            Api.getInstance()
                .getBouncedUsersColumnDefinitions('campaign')
                .then((response) => {
                    setState({ columns: response });
                });
        }
        else if (props.name === 'Failed Emails') {
            Api.getInstance()
                .getFailedColumnDefinitions('failed')
                .then((response) => {
                    setState({ columns: response });
                });
        }
                else if (props.name === 'Clicked Emails') {
            Api.getInstance()
                .getClickedColumnDefinitions('clicked')
                .then((response) => {
                    setState({ columns: response });
                });
        }
         else if (props.name === 'Opened Emails') {
            Api.getInstance()
                .getOpenedColumnDefinitions('opened')
                .then((response) => {
                    setState({ columns: response });
                });
        }
        else if (props.name === 'Option Votes') {
            Api.getInstance()
                .getVotesForOptionColumnDefinitions('campaign')
                .then((response) => {
                    setState({ columns: response });
                });
        } else {
            Api.getInstance()
                .getColumnDefinitions(listKey)
                .then((response) => {
                    setState({ columns: response });
                });
        }

        if (LikeName == 'PostLike') {
            Api.getInstance()
                .getLikeRows(listKey, Likeid, state.filters)
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        } else if (LikeName == 'TotalVotes') {
            Api.getInstance()
                .getTotalVotes(listKey, props.id, state.filters)
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        } else if (props.name == 'UnSubscribe') {
            Api.getInstance()
                .getUnsubscribeUsers('campaign', props.id, state.filters)
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        }else if (props.name == 'Bounced') {
            Api.getInstance()
                .getBouncedUsers('campaign', props.id, state.filters)
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        }
         else if (props.name == 'Failed Emails') {
            Api.getInstance()
                .getFailedEmails('campaign', props.id, state.filters)
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        }
         else if (props.name == 'Clicked Emails') {
            Api.getInstance()
                .getClickedEmails('campaign', props.id, state.filters)
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        }

         else if (props.name == 'Opened Emails') {
            Api.getInstance()
                .getOpenedEmails('campaign', props.id, state.filters)
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        }

        else if (props.name == 'Option Votes') {
            Api.getInstance()
                .getVotesForOption('poll', props.id, state.filters)
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        } else {
            Api.getInstance()
                .getRows(
                    listKey,
                    state.filters,
                    props.usePagination && limit !== 'all' ? limit : 0,
                    offset,
                )
                .then((response) => {
                    setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });
        }
        if (props.usePagination) {
            Api.getInstance()
                .getRowCount(listKey, state.filters)
                .then((response) => {
                    setTotalRecords(response.total);
                });
        }


    };

    useEffect(() => {
        loadData();
    }, [state.filters, currentPage, limit]);

    const updateChanges = () => {
        toggleHideDialog1();
    };

    const onDeleteItemClick = (item) => {
        setSelectedRecord([item.id]);
        setModalText(`${props.name}`);
        toggleHideDialog();
    };

    const actuallyDeleteItems = () => {
        setState({ loading: true });
        Api.getInstance()
            .deleteItems(props.type, selectedRecord)
            .then(loadData)
            .catch((e) => {
                setErrorText(
                    `An error has occurred while deleting selected record${
                        selectedRecord.length > 1 ? 's' : ''
                    }: ${e.error}`,
                );
                toggleHideDialog1();
            })
            .finally(() => {
                setCurrentPage(0);
            });
        toggleHideDialog();
    };

    const onDeleteSelected = () => {
        let selectedIds = state.items
            .filter((i) => i.selected)
            .map((i) => i.id);
        setModalText(`${props.tableName}`);
        toggleHideDialog();
        setSelectedRecord(selectedIds);
    };

    const toggleSelect = (index) => {
        let items = [...state.items];
        items[index].selected = !items[index].selected;
        setState({ items });
    };

    const toggleAllSelect = (checkAll) => {
        let items = state.items.map((item) => {
            item.selected = checkAll;
            return item;
        });
        setState({ items });
    };

    const _onFilter = (ev, text) => {
        setState({
            items: text
                ? state._allItems.filter(
                      (i) =>
                          JSON.stringify(i)
                              .toLowerCase()
                              .indexOf(text.toLowerCase()) > -1,
                  )
                : state._allItems,
        });
    };

    const filter = (e) => {
          Api.getInstance()
                .getRowsBasedOnStatus(
                      props.type,
                      e.target.value
                )
                .then((response) => {
                     setState({
                        items: response,
                        loading: false,
                        _allItems: response,
                    });
                });

    }

    const getInitials = (name) => {
        const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
        return initials;
    };

    const { items, columns } = state;
    const selectedCount = items.filter((i) => i.selected).length;
    const selectedColumns = columns.filter((column) => {
        return column.selected !== false;
    });

    const clearFilter = () => {
       setState({ filters:[] }, loadData);
    };

    return (
        <div>
            <ListHeader
                name={props.name}
                type={props.type}
                tableName={props.tableName}
                allowImport={props.allowImport}
                allowExport={props.allowExport}
                data={rows}
                dataCount={rows}
                TotalCount={totalRecords}
                filters={state.filters}

            />

            <ListFilters
                deleteIcon={props.deleteIcon}
                onDeleteSelected={onDeleteSelected}
                selectedCount={selectedCount}
                showAdvancedSearch={props.showAdvancedSearch}
                showSortFilter = {props.showSortFilter}
                defaultFilters={state.filters}
                onQueryChange={_onFilter}
                clearFilters={clearFilter}
                onFilterChange={(filters) => {
                    setState({ filters }, loadData);
                }}
                columns={columns}
                updateColumns={(columns) => {
                    setState({ columns });
                }}
                onFilterQuery={filter}

            />
            <div className="fluent-table-v2">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div className="check-group">
                                    {props.deleteIcon ? (
                                        <label
                                            style={{
                                                marginBottom: 0,
                                            }}
                                        >
                                            <input
                                                checked={
                                                    selectedCount ===
                                                        items.length &&
                                                    selectedCount > 0 &&
                                                    !state.loading
                                                }
                                                onChange={() => {
                                                    toggleAllSelect(
                                                        selectedCount !==
                                                            items.length,
                                                    );
                                                }}
                                                type="checkbox"
                                                name={'currentlyPursuing'}
                                            />
                                        </label>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </th>
                            {selectedColumns.map((c, index) => {
                                // @ts-ignore
                                return (
                                    <th key={`column-${index}`}>{c.name}</th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {items.length === 0 && !state.loading && (
                            <tr>
                                <td
                                    colSpan={selectedColumns.length + 1}
                                    style={{ backgroundColor: '#ffffff' }}
                                >
                                    <NoRecordsFound />
                                </td>
                            </tr>
                        )}
                        {state.loading && (
                            <tr>
                                <td
                                    colSpan={selectedColumns.length + 1}
                                    style={{ backgroundColor: '#ffffff' }}
                                >
                                    <Loading />
                                </td>
                            </tr>
                        )}
                        {!state.loading && (
                            <>
                                {items.map((item, rowIndex) => {
                                    if (!item.actions) {
                                        item.actions = {
                                            edit: true,
                                            delete: true,
                                            click: true,
                                        };
                                    }
                                    return (
                                        <tr key={`row-${rowIndex}`}>
                                            <td style={{ width: 60 }}>
                                                <ul>
                                                    {props.deleteIcon ? (
                                                        <li>
                                                            <div className="check-group">
                                                                <label
                                                                    style={{
                                                                        marginBottom: 0,
                                                                    }}
                                                                >
                                                                    <input
                                                                        checked={
                                                                            !!item.selected
                                                                        }
                                                                        onChange={() => {
                                                                            toggleSelect(
                                                                                rowIndex,
                                                                            );
                                                                        }}
                                                                        type="checkbox"
                                                                        name={
                                                                            'currentlyPursuing'
                                                                        }
                                                                    />
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {/* <li className="avatar-pic">
                                                        <img
                                                            src="../../../images/table-avatar.svg"
                                                            alt={'Avatar'}
                                                        />
                                                    </li> */}
                                                    {props.initialsField && (
                                                        <li className="table-intial">
                                                            <div className="intial-box">
                                                                <p className="intials">
                                                                    {getInitials(
                                                                        item[
                                                                            props
                                                                                .initialsField
                                                                        ],
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </td>
                                            {selectedColumns.map((c, index) => {
                                                // @ts-ignore
                                                if (
                                                    c.fieldName ===
                                                    '__actions__'
                                                ) {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item
                                                                        .actions
                                                                        .edit && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onItemClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="../../../images/edit-row.svg"
                                                                                alt="EditIcon"
                                                                            />
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                                {props.deleteIcon ? (
                                                                    <li>
                                                                        {item
                                                                            .actions
                                                                            .delete && (
                                                                            <Link
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    e.preventDefault();
                                                                                    onDeleteItemClick(
                                                                                        item,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src="../../../images/delete-row.svg"
                                                                                    alt="EditIcon"
                                                                                />
                                                                            </Link>
                                                                        )}
                                                                    </li>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </ul>
                                                        </td>
                                                    );
                                                }

                                                else if (
                                                    c.fieldName ===
                                                    '__cancel/approve__'
                                                ) {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item.approved ==
                                                                        true && (
                                                                        <p className="approve-link">
                                                                            Approved
                                                                        </p>
                                                                    )}
                                                                    {item.approved ==
                                                                        false && (
                                                                        <p className="reject-link">
                                                                            Rejected
                                                                        </p>
                                                                    )}
                                                                    {item.approved ===
                                                                        null && (
                                                                        <li>
                                                                            <Link
                                                                                className="approve-link"
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    e.preventDefault();
                                                                                    props.onApproveItem(
                                                                                        item,
                                                                                    );
                                                                                }}
                                                                                style={{ fontSize: '17px' , fontWeight: 'bold'   }}
                                                                            >
                                                                                Approve
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {item.approved ===
                                                                        null && (
                                                                        <li>
                                                                            <Link
                                                                                className="reject-link"
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    e.preventDefault();
                                                                                    props.onRejectItem(
                                                                                        item,
                                                                                    );
                                                                                }}
                                                                                style={{ fontSize: '17px' , fontWeight: 'bold'   }}
                                                                            >
                                                                                Reject
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {item.approved ===
                                                                        null && (
                                                                        <li>
                                                                            <Link
                                                                                className="view-link"
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    e.preventDefault();
                                                                                    props.onViewItem(
                                                                                        item,
                                                                                    );
                                                                                }}
                                                                                style={{ fontSize: '17px' , fontWeight: 'bold'   }}
                                                                            >
                                                                                View
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                } else if (
                                                    c.fieldName ===
                                                    'download'
                                                )
                                                {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item.approved ===
                                                                        null && item.download[0] && (
                                                                        <li>
                                                                                 {/* <Link
                                                                                className="download"
                                                                                onClick={(
                                                                                    e,
                                                                                ) => {
                                                                                    e.preventDefault();
                                                                                    props.onViewattachement(
                                                                                        item,
                                                                                    );
                                                                                }}
                                                                                style={{ fontSize: '17px' , fontWeight: 'bold'   }}
                                                                            >
                                                                            Verify the document
                                                                            </Link> */}
                                                                                  <a
                                                                                     rel="noreferrer"
                                                                                     target="_blank"
                                                                                     href={Api.getInstance().getImageUrl(
                                                                                        item.download[0].key,
                                                                                        true
                                                                                     )}
                                                                                 > Verify the document</a>

                                                                        </li>
                                                                    )}

                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                }

                                                else if (
                                                    c.fieldName ===
                                                    '__total_likes__'
                                                ) {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item
                                                                        .actions
                                                                        .click && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onLikeItemClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                } else if (
                                                    c.fieldName === 'votes'
                                                ) {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item
                                                                        .actions
                                                                        .click && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onVotesClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                } else if (
                                                    c.fieldName ===
                                                    'Total_votes'
                                                ) {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item
                                                                        .actions
                                                                        .click && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onOptionVotesClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                } else if (
                                                    c.fieldName ===
                                                    'bouncebacks'
                                                ) {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onBounceClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                } else if (
                                                    c.fieldName ===
                                                    'unsubscribes'
                                                ) {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onUnSubscribeClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                }
                                                 else if (
                                                    c.fieldName ===
                                                    '_status'
                                                ) {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                  {item.status === true && <p className='approve-link'>Solved</p>}
                                                                  {item.status === false && <p className='reject-link'>Pending</p>}
                                                                   {item.status === false && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onViewItem(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                             View
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                }
                                                else if(c.fieldName === 'failed')
                                                {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onFailedEmailClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    )
                                                }
                                                 else if(c.fieldName === 'actioncount')
                                                {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onClickedEmailClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    )
                                                }
                                                else if(c.fieldName === 'opened')
                                                {
                                                    return (
                                                        <td
                                                            key={`cell-${index}`}
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {item && (
                                                                        <Link
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                props.onOpenedEmailClick(
                                                                                    item,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <td
                                                                                key={`cell-${index}`}
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item[
                                                                                        c
                                                                                            .fieldName
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        </Link>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    )
                                                }
                                                // @ts-ignore
                                                return (
                                                    <td key={`cell-${index}`}>
                                                        {item[c.fieldName]}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            {props.usePagination && (
                <Pagination
                    currentPage={currentPage}
                    totalRecords={totalRecords}
                    limit={limit}
                    setLimit={setLimit}
                    onPageChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                    }}
                />
            )}
            {/*  Delete  Modal */}
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogStyles}
            >
                <DeleteModal
                    text={modalText}
                    onClick={actuallyDeleteItems}
                    onCancel={toggleHideDialog}
                />
            </Dialog>
            <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={toggleHideDialog1} />
            </Dialog>
            {/*  <div className="fluent-table">
                    <MarqueeSelection selection={_selection}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selection={_selection}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                            onItemInvoked={_onItemInvoked}
                        />
                    </MarqueeSelection>
                </div>*/}
        </div>
    );
};

const Pagination = ({
    currentPage,
    onPageChange,
    totalRecords,
    limit,
    setLimit,
}) => {
    if (totalRecords === 0) {
        return null;
    }

    let pageCount = Math.ceil(totalRecords / limit);
    return (
        <div className="pagination-ui">
            <div className="select-items-pagination">
                <span>Show items</span>
                <select
                    value={limit}
                    onChange={(e) => {
                        setLimit(e.target.value);
                    }}
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                    <option value="all">All</option>
                </select>
            </div>
            {limit !== 'all' && (
                <ReactPaginate
                    nextLabel={
                        <div className="arrows-page">
                            <span>Next</span>
                            {currentPage === pageCount - 1 ? (
                                <img
                                    src="../../../images/arrow-right-disable.svg"
                                    style={{ marginLeft: 10 }}
                                />
                            ) : (
                                <img
                                    src="../../../images/arrow-right.svg"
                                    style={{ marginLeft: 10 }}
                                />
                            )}
                        </div>
                    }
                    onPageChange={(e) => {
                        onPageChange(e.selected);
                    }}
                    pageCount={pageCount}
                    previousLabel={
                        <div className="arrows-page">
                            {currentPage === 0 ? (
                                <img
                                    src="../../../images/arrow-left-disable.svg"
                                    style={{ marginRight: 10 }}
                                />
                            ) : (
                                <img
                                    src="../../../images/arrow-left.svg"
                                    style={{ marginRight: 10 }}
                                />
                            )}
                            <span>Previous</span>
                        </div>
                    }
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item border-radius-left"
                    previousLinkClassName="page-link"
                    nextClassName="page-item border-radius-right"
                    forcePage={currentPage}
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    disabledClassName="disable"
                    renderOnZeroPageCount={null}
                />
            )}
        </div>
    );
};

export default Table;
