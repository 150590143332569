import React from 'react';
import { Link } from 'react-router-dom';

const AsideList = (props) => {
    return (
        <Link className={props.className} to={props.url?props.url:null}>
            <div className="nav-icons">
                {props.active && (
                <img
                    src={props.active}
                    alt="ActiveIcon"
                    className="active-icon"
                />)}
                {props.inactive && (
                <img
                    src={props.inactive}
                    alt="inActiveIcon"
                    className="inactive-icon"
                />
                )}
            </div>
            <div className='drop-icons'>
            {props.drop ? <span className='drop-downValue'>{props.tabname}</span> : <span>{props.tabname}</span>}

                {props.dropActiveIcon && (
                <img
                    src={props.dropActiveIcon}
                    alt="inActiveIcon"
                    className="inactive-icon1"
                    id='Id'
                />)}
                {props.dropInactiveIcon && (
                <img
                    src={props.dropInactiveIcon}
                    alt="ActiveIcon"
                    className="active-icon"
                />
                )}

        </div>
        </Link>
    );
};

export default AsideList;
