import React from 'react';

const ChatHeader = () => {
    return (
        <div className="converstion-header">
            <div className="card-header">
                <div className="card-header-col-left">
                    <img
                        src="../../../../../../images/user-intial.svg"
                        alt="ChitkaraLogo"
                    />
                    <div className="post-info">
                        <h4>Chitkara International School</h4>
                        <span>43.796 Talking</span>
                    </div>
                </div>
                <div className="card-header-col-right">
                    <img
                        src="../../../../../../images/vertical-icon.svg"
                        alt="Options"
                    />
                </div>
            </div>
        </div>
    );
};
export default ChatHeader;
