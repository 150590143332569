import React from 'react';
import AsideList from './components/AsideList';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

const Aside = (props) => {
    const getActiveMenuIndex = (url) => {
        let parts = url.split('/');

        switch (parts[3]) {
            case 'settings':
                return 1;
            case 'alumni':
            case 'registration':
            case 'fieldSettings':
                return 2;
            case 'campaign':
            case 'template':
            case 'campaign/EmailCampaign':
            case 'category':
                return 3;
            case 'poll':
            case 'post':
                return 4;
            case 'dashboard':
                return 5;
            case 'staff':
                return 6;
            case 'batch':
            case 'field':
            case 'lists':
                return 7;
            case 'contactUs':
                return 8;
        }
    };

    const getActiveClassNames = (defaultClass, tabname) => {
        let active = false;
        let url = window.location.pathname;

        if (tabname === 'ManageAlumni') {
            active = url.includes('/alumni');
        }

        if (tabname === 'RegistrationForm') {
            active = url.includes('/registration');
        }

        if (tabname === 'DetailsSettings') {
            active = url.includes('/fieldSettings');
        }

        if (tabname === 'Email Campaigns') {
            active = url.includes('/campaign/email');
        }

        if (tabname === 'EmailCampaignSettings') {
            active = url.includes('/campaign/EmailCampaign');
        }

        if (tabname === 'EmailCampaignCategory') {
            active = url.includes('/category');
        }

        if (tabname === 'Email Template') {
            active = url.includes('/template');
        }

        if (tabname === 'ManagePoll') {
            active = url.includes('/poll');
        }

        if (tabname === 'ManageContent') {
            active = url.includes('/post');
        }

        if (tabname === 'ManageBatches') {
            active = url.includes('/batch');
        }
        if (tabname === 'ManageCustomFields') {
            active = url.includes('/field');
        }

        if (tabname === 'PortalSettings') {
            active = url.includes('/settings');
        }
        if (tabname === 'Lists') {
            active = url.includes('/lists');
        }
         if (tabname === 'Manage Queries') {
            active = url.includes('/contactUs');
        }

        return classNames(defaultClass, { active: active });
    };

    const [showDrop, setShowDrop] = useState(
        getActiveMenuIndex(window.location.href),
    );

    const getClassNames = (defaultClass, active) => {
        return classNames(defaultClass, { active: active });
    };

    const getActiveModule = (url) => {
        let parts = url.split('/');
        return parts[3];
    };
    const activeModule = getActiveModule(window.location.href);

    return (
        <div className="aside-bar">
            <ul className="sidebar navbar-nav">
                <li className={getClassNames('nav-item1', showDrop === 5)}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/dashboard-active.png'
                        }
                        inactive={
                            './../../../images/aside-icons/dashboard-inactive.png'
                        }
                        url="/dashboard"
                        tabname="Dashboard"
                        containsDrop="false"
                    />
                </li>
                <li
                    className={getClassNames('nav-item1', showDrop === 2)}
                    onClick={() => setShowDrop(showDrop === 2 ? null : 2)}
                >
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/alumni-active.svg'
                        }
                        inactive={
                            './../../../images/aside-icons/alumni-inactive.svg'
                        }
                        dropActiveIcon={
                            '../../../../images/aside-icons/chevron-down.svg'
                        }
                        dropInactiveIcon={
                            '../../../../images/aside-icons/chevron-up.svg'
                        }
                        tabname="Alumni"
                        containsDrop="true"
                    />
                </li>
                {showDrop === 2 && (
                    <ul>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'ManageAlumni',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/alumni/list"
                                tabname="Manage Alumni"
                                drop="true"
                            />
                        </li>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'RegistrationForm',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/registration/list"
                                tabname="Registration Requests"
                                drop="true"
                            />
                        </li>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'DetailsSettings',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/fieldSettings"
                                tabname="Alumni's details settings"
                                drop="true"
                            />
                        </li>
                    </ul>
                )}

                <li
                    className={getClassNames('nav-item1', showDrop === 4)}
                    onClick={() => setShowDrop(showDrop === 4 ? null : 4)}
                >
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/content-active.svg'
                        }
                        inactive={
                            './../../../images/aside-icons/content-inactive.svg'
                        }
                        dropActiveIcon={
                            '../../../../images/aside-icons/chevron-down.svg'
                        }
                        dropInactiveIcon={
                            '../../../../images/aside-icons/chevron-up.svg'
                        }
                        tabname="Manage Content"
                        containsDrop="true"
                    />
                </li>
                {showDrop === 4 && (
                    <ul>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'ManageContent',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/post"
                                tabname="Manage Post"
                                drop="true"
                            />
                        </li>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'ManagePoll',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/poll"
                                tabname="Manage Poll"
                                drop="true"
                            />
                        </li>
                    </ul>
                )}
                <li
                    className={getClassNames('nav-item1', showDrop === 3)}
                    onClick={() => setShowDrop(showDrop === 3 ? null : 3)}
                >
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/mail-active.svg'
                        }
                        inactive={
                            './../../../images/aside-icons/mail-inactive.svg'
                        }
                        dropActiveIcon={
                            '../../../../images/aside-icons/chevron-down.svg'
                        }
                        dropInactiveIcon={
                            '../../../../images/aside-icons/chevron-up.svg'
                        }
                        url=""
                        tabname="Email Campaigns"
                        containsDrop="true"
                    />
                </li>
                {showDrop === 3 && (
                    <ul>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'Email Campaigns',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/campaign/email/list"
                                tabname="Send Email"
                                drop="true"
                            />
                        </li>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'Email Template',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/template/list"
                                tabname="Email Templates"
                                drop="true"
                            />
                        </li>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'EmailCampaignCategory',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/category/categoryList"
                                tabname="Email Category"
                                drop="true"
                            />
                        </li>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'EmailCampaignSettings',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/campaign/EmailCampaign"
                                tabname="Email Settings"
                                drop="true"
                            />
                        </li>
                    </ul>
                )}

                <li className={getClassNames('nav-item1', showDrop === 6)}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/staf-active.svg'
                        }
                        inactive={
                            './../../../images/aside-icons/staff-inactive.svg'
                        }
                        url="/staff/list"
                        tabname="Manage Staff"
                        containsDrop="false"
                    />
                </li>
                <li
                    className={getClassNames('nav-item1', showDrop === 7)}
                    onClick={() => setShowDrop(showDrop === 7 ? null : 7)}
                >
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/content-active.svg'
                        }
                        inactive={
                            './../../../images/aside-icons/content-inactive.svg'
                        }
                        dropActiveIcon={
                            '../../../../images/aside-icons/chevron-down.svg'
                        }
                        dropInactiveIcon={
                            '../../../../images/aside-icons/chevron-up.svg'
                        }
                        url=""
                        tabname="Additional Information"
                        containsDrop="true"
                    />
                </li>
                 {/* <li className={getClassNames('nav-item', 'Email Template')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/alumni-active.svg'
                        }
                        inactive={
                            './../../../images/aside-icons/alumni-inactive.svg'
                        }
                        url="/pollandpost/list"
                        tabname="Poll and Post Stats"
                    />
                </li> */}
                {showDrop === 7 && (
                    <ul>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'ManageCustomFields',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/field/list"
                                tabname="Manage Custom Fields"
                                drop="true"
                            />
                        </li>
                        <li
                            className={getActiveClassNames(
                                'nav-item',
                                'ManageBatches',
                            )}
                        >
                            <AsideList
                                className="nav-link"
                                url="/batch/list"
                                //tabname="Manage Batches"
                                tabname={window.location.host === "banyantreedel.almacircle.com" ? ('Manage Last class studied'):'Manage Batches'}
                                drop="true"
                            />
                        </li>
                        <li
                            className={getActiveClassNames('nav-item', 'Lists')}
                        >
                            <AsideList
                                className="nav-link"
                                url="/lists/Lists"
                                tabname="Manage Lists"
                                drop="true"
                            />
                        </li>
                    </ul>
                )}
                <li className={getClassNames('nav-item1', showDrop === 8)}>
                    
                    <AsideList
                        className="nav-link"
                        active={'./../../../images/aside-icons/query-active (2).svg'}
                        inactive={
                             './../../../images/aside-icons/query-icon.svg'
                        }
                        containsDrop="true"
                        url="/contactUs/list"
                        tabname="Manage Queries"
                    />
                </li>
                <li className={getClassNames('nav-item1', showDrop === 1)}>
                    <AsideList
                        className="nav-link"
                        active={'../../../../images/icons/account-active.svg'}
                        inactive={
                            './../../../images/icons/account-inactive.svg'
                        }
                        url="/settings/SchoolSettings"
                        tabname="Portal Settings"
                    />
                </li>

                {/* <li className={getClassNames('nav-item', 'Payments')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/payment-active.svg'
                        }
                        inactive={
                            './../../../images/aside-icons/payment-inactive.svg'
                        }
                        url="/payments"
                        tabname="Payments"
                    />
                </li>
                <li className={getClassNames('nav-item', 'Mailing')}>
                    <AsideList
                        className="nav-link"
                        active={
                            '../../../../images/aside-icons/mail-active.svg'
                        }
                        inactive={
                            './../../../images/aside-icons/mail-inactive.svg'
                        }
                        url="/mailing"
                        tabname="Mailing"
                    />
                </li>
                */}
            </ul>
        </div>
    );
};

export default Aside;
