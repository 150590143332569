import React, { useState } from 'react';
import { connect } from 'react-redux';
import Api from '../../../../../../common/api/api';
import Loader from 'react-js-loader';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import UpdateModal from '../../../../../../common/modals/UpdateModal';


const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px'
    },
};

const AccountUpdatePassword = ({ user, title, ...prpos }) => {
    const [input, setInput] = useState({
        current_password: '',
        new_password: '',
        confirm_new_password: '',


    });

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);
        const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const validate = () => {
        let fields = [
            {
                label: 'Current Password',
                key: 'current_password',
                required: true,
            },
            {
                label: 'New Password',
                key: 'new_password',
                required: true,
            },
            {
                label: 'Confirm New Password',
                key: 'confirm_new_password',
                required: true,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.required && input[field.key] === '') {
                e[field.key] = `${field.label} is required`;
            }
        });

        if (
            input.new_password &&
            input.confirm_new_password &&
            input.new_password !== input.confirm_new_password
        ) {
            e['confirm_new_password'] = 'Passwords do not match';
            e['new_password'] = 'Passwords do not match';
        }
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const updateChanges = () => {
       window.location.href = '/auth/schools';
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);

        Api.getInstance()
            .changePassword(input)
            .then((response) => {
                toggleHideDialog();
                setInput({
                    current_password: '',
                    new_password: '',
                    confirm_new_password: '',
                });
            })
            .catch((e) => {
                if (e.fields) {
                    setErrors(e.fields);
                } else {
                    alert(e.error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    return (
        <div className="home-middle-col">

            <div dd className="branch-member-card" style={{ marginTop: 0 }}>
                <div className="education-heading">
                    <span>{title}</span>
                </div>

                <div className="update-form">
                    <div className="form-group">
                        <label>Current Password</label>
                        <input
                            type="password"
                            name="current_password"
                            value={input.current_password}
                            onChange={handleChange}
                            placeholder="Current Password"
                            required={true}
                        />
                        <span>{errors.current_password}</span>
                    </div>
                    <div className="form-group">
                        <label>New Password</label>
                        <input
                            type="password"
                            name="new_password"
                            value={input.new_password}
                            onChange={handleChange}
                            placeholder="New Password"
                            required={true}
                        />
                        <span>{errors.new_password}</span>
                    </div>
                    <div className="form-group">
                        <label>Confirm New Password</label>
                        <input
                            type="password"
                            name="confirm_new_password"
                            value={input.confirm_new_password}
                            onChange={handleChange}
                            placeholder="Confirm New Password"
                            required={true}
                        />
                        <span>{errors.confirm_new_password}</span>
                    </div>
                        <div className="form-buttons">
                            <button className="cancel">Cancel</button>
                            <button className="save" loading={loading} onClick={handleSubmit}>
                                {loading
                                    ?
                                    <Loader
                                        type="bubble-scale"
                                        bgColor={'#FFFFFF'}
                                        color={'#FFFFFF'}
                                        size={40}
                                    />
                                    :
                                    'Save'
                                }
                            </button>
                        </div>
                    </div>
                </div>
                  <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <UpdateModal
                text={"Password has been updated successfully."}
                 onClick={updateChanges} 
                 />
            </Dialog>
            </div>
    );

};



const mapStateToProps = (state) => ({
    user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchLogin: (data) =>
        dispatch({
            type: 'AUTH_LOGIN',
            data: data,
        }),
    dispatchLogout: () =>
        dispatch({
            type: 'AUTH_LOGOUT',
        }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountUpdatePassword);

