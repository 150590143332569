import React from 'react';
import MainLayout from '../../Components/Layout/MainLayout';
import AccountItems from './components/AccountItems';

const MyAccount = () => {
    return (
        <MainLayout>
            <AccountItems />
        </MainLayout>
    );
};

export default MyAccount;
