import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './App.css';
import AuthRoutes from './routes/AuthRoutes';
import SwitchWithNotFound from './hoc/SwitchWithNotFound';
import UserRoutes from './routes/UserRoutes';
import { SchoolRoutes } from './routes/SchoolRoutes';
import AccessDeniedPage from './views/errors/AccessDeniedPage';
import { ConfigContext } from './context/ConfigContext';
import getSchool from './helpers/getSchool';
import config from './config/config';

const updateMeta = (school) => {
    const favicon = document.getElementById('favicon');

    favicon.href = `/schools/${school}/favicon.png`;

    const ogImage = document.getElementById('og:image');

    ogImage.href = `/schools/${school}/logo192.png`;

    const appleTouchIcon = document.getElementById('apple-touch-icon');

    appleTouchIcon.href = `/schools/${school}/logo192.png`;
    document.title = config[school].title;
};

function App() {
    const school = getSchool();
    useEffect(() => {
        updateMeta(school);
    }, [school]);

    return (
        <ConfigContext.Provider value={{ school }}>
            <Router>
                <SwitchWithNotFound>
                    <Route path="/403" component={AccessDeniedPage} exact />
                    <Route path="/auth" component={AuthRoutes} />
                    <Route path="/user" component={UserRoutes} />
                    <Route path="/" component={SchoolRoutes} />
                </SwitchWithNotFound>
            </Router>
        </ConfigContext.Provider>
    );
}

export default App;
