import * as React from 'react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';

const searchBoxStyles = {
    root: {
        width: 'auto',
        borderRadius: 0,
        border: 'none',
        height: 36,
    },
};

/* eslint-disable react/jsx-no-bind */
const SearchInput = ({ onChange }) => (
    <div className="search-input">
        <img src="../../../../images/search-new.svg" alt="Searchicon" />
        <SearchBox
            styles={searchBoxStyles}
            placeholder="Search anything"
            onEscape={(e) => {}}
            onChange={onChange}
            onSearch={(newValue) => {}}
        />
    </div>
);

export default SearchInput;
