import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Table from '../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Stack } from '@fluentui/react/lib/Stack';
import PortalSettingTabsButton from '../SchoolSettings/components/PortalSettingTabsButton';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 34,
    },
};



const ListCategoryPage = () => {
    const history = useHistory();
    return (
        <MainLayout>            
            <Table 
               type={'category'} 
               name={'Category'} 
               tableName={'Categories'}
               deleteIcon={false}
               onItemClick={(item) => {
                history.push(`/category/${item.id}/edit`);
            }} />
        </MainLayout>
    );
};

export default ListCategoryPage;
