import React from 'react';

const InputWithIcon = (props) => {
    return (
        <div className="icon-input">
            <label>{props.label}</label>
            <div className={props.className}>
                <input
                    type={props.type}
                    name={props.name}
                    onChange={props.onChange}
                    value={props.value}
                    placeholder={props.placeholder}
                />
                <img
                    src={props.icon}
                    onClick={props.onClick}
                    alt="Icon"
                    style={{ width: 22, height: 22 }}
                />
            </div>
            <span>{props.errorMessage}</span>
        </div>
    );
};

export default InputWithIcon;
