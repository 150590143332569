import React, { useEffect, useState } from 'react';
import BatchMemberList from './components/BatchMemberList';

const BatchMemberMiddle = () => {
    return (
        <div className="home-middle-col">
            <BatchMemberList />
        </div>
    );
};

export default BatchMemberMiddle;
