import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Table from '../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Api from '../../../common/api/api';
import ErrorModal from '../../../common/modals/ErrorModal';
import { useBoolean } from '@fluentui/react-hooks';
import { Dialog } from '@fluentui/react/lib/Dialog';
import AlumniImportConfirmationModal from '../../../common/modals/AlumniImportConfirmatiomModal';
import RemarksModal from '../../../common/modals/RemarksModal';

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '169px !important',
    },
};

const ListRegistrationFormPage = () => {
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);
    const [errorText, setErrorText] = useState('');
    const [hideRejectDialog, { toggle: toggleHideRejectDialog }] = useBoolean(true);
    const [viewItem, setViewItem] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: null,
        year_joining: null,
        year_leaving: null,
        batch: null,
    });

    const updateChanges = () => {
        window.location.reload();
    }

    const history = useHistory();
    return (
        <MainLayout>
            <Table
                type={'registration/form'}
                name={'Registration'}
                tableName={"Alumni Registration's"}
                deleteIcon={true}
                onApproveItem={(item) => {
                    Api.getInstance()
                        .approveAlumniRequest(item)
                        .then((response) => {
                            window.location.reload();
                        })
                        .catch((e) => {
                            setErrorText(e.error);
                            toggleHideDialog2();
                        })
                        .finally(() => {
                        });
                }}
                onRejectItem={(item) => {
                    // Api.getInstance()
                    //     .rejectAlumniRequest(item)
                    //     .then((response) => {
                    //         window.location.reload();
                    //     })
                    //     .catch((e) => {
                    //         setErrorText(e.error);
                    //         toggleHideDialog2();
                    //     })
                    //     .finally(() => {
                    //     });
                    setViewItem(item);
                    toggleHideRejectDialog();
                }}
                onViewItem={(item) => {
                    setViewItem(item);
                    toggleHideDialog1();
                }}
            />
            <Dialog
                hidden={hideDialog2}
                onDismiss={toggleHideDialog2}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={updateChanges} />
            </Dialog>

            <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <AlumniImportConfirmationModal
                    text={'View Details'}
                    item={viewItem}
                    onCancel={toggleHideDialog1}
                />
            </Dialog>

            <Dialog
                hidden={hideRejectDialog}
                onDismiss={toggleHideRejectDialog}
                styles={dialogNewStyles}
            >
                <RemarksModal
                    text={'Reject'}
                    item={viewItem}
                    onCancel={toggleHideRejectDialog}
                   
                />
            </Dialog>
        </MainLayout>
    );
};

export default ListRegistrationFormPage;