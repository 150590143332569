import React, { useState } from 'react';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import EducationDetailEditModal from '../../../../../Components/Modal/EducationDetailEditModal';
import DeleteModal from '../../../../../../../common/modals/DeleteModal';
import { Months } from '../../../../../../../common/months';
import Api from '../../../../../../../common/api/api';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '620px !important',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px',
    },
};

const EducationDetails = ({ education, refresh }) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [modalText, setModalText] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);

    const onEdit = (ed) => {
        setSelectedRecord(ed);
        toggleHideDialog();
    };

    const onDelete = (ed) => {
        setModalText(`Education`)
        setSelectedRecord(ed);
        toggleHideDialog1();
    };

    const onAdd = (index) => {
        setSelectedRecord({
            institute: '',
            course: '',
            from_month: '',
            from_year: '',
            to_month: '',
            to_year: '',
            currentlyPursuing: false,
        });
        toggleHideDialog();
    };

    const actuallyDelete = () => {
        Api.getInstance()
            .deleteEducation(selectedRecord.id)
            .then(refresh)
            .catch(console.log);
    };

    const actuallyUpdate = (updatedEducation) => {
        toggleHideDialog();
        Api.getInstance()
            .updateEducation(updatedEducation)
            .then(refresh)
            .catch(console.log);
    };

    const educationsToShow = education.filter((item) => item.from_month);

    return (
        <div className="branch-member-card">
            <div className="education-heading">
                <span>Education</span>
                <img
                    src="../../../../../../../images/add-new-icon.svg"
                    alt="Icon"
                    onClick={onAdd}
                />
            </div>
            <div className="education-list">
                {educationsToShow.length === 0 && (
                    <div className="member-list-col-new">
                        No education history found.
                    </div>
                )}
                {educationsToShow.map((item, index) => {
                    return (
                        <div
                            className="member-list-col-new"
                            key={`education-${index}`}
                        >
                            <div
                                className="col-left"
                                onClick={() => {
                                    onEdit(educationsToShow[index]);
                                }}
                            >
                                <div className="member-info-new-details">
                                    <h4>{item.institute}</h4>
                                    <span>{item.course}</span>
                                    <p>
                                        {Months[item.from_month]}{' '}
                                        {item.from_year} -{' '}
                                        {item.currentlyPursuing
                                            ? 'Present'
                                            : `${Months[item.to_month]} ${
                                                  item.to_year
                                              }`}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="col-right"
                                onClick={() => {
                                    onDelete(educationsToShow[index]);
                                }}
                            >
                                <img
                                    src="../../../../../../../images/delete-icon.svg"
                                    alt="ChatIcon"
                                />
                            </div>
                        </div>
                    );
                })}
            </div>

            {/*  Education Add/Edit Modal */}
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogStyles}
            >
                <EducationDetailEditModal
                    education={selectedRecord}
                    onUpdate={actuallyUpdate}
                    onCancel={toggleHideDialog}
                />
            </Dialog>

            {/*  Education Delete Modal */}
            <Dialog 
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <DeleteModal
                    text={modalText}
                    onClick={actuallyDelete}
                    onCancel={toggleHideDialog1}
                />
            </Dialog>
        </div>
    );
};

export default EducationDetails;
