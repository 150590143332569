import React, { useEffect, useState } from 'react';
import CollageView from './CollageView';
import linkify from '../../../../../../../helpers/linkify';
import readMorify from '../../../../../../../helpers/readMorify';
import PollProgress from './PollProgress';
import Api from '../../../../../../../common/api/api';
import { useBoolean } from '@fluentui/react-hooks';

const MediaSection = ({
    description,
    clickable,
    images,
    option,
    id,
    daysLeft,
    question,
    poll,
}) => {
    const [text, setText] = useState(description);
    const [options, setOptions] = useState([]);
    const [showingReadMore, setShowingReadMore] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [click, setClick] = useState(false);
    const [percentage, setPercentage] = useState([
        { id: 0, title: '', count: 0 },
    ]);

    const [showProgress, setShowProgress] = useState(false);
    const [showProgress1, setShowProgress1] = useState(false);

    useEffect(() => {
        const { readMore, text: t } = readMorify(description);
        setShowReadMore(readMore);
        setText(t);
        setClick(clickable);
    }, [description]);

    const onReadMore = (e, state) => {
        e.preventDefault();
        setShowingReadMore(state);
    };

    const getPollVisibilty = () => {
        if (
            (poll && poll.voted === true && poll.closed === true) ||
            (poll.voted === true && poll.closed === false) ||
            (poll.voted === false && poll.closed === true)
        ) {
            return true;
        }
    };

    useEffect(() => {
        if (poll !== null) {
            if (getPollVisibilty()) {
                let totalCount = 0;
                let index = 0;
                poll['count'].forEach((element) => {
                    percentage[index] = {
                        id: element['id'],
                        title: element['title'],
                        count: element['count'],
                    };
                    totalCount = totalCount + element['count'];
                    setTotalCount(totalCount);
                    setPercentage(percentage);
                    index++;
                });
                setShowProgress1(true);
            }
        }
    }, []);

    const handleProgressPercentage = (value) => {
        Api.getInstance()
            .savePollVotes(id, value)
            .then((response) => {
                let index = 0;
                let totalCount = 0;
                response.forEach((element) => {
                    percentage[index] = {
                        id: element['id'],
                        title: element['title'],
                        count: element['count'],
                    };
                    totalCount = totalCount + element['count'];
                    setTotalCount(totalCount);
                    setPercentage(percentage);
                    index++;
                });
            })
            .then(console.log)
            .finally(() => {
                setLoading(false);
            });

        setShowProgress(true);
    };

    return (
        <div>
            {/* Descroption Part */}
            <div className="card-description">
                <div
                    dangerouslySetInnerHTML={{
                        __html: linkify(
                            (showReadMore && !showingReadMore
                                ? text
                                : description
                            ).replace(/(?:\r\n|\r|\n)/g, '<br>'),
                        ),
                    }}
                />
                {showReadMore && (
                    <>
                        {showingReadMore ? (
                            <a
                                href="#"
                                onClick={(e) => {
                                    onReadMore(e, false);
                                }}
                            >
                                Read Less
                            </a>
                        ) : (
                            <a
                                onClick={(e) => {
                                    onReadMore(e, true);
                                }}
                                href="#"
                            >
                                Read More
                            </a>
                        )}
                    </>
                )}
            </div>

            {/*<div className="post-image">
                <img src="../../../../../../images/feedimage.svg" alt="Feed" />
            </div>*/}

           {poll && (<>
            <div className="options-card">
                <span>{question}</span>
                <div className="option-desc">
                    {poll &&
                        poll.contentType === 'poll' &&
                        poll.voted === false &&
                        poll.closed === false &&
                        option && (
                            <>
                                {Object.values(option).map((value, index) => {
                                    return (
                                        <>
                                            {click === true ? (
                                                <>
                                                    {!showProgress && (
                                                        <p
                                                            key={index}
                                                            onClick={() =>
                                                                handleProgressPercentage(
                                                                    value,
                                                                )
                                                            }
                                                        >
                                                            {value['title']}
                                                        </p>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {!showProgress && (
                                                        <p key={index}>
                                                            {value['title']}
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    );
                                })}
                            </>
                        )}
                </div>

                {/* Poll Progress */}
                {showProgress && (
                    <>
                        {Object.values(percentage).map((value, index) => {
                            return (
                                <>
                                    <PollProgress
                                        bgcolor={'#EAF4FF'}
                                        completed={
                                            percentage[index]['count'] === 0
                                                ? 0
                                                : Math.round(
                                                      (percentage[index][
                                                          'count'
                                                      ] /
                                                          totalCount) *
                                                          100,
                                                  ).toFixed(2)
                                        }
                                        title={percentage[index]['title']}
                                    />
                                </>
                            );
                        })}
                    </>
                )}

                {showProgress1 && (
                    <>
                        {Object.values(percentage).map((value, index) => {
                            return (
                                <>
                                    <PollProgress
                                        bgcolor={'#EAF4FF'}
                                        completed={
                                            percentage[index]['count'] === 0
                                                ? 0
                                                : Math.round(
                                                      (percentage[index][
                                                          'count'
                                                      ] /
                                                          totalCount) *
                                                          100,
                                                  ).toFixed(2)
                                        }
                                        title={percentage[index]['title']}
                                    />
                                </>
                            );
                        })}
                    </>
                )}

                {poll && poll.contentType === 'poll' && (
                    <>
                        <p className="days-left">
                            {poll.closed === true ? 'Poll Closed' : daysLeft}
                        </p>
                    </>
                )}
            </div>
            </>)}

            {/* Collage View */}

            <CollageView images={images} />
        </div>
    );
};

export default MediaSection;
