import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Table from '../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';
import ContactUsModalView from '../../../common/modals/ContactUsModalView';
import { Dialog } from '@fluentui/react';
import { useEffect, useState } from 'react';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 34,
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '169px !important',
    },
};

const ListContactUsPage = () => {
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);

    const [viewItem, setViewItem] = useState({
        name: '',
        email: '',
        subject: '',
        date: '',
        description: null,
    });

    const history = useHistory();
    return (
        <MainLayout>
            <Table
                type={'contactUs'}
                name={'Contact Us'}
                tableName={'Queries'}
                deleteIcon={true}
                showSortFilter={true}
                onSolveClick={(item) => {
                }}
                onViewItem={(item) => {
                    setViewItem(item);
                    toggleHideDialog1();
                }}
            />

              <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <ContactUsModalView
                    text={'Query Details'}
                    item={viewItem}
                    onCancel={toggleHideDialog1}
                />
            </Dialog>
        </MainLayout>
    );
};

export default ListContactUsPage;

