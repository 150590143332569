import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import ListCategoryPage from '../views/main/Category/ListCategoryPage';
import AddCategoryPage from '../views/main/Category/AddCategoryPage';
import EmailCampaignCategoryAccordianTab from '../common/EmailCampaignCategoryAccordianTab';


function CategoryRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/list`} exact component={ListCategoryPage} />
            <Route path={`${url}/add`} exact component={AddCategoryPage} />
            <Route path={`${url}/:id/edit`} exact component={AddCategoryPage} />
            <Route path={`${url}/categoryList`} exact component={ListCategoryPage} />

        </SwitchWithNotFound>
    );
}

export default CategoryRoutes;
