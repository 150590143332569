import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-main">
                <ul>
                    <li>
                        <Link>
                            <img
                                src="../../../../../images/mobile-icons/home.svg"
                                alt="Icon"
                            />
                            <span>Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <img
                                src="../../../../../images/mobile-icons/event.svg"
                                alt="Icon"
                            />
                            <span>Events</span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <img
                                src="../../../../../images/mobile-icons/network.svg"
                                alt="Icon"
                            />
                            <span>Network</span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <img
                                src="../../../../../images/mobile-icons/jobs.svg"
                                alt="Icon"
                            />
                            <span>Jobs</span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <img
                                src="../../../../../images/mobile-icons/other.svg"
                                alt="Icon"
                            />
                            <span>Others</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
