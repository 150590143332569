import React, { useEffect, useState } from 'react';

const DeleteModal = (props) => {
    return (
        <div className="manage-column">
            <div className="modal-icons">
                <div>
                    <img src="../../../images/delete-icons.svg"></img>
                </div>
                <div className="cross-icon">
                    <img
                        onClick={props.onCancel}
                        src="../../../images/cross-icon.svg"
                    ></img>
                </div>
            </div>
            <div className="modal-heading">
                <span>You are about to delete a {props.text}</span>
            </div>
            <div className="delete-columns">
                <p>Are you sure you want to delete {props.text} ?</p>
                <span>This action cannot be undone.</span>
            </div>
            <div className="column-buttons">
                <button onClick={props.onCancel} className="cancel-btn">
                    No, Keep it
                </button>
                <button className="delete" onClick={props.onClick}>
                    Yes, Delete 
                </button>
            </div>
        </div>
    );
};

export default DeleteModal;
