import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useHistory } from 'react-router-dom';
import SchoolSettingsAccordianTabs from '../../../common/SchoolSettingsAccordianTabs';
import PortalSettingTabsButton from './components/PortalSettingTabsButton';

// Styles definition

const stackStyles = {
    root: {
        padding: 30,
        width: '100%',
        float: 'left',
    },
};

const stackItemStyles = {
    root: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 30,
        border: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        borderRadius: 8,
    },
};

const SchoolSettings = () => {
    const history = useHistory();
    return (
        <MainLayout>

            <Stack styles={stackStyles}>
                <Stack.Item styles={stackItemStyles}>
                    <Stack.Item>
                        <SchoolSettingsAccordianTabs />
                    </Stack.Item>
                </Stack.Item>
            </Stack>
        </MainLayout>
    );
};

export default SchoolSettings;
