import React from 'react';
import { Route } from 'react-router-dom';

import LoginPage from '../views/auth/Login/LoginPage';
import ForgotPage from '../views/auth/Forgot/ForgotPage';
import ResetPage from '../views/auth/Forgot/ResetPage';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import SelectSchoolPage from '../views/auth/SelectSchool/SelectSchoolPage';

function AuthRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/login`} exact>
                <LoginPage />
            </Route>
            <Route path={`${url}/forgot`} exact>
                <ForgotPage />
            </Route>
            <Route path={`${url}/reset`} exact>
                <ResetPage />
            </Route>
            <Route path={`${url}/schools`} exact>
                <SelectSchoolPage />
            </Route>
        </SwitchWithNotFound>
    );
}

export default AuthRoutes;
