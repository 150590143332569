import React from 'react';
import HeaderLeft from './components/HeaderLeft';
import HeaderRight from './components/HeaderRight';

const HeaderItems = () => {
    return (
        <div className="container">
            <div className="header-main">
                <HeaderLeft />
                <HeaderRight />
            </div>
        </div>
    );
};

export default HeaderItems;
