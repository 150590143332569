import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../../../../../../common/api/api';
import Initialising from '../../../../../../../common/controls/Initialising';

const RightMemberSection = () => {
    const [loading, setLoading] = useState(true);
    const [batchMates, setBatchMates] = useState([]);
    useEffect(() => {
        Api.getInstance()
            .getBatchMates(3)
            .then(setBatchMates)
            .catch(console.log)
            .finally(() => {
                setLoading(false);
            });
    }, []);
    // if (loading) {
    //     return <Initialising />;
    // }
    return (
        <>
            {batchMates != '' ? (
                <div className="branch-member-card" style={{ marginTop: 0 }}>
                    <div className="memer-heading">
                        <span>Your Batchmates</span>
                    </div>
                    <div className="member-list">
                        {/* Loop Div */}
                        {batchMates.slice(0, 3).map((mate, index) => {
                            return (
                                <Link
                                    to={`/user/${mate.id}/profile`}
                                    key={`batch-mate-${mate.id}`}
                                >
                                    <div className="member-list-col">
                                        <div className="col-left">
                                            <div className="member-pic">
                                                <img
                                                    src={
                                                        Api.getInstance().getImageUrl(
                                                            mate.image,
                                                        ) ||
                                                        '../../../../../../images/new-default-icon.png'
                                                    }
                                                    alt={mate.name}
                                                />
                                            </div>
                                            <div className="member-info">
                                                <span>{mate.name}</span>
                                                <p>{mate.batch_name}</p>
                                            </div>
                                        </div>
                                        {/* <div className="col-right">
                        <img
                            src="../../../../../../images/chat.svg"
                            alt="ChatIcon"
                        />
                    </div> */}
                                    </div>
                                </Link>
                            );
                        })}
                        {/* All Members View Div */}
                        <div className="view-all-member">
                            <Link to="/user/batchmember">
                                <span>View all members</span>
                                <img
                                    src="../../../../../../images/view-all.svg"
                                    alt="viewall"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
export default RightMemberSection;
