import React from 'react';
import MainLayout from '../../Components/Layout/MainLayout';
import MessagesItems from './Components/MessagesItems';

const Messages = () => {
    return (
        <MainLayout>
            <MessagesItems />
        </MainLayout>
    );
};

export default Messages;
