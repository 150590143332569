import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { DatePicker, Pivot, PivotItem } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import Api from './api/api';
import UserFilters from './modals/UserFilters';
import { useHistory, useParams } from 'react-router-dom';
import Initialising from './controls/Initialising';
import ButtonWithLoader from './controls/ButtonWithLoader';
import { durationOptions } from './duration';
import { Dropdown } from '@fluentui/react';
import InputWithLabel from './controls/InputWithLabel';
import PollCard from '../views/AlumniFrontEnd/Main/Home/components/SubComponents/PollCard';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
        marginTop: 25,
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const CreatePollTabs = () => {
    const [poll, setPoll] = useState({
        description: '',
        question: '',
        option: [{ id: 0, title: '' }],
        duration: '',
        to: { type: 'all', filters: {} },
        emailAlert: '',
    });

    const [val, setVal] = useState([
        { id: 0, title: '' },
        { id: 0, title: '' },
    ]);

    const [errors, setErrors] = useState({});
    const [optionErrors, setOptionErrors] = useState([
        { option: '' },
        { option: '' },
    ]);

    const $todaysDate = new Date();
    {/*const $minDate = `${$todaysDate.getFullYear()}-${
        $todaysDate.getMonth() + 1
    }-${$todaysDate.getDate()}`;*/}

    const year = $todaysDate.getFullYear();
    const month = String($todaysDate.getMonth() + 1).padStart(2, '0');
    const day = String($todaysDate.getDate()).padStart(2, '0');
    const $minDate = `${year}-${month}-${day}`;


    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    const [selectedKey, setSelectedKey] = useState('0');
    const [initialising, setInitialising] = useState(true);

    const fileInput = useRef(null);

    const { id } = useParams();

    const handleAdd = () => {
        const indexes = [...val, { id: 0, title: '' }];
        setVal(indexes);
        const errors = [...optionErrors, { option: '' }];
        setOptionErrors(errors);
    };

    const removeOption = (i) => {
        const deleteVal = [...val];
        {
            i != 0 && i != 1 ? deleteVal.splice(i, 1) : '';
        }
        setVal(deleteVal);
        setPoll((i) => ({
            ...i,
            option: deleteVal,
        }));
    };

    const handleInputChange = (onChangeValue, i) => {
        const inputdata = [...val];
        inputdata[i] = { id: 0, title: onChangeValue.target.value };
        setVal(inputdata);
        setPoll((i) => ({
            ...i,
            option: inputdata,
        }));
    };

    useEffect(() => {
        if (id) {
            setInitialising(true);
            Api.getInstance()
                .getPoll(id)
                .then((response) => {
                    setPoll(response);
                    const inputdata = [...val];
                    for (
                        let index = 0;
                        index < response.option.length;
                        index++
                    ) {
                        inputdata[index] = response.option[index];
                        setVal(inputdata);
                        setOptionErrors((optionErrors) => [
                            ...optionErrors,
                            { option: '' },
                        ]);
                    }
                    setInitialising(false);
                })
                .catch(console.log);
        } else {
            setInitialising(false);
        }
    }, [id]);

    const handleChange = (e) => {
        let c = { ...poll };
        c[e.target.name] = e.target.value;
        setPoll(c);
    };

    const validate = () => {
        let fields = [
            {
                label: 'Description',
                key: 'description',
                required: true,
            },
            {
                label: 'Question',
                key: 'question',
                required: true,
            },
            {
                label: 'Option',
                key: 'option',
                required: true,
            },
        ];

        let e = {};
        let optionError = [...val];
        let foundValidationError = false;
        fields.forEach((field) => {
            if (field.key === 'option') {
                const inputdata = [...val];
                inputdata.forEach((element, i) => {
                    if (
                        element['title'] === '' &&
                        element['title'].trim() === ''
                    ) {
                        foundValidationError = true;
                        optionError[i][
                            field.key
                        ] = `${field.label} is required`;
                    }
                });
            }

            if (field.required && poll[field.key] === '') {
                e[field.key] = `${field.label} is required`;
            }

            if (field.regex && poll[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(poll[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        setErrors(e);
        setOptionErrors(optionError);

        return Object.keys(e).length === 0 && foundValidationError === false;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);
        Api.getInstance()
            .createPoll(poll)
            .then((response) => {
                setPoll(response);
                next();
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const publishPoll = () => {
        setSending(true);
        Api.getInstance()
            .publishPoll(poll.id)
            .then((response) => {
                next();
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setSending(false);
            });
    };

    const handleBack = () => {
        if (selectedKey === '0') {
            history.push('/poll');
            return;
        }
        setSelectedKey(String(parseInt(selectedKey) - 1));
    };

    const next = () => {
        if (selectedKey === '2') {
            history.push('/poll');
            return;
        }
        setSelectedKey(String(parseInt(selectedKey) + 1));
    };

    return (
        <div className="user-tabs">
            <Pivot aria-label="Basic Pivot Example" selectedKey={selectedKey}>
                <PivotItem
                    itemKey={'0'}
                    headerText="1. Create Your Poll"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'Poll',
                    }}
                >
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Create your poll
                    </span>
                    {initialising && <Initialising />}
                    {!initialising && (
                        <>
                            <Stack>
                                <div className="add-user-form">
                                    <div
                                        className="form-group current pr"
                                        style={{ height: 'auto' }}
                                    >
                                        <div
                                            className={
                                                errors.description
                                                    ? 'input-with-label-error'
                                                    : 'input-with-label'
                                            }
                                        >
                                            <label>
                                                Description{' '}
                                                <span className="estirc-sign">
                                                    *
                                                </span>
                                            </label>
                                            <textarea
                                                placeholder="Write something about your poll"
                                                rows={4}
                                                onChange={handleChange}
                                                name="description"
                                                value={poll.description}
                                                required={true}
                                                estric="*"
                                                cols={20}
                                                style={{
                                                    height: 'auto',
                                                    resize: 'auto',
                                                }}
                                            ></textarea>
                                            <span>{errors.description}</span>
                                        </div>
                                        <div
                                            className={
                                                errors.question
                                                    ? 'input-with-label-error'
                                                    : 'input-with-label'
                                            }
                                        >
                                            <label>
                                                Your question{' '}
                                                <span className="estirc-sign">
                                                    *
                                                </span>
                                            </label>
                                            <textarea
                                                placeholder="E.g.,How do commute to work?"
                                                rows={4}
                                                onChange={handleChange}
                                                name="question"
                                                value={poll.question}
                                                required={true}
                                                estric="*"
                                                cols={70}
                                                style={{
                                                    height: 'auto',
                                                    resize: 'auto',
                                                }}
                                            ></textarea>
                                            <span>{errors.question}</span>
                                        </div>
                                        <div>
                                            <div className="input-with-label">
                                                <label>Poll Duration</label>
                                                <input
                                                    type="date"
                                                    name="duration"
                                                    onChange={handleChange}
                                                    min={$minDate}
                                                    value={poll.duration}
                                                />
                                                {/* <DatePicker name="duration" /> */}
                                            </div>
                                        </div>
                                        <div className='send-email-alert-checkbox'>
                                         <input
                                            type="checkbox"
                                            name="emailAlert"
                                            onClick={(e) => {
                                                handleChange({
                                                    target: {
                                                        value: e.target.checked,
                                                        name: 'emailAlert',
                                                    },
                                                });
                                            }}
                                             checked={poll.emailAlert}
                                            value={poll.emailAlert}
                                            defaultChecked={false}
                                        />

                                        <span>I would like to send an email notification for this poll</span>
                                        </div>
                                    </div>

                                    <div
                                        className="form-group current pl"
                                        style={{ height: 'auto' }}
                                    >
                                        <div>
                                            {Object.keys(val).map((key, i) => {
                                                return (
                                                    <>
                                                        <InputWithLabel
                                                            placeholder="Add Option"
                                                            key={i}
                                                            required={true}
                                                            errorMessage={
                                                                optionErrors[i]
                                                                    .option
                                                            }
                                                            label="Add Option"
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    i,
                                                                )
                                                            }
                                                            value={
                                                                val[key][
                                                                    'title'
                                                                ]
                                                            }
                                                            name="option"
                                                            estric="*"
                                                            className={
                                                                optionErrors[i]
                                                                    .option
                                                                    ? 'drop-error actual-height'
                                                                    : ''
                                                            }
                                                        />
                                                        {i != 0 && i != 1 ? (
                                                            <button
                                                                onClick={() =>
                                                                    removeOption(
                                                                        i,
                                                                    )
                                                                }
                                                                className="remove-option"
                                                            >
                                                                Remove
                                                            </button>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <br />
                                                        <br />
                                                    </>
                                                );
                                            })}
                                            <button
                                                className="poll-buton"
                                                onClick={handleAdd}
                                            >
                                                <img src="../../../../images/plus-icon.svg" />
                                                Add option
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Stack>
                            <Stack styles={stackNewStyles}>
                                <DefaultButton
                                    onClick={handleBack}
                                    text="Cancel"
                                    styles={stackDefaultStyles}
                                />

                                <ButtonWithLoader
                                    onClick={handleSubmit}
                                    name="Next"
                                    arrow="../../../images/button-arrow.svg"
                                    loading={loading}
                                />
                            </Stack>
                        </>
                    )}
                </PivotItem>
                <PivotItem itemKey={'1'} headerText="2.  Select Users">
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Select users whom you want this post to be shared
                        with.This post will be visible only to these selected
                        users.
                    </span>
                    <Stack>
                        <div className="add-user-form-new">
                            <div className="mailto-text">
                                <span>Share with:</span>
                            </div>
                            <div className="choice-box">
                                <div className="radio-buton">
                                    <input
                                        type="radio"
                                        checked={poll.to.type === 'all'}
                                        name="to"
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'to',
                                                    value: {
                                                        ...poll.to,
                                                        type: 'all',
                                                    },
                                                },
                                            });
                                        }}
                                    />{' '}
                                    <span>All Users</span>
                                </div>
                                <div className="radio-buton">
                                    <input
                                        checked={poll.to.type === 'specific'}
                                        type="radio"
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'to',
                                                    value: {
                                                        ...poll.to,
                                                        type: 'specific',
                                                    },
                                                },
                                            });
                                        }}
                                        value="SpecificUsers"
                                        name="to"
                                    />{' '}
                                    <span>Specific Users</span>
                                </div>
                            </div>
                        </div>
                        <div className="Userfilters">
                            {poll.to.type === 'specific' && (
                                <UserFilters
                                    showSubmitAndCancel={false}
                                    defaultFilters={poll.to.filters || {}}
                                    onChange={(filters) => {
                                        handleChange({
                                            target: {
                                                name: 'to',
                                                value: {
                                                    ...poll.to,
                                                    filters,
                                                },
                                            },
                                        });
                                    }}
                                />
                            )}
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={handleBack}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        <ButtonWithLoader
                            onClick={handleSubmit}
                            name="Next"
                            arrow="../../../images/button-arrow.svg"
                            loading={loading}
                        />
                    </Stack>
                </PivotItem>
                <PivotItem itemKey={'2'} headerText="3. Preview and Publish">
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Preview your poll and publish it
                    </span>
                    <Stack styles={stackStyles}>
                        <div className="add-user-form">
                            <div className="">
                                <PollCard poll={poll} clickable={false} />
                            </div>
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={handleBack}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        <div style={{ marginRight: 10 }}>
                            <ButtonWithLoader
                                onClick={handleSubmit}
                                name="Save as Draft"
                                arrow="../../../images/button-arrow.svg"
                                loading={loading}
                            />
                        </div>
                        <div className="next-button">
                            <ButtonWithLoader
                                onClick={publishPoll}
                                name="Publish Poll"
                                loading={sending}
                            />
                        </div>
                    </Stack>
                </PivotItem>
            </Pivot>
        </div>
    );
};

export default CreatePollTabs;