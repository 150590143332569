import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Api from '../../../../../common/api/api';

const HeaderLeft = ({ school }) => {
    return (
        <div className="header-col-left">
            <Link className="dlogo" to="/user/home">
                <div className="dlogo">
                    {Api.getInstance().getImageUrl(school.logo) ? (
                        <img
                            src={Api.getInstance().getImageUrl(school.logo)}
                            alt={school.name}
                        />
                    ) : (
                        <div>{school.name}</div>
                    )}
                </div>
            </Link>
            <Link to="/user/home">
                <div className="mlogo">
                    {Api.getInstance().getImageUrl(school.logo) ? (
                        <img
                            src={Api.getInstance().getImageUrl(school.logo)}
                            alt={school.name}
                        />
                    ) : (
                        <div>{school.name}</div>
                    )}
                </div>
            </Link>
            {/* <div className="header-search">
                <img src="../../../../../images/search-header.svg" alt="Icon" />
                <input
                    className="search-input"
                    type="text"
                    placeholder="Search"
                    name="hsearch"
                />
            </div> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    school: state.app.school,
});

export default connect(mapStateToProps)(HeaderLeft);
