import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import ListPostAndPollPage from '../views/main/PostAndPoll/ListPostAndPollPage';

function PostAndPollRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/list`} exact component={ListPostAndPollPage} />
        </SwitchWithNotFound>
    );
}

export default PostAndPollRoutes;

