import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
    },
};
const stackItemStyles = {
    root: {
        height: '100vh',
        width: '30%',
    },
};

const newstackItemStyles = {
    root: {
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
};

const LoadingLayout = ({ children, heading, description }) => {
    return (
        <Stack horizontal styles={stackStyles}>
            {/* <div
                className="adminlogin-left"
                style={{
                    backgroundImage: `url(${'.../../../images/auth-back.svg'})`,
                }}
            ></div> */}
            <Stack.Item grow={10} styles={newstackItemStyles}>
                {children}
            </Stack.Item>
        </Stack>
    );
};

export default LoadingLayout;
