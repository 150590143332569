import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useParams,
} from 'react-router-dom';
import DashboardPage from '../views/main/Dashboard/DashboardPage';
import AlumniRoutes from './AlumniRoutes';
import BatchRoutes from './BatchRoutes';
import ContentRoutes from './ContentRoutes';
import CampaignRoutes from './CampaignRoutes';
import StaffRoutes from './StaffRoutes';
import AccountRoutes from './AccountRoutes';
import HomePage from '../views/main/Dashboard/HomePage';
import SchoolSettingsRoute from './SchoolSettingsRoute';
import SelectSchoolPage from '../views/auth/SelectSchool/SelectSchoolPage';
import CategoryRoutes from './CategoryRoutes';
import ListsRoutes from './ListsRoutes';
import PollRoutes from './PollRoutes';
import RegistrationFormRoutes from './RegistrationFormRoutes';
import EmailTemplateRoutes from './EmailTemplateRoutes';
import PostAndPollRoutes from './PostAndPollRoutes';
import ContactUsRoutes from './ContactUsRoutes';
import NotificationRoutes from './NotificationRoutes';
import FieldRoutes from './FieldRoutes';
import FieldSettingRoutes from './FieldSettingRoutes';

export const SchoolRoutes = () => {
    var { school_id } = useParams();
    //TODO: add some validation here and inform user if tenant is invalid
    return (
        <Router basename={school_id}>
            <Switch>
                <Route path="/" component={HomePage} exact />
                <Route
                    path="/auth/schools"
                    component={SelectSchoolPage}
                    exact
                />
                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/alumni" component={AlumniRoutes} />
                <Route path="/staff" component={StaffRoutes} />
                <Route path="/post" component={ContentRoutes} />
                <Route path="/batch" component={BatchRoutes} />
                <Route path="/field" component={FieldRoutes} />
                <Route path="/campaign" component={CampaignRoutes} />
                <Route path="/account" component={AccountRoutes} />
                <Route path="/settings" component={SchoolSettingsRoute} />
                <Route path="/category" component={CategoryRoutes} />
                <Route path="/lists" component={ListsRoutes} />
                <Route path="/poll" component={PollRoutes} />
                <Route path="/registration" component={RegistrationFormRoutes} />
                <Route path="/notification" component={NotificationRoutes} />
                <Route path="/template" component={EmailTemplateRoutes} />
                <Route path="/contactUs" component={ContactUsRoutes} />
                <Route path="/fieldSettings" component={FieldSettingRoutes} />
                {/* <Route path="/pollandpost" component={PostAndPollRoutes} /> */}
            </Switch>
        </Router>
    );
};
