import React, { useEffect, useState } from 'react';
import AuthLayout from '../../../layouts/auth/AuthLayout';
import Api from '../../../common/api/api';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Initialising from '../../../common/controls/Initialising';

const SelectSchoolPage = ({
    dispatchSelectSchool,
    dispatchUpdateSchool,
    user,
}) => {
    const [schools, setSchools] = useState([]);
    useEffect(() => {
        Api.getInstance()
            .getSchools()
            .then((response) => {
                setSchools(response);
                if (response.length === 1) {
                    selectSchool(response[0]);
                }
            })
            .then((e) => {});
    }, []);

    const history = useHistory();

    const selectSchool = (school) => {
        dispatchSelectSchool(school.school.id);
        dispatchUpdateSchool(school.school);
        setTimeout(() => {
            if (school.type === 'alumni') {
                history.push(`/user/home`);
            } else {
                history.push(`/dashboard`);
            }
        }, 1000);
    };

    return (
        <AuthLayout>
            {schools.length === 1 && <Initialising />}
            {schools.length > 1 && (
                <span className="choose-text">Choose Your School</span>
            )}
            {schools.length > 1 &&
                schools.map((school) => {
                    return (
                        <Link
                            to={'#'}
                            onClick={() => {
                                selectSchool(school);
                            }}
                            key={school.school.id}
                            className="select-school"
                        >
                            <span>{school.school.name}</span>
                        </Link>
                    );
                })}
        </AuthLayout>
    );
};
const mapStateToProps = (state) => ({
    user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSelectSchool: (school_id, school) =>
        dispatch({
            type: 'AUTH_SELECT_SCHOOL',
            data: { school_id, school },
        }),
    dispatchUpdateSchool: (school) =>
        dispatch({
            type: 'UPDATE_SCHOOL',
            data: { school },
        }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectSchoolPage);
