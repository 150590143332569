import * as React from 'react';
import { useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import InputWithLabel from './controls/InputWithLabel';
import ButtonWithLoader from './controls/ButtonWithLoader';
import Api from './api/api';
import UpdateModal from './modals/UpdateModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        maxHeight: '169px !important',
    },
};

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const stackNewDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: '#2962FF',
        color: '#ffffff',
        fontSize: 16,
        border: 'none',
        fontWeight: 'normal',
    },
};

const dialogNewStyles = {
   main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px'
    },
};

const PasswordAccordianTabs = () => {

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const [input, setInput] = useState({
        current_password: '',
        new_password: '',
        confirm_new_password: '',
    });

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const validate = () => {
        let fields = [
            {
                label: 'Current Password',
                key: 'current_password',
                required: true,
            },
            {
                label: 'New Password',
                key: 'new_password',
                required: true,
            },
            {
                label: 'Confirm New Password',
                key: 'confirm_new_password',
                required: true,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.required && input[field.key] === '') {
                e[field.key] = `${field.label} is required`;
            }
        });

        if (
            input.new_password &&
            input.confirm_new_password &&
            input.new_password !== input.confirm_new_password
        ) {
            e['confirm_new_password'] = 'Passwords do not match';
            e['new_password'] = 'Passwords do not match';
        }
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);

        Api.getInstance()
            .changePassword(input)
            .then((response) => {
               toggleHideDialog();
                setInput({
                    current_password: '',
                    new_password: '',
                    confirm_new_password: '',
                });
            })
            .catch((e) => {
                if (e.fields) {
                    setErrors(e.fields);
                } else {
                    alert(e.error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

     const updateChanges = () => {
        window.location.reload();
    };

    return (
        <div className="user-tabs" style={{ marginTop: 0 }}>
            <Stack>
                <div className="add-user-form">
                    <div className="form-group current pr">
                        <InputWithLabel
                            errorMessage={errors.current_password}
                            type="password"
                            value={input.current_password}
                            onChange={handleChange}
                            label="Current Password"
                            name="current_password"
                        />
                    </div>
                    <div className="form-group current pl">
                        <InputWithLabel
                            errorMessage={errors.new_password}
                            type="password"
                            onChange={handleChange}
                            value={input.new_password}
                            label="New Password"
                            name="new_password"
                        />
                    </div>
                    <div className="form-group current pr">
                        <InputWithLabel
                            errorMessage={errors.confirm_new_password}
                            type="password"
                            onChange={handleChange}
                            value={input.confirm_new_password}
                            label="Confirm New Password"
                            name="confirm_new_password"
                        />
                    </div>
                </div>
            </Stack>
            <Stack styles={stackNewStyles}>
                {/* <div className="next-button">
                    <button
                        style={{ width: 80 }}
                    >
                        <span>Update</span>
                    </button>
                </div> */}
                <ButtonWithLoader
                    onClick={handleSubmit}
                    // onClick={toggleHideDialog}
                    name="Update"
                    loading={loading}
                />
            </Stack>

            {/* </Pivot> */}


            {/*  Update  Modal */}
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <UpdateModal 
                text={"Password has been updated successfully."}
                onClick={updateChanges} 
                />
            </Dialog>
        </div>
    );
};

export default PasswordAccordianTabs;
