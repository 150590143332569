import React from 'react';

const ConversationInputTool = () => {
    return (
        <div className="converstion-input-tool">
            <div className="tool-bar">
                <div className="typing-bar">
                    <div className="smiley">
                        <img
                            src="../../../../../../images/smiley.svg"
                            alt="Icon"
                        />
                    </div>
                    <div className="type-box">
                        <textarea placeholder="Write a message..." />
                    </div>
                </div>
                <div className="action-bar">
                    <div className="attchment-box">
                        <img
                            src="../../../../../../images/attchment-icon.svg"
                            alt="Icon"
                        />
                    </div>
                    <div className="send-chat-buton">
                        <button>
                            <img
                                src="../../../../../../images/send-icon.svg"
                                alt="Icon"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ConversationInputTool;
