import * as React from 'react';
import { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import InputWithLabel from './controls/InputWithLabel';
import ButtonWithLoader from './controls/ButtonWithLoader';
import Api from './api/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Initialising from './controls/Initialising';
import UpdateModal from './modals/UpdateModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const stackNewDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: '#2962FF',
        color: '#ffffff',
        fontSize: 16,
        border: 'none',
        fontWeight: 'normal',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px'
    },
};

const checkboxsyle = {
    display: 'flex',
    float: 'left',
    width: '25%',
    overflowWrap: 'break-word',
};

const checkboxLabel = {
    marginLeft: 5,
};

const SettingsAccordianTabs = ({ school_id }) => {
    const [user, setUser] = useState({});
    const [uploading, setUploading] = useState(false);
    const [initialising, setInitialising] = useState(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
  

    useEffect(() => {
        if (!school_id) {
            return;
        }
        Api.getInstance()
            .getSchool(school_id)
            .then((response) => {
                setUser(response);
                
            })
            .catch(() => {})
            .finally(() => {
                setInitialising(false);
            });
    }, [school_id]);

    const [input, setInput] = useState({
        name: '',
        image: '',
        default_password: '',
        send_login_email: Boolean,
        batch_optional: '',
        phone_optional: Boolean,
    });

    useEffect(() => {
        setInput({
            name: user.name,
            logo: user.logo,
            default_password: user.default_password,
            send_login_email: user.send_login_email,
            year_of_joining: user.year_of_joining,
            year_of_leaving: user.year_of_leaving,
            batch_optional: user.batch_optional,
            phone_optional: user.phone_optional,
        });
    }, [user]);

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const validate = () => {
        let fields = [
            {
                label: 'Name',
                key: 'name',
                required: true,
            },
            {
                label: 'Sender Name',
                key: 'sender_name',
                required: true,
            },
            {
                label: 'Sender Email',
                key: 'sender_email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
            {
                label: 'Receiver Email',
                key: 'receiver_email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.required && input[field.key] === '') {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });

        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const updateChanges = () => {
        window.location.reload();
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);

        Api.getInstance()
            .updateSchool(school_id, input)
            .then((response) => {
                toggleHideDialog1();
            })
            .catch((e) => {
                if (e.fields) {
                    setErrors(e.fields);
                } else {
                    alert(e.error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        Api.getInstance()
            .uploadFile(
                {
                    extension: file.name.split('.').pop(),
                    'Content-Type': file.type,
                },
                file,
            )
            .then(({ key }) => {
                setInput((i) => ({ ...i, logo: key }));
            })
            .catch(console.log);
    };
    
    if (initialising) {
        return <Initialising />; 
    }

    return (
        <div className="user-tabs" style={{ marginTop: 0 }}>
            <h4 className="change-password">Institute Settings</h4>
            <Stack>
                <div className="add-user-form">
                    <div className="form-group current pr">
                        <InputWithLabel
                            errorMessage={errors.name}
                            type="text"
                            value={input.name}
                            onChange={handleChange}
                            label="Name"
                            name="name"
                            estric="*"
                        />
                    </div>
                    <div className="form-group current pl">
                        <div className="input-with-label file-uploader">
                            <label>Logo</label>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                label="Logo"
                                name="logo"
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        {input.logo && (
                            <img
                                height={100}
                                src={Api.getInstance().getImageUrl(input.logo)}
                                style={{ marginTop: 10 }}
                            />
                        )}
                    </div>
                    <div className="form-group current pr">
                        <InputWithLabel
                            errorMessage={errors.default_password}
                            type="text"
                            value={input.default_password}
                            onChange={handleChange}
                            label="Default Password"
                            name="default_password"
                        />
                    </div>
                </div>
            </Stack>
            <Stack styles={stackNewStyles}>
                {/* <div className="next-button">
                    <button
                        style={{ width: 80 }}
                    >
                        <span>Update</span>
                    </button>
                </div> */}
                <ButtonWithLoader
                    onClick={handleSubmit}
                    name="Update"
                    loading={loading}
                />
            </Stack>

            <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <UpdateModal
                    text={'Setting has been updated successfully.'}
                    onClick={updateChanges}
                />
            </Dialog>
        </div>
    );
};
const mapStateToProps = (state) => ({
    school_id: state.app.school_id,
});

export default withRouter(connect(mapStateToProps)(SettingsAccordianTabs));
