import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Api from '../api/api';

const HTMLEditor = ({ value, onChange, name, allowCodeEditing }) => {
    const [state, setState] = useState(EditorState.createEmpty());
    const [uploadedImages, setUploadedImages] = useState([]);
    const [val, setVal] = useState('');

    const [isEditingCode, setIsEditingCode] = useState(false);

    useEffect(() => {
        if (val !== value) {
            const contentBlock = htmlToDraft(value);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks,
                );
                setState(EditorState.createWithContent(contentState));
            }
        }
    }, [value]);

    const _uploadImageCallBack = (file) => {
        return new Promise((resolve, reject) => {
            Api.getInstance()
                .uploadFile(
                    {
                        extension: file.name.split('.').pop(),
                        'Content-Type': file.type,
                    },
                    file,
                )
                .then(({ key }) => {
                    resolve({
                        data: { link: Api.getInstance().getImageUrl(key) },
                    });
                })
                .catch(console.log);
        });
    };

    return (
        <>

            {allowCodeEditing ?
            <>
                <textarea
                    maxLength='20000'
                    rows={4}
                    onChange={onChange}
                    name={name}
                    value={value}
                    required={true}
                    estric='*'
                    cols={20}
                    style={{
                        height: 'auto',
                        resize: 'auto',
                    }}
                ></textarea>
                 <Editor
                    editorState={state}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={(e) => {
                        setState(e);
                        let v = draftToHtml(convertToRaw(state.getCurrentContent()));
                        setVal(v);
                        onChange({ target: { name, value: v } });
                    }}
                    toolbar={{
                        options: [
                            'inline',
                            'blockType',
                            'fontSize',
                            'fontFamily',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'image',
                            'remove',
                            'history',
                        ],
                        image: {
                            uploadCallback: _uploadImageCallBack,
                            previewImage: true,
                        },
                        inputAccept:
                            'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel',
                    }}
                />
                </>
                : <Editor
                    editorState={state}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={(e) => {
                        setState(e);
                        let v = draftToHtml(convertToRaw(state.getCurrentContent()));
                        setVal(v);
                        onChange({ target: { name, value: v } });
                    }}
                    toolbar={{
                        options: [
                            'inline',
                            'blockType',
                            'fontSize',
                            'fontFamily',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'image',
                            'remove',
                            'history',
                        ],
                        image: {
                            uploadCallback: _uploadImageCallBack,
                            previewImage: true,
                        },
                        inputAccept:
                            'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel',
                    }}
                />}
        </>
    );
};

export default HTMLEditor;