import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import SearchInput from '../../../../common/controls/SearchInput';
import { useBoolean } from '@fluentui/react-hooks';
import UserFilters from '../../../../common/modals/UserFilters';
import FullScreenModal from '../../../../hoc/FullScreenModal';
import ManageColumnModal from '../../../../common/modals/ManageColumnModal';
import SettingTableColumn from '../../../../common/modals/SettingTableColumn';
import ListSortPage from '../../ContactUs/ListSortPage';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 20,
    },
};

const stackItemStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        childrenGap: 10,
    },
};

const stackDefaultStyles = {
    root: {
        height: 36,
        borderRadius: 4,
        background: 'transparent',
        border: 1,
        borderColor: '#eeeeee',
        borderStyle: 'solid',
        marginRight: 10,
        fontWeight: 'normal',
        width: 162,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
};

const stackAdvancetStyles = {
    root: {
        height: 34,
        borderRadius: 4,
        background: 'transparent',
        border: 1,
        borderColor: '#2962FF',
        borderStyle: 'solid',
        marginRight: 10,
        fontWeight: 'normal',
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        paddingLeft: 10,
        paddingRight: 10,
    },
};
const stackAdvancetStylesForDropDown = {
    root: {
        height: 34,
        borderRadius: 4,
        background: 'transparent',
        marginRight: 10,
        fontWeight: 'normal',
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        paddingLeft: 10,
        paddingRight: 10,
    },
};

const stackgreytStyles = {
    root: {
        height: 34,
        borderRadius: 4,
        background: 'transparent',
        border: 1,
        borderColor: '#757575',
        borderStyle: 'solid',
        marginRight: 10,
        fontWeight: 'normal',
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        paddingLeft: 10,
        paddingRight: 10,
    },
};

const stackSetingStyles = {
    root: {
        cursor: 'pointer',
        height: 36,
    },
};

const stacknewStyle = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
};

const ListFilters = ({
    deleteIcon,
    onFilterChange,
    onQueryChange,
    onFilterQuery,
    defaultFilters,
    showAdvancedSearch = false,
    showSortFilter = false,
    onDeleteSelected,
    clearFilters,
    selectedCount,
    columns,
    updateColumns,
}) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialognew, { toggle: toggleHideDialognew }] = useBoolean(true);

    const selectedFilterCount = (filters) => {
        let count = 0;
        Object.keys(filters).forEach((k) => {
            if (Array.isArray(filters[k]) && filters[k].length > 0) {
                count++;
                return;
            }
            if (k === 'customFields') {
                Object.keys(filters[k]).forEach((k2) => {
                    if (filters[k][k2].type) {
                        count++;
                    }
                });
                return;
            }

            if (filters[k].type) {
                count++;
            }
        });
        return count;
    };

    let filterCount = selectedFilterCount(defaultFilters);

    return (
        <Stack horizontal styles={stackStyles}>
            <Stack.Item grow={12}>
                <SearchInput onChange={onQueryChange} />
            </Stack.Item>
            <Stack styles={stacknewStyle}>
                {showSortFilter && <>  <Stack.Item styles={stackAdvancetStylesForDropDown}>
                    <ListSortPage onChange={onFilterQuery}/>
                </Stack.Item></>} 
                <Stack.Item styles={stackItemStyles}>
                    {/* <Stack.Item styles={stackDefaultStyles}>
                    <img
                        src="../../../../images/filter.svg"
                        style={{ marginRight: 10 }}
                    />
                    <span
                        className="ms-fontSize-14 ms-fontWeight-regular "
                        style={{ marginTop: -3 }}
                    >
                        Batch
                    </span>
                </Stack.Item> */}
                 {showAdvancedSearch && (
                    <>
                        {filterCount > 0 ? (
                            <h5
                                className="ms-fontSize-16 ms-fontWeight-regular fontFamily blueColor"
                                style={{ marginRight: 10, cursor: 'pointer' }}
                            onClick={clearFilters}>
                                Clear Filters
                            </h5>
                        ) : (
                            ''
                        )}
                    </>
                )}
                    {deleteIcon ? (
                        <div>
                            {selectedCount > 0 && (
                                <div
                                    className={'delete-buton'}
                                    onClick={onDeleteSelected}
                                >
                                    <span>Delete</span>
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    {showAdvancedSearch && (
                        <>
                            {filterCount > 0 ? (
                                <Stack.Item
                                    styles={stackAdvancetStyles}
                                    onClick={toggleHideDialog}
                                >
                                    <img
                                        src="../../../../images/filter-blue.svg"
                                        style={{ marginRight: 10 }}
                                    />
                                    <span className="ms-fontSize-16 ms-fontWeight-regular fontFamily blueColor ">
                                        Advance Search{' '}
                                        {filterCount > 0
                                            ? `(${filterCount})`
                                            : ''}
                                    </span>
                                </Stack.Item>
                            ) : (
                                <Stack.Item
                                    styles={stackgreytStyles}
                                    onClick={toggleHideDialog}
                                >
                                    <img
                                        src="../../../../images/advance-search.svg"
                                        style={{ marginRight: 10 }}
                                    />
                                    <span className="ms-fontSize-16 ms-fontWeight-regular fontFamily filtercolor ">
                                        Advance Search
                                    </span>
                                </Stack.Item>
                            )}
                        </>
                    )}
                    <div
                        onClick={toggleHideDialognew}
                        className="setting-button"
                    >
                        <img src="../../../../images/settings.svg" />
                    </div>
                </Stack.Item>
            </Stack>

            {/* Advance Search Modal */}
            <FullScreenModal
                title={'Advanced Search'}
                hideDialog={hideDialog}
                toggleHideDialog={toggleHideDialog}
            >
                <UserFilters
                    defaultFilters={defaultFilters}
                    onCancel={toggleHideDialog}
                    showSubmitAndCancel={true}
                    onChange={(filters) => {
                        onFilterChange(filters);
                        toggleHideDialog();
                    }}
                />
            </FullScreenModal>

            {/* Manage Table Column Modal */}
            <ManageColumnModal
                title={'Manage Columns'}
                hideDialognew={hideDialognew}
                toggleHideDialognew={toggleHideDialognew}
            >
                <SettingTableColumn
                    onCancel={toggleHideDialognew}
                    columns={columns}
                    updateColumns={updateColumns}
                />
            </ManageColumnModal>
        </Stack>
    );
};

export default ListFilters;
