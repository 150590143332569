import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Link, withRouter } from 'react-router-dom';
import Api from '../../../common/api/api';
import { connect } from 'react-redux';
import NotificationDropDown from './NotificationDropDown';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
};

const stackItemStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        position: 'relative',
        cursor: 'pointer',
    },
};

const stackbellItemStyles = {
    root: {
        paddingRight: '20px',
    },
};

// Hook
function useOnClickOutside(ref, buttonRef, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (
                    !ref.current ||
                    ref.current.contains(event.target) ||
                    (buttonRef && buttonRef.current.contains(event.target))
                ) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler],
    );
}

const HeaderRightSection = ({ school_id, dispatchUpdateSchool }) => {
    const [user, setUser] = useState(null);
    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [audits, setAudits] = useState([]);

    // Account Settings Drop
    const [showDrop, setShowDrop] = useState(false);
    const [showNotificationDrop, setNotificationShowDrop] = useState(false);

    // OutsideClicked Event
    const ref = useRef();
    const buttonRef = useRef();
    useOnClickOutside(ref, buttonRef, () => setShowDrop(false));
    useOnClickOutside(ref, buttonRef, () => setNotificationShowDrop(false));

    const logout = () => {
        window.location.href = '/auth/login';
    };

    const goToNotificationPage = () => {
        window.location.href = '/notification/list';
    };

    useEffect(() => {
        let selected = undefined;
        schools.forEach((school) => {
            if (school.school.id === school_id) {
                selected = school.school;
            }
        });
        setSelectedSchool(selected);
        dispatchUpdateSchool(selected);
        if (schools.length > 0 && selected === undefined) {
            window.location.href = '/auth/schools';
        }
    }, [school_id, schools]);

    useEffect(() => {
        Api.getInstance()
            .getSchools()
            .then((response) => {
                setSchools(response);
            })
            .catch((e) => {});

        Api.getInstance()
            .getAdminProfile()
            .then((response) => {
                setUser(response);
            })
            .catch(() => {
                window.location.href = '/403';
            })
            .finally(() => {});
    }, []);

    const getInitials = (name) => {
        const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
        return initials;
    };

    if (!user) {
        return <></>;
    }

    return (
        <Stack horizontal styles={stackStyles}>
            {/* <Stack.Item>
                <Link>
                    <img
                        src="../../../../../images/notification.svg"
                        alt="Logo"
                    />
                </Link>
            </Stack.Item>
            <Stack.Item>
                <img src="../../../../../images/dashboard.svg" alt="Logo" />
            </Stack.Item> */}
            <Stack.Item
                style={stackbellItemStyles}
                onClick={() => setNotificationShowDrop(!showNotificationDrop)}
            >

                {/*<img
                    src="../../../../../images/notification.svg"
                    alt="Logo"
                    style={{ marginRight: 10, cursor: 'pointer' }}
                 />*/}


            </Stack.Item>
            <Stack.Item
                styles={stackItemStyles}
                onClick={() => setShowDrop(!showDrop)}
            >
                <img
                    width={36}
                    height={36}
                    style={{ borderRadius: 100 }}
                    src={
                        Api.getInstance().getImageUrl(user.image) ||
                        '../../../../../images/new-default-icon.png'
                    }
                    alt="Logo"
                />
                <Stack.Item style={{ paddingLeft: 10 }}>
                    <span
                        ref={buttonRef}
                        className="ms-fontSize-16 ms-fontWeight-regular fontFamily darkLightColor line-height100"
                    >
                        {user.name}
                    </span>
                    <p
                        className="ms-fontSize-14 ms-fontWeight-regular fontFamily navColor line-height110"
                        style={{ margin: 0 }}
                    >
                        {selectedSchool ? selectedSchool.name : ' '}
                    </p>
                </Stack.Item>
            </Stack.Item>
            {showDrop && (
                <div ref={ref} className="account-setting-drop">
                    <Link to="/account/settings">
                        <img
                            src="../../../images/account.svg"
                            alt="AccountIcon"
                        />
                        <span>Account Settings</span>
                    </Link>
                    <button onClick={logout}>
                        <img
                            src="../../../images/logout.svg"
                            alt="AccountIcon"
                        />
                        <span>Logout</span>
                    </button>
                </div>
            )}
            {showNotificationDrop && (
                <div ref={ref} className="notification-drop">
                    <div className="notify-heading">
                        <p>Notifications</p>
                    </div>
                    <div>
                         {Object.values(audits).map((value, index) => {
                                    if (value['action'] === 'Created') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    } else if (value['action'] === 'Deleted') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    } else if (value['action'] === 'update') {
                                        return (
                                            <NotificationDropDown
                                               initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                     else if (value['action'] === 'Imported') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                    else if (value['action'] === 'Liked') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                    else if (value['action'] === 'Disliked') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                    else if (value['action'] === 'Voted') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                })}
                    </div>
                    <div className="notify-show-more">
                        <button onClick={goToNotificationPage}>
                            Show More
                        </button>
                    </div>
                </div>
            )}
        </Stack>
    );
};

const mapStateToProps = (state) => ({
    school_id: state.app.school_id,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateSchool: (school) =>
        dispatch({
            type: 'UPDATE_SCHOOL',
            data: { school },
        }),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HeaderRightSection),
);
