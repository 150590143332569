import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Table from '../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 34,
    },
};

const ListStaffPage = () => {
    const history = useHistory();
    return (
        <MainLayout>
            <Table
                type={'staff'}
                name={'Staff'}
                tableName={'Staff'}
                deleteIcon={true}
                onItemClick={(item) => {
                    history.push(`/staff/${item.id}/edit`);
                }}
            />
        </MainLayout>
    );
};

export default ListStaffPage;
