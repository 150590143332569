import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Pivot, PivotItem } from '@fluentui/react';
import CheckboxButtons from '../controls/CheckboxButtons';
import Api from '../api/api';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import RadioButtons from '../controls/RadioButtons';
import InputWithLabel from '../controls/InputWithLabel';

const stackStyles = {
    root: {
        width: '100%',
        float: 'left',
    },
};

const stackPivotmainStyles = {
    root: {
        width: '25%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#E0E0E0',
        float: 'left',
    },
};

const stackInnerStyles = {
    root: {
        width: '75%',
        float: 'left',
        paddingLeft: 70,
    },
};

const stackItemStyles = {
    root: {
        paddingLeft: 30,
        paddingTop: 30,
    },
};

const stacksearchStyles = {
    root: {
        width: 'auto',
        float: 'left',
        paddingLeft: 30,
        paddingTop: 25,
    },
};

const stackdefaultsearchStyles = {
    root: {
        width: 368,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#9E9E9E',
        borderStyle: 'solid',
        height: 40,
        paddingLeft: 15,
        paddingRight: 15,
    },
};

const stacksearchinputStyles = {
    root: {
        border: '0',
    },
};

const stackButtonstStyles = {
    root: {
        width: '100%',
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        marginTop: 20,
    },
};

const stackNewDefaultStyles = {
    root: {
        height: 36,
        borderRadius: 4,
        background: '#2962FF',
        color: '#ffffff',
        fontSize: 16,
        fontfamily: 'Calibri',
        border: 'none',
        fontWeight: 'normal',
        paddingBottom: 0,
        width: 114,
    },
};

const stackCancelStyles = {
    root: {
        height: 36,
        borderRadius: 4,
        background: '#9E9E9E',
        color: '#ffffff',
        fontSize: 16,
        fontfamily: 'Calibri',
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        paddingBottom: 0,
        width: 114,
    },
};

const UserFilters = ({
    onChange,
    onCancel,
    defaultFilters,
    showSubmitAndCancel,
}) => {
   const [filters, setFilters] = useState({
        batches: [],
        courses: [],
        genders: [],
        lists: [],
        status: [],
        customFields: {},
        //company: { type: '', text: '' },
    });
    
    const [options, setOptions] = useState({
        batch: [],
        course: [],
        status: [],
        list: [],
        gender: [],
        custom_fields: [],
    });

    useEffect(() => {
        Api.getInstance()
            .getAlumniFilterOptions()
            .then((response) => {
                setOptions(response);
            });
    }, []);

    const getFilterCountText = (k, customField = false) => {
        let count = 0;

        if (Array.isArray(filters[k]) && filters[k].length > 0) {
            count = filters[k].length;
        } else if (customField) {
            if (filters['customFields'][k] && filters['customFields'][k].type) {
                count = 1;
            }
        } else if (filters[k].type) {
            count = 1;
        }

        return count > 0 ? `(${count})` : '';
    };

    const handleChange = (e) => {
        let f = { ...filters };
        f[e.target.name] = e.target.value;
        setFilters(f);
        if (!showSubmitAndCancel) {
            onChange(f);
        }
    };
    const handleCustomFieldChange = (e) => {
        let f = { ...filters };
        f.customFields[e.target.name] = e.target.value;
        setFilters(f);
        if (!showSubmitAndCancel) {
            onChange(f);
        }
    };

    useEffect(() => {
        if (Object.keys(defaultFilters).length > 0) {
            if (!defaultFilters.customFields) {
                defaultFilters.customFields = {};
            }
            setFilters({ ...defaultFilters });
        }
    }, [defaultFilters]);

    return (
        <div className="Userfilters ">
            <Stack styles={stackStyles}>
                <Pivot
                    aria-label="Basic Pivot Example"
                    styles={stackPivotmainStyles}
                >
                    <PivotItem
                        headerText={window.location.host === "banyantreedel.almacircle.com" ? 'Last class studied' + getFilterCountText('batches') : 'batches' + getFilterCountText('batches')}
                    >
                        <Stack styles={stackInnerStyles}>
                            <Stack.Item>
                                <CheckboxButtons
                                    name={'batches'}
                                    options={options.batch}
                                    onChange={handleChange}
                                    value={filters.batches}
                                />
                            </Stack.Item>
                        </Stack>
                    </PivotItem>
                    <PivotItem
                        headerText={`Status ${getFilterCountText('status')}`}
                    >
                        <Stack styles={stackInnerStyles}>
                            <Stack.Item>
                                <CheckboxButtons
                                    name={'status'}
                                    options={options.status}
                                    onChange={handleChange}
                                    value={filters.status}
                                />
                            </Stack.Item>
                        </Stack>
                    </PivotItem>
                    <PivotItem
                        headerText={`Gender ${getFilterCountText('genders')}`}
                    >
                        <Stack styles={stackInnerStyles}>
                            <Stack.Item>
                                <CheckboxButtons
                                    name={'genders'}
                                    options={options.gender}
                                    onChange={handleChange}
                                    value={filters.genders}
                                />
                            </Stack.Item>
                        </Stack>
                    </PivotItem>
                    {/* <PivotItem
                        headerText={`Company ${getFilterCountText('company')}`}
                    >
                        <Stack styles={stackInnerStyles}>
                            <Stack.Item>
                                <TextFilter
                                    name={'company'}
                                    value={filters.company}
                                    exclude={[
                                        'not_contains',
                                        'empty',
                                        'not_empty',
                                    ]}
                                    onChange={handleChange}
                                />
                            </Stack.Item>
                        </Stack>
                    </PivotItem> */}
                    <PivotItem
                        headerText={`Mailing List ${getFilterCountText(
                            'lists',
                        )}`}
                    >
                        <Stack styles={stackInnerStyles}>
                            <Stack.Item>
                                <CheckboxButtons
                                    name={'lists'}
                                    options={options.list}
                                    onChange={handleChange}
                                    value={filters.lists}
                                />
                            </Stack.Item>
                        </Stack>
                    </PivotItem>
                    {options.custom_fields.map((field) => {
                        return (
                            <PivotItem
                                key={`custom-field-${field.id}`}
                                headerText={`${field.name} ${getFilterCountText(
                                    field.id,
                                    true,
                                )}`}
                            >
                                <Stack styles={stackInnerStyles}>
                                    <Stack.Item>
                                        <TextFilter
                                            name={field.id}
                                            value={
                                                filters.customFields[
                                                    field.id
                                                ] || { type: '', text: '' }
                                            }
                                            onChange={handleCustomFieldChange}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </PivotItem>
                        );
                    })}
                </Pivot>
            </Stack>
            {showSubmitAndCancel && (
                <Stack styles={stackButtonstStyles}>
                    <DefaultButton
                        onClick={onCancel}
                        text="Cancel"
                        styles={stackCancelStyles}
                    />
                    <PrimaryButton
                        onClick={() => {
                            onChange(filters);
                        }}
                        text="Apply"
                        styles={stackNewDefaultStyles}
                    />
                </Stack>
            )}
        </div>
    );
};

const TextFilter = ({ name, onChange, value }) => {
    return (
        <>
            <RadioButtons
                options={[
                    { key: 'empty', text: 'Is Empty' },
                    {
                        key: 'not_empty',
                        text: 'Is Not Empty',
                    },
                    { key: 'contains', text: 'Contains' },
                    { key: 'not_contains', text: 'Not Contains' },
                    { key: 'equals', text: 'Equals' },
                ]}
                value={value.type}
                onChange={(e, option) => {
                    let v = { ...value };
                    v.type = option.key;
                    onChange({ target: { name, value: v } });
                }}
            />
            {['contains', 'not_contains', 'equals'].indexOf(value.type) >
                -1 && (
                <InputWithLabel
                    label="Text"
                    value={value.text}
                    onChange={(e) => {
                        let v = { ...value };
                        v.text = e.target.value;
                        onChange({ target: { name, value: v } });
                    }}
                    name="text"
                />
            )}
        </>
    );
};

export default UserFilters;
