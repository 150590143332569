import React from 'react';
import { ReactPhotoCollage } from 'react-photo-collage';
import Api from '../../../../../../../common/api/api';

const calculateLayout = (images) => {
    if (images.length === 1) {
        return [1];
    }
    if (images.length > 1) {
        return [1, Math.min(images.length - 1, 4)];
    }
};

const CollageView = ({ images }) => {
    if (!images || images.length === 0) {
        return <></>;
    }
    const setting = {
        width: '100%',
        height: ['320px', '170px'],
        border: 'none',
        layout: calculateLayout(images),
        photos: images.map((image) => {
            return { source: Api.getInstance().getImageUrl(image) };
        }),
        showNumOfRemainingPhotos: true,
    };

    return (
        <div className="post-collage-view">
            <ReactPhotoCollage {...setting} />
        </div>
    );
};

export default CollageView;
