import React from 'react';
import { Months } from '../../../../../../../common/months';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '620px !important',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const EducationDetails = ({ education }) => {
    const educationsToShow = education.filter((item) => item.from_month);

    return (
        <div className="branch-member-card">
            <div className="education-heading">
                <span>Education</span>
            </div>
            <div className="education-list">
                {educationsToShow.length === 0 && (
                    <div className="member-list-col-new">
                        No education history found.
                    </div>
                )}
                {educationsToShow.map((item, index) => {
                    return (
                        <div
                            className="member-list-col-new"
                            key={`education-${index}`}
                        >
                            <div className="col-left" style={{cursor:'unset'}}>
                                <div className="member-info-new-details">
                                    <h4>{item.institute}</h4>
                                    <span>{item.course}</span>
                                    <p>
                                        {Months[item.from_month]}{' '}
                                        {item.from_year} -{' '}
                                        {item.currentlyPursuing
                                            ? 'Present'
                                            : `${Months[item.to_month]} ${
                                                  item.to_year
                                              }`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default EducationDetails;
