import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Table from '../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 34,
    },
};

const ListCustomFieldPage = () => {
    const history = useHistory();

    return (
        <MainLayout>
            <Table
                type={'field'}
                name={'Custom Field'}
                tableName={'Custom Fields'}
                allowImport={false}
                deleteIcon={true}
                allowExport={false}
                onItemClick={(item) => {
                    history.push(`/field/${item.id}/edit`);
                }}
            />
        </MainLayout>
    );
};

export default ListCustomFieldPage;
