import React, { useState, useEffect } from 'react';

const SocialLinkModal = ({ onCancel, socialLink, onUpdate }) => {
    const [input, setInput] = useState({});

    const [errors, setErrors] = useState({});


    useEffect(() => {
        setInput({ ...socialLink[0] });
    }, [socialLink[0]]);

    const validate = () => {
        let fields = [
            {
                label: 'Facebook',
                key: 'facebook',
                regex: "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$"
            },
            {
                label: 'LinkedIn',
                key: 'linkedin',
                regex: "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$"
            },
            {
                label: 'Instagram',
                key: 'instagram',
                regex: "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$"
            },
            {
                label: 'Twitter',
                key: 'twitter',
                regex: "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$"
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (
                field.required &&
                (input[field.key] === undefined ||
                    input[field.key] === null ||
                    input[field.key] === '')
            ) {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });

        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handlesubmit = () => {
        // if (!validate()) {
        //     return;
        // }

        onUpdate({ ...input });
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    return (
        <div className="edit-profile-modal">
            <div className="modal-heading">
                <h4>Social Links</h4>
                <div onClick={onCancel}>
                    <img
                        src="../../../../images/edit-close.svg"
                        alt="CloseIcon"
                    />
                </div>
            </div>
            <div className="edit-form">
                <div className="form-group">
                    <label>Facebook</label>
                    <input
                        type="text"
                        value={input.facebook}
                        onChange={handleChange}
                        name="facebook"
                        placeholder="Facebook"
                    />
                    <span className="edu-error">{errors.facebook}</span>
                </div>
                <div className="form-group">
                    <label>Instagram</label>
                    <input
                        type="text"
                        value={input.instagram}
                        onChange={handleChange}
                        name="instagram"
                        placeholder="Instagram"
                    />
                    <span className="edu-error">{errors.instagram}</span>
                </div>
                <div className="form-group">
                    <label>Twitter</label>
                    <input
                        type="text"
                        value={input.twitter}
                        onChange={handleChange}
                        name="twitter"
                        placeholder="Twitter"
                    />
                    <span className="edu-error">{errors.twitter}</span>
                </div>
                <div className="form-group">
                    <label>LinkedIn</label>
                    <input
                        type="text"
                        value={input.linkedin}
                        onChange={handleChange}
                        name="linkedin"
                        placeholder="LinkedIn"
                    />
                    <span className="edu-error">{errors.linkedin}</span>
                </div>

                <div className="form-buttons">
                    <button className="cancel" onClick={onCancel}>
                        No, cancel
                    </button>
                    <button className="save" onClick={handlesubmit}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SocialLinkModal;
