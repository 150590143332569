import React, { useEffect, useState } from 'react';
import SimpleInput from '../../../../../common/controls/SimpleInput';
import { DefaultButton } from '@fluentui/react/lib/Button';
import Api from '../../../../../common/api/api';
import { connect } from 'react-redux';

const stackDefaultStyles = {
    root: {
        height: 50,
        borderRadius: 6,
        background: '#424242',
        color: '#ffffff',
        fontSize: 16,
        border: 1,
        borderColor: '#424242',
        borderStyle: 'solid',
        margin: 0,
        fontWeight: 'normal',
        width: '100%',
        fontFamily: 'Calibri',
    },
};

const LoginForm = ({ dispatchLogout, dispatchLogin }) => {
    // Password hide show
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        dispatchLogout();
    }, []);

    const [form, setForm] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleFormChange = (e) => {
        let _form = { ...form };
        _form[e.target.name] = e.target.value;
        setForm(_form);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});
        Api.getInstance()
            .login(form)
            .then((response) => {
                dispatchLogin(response.token);
                window.location.href = '/auth/schools';
            })
            .catch((e) => {
                setErrors(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div
            className="login-full-view"
            style={{
                backgroundImage: `url(${'.../../../images/login-back.jpg'})`,
            }}
        >
            <div className="form">
                <div className="logo">
                    <img src="../../../images/logo.svg" alt="Logo" />
                </div>
                <div className="form-fields">
                    <SimpleInput
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                    />
                    <SimpleInput
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Password"
                    />
                    <DefaultButton
                        disabled={loading}
                        text="Sign in with Email"
                        styles={stackDefaultStyles}
                    />
                </div>
                <div className="or">or</div>
                <div className="fb-button">
                    <img src="../../../images/facebook.svg" alt="FB" />
                    <button type="button">Sign in with Facebook</button>
                </div>
                <div className="google-button">
                    <img src="../../../images/google.svg" alt="Google" />
                    <button type="button">Sign in with Google</button>
                </div>
                <div className="apple-button">
                    <img src="../../../images/apple.svg" alt="Apple" />
                    <button type="button">Sign in with LinkedIn</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchLogin: (token) =>
        dispatch({
            type: 'AUTH_LOGIN',
            data: { token },
        }),
    dispatchLogout: () =>
        dispatch({
            type: 'AUTH_LOGOUT',
        }),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
