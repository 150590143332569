import React from 'react';
import { Importer } from 'react-csv-importer';
import MainLayout from '../../../layouts/main/MainLayout';
import DefaultDashboardScreen from './components/DefaultDashboardScreen';
import FileUpload from './FileUpload';

const DashboardPage = () => {
    return (
        <MainLayout requireLogin={true}>
            <DefaultDashboardScreen
                Heading="Add Alumni"
                Description="You can add single alumni or upload CSV of multiple alumni below."
                icon="../../../../images/user-icon.svg"
            />
        </MainLayout>
    );
};

export default DashboardPage;
