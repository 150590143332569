import React, { useState } from 'react';
import AuthLayout from '../../../layouts/auth/AuthLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import { Link } from 'react-router-dom';
import DefaultInput from '../../../common/controls/DefaultInput';
import Api from '../../../common/api/api';
import qs from 'qs';
import Loader from 'react-js-loader';
import UpdateModal from '../../../common/modals/UpdateModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import config from '../../../config/config';
import getSchool from '../../../helpers/getSchool';

const stackStyles = {
    root: {
        width: 340,
        flexDirection: 'column',
    },
};

const stackItemStyles = {
    root: {
        marginBottom: 12,
    },
};

const btnstackItemStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12,
    },
};

const questionstackItemStyles = {
    root: {
        textAlign: 'center',
    },
};

const dialogNewStyles = {
   main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px'
    },
};

const ResetPage = () => {
    // Password hide show
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const [input, setInput] = useState({ password: '', confirm_password: '' });
    const [errors, setErrors] = useState({});
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    // const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFormChange = (e) => {
        let _form = { ...input };
        _form[e.target.name] = e.target.value;
        setInput(_form);
    };

    const updateChanges = () => {
        window.location.href = '/auth/login';
    };

    const ErrorChanges = () => {
        toggleHideDialog1();
    };

    const validate = () => {
        let e = {};
        if (!input.password) {
            e.password = 'Password is required';
        }
        if (!input.confirm_password) {
            e.confirm_password = 'Confirm Password is required';
        }
        if (!e.confirm_password && input.confirm_password !== input.password) {
            e.confirm_password = 'Passwords do not match';
        }

        setErrors(e);
        return Object.keys(e).length === 0;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        if (!validate()) {
            return;
        }
        const { token } = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        setLoading(true);
        setErrors({});
        Api.getInstance()
            .resetPassword({ ...input, token })
            .then((response) => {
                toggleHideDialog1();
            })
            .catch((e) => {
                if (e.fields) {
                    setErrors(e.fields);
                } else {
                    // setErrorText(e.error);
                    // toggleHideDialog1();
                    alert(e.error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <AuthLayout>
            <div className="forgot-heading">
                <span className="ms-fontSize-24 ms-fontWeight-bold darkColor fontFamily">
                    Reset Password
                </span>
                <p className="ms-fontSize-16 ms-fontWeight-regular lightColor fontFamily margin">
                    Reset your password here
                </p>
            </div>
            <form onSubmit={handleSubmit}>
                <Stack horizontal styles={stackStyles}>
                    <Stack.Item styles={stackItemStyles}>
                        <DefaultInput
                            type={passwordShown ? 'text' : 'password'}
                            name={'password'}
                            value={input.password}
                            onChange={handleFormChange}
                            placeholder="Password"
                            icon="../../../../images/password-icon.svg"
                            onClick={togglePasswordVisibility}
                            error={errors.password}
                            righticon={
                                passwordShown
                                    ? '../../../../images/view.svg'
                                    : '../../../../images/view-hide.svg'
                            }
                            className={
                                errors.password
                                    ? 'default-input error'
                                    : 'default-input'
                            }
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <DefaultInput
                            type={passwordShown ? 'text' : 'password'}
                            name={'confirm_password'}
                            value={input.confirm_password}
                            onChange={handleFormChange}
                            placeholder="Confirm Password"
                            icon="../../../../images/password-icon.svg"
                            onClick={togglePasswordVisibility}
                            error={errors.confirm_password}
                            righticon={
                                passwordShown
                                    ? '../../../../images/view.svg'
                                    : '../../../../images/view-hide.svg'
                            }
                            className={
                                errors.confirm_password
                                    ? 'default-input error'
                                    : 'default-input'
                            }
                        />
                    </Stack.Item>
                    <div className="button-with-arrow">
                        <Link to="/auth/forgot">
                            <img
                                src="../../../../images/btn.svg "
                                style={{ marginRight: 10, marginTop: 4 }}
                            />
                        </Link>
                        <button
                            disabled={loading}
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            {loading ? (
                                <Loader
                                    type="bubble-scale"
                                    bgColor={'#FFFFFF'}
                                    color={'#FFFFFF'}
                                    size={40}
                                />
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>

                    <Stack.Item styles={questionstackItemStyles}>
                        <span className="ms-fontSize-14 ms-fontWeight-regular lightColor fontFamily line-height20">
                            Questions? Email us at{' '}
                        </span>
                        <a
                            href={`mailto:${config[getSchool()].supportEmail}`}
                            className="ms-fontSize-14 ms-fontWeight-regular blueColor fontFamily line-height20"
                            style={{ marginLeft: 1 }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {config[getSchool()].supportEmail}
                        </a>
                    </Stack.Item>
                </Stack>
                <Dialog
                    hidden={hideDialog1}
                    onDismiss={toggleHideDialog1}
                    styles={dialogNewStyles}
                >
                    <UpdateModal
                        text={'Your password has been successfully reset'}
                        onClick={updateChanges}
                    />
                </Dialog>
            </form>
        </AuthLayout>
    );
};

export default ResetPage;
