import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AddBatchPage from '../views/main/Batch/AddBatchPage';
import AddListsPage from '../views/main/Lists/AddListsPage';
import ListsListPage from '../views/main/Lists/ListListPage';

function ListsRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/Lists`} exact component={ListsListPage} />

             <Route path={`${url}/add`} exact component={AddListsPage} />
             <Route path={`${url}/:id/edit`} exact component={AddListsPage} /> 
        </SwitchWithNotFound>
    );
}

export default ListsRoutes;
