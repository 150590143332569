import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { classNamesFunction, Pivot, PivotItem } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import InputWithLabel from './controls/InputWithLabel';
import Api from './api/api';
import UserFilters from './modals/UserFilters';
import { connect } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { Dropdown, Icon} from '@fluentui/react';
import Initialising from './controls/Initialising';
import ButtonWithLoader from './controls/ButtonWithLoader';
import HTMLEditor from './controls/HTMLEditor';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import Select from 'react-select';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
        marginTop: 25,
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

initializeIcons();

const SendEmailTabs = ({ school, user }) => {
    const [campaign, setCampaign] = useState({
        name: '',
        to: { type: 'all', filters: {} },
        from_name: '',
        from_email: '',
        subject: '',
        reply_to: [],
        template: '',
        sent_on: '',
        category: '',
        attachments: [],
    });
    const [senderOption, setSenderoption] = useState([]);
    const [receiverOption, setReceiveroption] = useState([
        { key: '', value: '' },
    ]);
    const [senderNameoption, setsenderNameoption] = useState([]);

    const [errors, setErrors] = useState({});

    const [categories, setCategories] = useState([]);
    const [templates, setTemplates] = useState([]);
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    const [selectedKey, setSelectedKey] = useState('0');
    const [initialising, setInitialising] = useState(true);
    const fileInput = useRef(null);

    const { id } = useParams();

    useEffect(() => {
        let senderEmail = [];

       {
            school.sender_email.map((key, i) => {
                senderEmail.push({
                    value: key,
                    label: key,
                });
            });
        }


        setSenderoption(senderEmail);

        let receiverEmail = [];

        {
            school.receiver_email.map((key, i) => {
                receiverEmail.push({
                    value: key,
                    label: key,
                });
            });
        }

        setReceiveroption(receiverEmail);

        let senderName = [];
        if (school.sender_name) {
            senderName.push({
                key: school.sender_name,
                value: school.sender_name,
            });
        }
        let userName = {
            key: user.name,
            value: user.name,
        };
        senderName.push(userName);
        setsenderNameoption(senderName);

        if (school.sender_name) {
            let campaigns = { ...campaign };
            campaigns['from_name'] = school.sender_name;
            setCampaign(campaigns);
        }
    }, []);

    useEffect(() => {
        if (id) {
            setInitialising(true);
            Api.getInstance()
                .getEmailCampaign(id)
                .then((response) => {
                    setCampaign(response);

                    setInitialising(false);
                })
                .catch(console.log);
        } else {
            setInitialising(false);
        }
    }, [id]);

    useEffect(() => {
        Api.getInstance()
            .getCategories()
            .then((response) => {
                setCategories(response);
            });
    }, []);

    useEffect(() => {
        Api.getInstance()
            .getTemplates()
            .then((response) => {
                setTemplates(response);
            });
    }, []);

    const handleChange = (e) => {
        let c = { ...campaign };
        c[e.target.name] = e.target.value;
        setCampaign(c);
    };

    const handleReplyToChange = (event, item) => {
        setCampaign((i) => ({ ...i, reply_to: event }));
    };

    const handleTemplateChange = (e) => {
        let c = { ...campaign };
        console.log("hello");
        console.log(campaign);

        c['template'] = e.target.value?.key;
        c['subject'] = e.target.value?.subject;
        if(e.target.value?.subject == ""){
            document.getElementsByClassName("ms-Dropdown-title")[3].style.color = "#42424275";
        }
        else{
            document.getElementsByClassName("ms-Dropdown-title")[3].style.color = "#000000";
            }

         setCampaign(c);
    };

    const validate = () => {
        let fields = [
            [
                {
                    label: 'Campaign Name',
                    key: 'name',
                    required: true,
                },
                // {
                //     label: 'Category Name',
                //     key: 'category',
                //     required: true,
                // },
            ],
            [],

            [
                {
                    label: 'Sender Name',
                    key: 'from_name',
                    required: true,
                },
                {
                    label: 'Sender Email',
                    key: 'from_email',
                    required: true,
                },
                {
                    label: 'Subject',
                    key: 'subject',
                    required: true,
                },
                {
                    label: 'Receive Replies on',
                    key: 'reply_to',
                    required: true,
                },
                {
                    label: 'Category',
                    key: 'category',
                    required: true,
                },
            ],
            [],
        ];

        let e = {};
        fields[selectedKey].forEach((field) => {
            if (
                field.required &&
                (campaign[field.key] === '' || campaign[field.key] === null)
            ) {
                e[field.key] = `${field.label} is required`;
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleFileChange = (e) => {
        Object.keys(e.target.files).map((k) => {
            const file = e.target.files[k];
            Api.getInstance()
                .uploadFile(
                    {
                        extension: file.name.split('.').pop(),
                        'Content-Type': file.type,
                    },
                    file,
                    true,
                )
                .then(({ key }) => {
                    setCampaign((i) => ({
                        ...i,
                        attachments: [
                            ...i.attachments,
                            { key, name: file.name },
                        ],
                    }));
                })
                .catch(console.log);
        });
        fileInput.current.value = '';
    };

    const removeAttachment = (index) => {
        let attachments = campaign.attachments;
        attachments.splice(index, 1);
        setCampaign((i) => ({ ...i, attachments: attachments }));
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

            console.log("camp before cresting");
            console.log(campaign);
            setLoading(true);
            
        Api.getInstance()
            .createEmailCampaign(campaign)
            .then((response) => {
                setCampaign(response);
                next();
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const sendCampaign = () => {
        setSending(true);
        Api.getInstance()
            .sendEmailCampaign(campaign.id)
            .then((response) => {
                next();
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setSending(false);
            });
    };

    const handleBack = () => {
        if (selectedKey === '0') {
            history.push('/campaign/email/list');
            return;
        }
        setSelectedKey(String(parseInt(selectedKey) - 1));
    };

    const next = () => {
        if (selectedKey === '3') {
            history.push('/campaign/email/list');
            return;
        }
        setSelectedKey(String(parseInt(selectedKey) + 1));
    };

    return (
        <div className="user-tabs">
            <Pivot aria-label="Basic Pivot Example" selectedKey={selectedKey}>
                <PivotItem
                    itemKey={'0'}
                    headerText="1. Setup your Campaign"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'Campaign',
                    }}
                >
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Enter a name for your email campaign. Eg: Global Alumni
                        Meetup 2021
                    </span>
                    {initialising && <Initialising />}
                    {!initialising && (
                        <>
                            <Stack>
                                <div className="add-user-form">
                                    <div className="form-group pr">
                                        <InputWithLabel
                                            errorMessage={errors.name}
                                            onChange={handleChange}
                                            label="Campaign Name"
                                            name="name"
                                            value={campaign.name}
                                            estric="*"
                                        />
                                    </div>
                                </div>
                            </Stack>
                            <Stack styles={stackNewStyles}>
                                <DefaultButton
                                    onClick={handleBack}
                                    text="Cancel"
                                    styles={stackDefaultStyles}
                                />

                                <ButtonWithLoader
                                    onClick={handleSubmit}
                                    name="Next"
                                    arrow="../../../images/button-arrow.svg"
                                    loading={loading}
                                />
                            </Stack>
                        </>
                    )}
                </PivotItem>
                <PivotItem itemKey={'1'} headerText="2.  Select Users">
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Select users who will be part of this campaign. All
                        emails sent for this campaign will be sent only to these
                        selected users.
                    </span>
                    <Stack>
                        <div className="add-user-form-new">
                            <div className="mailto-text">
                                <span>Mail to:</span>
                            </div>
                            <div className="choice-box">
                                <div className="radio-buton">
                                    <input
                                        type="radio"
                                        checked={campaign.to.type === 'all'}
                                        name="to"
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'to',
                                                    value: {
                                                        ...campaign.to,
                                                        type: 'all',
                                                    },
                                                },
                                            });
                                        }}
                                    />{' '}
                                    <span>All Users</span>
                                </div>
                                <div className="radio-buton">
                                    <input
                                        checked={
                                            campaign.to.type === 'specific'
                                        }
                                        type="radio"
                                        onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: 'to',
                                                    value: {
                                                        ...campaign.to,
                                                        type: 'specific',
                                                    },
                                                },
                                            });
                                        }}
                                        value="SpecificUsers"
                                        name="to"
                                    />{' '}
                                    <span>Specific Users</span>
                                </div>
                            </div>
                        </div>
                        <div className="Userfilters">
                            {campaign.to.type === 'specific' && (
                                <UserFilters
                                    showSubmitAndCancel={false}
                                    defaultFilters={campaign.to.filters || {}}
                                    onChange={(filters) => {
                                        handleChange({
                                            target: {
                                                name: 'to',
                                                value: {
                                                    ...campaign.to,
                                                    filters,
                                                },
                                            },
                                        });
                                    }}
                                />
                            )}
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={handleBack}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        <ButtonWithLoader
                            onClick={handleSubmit}
                            name="Next"
                            arrow="../../../images/button-arrow.svg"
                            loading={loading}
                        />
                    </Stack>
                </PivotItem>
                <PivotItem itemKey={'2'} headerText="3. Setup Your Email">
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Configure the parameters required for your email message
                        and design the message that needs to be sent to the
                        users who are part of this campaign.
                    </span>
                    <Stack styles={stackStyles}>
                        <div className="add-user-form">
                            <div className="form-group pr">
                                <Dropdown
                                    placeholder="Select Sender Name"
                                    label={'Sender Name'}
                                    options={senderNameoption.map((opt) => {
                                        return {
                                            key: opt.key,
                                            text: opt.value,
                                        };
                                    })}
                                    selectedKey={campaign.from_name}
                                    onChange={(e, item) => {
                                        handleChange({
                                            target: {
                                                value: item.key,
                                                name: 'from_name',
                                            },
                                        });
                                    }}
                                    className={
                                        errors.from_name ? 'drop-error' : ''
                                    }
                                />
                            </div>
                            <div className="form-group subject pl">
                                <InputWithLabel
                                    errorMessage={errors.subject}
                                    onChange={handleChange}
                                    value={campaign.subject}
                                    label="Subject"
                                    name="subject"
                                />
                            </div>
                            <div className="form-group pr">
                                <Dropdown
                                    placeholder="Select Sender Email"
                                    label={'Sender Email'}
                                    options={senderOption.map((opt) => {
                                        return {
                                            key: opt.value,
                                            text: opt.label,
                                        };
                                    })}
                                    selectedKey={campaign.from_email}
                                    onChange={(e, item) => {
                                        handleChange({
                                            target: {
                                                value: item.key,
                                                name: 'from_email',
                                            },
                                        });
                                    }}
                                    className={
                                        errors.from_email ? 'drop-error' : ''
                                    }
                                />
                            </div>
                            <div className="form-group pl">
                                <label className="ms-Label ms-Dropdown-label root-182" id="Dropdown771-label">Receiver Email</label>
                                <Select
                                    placeholder="Select Receiver Email"
                                    label={'Receiver Email'}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={campaign.reply_to}
                                    onChange={handleReplyToChange}
                                    options={receiverOption.map((opt) => {
                                        return {
                                            value: opt.value,
                                            label: opt.label,
                                        };
                                    })}
                                />
                            </div>
                            <div className="form-group pr">
                                <Dropdown
                                    required={true}
                                    errorMessage={errors.category}
                                    placeholder="Select Category"
                                    label={'Category'}
                                    options={categories.map((category) => {
                                        return {
                                            key: category.id,
                                            text: category.name,
                                        };
                                    })}
                                    selectedKey={campaign.category}
                                    onChange={(e, item) => {
                                        handleChange({
                                            target: {
                                                value: item.key,
                                                name: 'category',
                                            },
                                        });
                                    }}
                                    className={
                                        errors.category ? 'drop-error' : ''
                                    }
                                />
                            </div>
                            <div className="form-group pl">
                                <Dropdown
                                    placeholder="Select Template"
                                    label={'Template'}
                                    options={templates.map((template) => {
                                        return {
                                            key: template.body,
                                            text: template.name,
                                            subject: template.subject,
                                        };
                                    })}
                                    // selectedKey={campaign.category}
                                    onRenderCaretDown={(event) => {
                                        return (
                                            <Stack horizontal verticalAlign={"center"}>
                                                {!!campaign.template && (
                                                <Icon
                                                    iconName={"Cancel"}
                                                    styles={{
                                                    root: {
                                                        color: "rgb(96, 94, 92)",
                                                        paddingRight: "8px",
                                                        "&:hover": {
                                                        fontWeight: 800
                                                        }
                                                    }
                                                    }}
                                                    onClick={(event) => {
                                                    event.stopPropagation();
                                                    // color: #42424275
                                                    let a = document.getElementsByClassName("ms-Dropdown-title")[3].innerHTML="Select Template";
                                                    // document.getElementsByClassName("ms-Dropdown-title")[3].style.color = "#42424275";
                                                    handleTemplateChange({

                                                        target: {
                                                            value: {key:"<p></p>\n",subject:"", text:"z"},
                                                            name: 'template',
                                                        },
                                                    });

                                                    }}
                                                />
                                                )}
                                                <span className="css-1u9des2-indicatorSeparator"></span>
                                                <Icon
                                                iconName={"ChevronDown"}
                                                styles={{
                                                    root: {
                                                    color: "rgb(96, 94, 92)",
                                                    paddingLeft: "12px",
                                                    "&:hover": {
                                                        fontWeight: 800
                                                    }
                                                    }
                                                }}
                                                onClick={(event) => event.currentTarget.parentNode.onClick}
                                                />
                                            </Stack>
                                            );
                                        }}
                                    onChange={(e, item) => {
                                        handleTemplateChange({
                                            target: {
                                                value: item,
                                                name: 'template',
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="add-user-form">
                            <div className="form-editor">
                                <label>Text Editor</label>
                                <HTMLEditor
                                    value={campaign.template}
                                    name={'template'}
                                    onChange={handleChange}
                                    allowCodeEditing={false}
                                />
                            </div>
                        </div>
                        <div className="add-user-form">
                            <div className="input-with-label file-uploader">
                                <label>Attachments</label>
                                <div className="multiple-new-upload new-attchment">
                                    <input
                                        multiple={true}
                                        type="file"
                                        ref={fileInput}
                                        onChange={handleFileChange}
                                        name="logo"
                                        className="custom-file-input"
                                        id="Logo"
                                        style={{
                                            backgroundImage: `url("${'../../../images/upload-multiple.svg'}")`,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="uploaded-images-list">
                                {campaign.attachments.map((image, index) => {
                                    return (
                                        <div
                                            className="uploaded-images-col"
                                            key={`image-${image.key}`}
                                        >
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={Api.getInstance().getImageUrl(
                                                    image.key,
                                                    true
                                                )}
                                            >
                                                {image.name}
                                            </a>{' '}
                                            {/* <a
                                                href={'#'}
                                                onClick={() => {
                                                    removeAttachment(index);
                                                }}
                                            >
                                                x
                                            </a> */}
                                            <div
                                                className="remove-attachment-cross"
                                                onClick={() => {
                                                    removeAttachment(index);
                                                }}
                                            >
                                                x
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            text="Back"
                            onClick={handleBack}
                            styles={stackDefaultStyles}
                        />
                        <ButtonWithLoader
                            onClick={handleSubmit}
                            name="Save & Preview"
                            arrow="../../../images/button-arrow.svg"
                            loading={loading}
                        />
                    </Stack>
                </PivotItem>
                <PivotItem itemKey={'3'} headerText="4. Preview and Send">
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Preview your email and send it to the users who are part
                        of this campaign.
                    </span>
                    <Stack styles={stackStyles}>
                        <div className="add-user-form" style={{ marginTop: 0 }}>
                            <div className="email-preview">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: campaign.template,
                                    }}
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={handleBack}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        <div style={{ marginRight: 10 }}>
                            <ButtonWithLoader
                                onClick={handleSubmit}
                                name="Save as Draft"
                                arrow="../../../images/button-arrow.svg"
                                loading={loading}
                            />
                        </div>
                        <div className="next-button">
                            <ButtonWithLoader
                                onClick={sendCampaign}
                                name="Send Campaign"
                                loading={sending}
                            />
                        </div>
                    </Stack>
                </PivotItem>
            </Pivot>
        </div>
    );
};

const mapStateToProps = (state) => ({
    school: state.app.school,
    user: state.app.user,
});

export default withRouter(connect(mapStateToProps)(SendEmailTabs));
