import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AddCustomFieldPage from '../views/main/CustomField/AddCustomFieldPage';
import ListCustomFieldPage from '../views/main/CustomField/ListCustomFieldPage';

function FieldRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/list`} exact component={ListCustomFieldPage} />

            <Route path={`${url}/add`} exact component={AddCustomFieldPage} />
            <Route
                path={`${url}/:id/edit`}
                exact
                component={AddCustomFieldPage}
            />
        </SwitchWithNotFound>
    );
}

export default FieldRoutes;
