import * as React from 'react';
import { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import InputWithLabel from './controls/InputWithLabel';
import ButtonWithLoader from './controls/ButtonWithLoader';
import Api from './api/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Initialising from './controls/Initialising';
import UpdateModal from './modals/UpdateModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const FieldSettingsAccordianTab = ({ school_id }) => {
    const [user, setUser] = useState({});
    const [uploading, setUploading] = useState(false);
    const [initialising, setInitialising] = useState(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [isSendEmailChecked, setIsSendEmailChecked] = useState(false);
    const [isJoiningYearChecked, setIsJoiningYearChecked] = useState(false);
    const [isLeavingYearChecked, setIsLeavingYearChecked] = useState(false);
    const [isPhoneChecked, setPhoneChecked] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isGenderChecked, setGenderChecked] = useState(false);

    useEffect(() => {
        if (!school_id) {
            return;
        }
        Api.getInstance()
            .getSchool(school_id)
            .then((response) => {
                setUser(response);
                setIsSendEmailChecked(response.send_login_email);
                setIsJoiningYearChecked(response.year_of_joining);
                setIsLeavingYearChecked(response.year_of_leaving);
                setIsChecked(response.batch_optional);
                setPhoneChecked(response.phone_optional);
                setGenderChecked(response.gender_optional);
            })
            .catch(() => {})
            .finally(() => {
                setInitialising(false);
            });
    }, [school_id]);

    const [input, setInput] = useState({
        name: '',
        image: '',
        default_password: '',
        send_login_email: Boolean,
        batch_optional: '',
        phone_optional: Boolean,
        gender_optional:'',
    });

    useEffect(() => {
        setInput({
            name: user.name,
            logo: user.logo,
            default_password: user.default_password,
            send_login_email: user.send_login_email,
            year_of_joining: user.year_of_joining,
            year_of_leaving: user.year_of_leaving,
            batch_optional: user.batch_optional,
            phone_optional: user.phone_optional,
            gender_optional: user.gender_optional,
        });
    }, [user]);

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const updateChanges = () => {
        window.location.reload();
    };

    const handleSubmit = () => {
        setLoading(true);

        Api.getInstance()
            .updateSchool(school_id, input)
            .then((response) => {
                toggleHideDialog1();
            })
            .catch((e) => {
                if (e.fields) {
                    setErrors(e.fields);
                } else {
                    alert(e.error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const sendemailhandleChanges = (event) => {
        let i = { ...input };
        i[event.target.name] = true;
        setInput(i);
    };

    const notsendhandleChanges = (event) => {
        let i = { ...input };
        i[event.target.name] = false;
        setInput(i);
    };

    const handleEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = false;
        setInput(i);
    };

    const batchnotrequiredEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = true;
        setInput(i);
    };

    const LeavingYearOptionalEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = false;
        setInput(i);
    };

    const LeavingYearRequiredEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = true;
        setInput(i);
    };

    const JoiningYearOptionalEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = false;
        setInput(i);
    };

    const JoiningYearRequiredEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = true;
        setInput(i);
    };

    const PhoneOptionalEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = false;
        setInput(i);
    };

    const PhoneRequiredEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = true;
        setInput(i);
    };

    const GenderOptionalEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = false;
        setInput(i);
    };

    const GenderRequiredEvent = (event) => {
        let i = { ...input };
        i[event.target.name] = true;
        setInput(i);
    };

    if (initialising) {
        return <Initialising />;
    }

    return (
        <div className="user-tabs" style={{ marginTop: 0 }}>
            <Stack>
                {/* New Optional field design */}
                <div className="new-optional-field">
                    <h4>Field Settings</h4>
                    <p>
                        You can make some changes for the alumni import feature.
                    </p>
                    {/* Checkbox changed to radio button */}
                    <div className="new-radio-button">
                        <div className="radio-text">
                            <span>Email</span>
                            <p>
                                Do you want to send an email with login
                                credentials to the alumni <br /> when they are
                                imported.
                            </p>
                        </div>
                        <div className="new-radio-input">
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="send_login_email"
                                    defaultChecked={
                                        isSendEmailChecked === false
                                    }
                                    onChange={notsendhandleChanges}
                                    //checked={isSendEmailChecked == 'false'}
                                />
                                <span>Do not send an email</span>
                            </div>
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="send_login_email"
                                    defaultChecked={isSendEmailChecked === true}
                                    onChange={sendemailhandleChanges}
                                    //checked={isSendEmailChecked == 'true'}
                                />
                                <span>Send an email</span>
                            </div>
                        </div>
                    </div>
                    <div className="new-radio-button">
                        <div className="radio-text">
                        {window.location.host === "banyantreedel.almacircle.com" ? (
                            <>
                            <span>Last class studied</span>
                            <p>
                                Do you want to keep the Last class studied field required or
                                optional.
                            </p></> ):(
                                <>
                            <span>Batch</span>
                            <p>
                                Do you want to keep the batch field required or
                                optional.
                            </p> </>)}

                        </div>
                        <div className="new-radio-input">
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="batch_optional"
                                    defaultChecked={isChecked === false}
                                    onChange={handleEvent}
                                    // defaultChecked={isChecked}
                                />
                                <span>Optional</span>
                            </div>
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="batch_optional"
                                    defaultChecked={isChecked === true}
                                    onChange={batchnotrequiredEvent}
                                    // defaultChecked={isChecked}
                                />
                                <span>Required</span>
                            </div>
                        </div>
                    </div>
                    <div className="new-radio-button">
                        <div className="radio-text">
                            <span>Year of Leaving</span>
                            <p>
                                Do you want to keep the year of leaving field
                                required or optional.
                            </p>
                        </div>
                        <div className="new-radio-input">
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="year_of_leaving"
                                    defaultChecked={
                                        isLeavingYearChecked === false
                                    }
                                    onChange={LeavingYearOptionalEvent}
                                />
                                <span>Optional</span>
                            </div>
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="year_of_leaving"
                                    defaultChecked={
                                        isLeavingYearChecked === true
                                    }
                                    onChange={LeavingYearRequiredEvent}
                                />
                                <span>Required</span>
                            </div>
                        </div>
                    </div>
                    <div className="new-radio-button">
                        <div className="radio-text">
                            <span>Year of Joining</span>
                            <p>
                                Do you want to keep the year of joining field
                                required or optional.
                            </p>
                        </div>
                        <div className="new-radio-input">
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="year_of_joining"
                                    defaultChecked={
                                        isJoiningYearChecked === false
                                    }
                                    onChange={JoiningYearOptionalEvent}
                                />
                                <span>Optional</span>
                            </div>
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="year_of_joining"
                                    defaultChecked={
                                        isJoiningYearChecked === true
                                    }
                                    onChange={JoiningYearRequiredEvent}
                                />
                                <span>Required</span>
                            </div>
                        </div>
                    </div>
                    <div className="new-radio-button">
                        <div className="radio-text">
                            <span>Phone</span>
                            <p>
                                Do you want to keep the phone field required or
                                optional.
                            </p>
                        </div>
                        <div className="new-radio-input">
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="phone_optional"
                                    defaultChecked={isPhoneChecked === false}
                                    onChange={PhoneOptionalEvent}
                                />
                                <span>Optional</span>
                            </div>
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="phone_optional"
                                    defaultChecked={isPhoneChecked === true}
                                    onChange={PhoneRequiredEvent}
                                />
                                <span>Required</span>
                            </div>
                        </div>
                    </div>
                    <div className="new-radio-button">
                        <div className="radio-text">
                            <span>Gender</span>
                            <p>
                                Do you want to keep the gender field required or
                                optional.
                            </p>
                        </div>
                        <div className="new-radio-input">
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="gender_optional"
                                    defaultChecked={isGenderChecked === false}
                                    onChange={GenderOptionalEvent}
                                />
                                <span>Optional</span>
                            </div>
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    name="gender_optional"
                                    defaultChecked={isGenderChecked === true}
                                    onChange={GenderRequiredEvent}
                                />
                                <span>Required</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Stack>
            <Stack styles={stackNewStyles}>
                {/* <div className="next-button">
                    <button
                        style={{ width: 80 }}
                    >
                        <span>Update</span>
                    </button>
                </div> */}
                <ButtonWithLoader
                    onClick={handleSubmit}
                    name="Update"
                    loading={loading}
                />
            </Stack>

            <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <UpdateModal
                    text={'Setting has been updated successfully.'}
                    onClick={updateChanges}
                />
            </Dialog>
        </div>
    );
};
const mapStateToProps = (state) => ({
    school_id: state.app.school_id,
});

export default withRouter(connect(mapStateToProps)(FieldSettingsAccordianTab));
