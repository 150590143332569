import { Dropdown } from '@fluentui/react/lib/Dropdown';
import React, { useEffect, useState } from 'react';
import { MonthOptions } from '../../../../common/months';
import { YearsOptions } from '../../../../common/years';

const WorkExperienceDetailEditModal = ({
    onCancel,
    work_experience,
    onUpdate,
}) => {
    const [input, setInput] = useState({
        company: '',
        position: '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setInput({ ...work_experience });
    }, [work_experience]);

    const validate = () => {
        let fields = [
            {
                label: 'Company Name',
                key: 'company',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            {
                label: 'Position',
                key: 'position',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            {
                label: 'From month',
                key: 'from_month',
                required: true,
            },
            {
                label: 'From year',
                key: 'from_year',
                required: true,
            },
            {
                label: 'To month',
                key: 'to_month',
                required: true,
            },
            {
                label: 'To year',
                key: 'to_year',
                required: true,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (
                field.required &&
                (input[field.key] === undefined ||
                    input[field.key] === null ||
                    input[field.key] === '')
            ) {
                if (
                    input.currentlyWorking &&
                    ['to_month', 'to_year'].indexOf(field.key) > -1
                ) {
                    return;
                }

                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        if (!input.currentlyWorking) {
            let from = parseFloat(`${input.from_year}.${input.from_month}`);
            let to = parseFloat(`${input.to_year}.${input.to_month}`);
            if (to < from && to !== 0) {
                e.from_month = 'From cannot be after to';
                e.from_year = 'From cannot be after to';
            }
        }

        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        onUpdate({ ...input });
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const frommonth = [
        { key: 0, text: '1' },
        { key: 1, text: '2' },
        { key: 2, text: '3' },
        { key: 3, text: '4' },
        { key: 4, text: '5' },
        { key: 5, text: '6' },
        { key: 6, text: '7' },
        { key: 7, text: '8' },
        { key: 8, text: '9' },
        { key: 9, text: '10' },
        { key: 10, text: '11' },
        { key: 11, text: '12' },
    ];

    const fromyear = [
        { key: 0, text: '2000' },
        { key: 1, text: '2001' },
        { key: 2, text: '2002' },
        { key: 3, text: '2003' },
        { key: 4, text: '2004' },
        { key: 5, text: '2005' },
    ];

    return (
        <div className="edit-profile-modal">
            <div className="modal-heading">
                <h4>Work Experience Detail</h4>
                <div onClick={onCancel}>
                    <img
                        src="../../../../images/edit-close.svg"
                        alt="CloseIcon"
                    />
                </div>
            </div>

            <div className="edit-form">
                <div className="form-group">
                    <label>Company</label>
                    <input
                        type="text"
                        value={input.company}
                        onChange={handleChange}
                        name="company"
                        placeholder="Name"
                        required={true}
                    />
                    <span className="edu-error">{errors.company}</span>
                </div>
                <div className="form-group">
                    <label>Position</label>
                    <input
                        type="text"
                        value={input.position}
                        onChange={handleChange}
                        name="position"
                        placeholder="Position"
                        required={true}
                    />
                    <span className="edu-error">{errors.position}</span>
                </div>
                <div className="form-group">
                    <div className="drop-with-icon">
                        <Dropdown
                            placeholder="Select month"
                            label={'From Month'}
                            options={MonthOptions}
                            className=""
                            name="from_month"
                            selectedKey={input.from_month}
                            onChange={(e, item) => {
                                handleChange({
                                    target: {
                                        value: item.key,
                                        name: 'from_month',
                                    },
                                });
                            }}
                            required={true}
                        />
                    </div>
                    <span className="edu-error">{errors.from_month}</span>
                </div>
                <div className="form-group">
                    <div className="drop-with-icon">
                        <Dropdown
                            placeholder="Select year"
                            label={'From Year'}
                            options={YearsOptions}
                            className=""
                            name="from_year"
                            selectedKey={input.from_year}
                            onChange={(e, item) => {
                                handleChange({
                                    target: {
                                        value: item.key,
                                        name: 'from_year',
                                    },
                                });
                            }}
                            required={true}
                        />
                    </div>
                    <span className="edu-error">{errors.from_year}</span>
                </div>
                {!input.currentlyWorking && (
                    <>
                        <div className="form-group">
                            <div className="drop-with-icon">
                                <Dropdown
                                    placeholder="Select month"
                                    label={'To Month'}
                                    options={MonthOptions}
                                    className=""
                                    name="to_month"
                                    selectedKey={input.to_month}
                                    onChange={(e, item) => {
                                        handleChange({
                                            target: {
                                                value: item.key,
                                                name: 'to_month',
                                            },
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                            <span className="edu-error">{errors.to_month}</span>
                        </div>
                        <div className="form-group">
                            <div className="drop-with-icon">
                                <Dropdown
                                    placeholder="Select year"
                                    label={'To Year'}
                                    options={YearsOptions}
                                    className=""
                                    name="to_year"
                                    selectedKey={input.to_year}
                                    onChange={(e, item) => {
                                        handleChange({
                                            target: {
                                                value: item.key,
                                                name: 'to_year',
                                            },
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                            <span className="edu-error">{errors.to_year}</span>
                        </div>
                    </>
                )}

                <div className="form-group education-checkbox">
                    <input
                        type="checkbox"
                        checked={input.currentlyWorking}
                        onChange={(e) => {
                            handleChange({
                                target: {
                                    value: e.target.checked,
                                    name: 'currentlyWorking',
                                },
                            });
                        }}
                        name="currentlyWorking"
                    />
                    <label>Currently Working</label>
                </div>

                <div className="form-buttons">
                    <button className="cancel" onClick={onCancel}>
                        No, cancel
                    </button>
                    <button className="save" onClick={handleSubmit}>
                        Yes, confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WorkExperienceDetailEditModal;
