import React from 'react';

const ListItems = () => {
    return (
        <>
            <div className="conversation-messages ">
                <div className="conversation-list">
                    <div className="list-user-pic">
                        <img
                            src="../../../../../../images/user-intial.svg"
                            alt={'profile'}
                        />
                        <div className="user-online">
                            <img
                                src="../../../../../../images/online.svg"
                                alt={'Intial'}
                            />
                        </div>
                    </div>
                    <div className="list-messages">
                        <div className="list-user-col-up">
                            <h4>Lee Williamson</h4>
                            <span>06.12</span>
                        </div>
                        <div className="list-user-col-down">
                            <p>Yes, that’s gonna work, hop...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="conversation-messages ">
                <div className="conversation-list">
                    <div className="list-user-pic">
                        <img
                            src="../../../../../../images/user-intial.svg"
                            alt={'profile'}
                        />
                        <div className="user-online">
                            <img
                                src="../../../../../../images/offline.svg"
                                alt={'Intial'}
                            />
                        </div>
                    </div>
                    <div className="list-messages">
                        <div className="list-user-col-up">
                            <h4>Lee Williamson</h4>
                            <span>06.12</span>
                        </div>
                        <div className="list-user-col-down">
                            <p>Yes, that’s gonna work, hop...</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListItems;
