import React from 'react';
import BannerRightSection from './components/BannerRightSection';
import JobApplicationsSection from './components/JobApplicationsSection';
import RightMemberSection from './components/RightMemberSection';

const HomeRightItems = () => {
    return (
        <div className="home-left-col">
            <RightMemberSection />
            {/* <JobApplicationsSection /> */}
            {/* <BannerRightSection /> */}
        </div>
    );
};

export default HomeRightItems;
