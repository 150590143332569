import React from 'react';
import Header from './Header';
import { Stack } from '@fluentui/react/lib/Stack';
import Aside from './Aside/Aside';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Styles definition
const stackStyles = {
    root: {
        minHeight: '100vh',
        width: 'calc(100% - 233px)',
        paddingLeft: 3,
        paddingRight: 3,
        float:'right',
        marginTop:60
    },
};

const MainLayout = ({ children }) => {
    return (
        <div className='main-admin'>
            <Header />
            <Aside />
            {/* <Nav /> */}
            <Stack styles={stackStyles}>{children}</Stack>
            {/*<Footer />*/}
        </div>
    );
};

const mapStateToProps = (state) => ({
    school_id: state.app.school_id,
});

export default withRouter(connect(mapStateToProps)(MainLayout));
