import React, { useEffect, useState } from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import Api from '../../../common/api/api';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import InputWithLabel from '../../../common/controls/InputWithLabel';
import Initialising from '../../../common/controls/Initialising';
import Loader from 'react-js-loader';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { DefaultButton } from '@fluentui/react/lib/Button';
import ErrorModal from '../../../common/modals/ErrorModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';

// Styles definition

const stackStyles = {
    root: {
        padding: 30,
        width: '100%',
        float: 'left',
    },
};

const stackItemStyles = {
    root: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 30,
        border: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        borderRadius: 8,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const dialogNewStyles = {
    main: {
        
        width: '100% !important',
    },
};


initializeIcons();

const AddCustomFieldPage = () => {
    const [input, setInput] = useState({
        name: null,
        entity: null,
        type: null,
        dropdown_options: '',
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [errorText, setErrorText] = useState("");

    const validate = () => {
        let fields = [
            {
                label: 'Field Name ',
                key: 'name',
                required: true,
            },
            {
                label: 'Entity',
                key: 'entity',
                required: true,
            },
            {
                label: 'Type',
                key: 'type',
                required: true,
            },
            {
                label: 'Dropdown Options',
                key: 'dropdown_options',
                required: true,
            },
        ];

        let e = {};
        fields.forEach((field) => {

            if (field.required && (!input[field.key] || input[field.key].trim() === '') || input[field.key] === null) {
                e[field.key] = `${field.label} is required`;
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const validateWithoutDropDownOption = () => {
        let fields = [
            {
                label: 'Field Name ',
                key: 'name',
                required: true,
            },
            {
                label: 'Entity',
                key: 'entity',
                required: true,
            },
            {
                label: 'Type',
                key: 'type',
                required: true,
            },

        ];

        let e = {};
        fields.forEach((field) => {

            if (field.required && (!input[field.key] || input[field.key].trim() === '')) {
                e[field.key] = `${field.label} is required`;
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

     const updateChanges = () => {
        toggleHideDialog();
    };

    const history = useHistory();
    const { id } = useParams();
    const handleSubmit = () => {
        if (input.type == 'Text') {
            if (!validateWithoutDropDownOption()) {
                return;
            }
        }
        else {
            if (!validate()) {
                return;
            }
        }
        setLoading(true);
        Api.getInstance()
            .createField(id, input)
            .then((response) => {
                history.push('/field/list');
            })
            .catch((e) => { 
                setErrorText(e.error);
                toggleHideDialog();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const [initialising, setInitialising] = useState(true);

    useEffect(() => {
        if (id) {
            setInitialising(true);
            Api.getInstance()
                .getField(id)
                .then(({ custom_data, work_experience, ...response }) => {
                    setInput({ ...response });
                    setInitialising(false);
                })
                .catch(console.log);
        } else {
            setInitialising(false);
        }
    }, [id]);

    return (
        <MainLayout>
            <Stack styles={stackStyles}>
                <Stack.Item styles={stackItemStyles}>
                    <Stack.Item>
                        <span className="ms-fontSize-24 ms-fontWeight-regular fontFamily line-height150 darkColor">
                            {id ? 'Edit Field' : 'Add Field'}
                        </span>
                    </Stack.Item>
                    {initialising && <Initialising />}
                    {!initialising && (
                        <Stack.Item>
                            <Stack>
                                <div
                                    className="add-user-form set-height "
                                    style={{ marginTop: 13 }}
                                >
                                    <div className="form-group pr">
                                        <InputWithLabel
                                            label="Field Name"
                                            value={input.name}
                                            onChange={handleChange}
                                            name="name"
                                            type="text"
                                            errorMessage={errors.name}
                                            required={true}
                                            estric="*"
                                        />
                                    </div>
                                    <div className="form-group pl">
                                        <div className="drop-with-icon">
                                            <Dropdown
                                                options={[
                                                    {
                                                        key: 'Alumni',
                                                        text: 'Alumni',
                                                    },
                                                ]}
                                                label="Entity"
                                                selectedKey={input.entity}
                                                onChange={(e, item) => {
                                                    handleChange({
                                                        target: {
                                                            value: item.key,
                                                            name: 'entity',
                                                        },
                                                    });
                                                }}
                                                name="entity"
                                                required={true}
                                                errorMessage={errors.entity}
                                                className={
                                                    errors.entity
                                                        ? 'drop-error'
                                                        : ''
                                                }
                                            />

                                        </div>
                                    </div>
                                    <div className="form-group pr">
                                        <div className="drop-with-icon">
                                            <Dropdown
                                                options={[
                                                    {
                                                        key: 'Text',
                                                        text: 'Text',
                                                    },
                                                    {
                                                        key: 'Dropdown',
                                                        text: 'Dropdown',
                                                    },
                                                ]}
                                                label="Type"
                                                selectedKey={input.type}
                                                onChange={(e, item) => {
                                                    handleChange({
                                                        target: {
                                                            value: item.key,
                                                            name: 'type',
                                                        },
                                                    });
                                                }}
                                                name="type"
                                                required={true}
                                                errorMessage={errors.type}
                                                className={
                                                    errors.type
                                                        ? 'drop-error'
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                    {input.type === 'Dropdown' && (
                                        <div className="form-group pl">
                                            <InputWithLabel
                                                label="Dropdown Options"
                                                value={input.dropdown_options}
                                                onChange={handleChange}
                                                name="dropdown_options"
                                                type="text"
                                                placeholder={
                                                    'Option 1,Option 2'
                                                }
                                                errorMessage={
                                                    errors.dropdown_options
                                                }
                                                required={true}
                                                estric="*"
                                            />
                                        </div>
                                    )}
                                    <Stack styles={stackNewStyles}>
                                        <DefaultButton
                                            onClick={() => {
                                                history.push('/field/list');
                                            }}
                                            text="Cancel"
                                            styles={stackDefaultStyles}
                                        />
                                        <div className="next-button">
                                            <button
                                                onClick={handleSubmit}
                                                // style={{ minWidth: 90 }}
                                                loading={loading}
                                            >
                                                {loading ? (
                                                    <Loader
                                                        type="bubble-scale"
                                                        bgColor={'#FFFFFF'}
                                                        color={'#FFFFFF'}
                                                        size={40}
                                                    />
                                                ) : (
                                                    <span>Save</span>
                                                )}
                                            </button>
                                        </div>
                                    </Stack>
                                </div>
                            </Stack>
                        </Stack.Item>
                    )}
                </Stack.Item>
            </Stack>
             <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <ErrorModal 
                text={errorText}
                onClick={updateChanges} 
                />
            </Dialog>
        </MainLayout>
        
    );
};

export default AddCustomFieldPage;
