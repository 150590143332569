import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import Api from '../../../../../../common/api/api';
import { useHistory, useParams } from 'react-router-dom';
import InputWithLabel from '../../../../../../common/controls/InputWithLabel';
import Loader from 'react-js-loader';
import { DefaultButton } from '@fluentui/react/lib/Button';
import ButtonWithLoader from '../../../../../../common/controls/ButtonWithLoader';
import ErrorModal from '../../../../../../common/modals/ErrorModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import ConfirmModal from '../../../../../../common/modals/ConfirmModal';
import ContactUsConfirmModal from '../../../../../../common/modals/ContactUsConfirmModal';

const stackStyles = {
    root: {
        padding: 30,
        width: '100%',
        float: 'left',
    },
};

const stackItemStyles = {
    root: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 30,
        border: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        borderRadius: 8,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const ContactUsForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const [input, setInput] = useState({
        subject: '',
        description: '',
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [errorText, setErrorText] = useState('');
    const [confirmModalText, setConfirmModalText] = useState('');

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const updateChanges = () => {
        toggleHideDialog();
    };

     const update = () => {
        toggleHideDialog1();
        window.location.reload();
    };

    const validate = () => {
        let fields = [
            {
                label: 'Subject',
                key: 'subject',
                required: true,
            },
            {
                label: 'Description',
                key: 'description',
                required: true,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.required && input[field.key].trim() === '') {
                e[field.key] = `${field.label} is required`;
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);
        Api.getInstance()
            .createConatctUsForm(id, input)
            .then((response) => {
                toggleHideDialog1();
            })
            .catch((e) => {
                setErrorText(e.error);
                toggleHideDialog();
            })
            .finally(() => {
                setLoading(false);
            });
    };

     const cancelImport = () => {
        toggleHideDialog1();
        history.push(`/${type}/list`);
    };

    return (
        <div className="home-middle-col">
            <div className="branch-member-card" style={{ marginTop: 0 }}>
                <div className='memer-heading'>
                      <span>Contact Us</span>
                    </div>
                <div className="update-form">
                    <div className="form-group">
                        <label>
                            Subject
                            <span className="estirc-sign">*</span>
                        </label>
                        <input
                            type="text"
                            required={true}
                            value={input.subject}
                            onChange={handleChange}
                            name="subject"
                            placeholder="subject"
                        />
                        <span className="edu-error">{errors.subject}</span>
                    </div>
                    <div className="form-group">
                        <label>
                            Description <span className="estirc-sign">*</span>
                        </label>
                        <textarea
                            className='description-contactUs-form'
                            maxLength="10000"
                            rows={4}
                            onChange={handleChange}
                            name="description"
                            value={input.description}
                            required={true}
                            estric="*"
                            cols={20}
                            placeholder="Post your query here..."
                            style={{
                                height: 'auto',
                                resize: 'auto',
                            }}
                        ></textarea>
                        <span className="edu-error">{errors.description}</span>
                    </div>
                    <div className="form-buttons">
                            <button className="save" loading={loading} onClick={handleSubmit}>
                                {loading
                                    ?
                                    <Loader
                                        type="bubble-scale"
                                        bgColor={'#FFFFFF'}
                                        color={'#FFFFFF'}
                                        size={40}
                                    />
                                    :
                                    'Submit'
                                }
                            </button>
                        </div>
                </div>
            </div>

            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={updateChanges} />
            </Dialog>

             <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <ContactUsConfirmModal
                    heading="Sent"
                    text={confirmModalText}
                    onClick={update}
                />
            </Dialog>
        </div>
    );
};

export default ContactUsForm;
