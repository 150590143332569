import React from 'react';
import EditLeft from './SubComponents/EditLeft';
import EditMiddle from './SubComponents/EditMiddle';

const EditItems = () => {
    return (
        <div className="home-body">
            <div className="container">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 ">
                            <EditLeft />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <EditMiddle />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditItems;
