import React from 'react';
import OtherProfileLeft from './SubComponents/OtherProfileLeft';
import OtherProfileMiddle from './SubComponents/OtherProfileMiddle';

const OtherProfileItems = () => {
    return (
        <div className="home-body">
            <div className="container">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 ">
                            <OtherProfileLeft />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <OtherProfileMiddle />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtherProfileItems;
