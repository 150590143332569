import React from 'react';
import { useDropzone } from 'react-dropzone';
import ButtonWithLoader from '../../../common/controls/ButtonWithLoader';
import { Stack } from '@fluentui/react';
import { DefaultButton } from '@fluentui/react';
import { useState } from 'react';
import Api from '../../../common/api/api';

// const [errors, setErrors] = useState({});

// const [loading, setLoading] = useState(false);

const stackNewStyles = {
    root: {
        // display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        marginLeft: 20,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

function FileUpload() {
    const [image, setFile] = useState(null);
    const { isDragActive, acceptedFiles, getRootProps, getInputProps } =
        useDropzone();

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const handleSubmit = () => {
        let file = acceptedFiles[0];
        if (!file) {
            return;
        }

        Api.getInstance()
            .uploadFile(
                {
                    extension: file.name.split('.').pop(),
                    'Content-Type': file.type,
                },
                file,
            )
            .then(({ key }) => {
                setFile(key);
            })
            .catch(console.log)
            .finally(() => {
                //setUploading(false);
            });
    };

    return (
        <div>
            <div
                {...getRootProps()}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className="upload-box">
                    <img
                        src="../../../../../../../images/upload-icon.svg"
                        alt="UploadIcon"
                    />
                </div>
                <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the file here to upload</p>
                        ) : (
                            <p>
                                Drag & drop or select an file to upload the file
                            </p>
                        )}
                    </div>
                    <aside
                        style={{
                            border: '2px solid black',
                            padding: 10,
                            borderRadius: 10,
                        }}
                    >
                        <h4 style={{ marginTop: 0, marginBottom: 0 }}>Files</h4>
                        <ul>{files}</ul>
                    </aside>
                </section>
            </div>
            <div>
               <a src={image}>Click me</a>
                {image}
            </div>
            <div className="next-button">
                <button
                    onClick={handleSubmit}
                    style={{ minWidth: 90 }}
                ></button>
            </div>
        </div>
    );
}

export default FileUpload;
