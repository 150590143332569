export default {
    can: {
        title: 'CAN - Chitkara University Alumni Network',
        supportEmail: 'alumni@chitkara.edu.in',
    },
    oia: {
        title: 'Office of International Affairs - Chitkara University',
        supportEmail: 'oia@chitkara.edu.in',
    },
    myonlinecollege: {
        title: 'My Online College',
        supportEmail: 'info@myonlinecollege.in',
    },

    cisan: {
        title: 'CISAN',
        supportEmail: 'cisan@chitkaraschool.in',
    },

    dps: {
        title: 'DPS Yamuna Nagar- The Best CBSE School In Haryana',
        supportEmail: 'info@dpsyamunanagar.com',
    },
    banyantreedel: {
        title: 'Banyan Tree School, Delhi',
        supportEmail: 'rina.bana@banyantree.in',
    },
    banyantreechd: {
        title: 'Banyan Tree School, Chandigarh',
        supportEmail: 'principal@chd.banyantree.in',
    },
    banyantreejpr: {
        title: 'Banyan Tree School, Jaipur',
        supportEmail: 'ruchi.srivastava@jpr.banyantree.in',
    },
    chitkara: {
        title: 'Chitkara University',
        supportEmail: 'director@chitkara.edu.in'
    },
    demo: {
        title: 'Almacircle Demo',
        supportEmail: 'demo@almacircle.com'
    },
    schoolpad: {
        title: 'Schoolpad',
        supportEmail: 'abhiraj@schoolpad.in'
    }
};
