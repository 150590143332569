import React from 'react';
import { Months } from '../../../../../../../common/months';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '620px !important',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const EducationDetails = ({ work_experience }) => {
    const workExperienceToShow = work_experience.filter(
        (item) => item.from_month,
    );
    return (
        <div className="branch-member-card">
            <div className="education-heading">
                <span>Work Experience</span>
            </div>
            <div className="education-list">
                {workExperienceToShow.length === 0 && (
                    <div className="member-list-col-new">
                        No employment history found.
                    </div>
                )}
                {/* Loop Div */}
                {workExperienceToShow.map((item, index) => {
                    return (
                        <div
                            className="member-list-col-new"
                            key={`work_experience-${index}`}
                        >
                            <div className="col-left" style={{cursor:'unset'}}>
                                <div className="member-info-new-details">
                                    <h4>{item.company}</h4>
                                    <span>{item.position}</span>
                                    <p>
                                        {Months[item.from_month]}{' '}
                                        {item.from_year} -{' '}
                                        {item.currentlyWorking
                                            ? 'Present'
                                            : `${Months[item.to_month]} ${
                                                  item.to_year
                                              }`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default EducationDetails;
