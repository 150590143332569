import React from 'react';

const InputWithLabel = (props) => {
    return (
        <div
            className={
                props.errorMessage
                    ? 'input-with-label-error'
                    : 'input-with-label'
            }
        >
            <label>{props.label} <span className='estirc-sign'>{props.estric}</span></label>
            <input
                type={props.type}
                name={props.name}
                onChange={props.onChange}
                disabled={props.disabled}
                value={props.value}
                placeholder={props.placeholder}
                minLength="30"
            />
            <span>{props.errorMessage}</span>
        </div>
    );
};

export default InputWithLabel;
