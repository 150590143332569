import React from 'react'
import ContactUsPage from './Components/ContactUsPage'
import MainLayout from '../../Components/Layout/MainLayout';

const ContactUs = () => {
    return (
        <MainLayout>
           <ContactUsPage />
        </MainLayout>
    );
}

export default ContactUs
