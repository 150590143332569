import * as React from 'react';
import { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import Api from './api/api';
import { useHistory } from 'react-router-dom';
import InputWithLabel from './controls/InputWithLabel';
import Initialising from './controls/Initialising';
import InputWithIcon from './controls/InputWithIcon';
import ButtonWithLoader from './controls/ButtonWithLoader';
import ErrorModal from './modals/ErrorModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const stackNewDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: '#2962FF',
        color: '#ffffff',
        fontSize: 16,
        border: 'none',
        fontWeight: 'normal',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const StaffAccordianTabs = ({ id }) => {
    const [input, setInput] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
    });

    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (id) {
            setUserId(id);
        }
    }, [id]);

    const [selectedKey, setSelectedKey] = useState('basic');

    const [errorText, setErrorText] = useState('');

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const [initialising, setInitialising] = useState(true);

    // Password hide show
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const ErrorChanges = () => {
        toggleHideDialog();
    };

    useEffect(() => {
        if (id) {
            setInitialising(true);
            Api.getInstance()
                .getStaff(id)
                .then(({ ...response }) => {
                    setInput({ ...response });
                    setInitialising(false);
                })
                .catch(console.log);
        } else {
            setInitialising(false);
        }
    }, [id]);

    const validate = () => {
        let fields = [
            {
                label: 'First Name',
                key: 'first_name',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            {
                label: 'Last Name',
                key: 'last_name',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            {
                label: 'Email',
                key: 'email',
                required: true,
                regex: /^\S+@\S+\.\S+$/,
            },
            {
                label: 'Password',
                key: 'password',
                required: true,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.required && input[field.key] === '') {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleFirstStep = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);

        if (userId) {
            Api.getInstance()
                .updateStaff(userId, input)
                .then((response) => {
                    history.push('/staff/list');
                })
                .catch((e) => {
                    setErrorText(e.error);
                    toggleHideDialog();
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            Api.getInstance()
                .createStaff(input)
                .then((response) => {
                    setUserId(response.id);
                    history.push('/staff/list');
                })
                .catch((e) => {
                    setErrorText(e.error);
                    toggleHideDialog();
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const history = useHistory();

    return (
        <div className="user-tabs" style={{ marginTop: 0 }}>
            <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                Add staff who will access and use this alumni portal
            </span>
            {initialising && <Initialising />}
            {!initialising && (
                <>
                    <Stack>
                        <div className="add-user-form">
                            <div className="form-group pr">
                                <InputWithLabel
                                    required={true}
                                    errorMessage={errors.first_name}
                                    label="First Name"
                                    value={input.first_name}
                                    onChange={handleChange}
                                    name="first_name"
                                    type="text"
                                    estric="*"
                                />
                            </div>
                            <div className="form-group pl">
                                <InputWithLabel
                                    required={true}
                                    label="Last Name"
                                    errorMessage={errors.last_name}
                                    value={input.last_name}
                                    onChange={handleChange}
                                    name="last_name"
                                    type="text"
                                    estric="*"
                                />
                            </div>
                            <div className="form-group pr">
                                <InputWithLabel
                                    required={true}
                                    errorMessage={errors.email}
                                    label="Email"
                                    value={input.email}
                                    onChange={handleChange}
                                    name="email"
                                    type="email"
                                    estric="*"
                                />
                            </div>
                            <div className="form-group pl">
                                <InputWithIcon
                                    required={true}
                                    errorMessage={errors.password}
                                    label="Password"
                                    value={input.password}
                                    onChange={handleChange}
                                    name="password"
                                    type={passwordShown ? 'text' : 'password'}
                                    onClick={togglePasswordVisibility}
                                    icon={
                                        passwordShown
                                            ? '../../../../images/view.svg'
                                            : '../../../../images/view-hide.svg'
                                    }
                                    className={
                                        errors.password
                                            ? 'with-icon-input-error'
                                            : 'with-icon-input'
                                    }
                                />
                            </div>
                        </div>
                    </Stack>

                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={() => {
                                history.push('/staff/list');
                            }}
                            text="Cancel"
                            styles={stackDefaultStyles}
                        />
                        {/* <div className="next-button">
                            <button
                                disabled={loading}
                                onClick={handleFirstStep}
                                style={{ width: 80 }}
                            >
                                <span>Save</span>
                            </button>
                        </div> */}
                        <ButtonWithLoader
                            loading={loading}
                            onClick={handleFirstStep}
                            name="Save"
                        />
                    </Stack>
                </>
            )}
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={ErrorChanges} />
            </Dialog>
        </div>
    );
};

export default StaffAccordianTabs;
