import React from 'react';
import HeaderItems from './HeaderItems';

const Header = () => {
    return (
        <div className="header">
            <HeaderItems />
        </div>
    );
};
export default Header;
