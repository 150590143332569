import React, { useState } from 'react';
import AuthLayout from '../../../layouts/auth/AuthLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import { Link } from 'react-router-dom';
import DefaultInput from '../../../common/controls/DefaultInput';
import Api from '../../../common/api/api';
import Loader from 'react-js-loader';
import ErrorModal from '../../../common/modals/ErrorModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import ConfirmModal from '../../../common/modals/ConfirmModal';
import config from '../../../config/config';
import getSchool from '../../../helpers/getSchool';
import PasswordResetModal from '../../../common/modals/PasswordResetModal';

const stackStyles = {
    root: {
        width: 340,
        flexDirection: 'column',
    },
};

const stackItemStyles = {
    root: {
        marginBottom: 12,
    },
};

const questionstackItemStyles = {
    root: {
        textAlign: 'center',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px'
    },
};

const ForgotPage = (props) => {
    const [input, setInput] = useState({ email: '' });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [errorText, setErrorText] = useState('');
    const [confirmModalText, setConfirmModalText] = useState('');

    const handleFormChange = (e) => {
        let _form = { ...input };
        _form[e.target.name] = e.target.value;
        setInput(_form);
    };

    const updateChanges = () => {
        toggleHideDialog();
    };

    const OkayChanges = () => {
        toggleHideDialog1();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});
        Api.getInstance()
            .forgotPassword(input)
            .then((response) => {
                setConfirmModalText(
                    `An email with instructions to reset your password has been sent to ${input.email}`,
                );
                // alert(
                //     `An email with instructions to reset your password has been sent to ${input.email}`,
                // );
                toggleHideDialog1();
            })
            .catch((e) => {
                if (e.error == 'Required') {
                    setErrors(e);
                } else {
                    setErrorText(e.error);
                    toggleHideDialog();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <AuthLayout>
            <div className="forgot-heading">
                <span className="ms-fontSize-24 ms-fontWeight-bold darkColor fontFamily">
                    Forgot Password
                </span>
                <p className="ms-fontSize-16 ms-fontWeight-regular lightColor fontFamily margin">
                    Enter Email
                </p>
            </div>
            <form onSubmit={handleSubmit}>
                <Stack horizontal styles={stackStyles}>
                    <Stack.Item styles={stackItemStyles}>
                        <DefaultInput
                            onChange={handleFormChange}
                            name={'email'}
                            value={input.email}
                            type="email"
                            placeholder="Email"
                            icon="../../../../images/email-icon.svg"
                            error={errors.error ? 'Email is required' : ''}
                            className={
                                errors.error
                                    ? 'default-input error'
                                    : 'default-input'
                            }
                        />
                    </Stack.Item>

                    <div className="button-with-arrow">
                        <Link to="/auth/login">
                            <img
                                src="../../../../images/btn.svg "
                                style={{ marginRight: 10, marginTop: 4 }}
                            />
                        </Link>

                        <button
                            disabled={loading}
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            {loading ? (
                                <Loader
                                    type="bubble-scale"
                                    bgColor={'#FFFFFF'}
                                    color={'#FFFFFF'}
                                    size={40}
                                />
                            ) : (
                                'Reset Password'
                            )}
                        </button>
                    </div>

                    <Stack.Item styles={questionstackItemStyles}>
                        <span className="ms-fontSize-14 ms-fontWeight-regular lightColor fontFamily line-height20">
                            Questions? Email us at{' '}
                        </span>
                        <a
                            href={`mailto:${config[getSchool()].supportEmail}`}
                            className="ms-fontSize-14 ms-fontWeight-regular blueColor fontFamily line-height20"
                            style={{ marginLeft: 1 }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {config[getSchool()].supportEmail}
                        </a>
                    </Stack.Item>
                </Stack>
            </form>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={updateChanges} />
            </Dialog>

            <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <PasswordResetModal
                    heading="Password Reset"
                    text={confirmModalText}
                    onClick={OkayChanges}
                    buttonText={"Okay"}
                />
            </Dialog>
        </AuthLayout>
    );
};

export default ForgotPage;
