import * as React from 'react';
import { useEffect, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import Api from './api/api';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { useHistory, withRouter } from 'react-router-dom';
import InputWithLabel from './controls/InputWithLabel';
import ButtonWithLoader from './controls/ButtonWithLoader';
import Initialising from './controls/Initialising';
import { countries, states } from './Utils';
import { MonthOptions } from './months';
import { YearsOptions } from './years';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import ErrorModal from './modals/ErrorModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import { connect } from 'react-redux';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const stackNewDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: '#2962FF',
        color: '#ffffff',
        fontSize: 16,
        border: 'none',
        fontWeight: 'normal',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

initializeIcons();

const iconStyles = { marginRight: '8px' };

const AccordianTabs = ({ id, school }) => {
    const genderoptions = [
        { key: 0, text: 'Male' },
        { key: 1, text: 'Female' },
        { key: 2, text: 'Others' },
    ];
    const maritalstatusoptions = [
        { key: 0, text: 'Single' },
        { key: 1, text: 'Married' },
    ];

    const [input, setInput] = useState({
        first_name: '',
        last_name: '',
        email: null,
        username: '',
        phone: '',
        year_joining: null,
        year_leaving: null,
        batch: null,
        gender: null,
    });

    const [address, setAddress] = useState({
        correspondence: {
            country: 'India',
            state: '',
            city: '',
            address: '',
            postalCode: '',
        },
        permanent: {
            same: false,
            country: 'India',
            state: '',
            city: '',
            address: '',
            postalCode: '',
        },
    });

    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (id) {
            setUserId(id);
        }
    }, [id]);

    const [checked, setChecked] = useState(false);

    const [initialising, setInitialising] = useState(true);

    const [selectedKey, setSelectedKey] = useState('basic');

    const [customFieldInput, setCustomFieldInput] = useState({});
    const [customFieldErrors, setCustomFieldErrors] = useState({});

    const [errors, setErrors] = useState({});
    const [educationErrors, setEducationErrors] = useState({});
    const [workErrors, setWorkErrors] = useState({});
    const [linkErrors, setLinkErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [batches, setBatches] = useState([]);

    const [customFields, setCustomFields] = useState([]);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [errorText, setErrorText] = useState('');
    const [year_joining, setYearOfJoining] = useState('');
    const [year_leaving, setYearOfLeaving] = useState('');
    const [phone, setPhone] = useState('');
    const [batch, setBatch] = useState('');
    const [gender, setGender] = useState('');

    const WORK_EXPERIENCE_ITEM = {
        company: '',
        position: '',
        from_month: '',
        to_month: '',
        from_year: '',
        to_year: '',
        currentlyWorking: false,
    };

    const EDUCATION_ITEM = {
        institute: '',
        course: '',
        professionalqualification: '',
        from_month: '',
        to_month: '',
        from_year: '',
        to_year: '',
        currentlyPursuing: false,
    };
    const SOCIAL_ITEM = {
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
    };

    const [workExperience, setWorkExperience] = useState([
        { ...WORK_EXPERIENCE_ITEM },
    ]);
    const [education, setEducation] = useState([{ ...EDUCATION_ITEM }]);

    const [socialLink, setSocialLink] = useState([{ ...SOCIAL_ITEM }]);

    const ErrorChanges = () => {
        toggleHideDialog();
    };
    useEffect(() => {
        if (school) {
            setYearOfJoining(school.year_of_joining);
            setYearOfLeaving(school.year_of_leaving);
            setPhone(school.phone_optional);
            setGender(school.gender_optional);
        }
    }, [school]);

    useEffect(() => {
        if (id) {
            setInitialising(true);
            Api.getInstance()
                .getAlumni(id)
                .then(
                    ({
                        custom_data,
                        work_experience,
                        education,
                        socialLink,
                        address,
                        ...response
                    }) => {
                        setInput({ ...response });
                        setCustomFieldInput({ ...custom_data });
                        setWorkExperience(
                            work_experience.length > 0
                                ? [...work_experience]
                                : [{ ...WORK_EXPERIENCE_ITEM }],
                        );
                        setEducation(
                            education.length > 0
                                ? [...education]
                                : [{ ...EDUCATION_ITEM }],
                        );
                        setSocialLink(
                            socialLink.length > 0
                                ? [...socialLink]
                                : [{ ...SOCIAL_ITEM }],
                        );
                        if (address) {
                            address.correspondence.country = 'India'
                            address.permanent.country = 'India'
                            setAddress(address);
                        }
                        setInitialising(false);
                    },
                )
                .catch(console.log);
        } else {
            setInitialising(false);
        }
    }, [id]);

    useEffect(() => {
        Api.getInstance()
            .getBatches()
            .then((response) => {
                setBatches(response);
            })
            .catch(console.log);
        Api.getInstance()
            .getFields()
            .then((response) => {
                setCustomFields(response);
            })
            .catch(console.log);
    }, []);

    useEffect(() => {
        if (school) {
            setBatch(school.batch_optional);
        }
    }, [school]);

    const validate = () => {
        let fields = [
            {
                label: 'Name',
                key: 'first_name',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            // {
            //     label: 'Last Name',
            //     key: 'last_name',
            //     required: false,
            //     regex: /^[aA-zZ\s]+$/,
            // },
            {
                label: 'Email',
                key: 'email',
                required: false,
                regex: /\S+@\S+\.\S+/,
            },
            {
                label: window.location.host === "banyantreedel.almacircle.com" ? ('Last class studied'):'Batch',
                key: 'batch',
                required: batch,
            },
            {
                label: 'Year of Joining',
                key: 'year_joining',
                required: year_joining,
                regex: /[0-9]{4}$/,
            },
            {
                label: 'Year of Leaving',
                key: 'year_leaving',
                required: year_leaving,
                regex: /[0-9]{4}$/,
            },
            {
                label: 'Phone',
                key: 'phone',
                required: false,
                regex: /[0-9]{0,14}$/,
            },
            {
                label: 'Gender',
                key: 'gender',
                required: gender,
            },
            {
                label: 'Field in which you can contribute to school',
                key: 'contribution_to_school',
                required: false,
            },
            {
                label: 'Marital Status',
                key: 'maritalstatus',
                required: false,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (
                field.required &&
                (input[field.key] === undefined ||
                    input[field.key] === null ||
                    input[field.key] === '')
            ) {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        if (input.year_joining != null && input.year_leaving != null)
            if (input.year_joining > input.year_leaving) {
                e['year_leaving'] =
                    'Year of joining cannot be after year of leaving';
            }
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const validateCustomFields = () => {
        let e = {};
        customFields.forEach((field) => {
            if (customFieldInput[field.id] === '') {
                e[field.id] = `${field.label} is required`;
            }
        });
        setCustomFieldErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleFirstStep = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);

        if (userId) {
            Api.getInstance()
                .updateAlumni(userId, input)
                .then((response) => {
                    setSelectedKey('custom_fields');
                })
                .catch((e) => {
                    setErrorText(e.error);
                    toggleHideDialog();
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            Api.getInstance()
                .createAlumni(input)
                .then((response) => {
                    setUserId(response.id);
                    setSelectedKey('custom_fields');
                })
                .catch((e) => {
                    setErrorText(e.error);
                    toggleHideDialog();
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleSecondStep = () => {
        setLoading(true);
        Api.getInstance()
            .updateCustomFields(userId, { address })
            .then((response) => {
                setSelectedKey('work_experience');
            })
            .catch((e) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const validateWorkAndEducation = () => {
        let ee = {};

        education.forEach((edu, index) => {
            let from = parseFloat(`${edu.from_year}.${edu.from_month}`);
            let to = parseFloat(`${edu.to_year}.${edu.to_month}`);
            if (to < from && to !== 0) {
                ee[index] = { from_month: 'From cannot be after to' };
            }
        });
        setEducationErrors(ee);
        let we = {};

        workExperience.forEach((work, index) => {
            let from = parseFloat(`${work.from_year}.${work.from_month}`);
            let to = parseFloat(`${work.to_year}.${work.to_month}`);
            if (to < from && to !== 0) {
                we[index] = { from_month: 'From cannot be after to' };
            }
        });
        setWorkErrors(we);
        return Object.keys(ee).length === 0 && Object.keys(we).length === 0;
    };

    const validateSocialLinks = () => {
        let fields = [
            {
                label: 'Facebook',
                key: 'facebook',
                regex: '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
            },
            {
                label: 'LinkedIn',
                key: 'linkedin',
                regex: '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
            },
            {
                label: 'Instagram',
                key: 'instagram',
                regex: '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
            },
            {
                label: 'Twitter',
                key: 'twitter',
                regex: '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
            },
        ];
        let er = {};

        fields.forEach((field) => {
            if (field.regex && socialLink[0][field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(socialLink[0][field.key])) {
                    er[field.key] = `Please enter a valid ${field.label} url`;
                    return;
                }
            }
        });
        setLinkErrors(er);
        return Object.keys(er).length === 0;
    };

    const handleThirdStep = () => {
        setErrors({});
        
        if (!validateCustomFields()) {
            return;
        }
        if (!validateWorkAndEducation()) {
            return;
        }
        setLoading(true);
        Api.getInstance()
            .updateWorkExperience(userId, {
                work: workExperience,
                education,
                socialLink,
                custom_fields: customFieldInput,
            })
            .then((response) => {
                history.push('/alumni/list');
            })
            .catch((e) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const handleAddressChange = (type, e) => {
        let i = { ...address };
        if (
            type === 'permanent' &&
            e.target.name === 'same' &&
            e.target.value === true
        ) {
            i['permanent'] = JSON.parse(JSON.stringify(i.correspondence));
        }

        i[type][e.target.name] = e.target.value;
        if (e.target.name === 'country' && e.target.value !== 'India') {
            i[type]['state'] = 'Other';
        }
        if (i.permanent.same) {
            i['permanent'][e.target.name] = e.target.value;
        }
        setAddress(i);
    };

    const handleWorkExperienceChange = (index, e) => {
        let i = [...workExperience];
        i[index][e.target.name] = e.target.value;
        if (e.target.name === 'currentlyWorking') {
            i[index]['to'] = '';
        }
        setWorkExperience(i);
    };

    const handleEducationChange = (index, e) => {
        let i = [...education];
        i[index][e.target.name] = e.target.value;
        if (e.target.name === 'currentlyPursuing') {
            i[index]['to'] = '';
        }
        setEducation(i);
    };

    const handleSocialLinkChange = (index, e) => {
        let i = [...socialLink];
        i[index][e.target.name] = e.target.value;

        setSocialLink(i);
    };

    const handleCustomFieldChange = (e) => {
        let i = { ...customFieldInput };
        i[e.target.name] = e.target.value;
        setCustomFieldInput(i);
    };

    const columnProps = {
        tokens: { childrenGap: 10 },
        styles: { root: { width: '49%', marginRight: 10 } },
    };

    const history = useHistory();

    return (
        <div className="user-tabs">
            <Pivot
                aria-label="Basic Pivot Example"
                onChange={(e) => {}}
                selectedKey={selectedKey}
            >
                <PivotItem
                    key={'basic'}
                    itemKey={'basic'}
                    onClick={(e) => {}}
                    headerText="1. Basic Information of User"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'Noticeboard',
                        onClick: (e) => {},
                    }}
                >
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Use this wizard to create a new Alumni. Add the basic
                        alumni information on this screen.
                    </span>
                    {initialising && <Initialising />}
                    {!initialising && (
                        <>
                            <Stack>
                                <div className="add-user-form set-height">
                                    <div className="form-group pr">
                                        <InputWithLabel
                                            required={true}
                                            errorMessage={errors.first_name}
                                            label="Name"
                                            value={input.first_name}
                                            onChange={handleChange}
                                            name="first_name"
                                            estric="*"
                                        />
                                    </div>
                                    {/* <div className="form-group pl">
                                        <InputWithLabel
                                            required={true}
                                            label="Last Name"
                                            errorMessage={errors.last_name}
                                            value={input.last_name}
                                            onChange={handleChange}
                                            name="last_name"
                                        />
                                    </div> */}

                                    <div className="form-group pl">
                                        <InputWithLabel
                                            required={false}
                                            errorMessage={errors.email}
                                            label="Email"
                                            value={input.email}
                                            onChange={handleChange}
                                            name="email"
                                        />
                                    </div>

                                    <div
                                        className={
                                            errors.batch
                                                ? 'form-group error pr'
                                                : 'form-group pr'
                                        }
                                    >
                                        <div className="drop-with-icon">
                                            <Dropdown
                                                required={batch ? true : false}
                                                errorMessage={errors.batch}
                                                // placeholder="Select batch"
                                                // label={'Batch'}
                                                placeholder = {window.location.host === "banyantreedel.almacircle.com" ? ('Select Last class studied'):' Select Batch'}
                                                label = {window.location.host === "banyantreedel.almacircle.com" ? ('Last class studied'):'Batch'}
                                                options={batches.map(
                                                    (batch) => {
                                                        return {
                                                            key: batch.id,
                                                            text: batch.name,
                                                        };
                                                    },
                                                )}
                                                selectedKey={input.batch}
                                                onChange={(e, item) => {
                                                    handleChange({
                                                        target: {
                                                            value: item.key,
                                                            name: 'batch',
                                                        },
                                                    });
                                                }}
                                                className={
                                                    errors.batch
                                                        ? 'drop-error'
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group pl">
                                        <div className="drop-with-icon">
                                            <Dropdown
                                                required={
                                                    year_joining ? true : false
                                                }
                                                errorMessage={
                                                    errors.year_joining
                                                }
                                                placeholder="Year of Joining"
                                                label={'Year of Joining'}
                                                options={YearsOptions}
                                                selectedKey={input.year_joining}
                                                onChange={(e, item) => {
                                                    handleChange({
                                                        target: {
                                                            value: item.key,
                                                            name: 'year_joining',
                                                        },
                                                    });
                                                }}
                                                className={
                                                    errors.year_joining
                                                        ? 'drop-error actual-height'
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group pr">
                                        <div className="drop-with-icon">
                                            <Dropdown
                                                required={
                                                    year_leaving ? true : false
                                                }
                                                errorMessage={
                                                    errors.year_leaving
                                                }
                                                placeholder="Year of Leaving"
                                                label={'Year of Leaving'}
                                                options={YearsOptions}
                                                selectedKey={input.year_leaving}
                                                onChange={(e, item) => {
                                                    handleChange({
                                                        target: {
                                                            value: item.key,
                                                            name: 'year_leaving',
                                                        },
                                                    });
                                                }}
                                                className={
                                                    errors.year_leaving
                                                        ? 'drop-error'
                                                        : ''
                                                }
                                            />{' '}
                                        </div>
                                    </div>
                                    <div className="form-group pl">
                                        <div className="drop-with-icon">
                                            <Dropdown
                                                onChange={(e, item) => {
                                                    handleChange({
                                                        target: {
                                                            value: item.key,
                                                            name: 'gender',
                                                        },
                                                    });
                                                }}
                                                required={gender ? true : false}
                                                selectedKey={input.gender}
                                                name={'gender'}
                                                placeholder="Select an option"
                                                label="Gender"
                                                options={genderoptions}
                                                //required={true}
                                                errorMessage={errors.gender}
                                                className={
                                                    errors.gender
                                                        ? 'drop-error'
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group pr">
                                     <Dropdown
                                        required={true}
                                         onChange={(e, item) => {
                                             handleChange({
                                                 target: {
                                                     value: item.key,
                                                     name: 'maritalstatus',
                                                 },
                                             });
                                         }}
                                         selectedKey={input.maritalstatus}
                                         placeholder="Select an option"
                                         label="Marital Status"
                                         options={maritalstatusoptions}
                                         errorMessage={errors.maritalstatus}
                                         className={
                                             errors.maritalstatus
                                                 ? 'drop-error actual-height'
                                                 : 'alman-dropdown'
                                         }
                                     />
                                 </div>
                                </div>
                            </Stack>
                            <Stack styles={stackNewStyles}>
                                <DefaultButton
                                    onClick={() => {
                                        history.push('/alumni/list');
                                    }}
                                    text="Cancel"
                                    styles={stackDefaultStyles}
                                />
                                {/* <div className="next-button">
                            <button
                                loading={loading}
                                onClick={handleFirstStep}
                            >
                                <span>Save & Next</span>
                                <img
                                    src="../../../images/button-arrow.svg"
                                    alt="Arrow"
                                />
                            </button>

                        </div> */}
                                <ButtonWithLoader
                                    onClick={handleFirstStep}
                                    loading={loading}
                                    name="Save & Next"
                                    arrow="../../../images/button-arrow.svg"
                                />
                            </Stack>
                        </>
                    )}
                </PivotItem>
                <PivotItem
                    key={'custom_fields'}
                    itemKey={'custom_fields'}
                    headerText="2. Contact Information"
                >
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Use this wizard to add contact information of the alumni
                        on this screen.
                    </span>
                    <Stack>
                        <div className="add-user-form">
                            <h4>Correspondence Address</h4>
                            <div className="form-group pr">
                                <div className="drop-with-icon">
                                    <Dropdown
                                        required={false}
                                        placeholder="Country"
                                        label={'Country'}
                                        options={countries().map((country) => {
                                            return {
                                                key: country,
                                                text: country,
                                            };
                                        })}
                                        selectedKey={
                                            address.correspondence.country
                                        }
                                        onChange={(e, item) => {
                                            handleAddressChange(
                                                'correspondence',
                                                {
                                                    target: {
                                                        value: item.key,
                                                        name: 'country',
                                                    },
                                                },
                                            );
                                        }}
                                    />
                                </div>
                            </div>

                            {address.correspondence.country  === 'India' && (
                            <div>
                                <div className="form-group pl">
                                    <div className="drop-with-icon">
                                        <Dropdown
                                            required={false}
                                            placeholder="State"
                                            label={'State'}
                                            options={states().map((state) => {
                                                return {
                                                    key: state,
                                                    text: state,
                                                };
                                            })}
                                            selectedKey={
                                                address.correspondence.state
                                            }
                                            onChange={(e, item) => {
                                                handleAddressChange(
                                                    'correspondence',
                                                    {
                                                        target: {
                                                            value: item.key,
                                                            name: 'state',
                                                        },
                                                    },
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group pr">
                                    <InputWithLabel
                                        label="City"
                                        required={false}
                                        value={address.correspondence.city}
                                        onChange={(e) => {
                                            handleAddressChange(
                                                'correspondence',
                                                e,
                                            );
                                        }}
                                        name="city"
                                    />
                                </div>
                            </div>
                            )}

                            <div>
                                <div className="form-group pl">
                                    <div className="input-with-label">
                                        <label>Address</label>
                                        <textarea
                                            name="address"
                                            value={address.correspondence.address}
                                            onChange={(e) => {
                                                handleAddressChange(
                                                    'correspondence',
                                                    e,
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group pr">
                                    <InputWithLabel
                                        label="Postal Code"
                                        required={false}
                                        value={address.correspondence.postalCode}
                                        onChange={(e) => {
                                            handleAddressChange(
                                                'correspondence',
                                                e,
                                            );
                                        }}
                                        name="postalCode"
                                    />
                                </div>
                            </div>

                            <div className="permanent-box">
                                <h4>Permanent Address</h4>
                                <div className="check-group">
                                    <label>
                                        <input
                                            checked={address.permanent.same}
                                            type="checkbox"
                                            name="permanentaddress"
                                            value="permanentaddress"
                                            onClick={(e) => {
                                                handleAddressChange(
                                                    'permanent',
                                                    {
                                                        target: {
                                                            value: e.target
                                                                .checked,
                                                            name: 'same',
                                                        },
                                                    },
                                                );
                                            }}
                                        />
                                        <span>
                                            Same As Correspondence Address{' '}
                                        </span>
                                    </label>
                                </div>
                            </div>

                            {/* when click on same as correspondence address */}
                            {!address.permanent.same && (
                                <div>
                                    <div className="form-group pr">
                                        <div className="drop-with-icon">
                                            <Dropdown
                                                required={false}
                                                placeholder="Country"
                                                label={'Country'}
                                                options={countries().map(
                                                    (country) => {
                                                        return {
                                                            key: country,
                                                            text: country,
                                                        };
                                                    },
                                                )}
                                                selectedKey={
                                                    address.permanent.country
                                                }
                                                onChange={(e, item) => {
                                                    handleAddressChange(
                                                        'permanent',
                                                        {
                                                            target: {
                                                                value: item.key,
                                                                name: 'country',
                                                            },
                                                        },
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    
                                    {address.permanent.country  === 'India' && (
                                    <div>
                                        <div className="form-group pl">
                                            <div className="drop-with-icon">
                                                <Dropdown
                                                    required={false}
                                                    placeholder="State"
                                                    label={'State'}
                                                    options={states().map(
                                                        (state) => {
                                                            return {
                                                                key: state,
                                                                text: state,
                                                            };
                                                        },
                                                    )}
                                                    selectedKey={
                                                        address.permanent.state
                                                    }
                                                    onChange={(e, item) => {
                                                        handleAddressChange(
                                                            'permanent',
                                                            {
                                                                target: {
                                                                    value: item.key,
                                                                    name: 'state',
                                                                },
                                                            },
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group pr">
                                            <InputWithLabel
                                                label="City"
                                                required={false}
                                                value={address.permanent.city}
                                                onChange={(e) => {
                                                    handleAddressChange(
                                                        'permanent',
                                                        e,
                                                    );
                                                }}
                                                name="city"
                                            />
                                        </div>  
                                    </div>
                                    )}

                                    <div className="form-group pl">
                                        <div className="input-with-label">
                                            <label>Address</label>
                                            <textarea
                                                name="address"
                                                value={
                                                    address.permanent.address
                                                }
                                                onChange={(e) => {
                                                    handleAddressChange(
                                                        'permanent',
                                                        e,
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group pr">
                                        <InputWithLabel
                                            label="Postal Code"
                                            required={false}
                                            value={address.permanent.postalCode}
                                            onChange={(e) => {
                                                handleAddressChange(
                                                    'permanent',
                                                    e,
                                                );
                                            }}
                                            name="postalCode"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={() => {
                                setSelectedKey('basic');
                            }}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        {/* <div className="next-button">
                            <button onClick={handleSecondStep}>
                                <span>Save & Next</span>
                                <img
                                    src="../../../images/button-arrow.svg"
                                    alt="Arrow"
                                />
                            </button>
                        </div> */}
                        <ButtonWithLoader
                            onClick={handleSecondStep}
                            loading={loading}
                            name="Save & Next"
                            arrow="../../../images/button-arrow.svg"
                        />
                    </Stack>
                </PivotItem>
                <PivotItem
                    key={'work_experience'}
                    itemKey={'work_experience'}
                    headerText="3. Additional Information"
                >
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        This information can help create a customised user
                        experience for the alumni. Consider adding as much
                        information as possible.
                    </span>
                    <Stack styles={stackStyles}>
                        <div className="add-user-form custom-form-field">
                            {customFields.length > 0 && (
                                <>
                                    <h4 className="float width100">
                                        Custom Fields
                                    </h4>
                                    {customFields.map((field, index) => {
                                        if (
                                            field.type.toLowerCase() === 'text'
                                        ) {
                                            return (
                                                <div
                                                    className={`form-group ${
                                                        index % 2 === 0
                                                            ? 'pr'
                                                            : 'pl'
                                                    }`}
                                                    key={field.name}
                                                >
                                                    <InputWithLabel
                                                        required={true}
                                                        key={field.name}
                                                        label={field.name}
                                                        value={
                                                            customFieldInput[
                                                                field.id
                                                            ]
                                                        }
                                                        // errorMessage={
                                                        //     customFieldErrors[field.id]
                                                        // }
                                                        onChange={
                                                            handleCustomFieldChange
                                                        }
                                                        name={field.id}
                                                    />
                                                </div>
                                            );
                                        }
                                        if (
                                            field.type.toLowerCase() ===
                                            'dropdown'
                                        ) {
                                            return (
                                                <div
                                                    className={`form-group ${
                                                        index % 2 === 0
                                                            ? 'pr'
                                                            : 'pl'
                                                    }`}
                                                    key={field.name}
                                                >
                                                    <div className="drop-with-icon">
                                                        <Dropdown
                                                            onChange={(
                                                                e,
                                                                item,
                                                            ) => {
                                                                handleCustomFieldChange(
                                                                    {
                                                                        target: {
                                                                            value: item.key,
                                                                            name: field.id,
                                                                        },
                                                                    },
                                                                );
                                                            }}
                                                            selectedKey={
                                                                customFieldInput[
                                                                    field.id
                                                                ]
                                                            }
                                                            name={field.id}
                                                            placeholder="Select an option"
                                                            label={field.name}
                                                            options={field.dropdown_options
                                                                .split(',')
                                                                .map(
                                                                    (
                                                                        option,
                                                                    ) => ({
                                                                        key: option,
                                                                        text: option,
                                                                    }),
                                                                )}
                                                            required={true}
                                                            // errorMessage={
                                                            //     customFieldErrors[
                                                            //         field.id
                                                            //     ]
                                                            // }
                                                            className={
                                                                customFieldErrors[
                                                                    field.id
                                                                ]
                                                                    ? 'drop-error'
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </>
                            )}
                            <div className="border-space">
                                <h4 className=" marginTop float width100">
                                    Work Experience
                                </h4>
                                {workExperience.map((experience, index) => {
                                    return (
                                        <div key={`work-experience-${index}`}>
                                            <div className="form-group pr">
                                                <InputWithLabel
                                                    label={'Company'}
                                                    value={experience.company}
                                                    onChange={(e) => {
                                                        handleWorkExperienceChange(
                                                            index,
                                                            e,
                                                        );
                                                    }}
                                                    name={'company'}
                                                />
                                            </div>
                                            <div className="form-group pl">
                                                <InputWithLabel
                                                    label={'Position'}
                                                    value={experience.position}
                                                    onChange={(e) => {
                                                        handleWorkExperienceChange(
                                                            index,
                                                            e,
                                                        );
                                                    }}
                                                    name={'position'}
                                                />
                                            </div>
                                            <div className="form-group pr">
                                                <div className="drop-with-icon">
                                                    <Dropdown
                                                        required={true}
                                                        placeholder="From Month"
                                                        label={'From Month'}
                                                        options={MonthOptions}
                                                        selectedKey={
                                                            experience.from_month
                                                        }
                                                        errorMessage={
                                                            workErrors[index]
                                                                ?.from_month
                                                        }
                                                        onChange={(e, item) => {
                                                            handleWorkExperienceChange(
                                                                index,
                                                                {
                                                                    target: {
                                                                        value: item.key,
                                                                        name: 'from_month',
                                                                    },
                                                                },
                                                            );
                                                        }}
                                                    />{' '}
                                                </div>
                                            </div>
                                            <div className="form-group pl">
                                                <div className="drop-with-icon">
                                                    <Dropdown
                                                        required={true}
                                                        placeholder="From Year"
                                                        label={'From Year'}
                                                        options={YearsOptions}
                                                        selectedKey={
                                                            experience.from_year
                                                        }
                                                        onChange={(e, item) => {
                                                            handleWorkExperienceChange(
                                                                index,
                                                                {
                                                                    target: {
                                                                        value: item.key,
                                                                        name: 'from_year',
                                                                    },
                                                                },
                                                            );
                                                        }}
                                                    />{' '}
                                                </div>
                                            </div>

                                            {!experience.currentlyWorking && (
                                                <>
                                                    <div className="form-group pr">
                                                        <div className="drop-with-icon">
                                                            <Dropdown
                                                                disabled={
                                                                    experience.currentlyWorking
                                                                }
                                                                required={true}
                                                                placeholder="To Month"
                                                                label={
                                                                    'To Month'
                                                                }
                                                                options={
                                                                    MonthOptions
                                                                }
                                                                selectedKey={
                                                                    experience.to_month
                                                                }
                                                                onChange={(
                                                                    e,
                                                                    item,
                                                                ) => {
                                                                    handleWorkExperienceChange(
                                                                        index,
                                                                        {
                                                                            target: {
                                                                                value: item.key,
                                                                                name: 'to_month',
                                                                            },
                                                                        },
                                                                    );
                                                                }}
                                                            />{' '}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {!experience.currentlyWorking && (
                                                <div className="form-group pl">
                                                    <div className="drop-with-icon">
                                                        <Dropdown
                                                            disabled={
                                                                experience.currentlyWorking
                                                            }
                                                            required={true}
                                                            placeholder="To Year"
                                                            label={'To Year'}
                                                            options={
                                                                YearsOptions
                                                            }
                                                            selectedKey={
                                                                experience.to_year
                                                            }
                                                            onChange={(
                                                                e,
                                                                item,
                                                            ) => {
                                                                handleWorkExperienceChange(
                                                                    index,
                                                                    {
                                                                        target: {
                                                                            value: item.key,
                                                                            name: 'to_year',
                                                                        },
                                                                    },
                                                                );
                                                            }}
                                                        />{' '}
                                                    </div>
                                                </div>
                                            )}
                                            <div
                                                className="form-group-checkbox "
                                                style={{
                                                    width: '100%',
                                                    marginTop: 0,
                                                }}
                                            >
                                                <div className="check-group">
                                                    <label
                                                        style={{
                                                            marginBottom: 0,
                                                        }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name={
                                                                'currentlyWorking'
                                                            }
                                                            checked={
                                                                experience.currentlyWorking
                                                            }
                                                            onChange={(e) => {
                                                                handleWorkExperienceChange(
                                                                    index,
                                                                    {
                                                                        target: {
                                                                            name: e
                                                                                .target
                                                                                .name,
                                                                            value: e
                                                                                .target
                                                                                .checked,
                                                                        },
                                                                    },
                                                                );
                                                            }}
                                                        />
                                                        <span>
                                                            Currently Working
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="add-more-field">
                                    <button
                                        onClick={() => {
                                            setWorkExperience(
                                                (workExperience) => [
                                                    ...workExperience,
                                                    { ...WORK_EXPERIENCE_ITEM },
                                                ],
                                            );
                                        }}
                                    >
                                        <img
                                            src="../../../images/plus.png"
                                            alt="Icon"
                                        />
                                        <span>Add More</span>
                                    </button>
                                </div>
                            </div>
                            {/* Education Details */}
                            <h4 className=" marginTop float width100">
                                Education Details
                            </h4>
                            {education.map((experience, index) => {
                                return (
                                    <div key={`work-experience-${index}`}>
                                        <div className="form-group pr">
                                            <InputWithLabel
                                                label={
                                                    'School/College/University'
                                                }
                                                value={experience.institute}
                                                onChange={(e) => {
                                                    handleEducationChange(
                                                        index,
                                                        e,
                                                    );
                                                }}
                                                name={'institute'}
                                            />
                                        </div>
                                        <div className="form-group pl">
                                            <InputWithLabel
                                                label={'Class/Degree'}
                                                value={experience.course}
                                                onChange={(e) => {
                                                    handleEducationChange(
                                                        index,
                                                        e,
                                                    );
                                                }}
                                                name={'course'}
                                            />
                                        </div>
                                        <div className="form-group pr">
                                            <InputWithLabel
                                                label={'Professional Qualifications'}
                                                value={experience.professionalqualification}
                                                onChange={(e) => {
                                                    handleEducationChange(
                                                        index,
                                                        e,
                                                    );
                                                }}
                                                name={'professionalqualification'}
                                            />
                                        </div>
                                        <div className="form-group pl">
                                            <div className="drop-with-icon">
                                                <Dropdown
                                                    required={true}
                                                    placeholder="From Month"
                                                    label={'From Month'}
                                                    options={MonthOptions}
                                                    selectedKey={
                                                        experience.from_month
                                                    }
                                                    errorMessage={
                                                        educationErrors[index]
                                                            ?.from_month
                                                    }
                                                    onChange={(e, item) => {
                                                        handleEducationChange(
                                                            index,
                                                            {
                                                                target: {
                                                                    value: item.key,
                                                                    name: 'from_month',
                                                                },
                                                            },
                                                        );
                                                    }}
                                                />{' '}
                                            </div>
                                        </div>

                                        <div className="form-group pr">
                                            <div className="drop-with-icon">
                                                <Dropdown
                                                    required={true}
                                                    placeholder="From Year"
                                                    label={'From Year'}
                                                    options={YearsOptions}
                                                    selectedKey={
                                                        experience.from_year
                                                    }
                                                    onChange={(e, item) => {
                                                        handleEducationChange(
                                                            index,
                                                            {
                                                                target: {
                                                                    value: item.key,
                                                                    name: 'from_year',
                                                                },
                                                            },
                                                        );
                                                    }}
                                                />{' '}
                                            </div>
                                        </div>
                                        {!experience.currentlyPursuing && (
                                            <>
                                                <div className="form-group pl">
                                                    <div className="drop-with-icon">
                                                        <Dropdown
                                                            disabled={
                                                                experience.currentlyPursuing
                                                            }
                                                            required={true}
                                                            placeholder="To Month"
                                                            label={'To Month'}
                                                            options={
                                                                MonthOptions
                                                            }
                                                            selectedKey={
                                                                experience.to_month
                                                            }
                                                            onChange={(
                                                                e,
                                                                item,
                                                            ) => {
                                                                handleEducationChange(
                                                                    index,
                                                                    {
                                                                        target: {
                                                                            value: item.key,
                                                                            name: 'to_month',
                                                                        },
                                                                    },
                                                                );
                                                            }}
                                                        />{' '}
                                                    </div>
                                                </div>
                                                <div className="form-group pr">
                                                    <div className="drop-with-icon">
                                                        <Dropdown
                                                            disabled={
                                                                experience.currentlyPursuing
                                                            }
                                                            required={true}
                                                            placeholder="To Year"
                                                            label={'To Year'}
                                                            options={
                                                                YearsOptions
                                                            }
                                                            selectedKey={
                                                                experience.to_year
                                                            }
                                                            onChange={(
                                                                e,
                                                                item,
                                                            ) => {
                                                                handleEducationChange(
                                                                    index,
                                                                    {
                                                                        target: {
                                                                            value: item.key,
                                                                            name: 'to_year',
                                                                        },
                                                                    },
                                                                );
                                                            }}
                                                        />{' '}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="width100 float ">
                                            <div
                                                className="form-group-checkbox "
                                                style={{
                                                    width: '100%',
                                                    marginTop: 0,
                                                }}
                                            >
                                                <div className="check-group">
                                                    <label
                                                        style={{
                                                            marginBottom: 0,
                                                        }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name={
                                                                'currentlyPursuing'
                                                            }
                                                            checked={
                                                                experience.currentlyPursuing
                                                            }
                                                            onChange={(e) => {
                                                                handleEducationChange(
                                                                    index,
                                                                    {
                                                                        target: {
                                                                            name: e
                                                                                .target
                                                                                .name,
                                                                            value: e
                                                                                .target
                                                                                .checked,
                                                                        },
                                                                    },
                                                                );
                                                            }}
                                                        />
                                                        <span>
                                                            Currently Pursuing
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {/* Education Details */}
                            <h4 className=" marginTop float width100">
                                Social Profile
                            </h4>
                            {socialLink.map((social, index) => {
                                return (
                                    <div key={`work-experience-${index}`}>
                                        <div className="form-group pr">
                                            <InputWithLabel
                                                label={'Facebook'}
                                                value={social.facebook}
                                                onChange={(e) => {
                                                    handleSocialLinkChange(
                                                        index,
                                                        e,
                                                    );
                                                }}
                                                name={'facebook'}
                                                errorMessage={
                                                    linkErrors.facebook
                                                }
                                            />
                                        </div>
                                        <div className="form-group pl">
                                            <InputWithLabel
                                                label={'Linkedin'}
                                                value={social.linkedin}
                                                onChange={(e) => {
                                                    handleSocialLinkChange(
                                                        index,
                                                        e,
                                                    );
                                                }}
                                                name={'linkedin'}
                                                errorMessage={
                                                    linkErrors.linkedin
                                                }
                                            />
                                        </div>
                                        <div className="form-group pr">
                                            <InputWithLabel
                                                label={'Twitter'}
                                                value={social.twitter}
                                                onChange={(e) => {
                                                    handleSocialLinkChange(
                                                        index,
                                                        e,
                                                    );
                                                }}
                                                name={'twitter'}
                                                errorMessage={
                                                    linkErrors.twitter
                                                }
                                            />
                                        </div>

                                        <div className="form-group pl">
                                            <InputWithLabel
                                                label={'Instagram'}
                                                value={social.instagram}
                                                onChange={(e) => {
                                                    handleSocialLinkChange(
                                                        index,
                                                        e,
                                                    );
                                                }}
                                                name={'instagram'}
                                                errorMessage={
                                                    linkErrors.instagram
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={() => {
                                setSelectedKey('custom_fields');
                            }}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        {/* <div className="next-button">
                            <button onClick={handleThirdStep}>
                                <span>Save & Close</span>
                                <img
                                    src="../../../images/button-arrow.svg"
                                    alt="Arrow"
                                />
                            </button>
                        </div> */}
                        <ButtonWithLoader
                            onClick={handleThirdStep}
                            loading={loading}
                            name="Save & Close"
                            arrow="../../../images/button-arrow.svg"
                        />
                    </Stack>
                </PivotItem>
            </Pivot>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={ErrorChanges} />
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    school: state.app.school,
});

export default withRouter(connect(mapStateToProps)(AccordianTabs));
