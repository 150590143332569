import React, { useEffect, useState } from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import Api from '../../../common/api/api';
import { useHistory, useParams } from 'react-router-dom';
import InputWithLabel from '../../../common/controls/InputWithLabel';
import Loader from 'react-js-loader';
import { DefaultButton } from '@fluentui/react/lib/Button';
import ErrorModal from '../../../common/modals/ErrorModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import HTMLEditor from '../../../common/controls/HTMLEditor';
import EmailTemplateTabs from '../../../common/EmailTemplateTabs';

const stackStyles = {
    root: {
        padding: 30,
        width: '100%',
        float: 'left',
    },
};

const stackItemStyles = {
    root: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 30,
        border: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        borderRadius: 8,
    },
};


const AddEmailTemplatePage = () => {
    const [input, setInput] = useState({
        name: '',
        subject: '',
        body: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    return (
 <MainLayout>
            <Stack styles={stackStyles}>
                <Stack.Item styles={stackItemStyles}>
                    <Stack.Item>
                        <span className="ms-fontSize-24 ms-fontWeight-regular fontFamily line-height150 darkColor">
                            Create Template
                        </span>
                    </Stack.Item>
                    <Stack.Item>
                        <EmailTemplateTabs />
                    </Stack.Item>
                </Stack.Item>
            </Stack>
        </MainLayout>
    );
};

export default AddEmailTemplatePage;
