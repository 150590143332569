import React from 'react';
import MainLayout from '../../../../layouts/main/MainLayout';
import Table from '../../../../common/table/TableV3';
import { useHistory } from 'react-router-dom';
import { DefaultButton } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { useParams } from 'react-router-dom';

// Styles definition
const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

const OptionVotesPage = () => {
    let { id } = useParams();
    let {optionId} = useParams();
    const history = useHistory();

    return (
        <MainLayout>
            <Table
            id={id}
            type={'Option Votes'}
            name={'Option Votes'}
            tableName={'Vote For Option'}
            deleteIcon={false}
        />
            <Stack style={{ width: '10%', padding: '1%', marginLeft: '1.2%' }}>
                <DefaultButton
                    // onClick={() => {
                    //     history.push(`/poll/${pollId}/list`);
                    // }}
                    onClick={() => {
                        history.push(`/poll/${optionId}/list`);
                    }}
                    text="Back"
                    styles={stackDefaultStyles}
                /></Stack>
        </MainLayout>
    );
};

export default OptionVotesPage;
