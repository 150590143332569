import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AddStaffPage from '../views/main/Staff/AddStaffPage';
import ListStaffPage from '../views/main/Staff/ListStaffPage';

function StaffRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/add`} exact component={AddStaffPage} />
            <Route path={`${url}/:id/edit`} exact component={AddStaffPage} />

            <Route path={`${url}/list`} exact component={ListStaffPage} />
        </SwitchWithNotFound>
    );
}

export default StaffRoutes;
