import React from 'react';
import AllMessagesList from './sub-components/AllMessagesList';
import Chat from './Chat/Chat';

const MessagesMiddle = () => {
    return (
        <div>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 chat-left">
                <AllMessagesList />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 chat-right">
                <Chat />
            </div>
        </div>
    );
};

export default MessagesMiddle;
