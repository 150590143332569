import { Label } from '@fluentui/react';
import React, { useRef, useEffect, useState } from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import { Dropdown } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import Api from '../../../common/api/api';
import InputWithLabel from '../../../common/controls/InputWithLabel';
import Loader from 'react-js-loader';
import ErrorModal from '../../../common/modals/ErrorModal';
import { Dialog } from '@fluentui/react';
import { useHistory, withRouter } from 'react-router-dom';
import { YearsOptions } from '../../../common/years';
import AuthLayout from '../../../layouts/auth/AuthLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import ButtonWithLoader from '../../../common/controls/ButtonWithLoader';
import getSchool from '../../../helpers/getSchool';
import RegistrationConfirmModal from '../../../common/modals/RegistrationConfirmModal';

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
        float:'left',
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const AddRegistrationFormBanyan = () => {
    const school = getSchool();
    const genderoptions = [
        { key: 0, text: 'Male' },
        { key: 1, text: 'Female' },
        { key: 2, text: 'Others' },
    ];
    const maritalstatusoptions = [
        { key: 0, text: 'Single' },
        { key: 1, text: 'Married' },
    ];

    const [input, setInput] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phone: '',
        gender: null,
        year_joining: null,
        year_leaving: null,
        batch: null,
        attachments: [],
        //custom_fields: {},
        educationalqualification: '',
        professionalqualification:'',
        occupation:'',
        currentplacement:'',
        contribution_to_school:'',
        currentresidence:'',
        comment:'',
        maritalstatus: null,
    });

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [errors, setErrors] = useState({});
    const [userNameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);
    const [errorText, setErrorText] = useState('');
    const [batches, setBatches] = useState([]);
    const [username, setUsername] = useState('');
    const [successMessages, setSuccessMessages] = useState([]);
    const [email, setEmail] = useState('');
    const [year_joining, setYearOfJoining] = useState('');
    const [year_leaving, setYearOfLeaving] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [batch, setBatch] = useState('');

    useEffect(() => {
        if (school) {
            Api.getInstance()
                .getSchool(school)
                .then((response) => {
                    setYearOfJoining(response.year_of_joining);
                    setYearOfLeaving(response.year_of_leaving);
                    setPhone(response.phone_optional);
                    setBatch(response.batch_optional);
                    setGender(response.gender_optional);
                })
                .catch((e) => {});
        }
    }, [school]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
            setLoading1(true);
        if (file) {
            Api.getInstance()
                .uploadFile(
                    {
                        extension: file.name.split('.').pop(),
                        'Content-Type': file.type,
                    },
                    file,
                    true,
                )
                .then(({ key }) => {
                    setInput((i) => ({
                        ...i,
                        attachments: [{ key, name: file.name }],
                    }));
                    setLoading1(false);

                })
                .catch(console.log);

            let i = { ...input };
            i[e.target.name] = e.target.value;
            setInput(i);
        }
    };


    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const handleUsernameChange = (e) => {
        let i = { ...input };
        i['username'] = username;
        setInput(i);
        Api.getInstance()
            .checkForExistingUsername(username, school)
            .then((response) => {
                setInput(i);
                setUsernameError('');
            })
            .catch((e) => {
                setUsernameError(e.error);
            });
    };

    const handleEmailChange = (e) => {
        let i = { ...input };
        i['email'] = email;
        setInput(i);
        Api.getInstance()
            .checkForExistingEmail(email, school)
            .then((response) => {
                setInput(i);
                setEmailError('');
            })
            .catch((e) => {
                setEmailError(e.error);
            });
    };


    useEffect(() => {
        const removeSuccessMessage = (key) => {
          setTimeout(() => {
            setSuccessMessages((prevMessages) =>
              prevMessages.filter((msg) => msg.key !== key)
            );
          }, 1500);
        };

        if (input.attachments && input.attachments.length > 0) {
          const newSuccessMessages = input.attachments.map((image) => ({
            key: `image-${image.key}`,
            message: 'File has been uploaded successfully!',
          }));

          setSuccessMessages((prevMessages) => [
            ...prevMessages,
            ...newSuccessMessages,
          ]);

          newSuccessMessages.forEach((msg) => removeSuccessMessage(msg.key));
        }
      }, [input.attachments]);

    useEffect(() => {
        const timeOutId = setTimeout(() => handleUsernameChange(username), 500);
        return () => clearTimeout(timeOutId);
    }, [username]);

    useEffect(() => {
        const timeOutId1 = setTimeout(() => handleEmailChange(email), 500);
        return () => clearTimeout(timeOutId1);
    }, [email]);

    const updateChanges = () => {
        toggleHideDialog();
    };

    useEffect(() => {
        Api.getInstance()
            .getBatchesForRegistrationForm(school)
            .then((response) => {
                setBatches(response);
            })
            .catch(console.log);
    }, []);

    const confirmModalChanges = () => {
        toggleHideDialog2();
        window.location.reload();
    };

    const validate = () => {
        let fields = [
            {
                label: 'Name',
                key: 'firstName',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            // {
            //     label: 'Last Name',
            //     key: 'lastName',
            //     regex: /^[aA-zZ\s]+$/,
            // },
            {
                label: 'Email',
                key: 'email',
                required: true,
                regex: /\S+@\S+\.\S+/,
            },
            {
                label: 'Phone',
                key: 'phone',
                required: phone,
                regex:
                    /^(\+\d{1,3}[- ]?)?\d{10}$/ ||
                    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
            },
            {
                label: window.location.host === "banyantreedel.almacircle.com" ? ('Last class studied'):'Batch',
                key: 'batch',
                required: batch,
            },
            {
                label: 'Gender',
                key: 'gender',
                required: gender,
            },
            {
                label: 'Year of Joining',
                key: 'year_joining',
                required: year_joining,
                regex: /[0-9]{4}$/,
            },
            {
                label: 'Year of Leaving',
                key: 'year_leaving',
                required: year_leaving,
                regex: /[0-9]{4}$/,
            },
            {
                label: 'Attachment',
                key: 'att',
                required: true,
            },
            {
                label: 'Educational Qualifications',
                key: 'educationalqualification',
                //regex:/^[a-zA-Z0-9]{4}$/,
            },
            {
                label: 'Professional Qualifications',
                key: 'professionalqualification',
                //regex:/^[a-zA-Z0-9]{4}$/,
            },
            {
                label: 'Occupation',
                key: 'occupation',
                //regex:/^[a-zA-Z0-9]{4}$/,
            },
            {
                label: 'Current Placement',
                key: 'currentplacement',
                //regex:/^[a-zA-Z0-9]{4}$/,
            },


            {
                label: 'Field in which you can contribute to school',
                key: 'contribution_to_school',
                required: false,
            },

            {
                label: 'Current City of Residence',
                key: 'currentresidence',
                required: false,
            },

            {
                label: 'comment',
                key: 'comment',
                required: false,
            },

            {
                label: 'Marital Status',
                key: 'maritalstatus',
                required: true,
            },

        ];
        let e = {};
        fields.forEach((field) => {
            if (
                field.required &&
                (input[field.key] === undefined ||
                    input[field.key] === null ||
                    input[field.key] === '')
            ) {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        if (input.year_joining != null && input.year_leaving != null)
            if (input.year_joining > input.year_leaving) {
                e['year_leaving'] =
                    'Year of joining cannot be after year of leaving';
            }
        setErrors(e);
        return Object.keys(e).length === 0;
    };
    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

        if (userNameError) {
            return;
        }

        if (emailError) {
            return;
        }

        setLoading(true);
        Api.getInstance()
            .createRegistrationForm(input, school)
            .then((response) => {
                toggleHideDialog2();
            })
            .catch((e) => {
                setErrorText(e.error);
                toggleHideDialog();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="form-page">
                <AuthLayout>
                    <div className="login-heading registtration-form-content">
                        <span className="ms-fontSize-24 ms-fontWeight-bold darkColor fontFamily">
                            Alumni Registration Form
                        </span>
                        <p className="form-para">
                        <span>Welcome to the <b>Banyan Tree School, New Delhi Almacircle Registration Page</b> for Alumni.</span>
                        <br/>
                        Take a moment to fill out the form below. Kindly supply all the requested information and SUBMIT.
                        </p>
                    </div>
                    <div className="add-user-form set-height registtration-form">
                        <div className="form-group pr">
                            <InputWithLabel
                                required={true}
                                errorMessage={errors.firstName}
                                label="Name"
                                value={input.firstName}
                                onChange={handleChange}
                                name="firstName"
                                estric="*"
                                placeholder="Name"
                            />
                        </div>
                        {/* <div className="form-group pl">
                            <InputWithLabel
                                required={false}
                                errorMessage={errors.lastName}
                                label="Last Name"
                                value={input.lastName}
                                onChange={handleChange}
                                name="lastName"
                                placeholder="Last Name"
                            />
                        </div> */}
                        <div className="form-group pl">
                            <InputWithLabel
                                required={true}
                                errorMessage={errors.email || emailError}
                                label="Email"
                                value={email}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                name="email"
                                estric="*"
                                placeholder="Email"
                            />
                        </div>
                        <div className="form-group pr">
                            <InputWithLabel
                                required={phone ? true : false}
                                errorMessage={errors.phone}
                                label="Phone"
                                value={input.phone}
                                onChange={handleChange}
                                name="phone"
                                estric={phone ? '*':''}
                                placeholder="Phone"
                            />
                        </div>
                        <div className="form-group pl">
                            <Dropdown
                                required={batch ? true : false}
                                errorMessage={errors.batch}
                                placeholder = {window.location.host === "banyantreedel.almacircle.com" ? ('Last class studied at Banyan Tree School, New Delhi'):'Batch'}
                                label = {window.location.host === "banyantreedel.almacircle.com" ? ('Last class studied at Banyan Tree School, New Delhi'):'Batch'}
                                options={batches.map((batch) => {
                                    return {
                                        key: batch.id,
                                        text: batch.name,
                                    };
                                })}
                                selectedKey={input.batch}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'batch',
                                        },
                                    });
                                }}
                                className={
                                    errors.batch
                                        ? 'drop-error actual-height'
                                        : 'alman-dropdown'
                                }
                            />
                        </div>
                        <div className="form-group pr">
                            <Dropdown
                                required={gender ? true : false}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'gender',
                                        },
                                    });
                                }}
                                selectedKey={input.gender}
                                placeholder="Select an option"
                                label="Gender"
                                options={genderoptions}
                                errorMessage={errors.gender}
                                className={
                                    errors.gender
                                        ? 'drop-error actual-height'
                                        : 'alman-dropdown'
                                }
                            />
                        </div>
                        <div className="form-group pl">
                            <Dropdown
                                required={year_joining}
                                placeholder="Year of Joining Banyan Tree School"
                                errorMessage={errors.year_joining}
                                label={'Year of Joining Banyan Tree School'}
                                options={YearsOptions}
                                selectedKey={input.year_joining}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'year_joining',
                                        },
                                    });
                                }}
                                className={
                                    errors.year_joining ? 'drop-error' : ''
                                }
                            />
                        </div>
                        <div className="form-group pr">
                            <Dropdown
                                required={year_leaving}
                                errorMessage={errors.year_leaving}
                                placeholder="Year of Leaving Banyan Tree School"
                                label={'Year of Leaving Banyan Tree School'}
                                options={YearsOptions}
                                selectedKey={input.year_leaving}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'year_leaving',
                                        },
                                    });
                                }}
                                className={
                                    errors.year_leaving ? 'drop-error' : ''
                                }
                            />{' '}
                        </div>

                        {/* <div className="input-with-label file-uploader"  style={{ position: 'relative' }}>
                            {loading && <span style={{ position: 'absolute', top: 0, right: '10px' }}>Loading...</span>}
                            <InputWithLabel
                                type="file"
                                label="Attachment"
                                required={true}
                                errorMessage={errors.att}
                                value={input.att}
                                onChange={handleFileChange}
                                name="att"
                            />
                        </div> */}

                    <div className="form-group pl">
                            <InputWithLabel
                                errorMessage={''}
                                label="Educational Qualifications"
                                value={input.educationalqualification}
                                onChange={handleChange}
                                name="educationalqualification"
                                placeholder="Educational Qualifications"
                            />
                        </div>
                        <div className="form-group pr">
                            <InputWithLabel
                                errorMessage={''}
                                label="Professional Qualifications"
                                value={input.professionalqualification}
                                onChange={handleChange}
                                name="professionalqualification"
                                placeholder="Professional Qualifications"
                            />
                        </div>
                        <div className="form-group pl">
                            <InputWithLabel
                                errorMessage={''}
                                label="Occupation"
                                value={input.occupation}
                                onChange={handleChange}
                                name="occupation"
                                placeholder="Occupation"
                            />
                        </div>
                        <div className="form-group pr">
                            <Dropdown
                               required={true}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'maritalstatus',
                                        },
                                    });
                                }}
                                selectedKey={input.maritalstatus}
                                placeholder="Select an option"
                                label="Marital Status"
                                options={maritalstatusoptions}
                                errorMessage={errors.maritalstatus}
                                className={
                                    errors.maritalstatus
                                        ? 'drop-error actual-height'
                                        : 'alman-dropdown'
                                }
                            />
                        </div>
                        <div className="form-group pl">
                            <InputWithLabel
                                errorMessage={''}
                                label="Current Placement"
                                value={input.currentplacement}
                                onChange={handleChange}
                                name="currentplacement"
                                placeholder="Current Placement"
                            />
                        </div>

                        <div className="form-group pr">
                            <InputWithLabel
                                errorMessage={''}
                                label="Current City of Residence"
                                value={input.currentresidence}
                                onChange={handleChange}
                                name="currentresidence"
                                placeholder="Current City of Residence"
                            />
                        </div>

                        <div className="form-group pl">
                            <InputWithLabel
                                errorMessage={''}
                                required={false}
                                label="Field in which you can contribute to school"
                                value={input.contribution_to_school}
                                onChange={handleChange}
                                name="contribution_to_school"
                                placeholder="Field in which you can contribute to school"
                            />
                        </div>

                        <div className="input-with-label file-uploader">
                                <div className="form-group pr">
                                    <InputWithLabel
                                        type="file"
                                        label="Upload your Class X, XII or the mark-sheet of the last class studied at Banyan Tree School"
                                        required={true}
                                        errorMessage={errors.att}
                                        value={input.att}
                                        onChange={handleFileChange}
                                        name="att"
                                        estric="*"
                                    />
                                    <div className="show-loading">
                                        <label>*The file should be less than 5 MB.</label>
                                        {loading1 && <span>loading....</span> }
                                    </div>

                                    {input.attachments.map((image) => {
                                    return (
                                        <>
                                        <div>
                                          {successMessages.map((successMessage) => (
                                            <div
                                              className="uploaded-images-col attactment-successfull-msg"
                                              key={successMessage.key}
                                            >
                                              <p>{successMessage.message}</p>
                                            </div>
                                          ))}
                                        </div>
                                        </>
                                      );
                                    })}

                                </div>
                        </div>

                        <div className="form-group registration-form-remark-field">
                            <div className="input-with-label">
                                <label>Leave a comment/Remark</label>
                                <textarea
                                    required={false}
                                    name="comment"
                                    value={input.comment}
                                    onChange={handleChange}
                                    placeholder="Leave a comment/Remark"
                                    style={{width:'98% !important'}}
                                />
                            </div>
                        </div>
                        <Stack styles={stackNewStyles}>
                            <ButtonWithLoader
                                onClick={handleSubmit}
                                loading={loading}
                                name="Submit"
                            />
                        </Stack>
                    </div>

                    <Dialog
                        hidden={hideDialog}
                        onDismiss={toggleHideDialog}
                        styles={dialogNewStyles}
                    >
                        <ErrorModal text={errorText} onClick={updateChanges} />
                    </Dialog>

                    <Dialog
                        hidden={hideDialog2}
                        onDismiss={toggleHideDialog2}
                        styles={dialogNewStyles}
                    >
                        <RegistrationConfirmModal
                            heading="Thank You"
                            text={
                                'Your Alumni registration request has been successfully received. We will be sharing the login details soon'
                            }
                            onClick={confirmModalChanges}
                        />
                    </Dialog>
                </AuthLayout>
            </div>
        </>
    );
};



const AddRegistrationFormNormal = () => {
    const school = getSchool();
    const genderoptions = [
        { key: 0, text: 'Male' },
        { key: 1, text: 'Female' },
        { key: 2, text: 'Others' },
    ];

    const [input, setInput] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phone: '',
        gender: null,
        year_joining: null,
        year_leaving: null,
        batch: null,
        attachments: [],
        educationalqualification: '',
        professionalqualification:'',
        occupation:'',
        currentplacement:'',
    });

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [errors, setErrors] = useState({});
    const [userNameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);
    const [errorText, setErrorText] = useState('');
    const [batches, setBatches] = useState([]);
    const [username, setUsername] = useState('');
    const [successMessages, setSuccessMessages] = useState([]);
    const [email, setEmail] = useState('');
    const [year_joining, setYearOfJoining] = useState('');
    const [year_leaving, setYearOfLeaving] = useState('');
    const [phone, setPhone] = useState('');
    const [batch, setBatch] = useState('');
    const [gender, setGender] = useState('');

    useEffect(() => {
        if (school) {
            Api.getInstance()
                .getSchool(school)
                .then((response) => {
                    setYearOfJoining(response.year_of_joining);
                    setYearOfLeaving(response.year_of_leaving);
                    setPhone(response.phone_optional);
                    setBatch(response.batch_optional);
                    setGender(response.gender_optional);
                })
                .catch((e) => {});
        }
    }, [school]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setLoading1(true);
        if (file) {
            Api.getInstance()
                .uploadFile(
                    {
                        extension: file.name.split('.').pop(),
                        'Content-Type': file.type,
                    },
                    file,
                    true,
                )
                .then(({ key }) => {
                    setInput((i) => ({
                        ...i,
                        attachments: [{ key, name: file.name }],
                    }));
                    setLoading1(false);

                })
                .catch(console.log);

            let i = { ...input };
            i[e.target.name] = e.target.value;
            setInput(i);
        }
    };


    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const handleUsernameChange = (e) => {
        let i = { ...input };
        i['username'] = username;
        setInput(i);
        Api.getInstance()
            .checkForExistingUsername(username, school)
            .then((response) => {
                setInput(i);
                setUsernameError('');
            })
            .catch((e) => {
                setUsernameError(e.error);
            });
    };

    const handleEmailChange = (e) => {
        let i = { ...input };
        i['email'] = email;
        setInput(i);
        Api.getInstance()
            .checkForExistingEmail(email, school)
            .then((response) => {
                setInput(i);
                setEmailError('');
            })
            .catch((e) => {
                setEmailError(e.error);
            });
    };


    useEffect(() => {
        const removeSuccessMessage = (key) => {
            setTimeout(() => {
                setSuccessMessages((prevMessages) =>
                    prevMessages.filter((msg) => msg.key !== key)
                );
            }, 1500);
        };

        if (input.attachments && input.attachments.length > 0) {
            const newSuccessMessages = input.attachments.map((image) => ({
                key: `image-${image.key}`,
                message: 'File has been uploaded successfully!',
            }));

            setSuccessMessages((prevMessages) => [
                ...prevMessages,
                ...newSuccessMessages,
            ]);

            newSuccessMessages.forEach((msg) => removeSuccessMessage(msg.key));
        }
    }, [input.attachments]);


    useEffect(() => {
        const timeOutId = setTimeout(() => handleUsernameChange(username), 500);
        return () => clearTimeout(timeOutId);
    }, [username]);

    useEffect(() => {
        const timeOutId1 = setTimeout(() => handleEmailChange(email), 500);
        return () => clearTimeout(timeOutId1);
    }, [email]);

    const updateChanges = () => {
        toggleHideDialog();
    };

    useEffect(() => {
        Api.getInstance()
            .getBatchesForRegistrationForm(school)
            .then((response) => {
                setBatches(response);
            })
            .catch(console.log);
    }, []);

    const confirmModalChanges = () => {
        toggleHideDialog2();
        window.location.reload();
    };

    const validate = () => {
        let fields = [
            {
                label: 'Name',
                key: 'firstName',
                required: true,
                regex: /^[aA-zZ\s]+$/,
            },
            // {
            //     label: 'Last Name',
            //     key: 'lastName',
            //     regex: /^[aA-zZ\s]+$/,
            // },
            {
                label: 'Email',
                key: 'email',
                required: true,
                regex: /\S+@\S+\.\S+/,
            },
            {
                label: 'Phone',
                key: 'phone',
                required: phone,
                regex:
                    /^(\+\d{1,3}[- ]?)?\d{10}$/ ||
                    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
            },
            {
                label: 'Batch',
                key: 'batch',
                required: batch,
            },
            {
                label: 'Gender',
                key: 'gender',
                required: gender,
            },
            {
                label: 'Year of Joining',
                key: 'year_joining',
                required: year_joining,
                regex: /[0-9]{4}$/,
            },
            {
                label: 'Year of Leaving',
                key: 'year_leaving',
                required: year_leaving,
                regex: /[0-9]{4}$/,
            },
            {
                label: 'Attachment',
                key: 'att',
                required: true,
            },
            {
                label: 'Educational Qualifications',
                key: 'educationalqualification',
                //regex:/^[a-zA-Z0-9]{4}$/,
            },
            {
                label: 'Professional Qualifications',
                key: 'professionalqualification',
                //regex:/^[a-zA-Z0-9]{4}$/,
            },
            {
                label: 'Occupation',
                key: 'occupation',
                //regex:/^[a-zA-Z0-9]{4}$/,
            },
            {
                label: 'Current Placement',
                key: 'currentplacement',
                //regex:/^[a-zA-Z0-9]{4}$/,
            },


        ];

        let e = {};
        fields.forEach((field) => {
            if (
                field.required &&
                (input[field.key] === undefined ||
                    input[field.key] === null ||
                    input[field.key] === '')
            ) {
                e[field.key] = `${field.label} is required`;
                return;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });
        if (input.year_joining != null && input.year_leaving != null)
            if (input.year_joining > input.year_leaving) {
                e['year_leaving'] =
                    'Year of joining cannot be after year of leaving';
            }
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

        if (userNameError) {
            return;
        }

        if (emailError) {
            return;
        }

        setLoading(true);
        Api.getInstance()
            .createRegistrationForm(input, school)
            .then((response) => {
                toggleHideDialog2();
            })
            .catch((e) => {
                setErrorText(e.error);
                toggleHideDialog();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="form-page">
                <AuthLayout>
                    <div className="login-heading registtration-form-content">
                        <span className="ms-fontSize-24 ms-fontWeight-bold darkColor fontFamily">
                            Alumni Registration Form
                        </span>
                        <span>
                            Welcome to the registration page for alumni. Please
                            take a moment to fill out the form below. Please
                            supply all the requested information and click on
                            SUBMIT, below.
                        </span>
                    </div>
                    <div className="add-user-form set-height registtration-form">
                        <div className="form-group pr">
                            <InputWithLabel
                                required={true}
                                errorMessage={errors.firstName}
                                label="Name"
                                value={input.firstName}
                                onChange={handleChange}
                                name="firstName"
                                estric="*"
                                placeholder="Name"
                            />
                        </div>
                        {/* <div className="form-group pl">
                            <InputWithLabel
                                required={false}
                                errorMessage={errors.lastName}
                                label="Last Name"
                                value={input.lastName}
                                onChange={handleChange}
                                name="lastName"
                                placeholder="Last Name"
                            />
                        </div> */}
                        <div className="form-group pl">
                            <InputWithLabel
                                required={true}
                                errorMessage={errors.email || emailError}
                                label="Email"
                                value={email}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                name="email"
                                estric="*"
                                placeholder="Email"
                            />
                        </div>
                        <div className="form-group pr">
                            <InputWithLabel
                                required={phone ? true : false}
                                errorMessage={errors.phone}
                                label="Phone"
                                value={input.phone}
                                onChange={handleChange}
                                name="phone"
                                estric={phone ? '*':''}
                                placeholder="Phone"
                            />
                        </div>
                        <div className="form-group pl">
                            <Dropdown
                                required={batch ? true : false}
                                errorMessage={errors.batch}
                                placeholder="Select batch"
                                label={'Batch'}
                                options={batches.map((batch) => {
                                    return {
                                        key: batch.id,
                                        text: batch.name,
                                    };
                                })}
                                selectedKey={input.batch}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'batch',
                                        },
                                    });
                                }}
                                className={
                                    errors.batch
                                        ? 'drop-error actual-height'
                                        : 'alman-dropdown'
                                }
                            />
                        </div>
                        <div className="form-group pr">
                            <Dropdown
                                required={gender ? true : false}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'gender',
                                        },
                                    });
                                }}
                                selectedKey={input.gender}
                                placeholder="Select an option"
                                label="Gender"
                                options={genderoptions}
                                errorMessage={errors.gender}
                                className={
                                    errors.gender
                                        ? 'drop-error actual-height'
                                        : 'alman-dropdown'
                                }
                            />
                        </div>
                        <div className="form-group pl">
                            <Dropdown
                                required={year_joining}
                                placeholder="Year of Joining"
                                errorMessage={errors.year_joining}
                                label={'Year of Joining'}
                                options={YearsOptions}
                                selectedKey={input.year_joining}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'year_joining',
                                        },
                                    });
                                }}
                                className={
                                    errors.year_joining ? 'drop-error' : ''
                                }
                            />
                        </div>
                        <div className="form-group pr">
                            <Dropdown
                                required={year_leaving}
                                errorMessage={errors.year_leaving}
                                placeholder="Year of Leaving"
                                label={'Year of Leaving'}
                                options={YearsOptions}
                                selectedKey={input.year_leaving}
                                onChange={(e, item) => {
                                    handleChange({
                                        target: {
                                            value: item.key,
                                            name: 'year_leaving',
                                        },
                                    });
                                }}
                                className={
                                    errors.year_leaving ? 'drop-error' : ''
                                }
                            />{' '}
                        </div>

                        {/* <div className="input-with-label file-uploader"  style={{ position: 'relative' }}>
                            {loading && <span style={{ position: 'absolute', top: 0, right: '10px' }}>Loading...</span>}
                            <InputWithLabel
                                type="file"
                                label="Attachment"
                                required={true}
                                errorMessage={errors.att}
                                value={input.att}
                                onChange={handleFileChange}
                                name="att"
                            />
                        </div> */}

                        <div className="form-group pl">
                            <InputWithLabel
                                errorMessage={''}
                                label="Educational Qualifications"
                                value={input.educationalqualification}
                                onChange={handleChange}
                                name="educationalqualification"
                                placeholder="Educational Qualifications"
                            />
                        </div>
                        <div className="form-group pr">
                            <InputWithLabel
                                errorMessage={''}
                                label="Professional Qualifications"
                                value={input.professionalqualification}
                                onChange={handleChange}
                                name="professionalqualification"
                                placeholder="Professional Qualifications"
                            />
                        </div>
                        <div className="form-group pl">
                            <InputWithLabel
                                errorMessage={''}
                                label="Occupation"
                                value={input.occupation}
                                onChange={handleChange}
                                name="occupation"
                                placeholder="Occupation"
                            />
                        </div>
                        <div className="form-group pr">
                            <InputWithLabel
                                errorMessage={''}
                                label="Current Placement"
                                value={input.currentplacement}
                                onChange={handleChange}
                                name="currentplacement"
                                placeholder="Current Placement"
                            />
                        </div>
                        <div className="input-with-label file-uploader">
                            <div className="form-group pl" style={{paddingLeft: 0}}>
                                <InputWithLabel
                                    type="file"
                                    label="Attachment"
                                    required={true}
                                    errorMessage={errors.att}
                                    value={input.att}
                                    onChange={handleFileChange}
                                    name="att"
                                />
                                <div className="show-loading">
                                    <label>*The file should be less than 5 MB. </label>
                                    {loading1 && <span>loading....</span> }
                                </div>

                                {/* {input.attachments.map((image) => {
                                    return (
                                        <div
                                            className="uploaded-images-col attactment-successfull-msg"
                                            key={`image-${image.key}`}
                                        >
                                            <p>
                                                File has been  uploaded successfully!
                                            </p>
                                        </div>
                                    );
                                })} */}
                                {input.attachments.map((image) => {
                                    return (
                                        <>
                                            <div>
                                                {successMessages.map((successMessage) => (
                                                    <div
                                                        className="uploaded-images-col attactment-successfull-msg"
                                                        key={successMessage.key}
                                                    >
                                                        <p>{successMessage.message}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    );
                                })}

                            </div>
                        </div>

                        <Stack styles={stackNewStyles}>
                            <ButtonWithLoader
                                onClick={handleSubmit}
                                loading={loading}
                                name="Submit"
                            />
                        </Stack>
                    </div>

                    <Dialog
                        hidden={hideDialog}
                        onDismiss={toggleHideDialog}
                        styles={dialogNewStyles}
                    >
                        <ErrorModal text={errorText} onClick={updateChanges} />
                    </Dialog>

                    <Dialog
                        hidden={hideDialog2}
                        onDismiss={toggleHideDialog2}
                        styles={dialogNewStyles}
                    >
                        <RegistrationConfirmModal
                            heading="Thank You"
                            text={
                                'Your Alumni registration request has been successfully received. We will be sharing the login details soon'
                            }
                            onClick={confirmModalChanges}
                        />
                    </Dialog>
                </AuthLayout>
            </div>
        </>
    );
};

const AddRegistrationForm = ()=>{
    const school = getSchool();
    if (school === 'banyantreedel') {
        return <AddRegistrationFormBanyan />;
    } else {
        return <AddRegistrationFormNormal />;
    }
}
export default AddRegistrationForm;
