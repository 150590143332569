import React from 'react';
import HomeLeftItems from './SubComponents/HomeLeftItems';
import HomeMiddleItems from './SubComponents/HomeMiddleItems';
import HomeRightItems from './SubComponents/HomeRightItems';

const HomeItems = () => {
    return (
        <div className="home-body">
            <div className="container">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 ">
                            <HomeLeftItems />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <HomeMiddleItems />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <HomeRightItems />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeItems;
