import React, { useEffect, useState } from 'react';
import OtherEducationDetail from './Components/OtherEducationDetail';
import OtherProfileInfo from './Components/OtherProfileInfo';
import OtherWorkExperience from './Components/OtherWorkExperience';
import Api from '../../../../../../common/api/api';
import { useParams } from 'react-router-dom';
import Initialising from '../../../../../../common/controls/Initialising';

const OtherProfileMiddle = () => {
    const [profile, setProfile] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        Api.getInstance().getAlumni(id).then(setProfile).catch(console.log);
    }, [id]);

    if (!profile) {
        return <Initialising />;
    }
    return (
        <div className="home-middle-col">
            <OtherProfileInfo profile={profile} />
            <OtherEducationDetail education={profile.education} />
            <OtherWorkExperience work_experience={profile.work_experience} />
        </div>
    );
};

export default OtherProfileMiddle;
