import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import InputWithLabel from './controls/InputWithLabel';
import Api from './api/api';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import ButtonWithLoader from './controls/ButtonWithLoader';
import HTMLEditor from './controls/HTMLEditor';
import { initializeIcons } from '@fluentui/react/lib/Icons';

// Styles definition
const stackStyles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
        marginTop: 25,
    },
    children: {
        marginRight: 10,
    },
};

const stackNewStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 25,
    },
};

const stackDefaultStyles = {
    root: {
        height: 40,
        borderRadius: 4,
        background: 'rgba(0, 66, 105, 0.07)',
        color: 'rgba(0, 57, 92, 0.8)',
        fontSize: 16,
        border: 'none',
        marginRight: 10,
        fontWeight: 'normal',
        fontFamily: 'Calibri',
    },
};

initializeIcons();

const EmailTemplateTabs = ({ school, user }) => {
    const [input, setInput] = useState({
        name: '',
        subject: '',
        body: '',
    });

    const [errors, setErrors] = useState({});
    const [selectedKey, setSelectedKey] = useState('0');
    const [initialising, setInitialising] = useState(true);
    const [loading, setLoading] = useState(false);
    const history = useHistory()

    const { id } = useParams();

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const validate = () => {
        let fields = [
            [
                {
                    label: 'Template Name',
                    key: 'name',
                    required: true,
                },
                {
                    label: 'Subject',
                    key: 'subject',
                    required: true,
                },
                {
                    label: 'Body',
                    key: 'body',
                },
            ],
            [],
        ];

        let e = {};
        fields[selectedKey].forEach((field) => {
            if (
                field.required &&
                (input[field.key] === '' || input[field.key] === null)
            ) {
                e[field.key] = `${field.label} is required`;
            }
        });
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

        setLoading(true);
        Api.getInstance()
            .createEmailTemplate(input)
            .then((response) => {
                setInput(response);
                next();
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (id) {
            setInitialising(true);
            Api.getInstance()
                .getEmailTemplate(id)
                .then((response) => {
                    setInput(response);
                    setInitialising(false);
                })
                .catch(console.log);
        } else {
            setInitialising(false);
        }
    }, [id]);

    const next = () => {
        if (selectedKey === '1') {
            history.push('/template/list');
            return;
        }
        setSelectedKey(String(parseInt(selectedKey) + 1));
    };

    const handleBack = () => {
        if (selectedKey === '0') {
            history.push('/template/list');
            return;
        }
        setSelectedKey(String(parseInt(selectedKey) - 1));
    };

    return (
        <div className="user-tabs">
            <Pivot aria-label="Basic Pivot Example" selectedKey={selectedKey}>
                <PivotItem
                    itemKey={'0'}
                    headerText="1. Create Email Template"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'Template',
                    }}
                >
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Enter a name for your template. Eg: Global Alumni
                        Meetup 2021
                    </span>

                    <Stack>
                        <div className="add-user-form">
                            <div className="form-group pr">
                                <InputWithLabel
                                    errorMessage={errors.name}
                                    onChange={handleChange}
                                    label="Template Name"
                                    name="name"
                                    value={input.name}
                                    estric="*"
                                />
                            </div>
                            <div className="form-group pr">
                                <InputWithLabel
                                    errorMessage={errors.subject}
                                    onChange={handleChange}
                                    label="Subject"
                                    name="subject"
                                    value={input.subject}
                                    estric="*"
                                />
                            </div>

                            <div className="form-editor">
                                <label>Text Editor</label>
                                <HTMLEditor
                                    allowCodeEditing={true}
                                    value={input.body}
                                    name={'body'}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <ButtonWithLoader
                            onClick = {handleSubmit}
                            name="Next"
                            arrow="../../../images/button-arrow.svg"
                            loading={loading}
                        />
                    </Stack>
                </PivotItem>
                <PivotItem itemKey={'1'} headerText="2. Preview and Save">
                    <span className="ms-fontSize-14 ms-fontWeight-regular darkLightColor fontFamily line-height150 marginTop dblock">
                        Preview your template.
                    </span>
                    <Stack styles={stackStyles}>
                        <div className="add-user-form" style={{ marginTop: 0 }}>
                            <div className="email-preview">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: input.body,
                                    }}
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack styles={stackNewStyles}>
                        <DefaultButton
                            onClick={handleBack}
                            text="Back"
                            styles={stackDefaultStyles}
                        />
                        <div style={{ marginRight: 10 }}>
                            <ButtonWithLoader
                                onClick={handleSubmit}
                                name="Save & Close"
                                arrow="../../../images/button-arrow.svg"
                                loading={loading}
                            />
                        </div>
                    </Stack>
                </PivotItem>
            </Pivot>
        </div>
    );
};

export default EmailTemplateTabs;
