import React, { useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Api from '../../../../../common/api/api';

// Hook
function useOnClickOutside(ref, buttonRef, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (
                    !ref.current ||
                    ref.current.contains(event.target) ||
                    (buttonRef && buttonRef.current.contains(event.target))
                ) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler],
    );
}

const HeaderRight = ({ alumni }) => {
    const [showDrop, setShowDrop] = useState(false);

    // OutsideClicked Event
    const ref = useRef();
    const buttonRef = useRef();
    useOnClickOutside(ref, buttonRef, () => setShowDrop(false));

    return (
        <div className="header-col-right">
            {/* <div className="notification">
                <img
                    src="../../../../../images/header-notification.svg"
                    alt="NotificationIcon"
                />
            </div> */}

            {/* Mobile Search */}
            {/* <div className="mobile-search">
                <img
                    src="../../../../../images/mobile-search.svg"
                    alt="MobileSearch"
                />
            </div> */}

            <div
                className="user-profile"
                onClick={() => setShowDrop(!showDrop)}
            >
                <div className="profile-pic">
                    <img
                        src={
                            Api.getInstance().getImageUrl(alumni.image) ||
                            '../../../../../images/new-default-icon.png'
                        }
                        alt={alumni.name}
                    />
                </div>
                <div className="user-info" ref={buttonRef}>
                    <span className='usser-name'>{alumni.name}</span>
                    <img
                        src="../../../../../images/drop-icon.svg"
                        alt="DropIcon"
                    />
                    {/* Mobile Dot Icon */}
                    <div className="mobile-dot">
                        <img
                            src="../../../../../images/mobile-menu.png"
                            alt="MobileDot"
                        />
                    </div>
                </div>

                {/* UserDrop Down */}
                {showDrop && (
                    <div ref={ref} className="user-drop">
                        <ul>
                        <li>
                                <Link to="/user/home">
                                    <img
                                        src="../../../../../images/icons/home-inactive.svg"
                                        alt="MyAccount"
                                    />
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/user/profile">
                                    <img
                                        // src="../../../../../images/icons/profile-inactive.svg"
                                        src="../../../../../..//images/icons/alumni-inactive 1.svg"
                                        alt="MyAccount"
                                    />
                                    <span>My Profile</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/user/account">
                                    <img
                                        src="../../../../../images/icons/account-inactive.svg"
                                        alt="MyAccount"
                                    />
                                    <span>My Account</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/user/batchmember">
                                    <img
                                        src="../../../../../images/icons/member-inactive.svg"
                                        alt="MyAccount"
                                    />
                                    <span>Batchmates</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/auth/login">
                                    <img
                                        src="../../../../../images/icons/logout-inactive.svg"
                                        alt="MyAccount"
                                    />
                                    <span>Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}


            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    alumni: state.app.alumni,
});

export default withRouter(connect(mapStateToProps)(HeaderRight));
