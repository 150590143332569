import React from 'react';
import MainLayout from '../../Components/Layout/MainLayout';
import EditItems from './components/EditItems';

const EditProfile = () => {
    return (
        <MainLayout>
            <EditItems />
        </MainLayout>
    );
};

export default EditProfile;
