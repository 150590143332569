import React, { useEffect } from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Api from '../../../../common/api/api';
import { connect } from 'react-redux';

// Styles definition
const stackStyles = {
    root: {
        height: 'auto',
        width: 'calc(100% - 236)',
    },
};

const MainLayout = ({ alumni, dispatchProfile, children }) => {
    useEffect(() => {
        Api.getInstance()
            .getAlumniProfile()
            .then(dispatchProfile)
            .catch(console.log);
    }, []);

    return (
        <div>
            <Header />
            <div className="screens">{children}</div>
            <Footer />
        </div>
    );
};

const mapStateToProps = (state) => ({
    alumni: state.app.alumni,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchProfile: (alumni) =>
        dispatch({
            type: 'UPDATE_ALUMNI_PROFILE',
            data: { alumni },
        }),
});
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
