import React, { useEffect, useState } from 'react';
import Api from '../../../../../../../common/api/api';
import Initialising from '../../../../../../../common/controls/Initialising';
import { Link } from '@fluentui/react';

const BatchMemberList = () => {
    const [loading, setLoading] = useState(true);
    const [batchMates, setBatchMates] = useState([]);

    useEffect(() => {
        Api.getInstance()
            .getBatchMates()
            .then(setBatchMates)
            .catch(console.log)
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Initialising />;
    }
    return (
        <div className="branch-member-card" style={{ marginTop: 0 }}>
            <div className="memer-heading">
                <span>Your Batchmates</span>
            </div>
            <div className="member-list">
                {/* Conditional rendering */}
                {batchMates.length === 0 ? (
                    
        
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div style={{ marginTop: '120px' }}>
                        <img
                         src="../../../images/no-result.svg"
                         alt="No BatchMates"
                         style={{ width: 60 }}
                     />
                     </div>
                     <div style={{ marginBottom: '130px' }}>
                        <p>No Batchmates Found</p></div>
                    </div>
                ) : (
                    batchMates.map((mate, index) => (
                        <Link
                            href={`/user/${mate.id}/profile`}
                            key={`batch-mate-${mate.id}`}
                        >
                            <div className="member-list-col">
                                <div className="col-left">
                                    <div className="member-pic">
                                        <img
                                            src={
                                                Api.getInstance().getImageUrl(
                                                    mate.image,
                                                ) ||
                                                '../../../../../../images/new-default-icon.png'
                                            }
                                            alt={mate.name}
                                        />
                                    </div>
                                    <div className="member-info">
                                        <span>{mate.name}</span>
                                        <p>{mate.batch_name}</p>
                                    </div>
                                </div>
                                {/* <div className="col-right">
                                    <img
                                        src="../../../../../../images/chat.svg"
                                        alt="ChatIcon"
                                    />
                                </div> */}
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </div>
    );
};

export default BatchMemberList;
