import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AddPollPage from '../views/main/Poll/AddPollPage';
import PollPage from '../views/main/Poll/PollPage';
import TotalVotesPage from '../views/main/Poll/components/TotalVotesPage';
import OptionVotesPage from '../views/main/Poll/components/OptionVotesPage';
import UnsubscribeFromAlert from '../views/main/Content/components/UnsubscribeFromPost';
import UnsubscribeFromPoll from '../views/main/Poll/components/UnsubscribeFromPoll';

function PollRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}`} exact>
                <PollPage />
            </Route>
             <Route path={`${url}/add`} exact component={AddPollPage} />
            <Route path={`${url}/:id`} exact component={AddPollPage} />
            <Route path={`${url}/:id/edit`} exact component={AddPollPage} /> 
            <Route path={`${url}/:id/list`} exact component={TotalVotesPage} />
            <Route path={`${url}/:optionId/:id/list`} exact component={OptionVotesPage} />
            <Route path={`${url}/:id/:type`} exact component={UnsubscribeFromPoll} />
        </SwitchWithNotFound>
    );
}

export default PollRoutes;
