/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './history';

import produce from 'immer';

export const initialState = {
    token: null,
    school_id: null,
    user: {},
    alumni: {},
    school: {},
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case 'AUTH_LOGIN':
                draft.token = action.data.token;
                draft.user = action.data.user;
                localStorage.setItem('token', draft.token);
                localStorage.setItem('user', JSON.stringify(draft.user));
                break;
            case 'AUTH_SELECT_SCHOOL':
                draft.school_id = action.data.school_id;
                localStorage.setItem('school_id', draft.school_id);
                break;
            case 'UPDATE_ALUMNI_PROFILE':
                draft.alumni = action.data.alumni;
                localStorage.setItem('alumni', JSON.stringify(draft.alumni));
                break;
            case 'UPDATE_SCHOOL':
                draft.school = action.data.school;
                localStorage.setItem('school', JSON.stringify(draft.school));
                break;
            case 'AUTH_LOGOUT':
                draft.token = null;
                draft.user = {};
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('alumni');
                localStorage.removeItem('school');
                break;
        }
    });

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
    return combineReducers({
        app: appReducer,
        router: connectRouter(history),
        ...injectedReducers,
    });
}
