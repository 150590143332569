import React, { useEffect, useState } from 'react';

const ConfirmModal = (props) => {
    return (
        <div className="manage-column">
            <div className="modal-icons">
                <div>
                    <img src="../../../images/Success.svg"></img>
                </div>
                <div className="cross-icon">
                    <img
                        onClick={props.onCancel}
                        src="../../../images/cross-icon.svg"
                    ></img>
                </div>
            </div>
            <div className="modal-heading">
                <span>{props.heading}</span>
            </div>
            <div className="delete-columns">
                <p>{props.text}</p>
            </div>
            <div className="column-buttons">
                <button onClick={props.onCancel} className="cancel-btn">
                    No, Cancel
                </button>
                <button className="apply" onClick={props.onClick}>
                   {props.buttonText}
                </button>
            </div>
        </div>
    );
};

export default ConfirmModal;
