import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../../../common/api/api';
import Loader from 'react-js-loader';
import { useHistory } from 'react-router-dom';

const Unsubscribe = () => {
    let { id, campaignId, schoolId, categoryId } = useParams();
    let  i =1;

    const [errorText, setErrorText] = useState('');
    const [categories, setCategories] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedCategories, setselectedCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState({
        category: '',
        user: '',
    });

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    const history = useHistory();

    const handleSubmit = () => {
        setLoading(true);
        Api.getInstance()
            .unSubscribe(id, campaignId, categoryId)
            .then((response) => {
                history.push('/campaign/unsubscribedSuccessfully');
            });
    };

    // const handelEvent = (event, id) => {
    //     const { value, checked } = event.target;
    //     if (checked) {
    //         selectedCategories.push(id);
    //     } else {
    //         selectedCategories.map((a, index) => {
    //             console.log(a);
    //             a === id ? selectedCategories.splice(index, 1) : '';
    //         });
    //     }
    //     console.log(selectedCategories);
    // };

    const items = [
        {
            text: 'Jobs & employment',
        },
        {
            text: 'Events',
        },
        {
            text: 'Promotional emails',
        },
        {
            text: 'Jobs & Employment',
        },
        {
            text: 'University achievements',
        },
    ];

    return (
        <div className="unsubscribe-box">
            <div className="unsubscribe-content">
                <div className="for-unsubscribe-mobile">
                    <span>Thinking of unsubscribing?</span>
                </div>
                {/* form */}
                <div className="unsubscribe-form">
                    
                    <div className="unsubsscri-button">
                        <button onClick={handleSubmit}>
                            Unsubscribe
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Unsubscribe;
