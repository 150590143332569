import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AddEmailTemplatePage from '../views/main/EmailTemplate/AddEmailTemplatePage';
import ListEmailTemplate from '../views/main/EmailTemplate/ListEmailTemplatePage';

function EmailTemplateRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/list`} exact component={ListEmailTemplate} />
            <Route path={`${url}/add`} exact component={AddEmailTemplatePage} />
            <Route path={`${url}/:id/edit`} exact component={AddEmailTemplatePage} />
        </SwitchWithNotFound>
    );
}

export default EmailTemplateRoutes;