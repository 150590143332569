import React from 'react';
import { withRouter } from 'react-router-dom';
import { Stack } from '@fluentui/react/lib/Stack';
import HeaderRightSection from './components/HeaderRightSection';
import { connect } from 'react-redux';
import Api from '../../common/api/api';

// Styles definition
const stackStyles = {
    root: {
        paddingLeft: 15,
        paddingRight: 15,
        height: 60,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        borderBottom: 1,
        borderBottomColor: '#EEEEEE',
        borderBottomStyle: 'solid',
        position:'fixed',
        top:0,
        background:'#ffffff',
        zIndex:99
    },
};

const stackItemStyles = {
    root: {
        paddingLeft: 40,
    },
};

const Header = ({ school }) => {
    return (
        <Stack horizontal styles={stackStyles}>
            <Stack.Item grow={2}>
                {school && school.logo && (
                    <img
                        src={Api.getInstance().getImageUrl(school.logo)}
                        alt="Logo"
                        style={{ height:40 }}
                    />
                )}
            </Stack.Item>
            {/* <Stack.Item grow={9} styles={stackItemStyles}>
                <Links />
            </Stack.Item> */}
            <Stack.Item grow={1}>
                <HeaderRightSection />
            </Stack.Item>
        </Stack>
    );
};

const mapStateToProps = (state) => ({
    school: state.app.school,
});

export default withRouter(connect(mapStateToProps)(Header));
