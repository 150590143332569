import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

function FileDrop({ handleFileChange }) {
    const onDrop = useCallback((acceptedFiles) => {
        handleFileChange(acceptedFiles);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <div
            {...getRootProps()}
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div className="upload-box">
                <img
                    src="../../../../../../../images/upload-icon.svg"
                    alt="UploadIcon"
                />
            </div>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the image here to update the profile picture</p>
            ) : (
                <p>
                    Drag & drop or select an image to upload the profile picture
                </p>
            )}
        </div>
    );
}

export default FileDrop;
