import React, { useEffect, useState } from 'react';

const SettingTableColumn = ({ onCancel, columns, updateColumns }) => {
    const [localColumns, setLocalColumns] = useState(
        JSON.parse(JSON.stringify(columns)),
    );

    useEffect(() => {
        if (columns.length > 0) {
            setLocalColumns(JSON.parse(JSON.stringify(columns)));
        }
    }, [columns]);

    const toggleColumn = (index) => {
        let _columns = [...localColumns];
        _columns[index].selected = _columns[index].selected === false;
        setLocalColumns(_columns);
    };
    return (
        <div className="manage-column">
            <div className='modal-heading'>
               <span>Manage Columns</span> 
            </div>
            <div className="table-columns">
                {localColumns.map((column, index) => {
                    return (
                        <div
                            key={`manage-column-${index}`}
                            className="check-group"
                        >
                            <label
                                style={{
                                    marginBottom: 0,
                                }}
                            >
                                <input
                                    checked={column.selected !== false}
                                    onChange={() => {
                                        toggleColumn(index);
                                    }}
                                    type="checkbox"
                                    name={'currentlyWorking'}
                                />
                                <span>{column.name}</span>
                            </label>
                        </div>
                    );
                })}
            </div>
            <div className="column-buttons">
                <button onClick={onCancel} className="cancel-btn">
                    Cancel
                </button>
                <button
                    className="apply"
                    onClick={() => {
                        updateColumns(JSON.parse(JSON.stringify(localColumns)));
                        onCancel();
                    }}
                >
                    Apply
                </button>
            </div>
        </div>
    );
};

export default SettingTableColumn;
