import React from 'react'
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import FieldSettings from '../views/main/SchoolSettings/FieldSettings';

function FieldSettingRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}`} exact component={FieldSettings} />
        </SwitchWithNotFound>
    );
}

export default FieldSettingRoutes
