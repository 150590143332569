import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AddEmailCampaignPage from '../views/main/Campaigns/Email/AddEmailCampaignPage';
import ClickedEmailPage from '../views/main/Campaigns/Email/ClickedEmailPage';
import FailedEmailsPage from '../views/main/Campaigns/Email/FailedEmailsPage';
import ListEmailCampaignPage from '../views/main/Campaigns/Email/ListEmailCampaignPage';
import OpenedEmailPage from '../views/main/Campaigns/Email/OpenedEmailPage';
import UnSubscribedUserPage from '../views/main/Campaigns/Email/UnSubscribedUserPage';
import EmailCampaign from '../views/main/SchoolSettings/EmailCampaign';
import Unsubscribe from '../views/main/Unsubscribed/Unsubscribe';
import UnsubscribedSuccessful from '../views/main/Unsubscribed/UnsubscribedSuccessful';
import BouncedUserPage from '../views/main/Campaigns/Email/BouncedUserPage';

function CampaignRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route
                path={`${url}/email/list`}
                exact
                component={ListEmailCampaignPage}
            />
            <Route
                path={`${url}/email/add`}
                exact
                component={AddEmailCampaignPage}
            />
            <Route
                path={`${url}/email/:id`}
                exact
                component={AddEmailCampaignPage}
            />

            <Route
                path={`${url}/unsubscribe/:id/:campaignId/:schoolId/:categoryId`}
                exact
                component={Unsubscribe}
            />
            <Route
                path={`${url}/unsubscribedSuccessfully`}
                exact
                component={UnsubscribedSuccessful}
            />
            <Route
                path={`${url}/:id/list/UnSubscribe`}
                exact
                component={UnSubscribedUserPage}
            />
            <Route
                path={`${url}/:id/list/Bounced`}
                exact
                component={BouncedUserPage}
            />
             <Route
                path={`${url}/:id/list/failed`}
                exact
                component={FailedEmailsPage}
            />
             <Route
                path={`${url}/:id/list/clicked`}
                exact
                component={ClickedEmailPage}
            />
            <Route
                path={`${url}/:id/list/opened`}
                exact
                component={OpenedEmailPage}
            />

            <Route path={`${url}/EmailCampaign`} exact component={EmailCampaign} />
            

            <Route path={`${url}/EmailCampaign`} exact component={EmailCampaign} />
            
        </SwitchWithNotFound>
    );
}

export default CampaignRoutes;
