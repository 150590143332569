import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import SettingsAccordianTabs from '../../../common/SettingsAccordianTabs';
import PasswordAccordianTabs from '../../../common/PasswordAccordianTabs';

// Styles definition

const stackStyles = {
    root: {
        padding: 30,
        width: '100%',
        float: 'left',
    },
};

const stackItemStyles = {
    root: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 30,
        border: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        borderRadius: 8,
    },
};

const AccountSettings = () => {
    return (
        <MainLayout>
            <Stack styles={stackStyles}>
                <Stack.Item styles={stackItemStyles}>
                    <Stack.Item>
                        <span className="ms-fontSize-24 ms-fontWeight-regular fontFamily line-height150 darkColor">
                            Update Profile
                        </span>
                    </Stack.Item>
                    <Stack.Item>
                        <SettingsAccordianTabs />
                    </Stack.Item>
                </Stack.Item>{' '}
            </Stack>
            <Stack styles={stackStyles}>
                <Stack.Item styles={stackItemStyles}>
                    <Stack.Item>
                        <span className="ms-fontSize-24 ms-fontWeight-regular fontFamily line-height150 darkColor">
                            Change Password
                        </span>
                    </Stack.Item>
                    <Stack.Item>
                        <PasswordAccordianTabs />
                    </Stack.Item>
                </Stack.Item>
            </Stack>
        </MainLayout>
    );
};

export default AccountSettings;
