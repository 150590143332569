import React, { useState } from 'react';
import Api from '../api/api';
import { Dialog } from '@fluentui/react';
import ErrorModal from './ErrorModal';
import { useBoolean } from '@fluentui/react-hooks';
import getSchool from '../../helpers/getSchool';
import RemarksModal from '../modals/RemarksModal';
const dialogNewStyles = {
    main: {
        width: '100% !important',
    },
};

const AlumniImportConfirmationModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);
    const [hideRejectDialog, { toggle: toggleHideRejectDialog }] = useBoolean(true);
    const onApprove = () => {
        Api.getInstance()
            .approveAlumniRequest(props.item)
            .then((response) => {
            })
            .catch((e) => {
                setErrorText(e.error);
                toggleHideDialog2();
            })
            .finally(() => {
                window.location.reload();
            });
    };

    const onReject = () => {
        // Api.getInstance()
        //     .rejectAlumniRequest(props.item)
        //     .then((response) => {
        //     })
        //     .catch((e) => {
        //         setErrorText(e.error);
        //         toggleHideDialog2();
        //     })
        //     .finally(() => {
        //         window.location.reload();
        //     });
        toggleHideRejectDialog();
    };

    return (
        <>
            <div className='manage-column'>
                <div className='modal-heading'>
                    <span>{props.heading}</span>
                </div>
                <div className='delete-columns'>
                    <div>
                        <div>
                            <p>Name: {props.item.firstName.length > 0 ? (<b>{props.item.firstName}</b>) : (
                                <span style={{ marginLeft: 10 }}>-</span>
                            )}</p>
                        </div>
                        {/* <div>
                        <p>Last Name: <b>{props.item.lastName}</b></p>
                    </div> */}
                        <div>
                            <p>Email:
                                {props.item.email.length > 0 ? (<b>{props.item.email}</b>) : (
                                    <span style={{ marginLeft: 10 }}>-</span>
                                )}</p>
                        </div>
                        <div>
                            <p>Phone:
                                {props.item.phone.length > 0 ? (<b>{props.item.phone}</b>) : (
                                    <span style={{ marginLeft: 10 }}>-</span>
                                )}</p>
                        </div>
                        <div>
                            <p>Batch:
                                {props.item.batch.length > 0 ? (<b>{props.item.batch}</b>) : (
                                    <span style={{ marginLeft: 10 }}>-</span>
                                )}</p>
                        </div>
                        <div>
                            <p>Year of Joining:
                                {props.item.year_joining > 0 ? (<b>{props.item.year_joining}</b>) : (
                                    <span style={{ marginLeft: 10 }}>-</span>
                                )}</p>
                        </div>
                        <div>
                            <p>Year of Leaving:
                                {props.item.year_leaving > 0 ? (<b>{props.item.year_leaving}</b>) : (
                                    <span style={{ marginLeft: 10 }}>-</span>
                                )}</p>
                        </div>

                        <div>
                            <p>Educational Qualifications:
                                {props.item.educationalqualification.length > 0 ? (
                                    <b>{props.item.educationalqualification}</b>) : (
                                    <span style={{ marginLeft: 10 }}>-</span>
                                )}</p>
                        </div>
                        <div>
                            <p>Professional Qualifications:
                                {props.item.professionalqualification.length > 0 ? (
                                    <b>{props.item.professionalqualification}</b>) : (
                                    <span style={{ marginLeft: 10 }}>-</span>
                                )}</p>
                        </div>
                        <div>
                            <p>Occupation:
                                {props.item.occupation.length > 0 ? (<b>{props.item.occupation}</b>
                                ) : (
                                    <span style={{ marginLeft: 10 }}>-</span>
                                )}</p>
                        </div>
                        <div>
                            <p>Current Placement: {props.item.currentplacement.length > 0 ? (
                                <b>{props.item.currentplacement}</b>) : (
                                <span style={{ marginLeft: 10 }}>-</span>
                            )}</p>
                        </div>
                        {/* {props.item.download.length > 0 && ( */}
                        {/* <div>
                                <p>
                                    Attachments:
                                    {props.item.download.length > 0 ? (
                                        props.item.download.map((attachment, index) => (
                                            <span key={index}>
                                                <b>{attachment.name}</b>
                                                {index < props.item.download.length - 1 && ', '}
                                            </span>
                                        ))
                                    ) : (
                                        <span style={{ marginLeft: 10 }}>-</span>
                                    )}

                                </p>
                            </div> */}
                        {/* )} */}
                        {getSchool() === 'banyantreedel' &&
                            <>
                                <div>
                                    <p>Field in which you can contribute to
                                        school: {props.item.contribution_to_school && props.item.contribution_to_school.length > 0 ? (
                                            <b>{props.item.contribution_to_school}</b>) : (
                                            <span style={{ marginLeft: 1 }}>-</span>
                                        )}</p>
                                </div>

                                <div>
                                    <p>Current City of
                                        Residence: {props.item.currentresidence && props.item.currentresidence.length > 0 ? (
                                            <b>{props.item.currentresidence}</b>) : (
                                            <span style={{ marginLeft: 1 }}>-</span>
                                        )}</p>
                                </div>
                                <div>
                                    <p>Comment/Remark: {props.item.comment && props.item.comment.length > 0 ? (
                                        <b>{props.item.comment}</b>) : (
                                        <span style={{ marginLeft: 1 }}>-</span>
                                    )}</p>
                                </div>
                                <div>
                                    <p>Marital Status:
                                        {props.item.maritalstatus === 1 ? (
                                            <b>Married</b>
                                        ) : (
                                            props.item.maritalstatus === 0 ? (
                                                <b>Single</b>
                                            ) : (
                                                <span style={{ marginLeft: 10 }}>-</span>
                                            )
                                        )}
                                    </p>
                                </div>
                            </>
                        }

                        <div>
                        </div>
                    </div>
                    <div className='column-buttons'>
                        <button onClick={props.onCancel} className='cancel-btn'>
                            Cancel
                        </button>
                        <button onClick={onReject} className='cancel-btn'>
                            Reject
                        </button>
                        <button className='apply' onClick={onApprove}>
                            Approve
                        </button>
                    </div>
                </div>
            </div>
            <Dialog
                hidden={hideRejectDialog}
                onDismiss={toggleHideRejectDialog}
                styles={dialogNewStyles}
            >
                <RemarksModal
                    text={'Reject'}
                    item={props.item}
                    onCancel={toggleHideRejectDialog}

                />
            </Dialog>
            <Dialog
                hidden={hideDialog2}
                onDismiss={toggleHideDialog2}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={toggleHideDialog2} />
            </Dialog>
        </>
    );
};

export default AlumniImportConfirmationModal;
