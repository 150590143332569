import React from 'react';
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import AccountSettings from '../views/main/AccountSettings/AccountSettings';
import ChangePassword from '../views/main/AccountSettings/ChangePassword';

function AccountRoutes({ match: { url } }) {
    return (
        <SwitchWithNotFound>
            <Route path={`${url}/settings`} exact component={AccountSettings} />
            <Route path={`${url}/password`} exact component={ChangePassword} />
        </SwitchWithNotFound>
    );
}

export default AccountRoutes;
