import React from 'react'
import HomeLeftItems from '../../Home/components/SubComponents/HomeLeftItems'
import ContactUsForm from './SubComponents/ContactUsForm';

const ContactUsPage = () => {
  return (
    <div className="home-body">
            <div className="container">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 ">
                            <HomeLeftItems />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                           <ContactUsForm />
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
  );
}

export default ContactUsPage
