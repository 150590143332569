import React from 'react';
import { DialogType } from '@fluentui/react/lib/Dialog';
import { Dialog } from '@fluentui/react';

const dialogContentProps = {
    type: DialogType.normal,
    closeButtonAriaLabel: 'Close',
};
const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '423px !important',
        minHeight:'100px !important'
    },
};

const ManageColumnModal = ({
    hideDialognew,
    toggleHideDialognew,
    title,
    children,
}) => {
    const modalProps = React.useMemo(() => ({
        isBlocking: false,
    }));

    return (
        <Dialog
            hidden={hideDialognew}
            onDismiss={toggleHideDialognew}
            dialogContentProps={{ ...dialogContentProps, title }}
            modalProps={modalProps}
            styles={dialogStyles}
        >
            {children}
        </Dialog>
    );
};

export default ManageColumnModal;
