import React from 'react';
import { Switch } from 'react-router-dom';
import NotFoundPage from '../views/errors/NotFoundPage';

function SwitchWithNotFound(props) {
    const { children } = props;
    return (
        <Switch>
            {children}
            <NotFoundPage />
        </Switch>
    );
}

export default SwitchWithNotFound;
