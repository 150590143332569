import React, { useState } from 'react';

const NotificationDropDown = (props) => {
    const [showinfo, setShowinfo] = useState(false);
    const [viewItem, setViewItem] = useState({
        entityData: [],
    });

    const [feildName, setFeildName] = useState({
        feildName: [],
    });

    return (
        <div className="notify-drop-box">
            <div className="notify-left-box">
                <div className="notify-intial">
                    <div className="intial-box">
                        <p className="intials">{props.initials}</p>
                    </div>
                </div>
                <div className="notification-by">
                    <div className="info-notify">
                        {props.action === 'Created' && (
                            <h5>
                                {props.name}
                                <span>has created</span>
                                new {props.entityType}
                            </h5>
                        )}
                        {props.action === 'Deleted' && (
                            <h5>
                                {props.name}
                                <span>has Deleted</span>
                                {props.entityType}
                            </h5>
                        )}
                        {props.action === 'update' && (
                            <h5>
                                {props.name}
                                <span>has Updated</span>
                                {props.entityType}
                            </h5>
                        )}
                        {props.action === 'Imported' && (
                            <h5>
                                {props.name}
                                <span>has Imported</span>
                                {props.entityType} {`Alumni's`}
                            </h5>
                        )}
                        {props.action === 'Liked' && (
                            <h5>
                                {props.name}
                                <span>has Liked</span>
                                {props.entityType}
                            </h5>
                        )}
                        {props.action === 'Disliked' && (
                            <h5>
                                {props.name}
                                <span>has Disliked</span>
                                {props.entityType}
                            </h5>
                        )}
                        {props.action === 'Voted' && (
                            <h5>
                                {props.name}
                                <span>has Voted</span>
                                for {props.entityType}
                            </h5>
                        )}
                    </div>
                    {showinfo && (
                        <div className="name-info-show">
                            <span></span>
                            <div className="firstlastname-info">
                                {props.action === 'Created' &&
                                    Object.values(feildName).map(
                                        (value, index) => {
                                            return (
                                                <p key={index}>
                                                    {value} : {viewItem[value]}
                                                </p>
                                            );
                                        },
                                    )}
                                {props.action === 'Deleted' &&
                                    Object.values(feildName).map(
                                        (value, index) => {
                                            return (
                                                <p key={index}>
                                                    {value} : {viewItem[value]}
                                                </p>
                                            );
                                        },
                                    )}
                                {props.action === 'update' &&
                                    Object.values(feildName).map(
                                        (value, index) => {
                                            return (
                                                <p key={index}>
                                                    {value} - From{' '}
                                                    <b>
                                                        {viewItem[value]
                                                            .from !== ''
                                                            ? viewItem[value]
                                                                  .from
                                                            : '-'}
                                                    </b>{' '}
                                                    to{' '}
                                                    <b>
                                                        {viewItem[value].to !==
                                                        ''
                                                            ? viewItem[value].to
                                                            : '-'}
                                                    </b>{' '}
                                                </p>
                                            );
                                        },
                                    )}
                                {props.action === 'Imported' &&
                                    Object.values(feildName).map(
                                        (value, index) => {
                                            return (
                                                <p key={index}>
                                                    {value} : {viewItem[value]}
                                                </p>
                                            );
                                        },
                                    )}
                                     {props.action === 'Liked' &&
                                    Object.values(feildName).map(
                                        (value, index) => {
                                            return (
                                                <p key={index}>
                                                    {value} : {viewItem[value]}
                                                </p>
                                            );
                                        },
                                    )}
                                     {props.action === 'Disliked' &&
                                    Object.values(feildName).map(
                                        (value, index) => {
                                            return (
                                                <p key={index}>
                                                    {value} : {viewItem[value]}
                                                </p>
                                            );
                                        },
                                    )}
                                    {props.action === 'Voted' &&
                                    Object.values(feildName).map(
                                        (value, index) => {
                                            return (
                                                <p key={index}>
                                                    {value} : {viewItem[value]}
                                                </p>
                                            );
                                        },
                                    )}
                            </div>
                        </div>
                    )}
                    <div className="notify-created-date">
                        <p>Today at 11:05 AM</p>
                    </div>
                </div>
            </div>
            {props.action != 'Imported' && (
                <div className="notify-view-detail">
                    <span
                        onClick={() => {
                            setShowinfo(!showinfo);
                            setViewItem(props.entityData);
                            setFeildName(props.feildName);
                        }}
                    >
                        View Details
                    </span>
                </div>
            )}
        </div>
    );
};

export default NotificationDropDown;
