import React, { useState } from 'react';
import { connect } from 'react-redux';
import Api from '../../../../../../common/api/api';
import Loader from 'react-js-loader';
import { Stack } from '@fluentui/react/lib/Stack';
import DefaultInput from '../../../../../../common/controls/DefaultInput';
import { Link } from 'react-router-dom';
import UpdateModal from '../../../../../../common/modals/UpdateModal';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import ErrorModal from '../../../../../../common/modals/ErrorModal';

const stackStyles = {
    root: {
        width: 340,
        flexDirection: 'column',
    },
};

const stackItemStyles = {
    root: {
        marginBottom: 12,
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '165px !important',
        borderRadius: '4px'
    },
};

const AccountSetPassword = ({ user, required, title, ...prpos }) => {
    const [input, setInput] = useState({
        current_password: '',
        new_password: '',
        confirm_new_password: '',
        email: '',
    });

    // Password hide show
    const [passwordShown, setPasswordShown] = useState(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog1, { toggle: toggleHideDialog1 }] = useBoolean(true);
    const [errorText, setErrorText] = useState('');

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const validate = () => {
        let fields = [
            {
                label: 'Current Password',
                key: 'current_password',
                required: true,
            },
            {
                label: 'New Password',
                key: 'new_password',
                required: true,
            },
            {
                label: 'Confirm New Password',
                key: 'confirm_new_password',
                required: true,
            },
            {
                label: 'Email',
                key: 'email',
                required: required,
                regex: /\S+@\S+\.\S+/,
            },
        ];

        let e = {};
        fields.forEach((field) => {
            if (field.required && input[field.key] === '') {
                e[field.key] = `${field.label} is required`;
            }

            if (field.regex && input[field.key]) {
                let regex = new RegExp(field.regex);
                if (!regex.test(input[field.key])) {
                    e[field.key] = `Please enter a valid ${field.label}`;
                }
            }
        });

        if (
            input.new_password &&
            input.confirm_new_password &&
            input.new_password !== input.confirm_new_password
        ) {
            e['confirm_new_password'] = 'Passwords do not match';
            e['new_password'] = 'Passwords do not match';
        }
        setErrors(e);
        return Object.keys(e).length === 0;
    };

    const updateChanges = () => {
        window.location.href = '/auth/schools';
    };

    const ErrorChanges = () => {
        toggleHideDialog1();
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);

        Api.getInstance()
            .setPassword(input)
            .then((response) => {
                toggleHideDialog();
                setInput({
                    current_password: '',
                    new_password: '',
                    confirm_new_password: '',
                });
            })
            .catch((e) => {
                if (e.fields) {
                    setErrors(e.fields);
                } else {
                    setErrorText(e.error);
                    toggleHideDialog1();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        let i = { ...input };
        i[e.target.name] = e.target.value;
        setInput(i);
    };

    return (
        <Stack horizontal styles={stackStyles}>
            <div>
                <div>
                    <div>
                        <span style={{ color: '#2962FF' }}>{title}</span>
                    </div>

                    <div>
                        <Stack.Item styles={stackItemStyles}>
                            <div>
                                <DefaultInput
                                    type={passwordShown ? 'text' : 'password'}
                                    name="current_password"
                                    value={input.current_password}
                                    onChange={handleChange}
                                    placeholder="Current Password"
                                    required={true}
                                    onClick={togglePasswordVisibility}
                                    icon="../../../../../images/password-icon.svg"
                                    error={errors.current_password}
                                    className={
                                        errors.current_password
                                            ? 'default-input error'
                                            : 'default-input'
                                    }
                                    righticon={
                                        passwordShown
                                            ? '../../../../../images/view.svg'
                                            : '../../../../../images/view-hide.svg'
                                    }
                                />
                            </div>
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <div>
                                <DefaultInput
                                    type={passwordShown ? 'text' : 'password'}
                                    name="new_password"
                                    value={input.new_password}
                                    onChange={handleChange}
                                    placeholder="New Password"
                                    required={true}
                                    onClick={togglePasswordVisibility}
                                    icon="../../../../../images/password-icon.svg"
                                    error={errors.new_password}
                                    className={
                                        errors.new_password
                                            ? 'default-input error'
                                            : 'default-input'
                                    }
                                    righticon={
                                        passwordShown
                                            ? '../../../../../images/view.svg'
                                            : '../../../../../images/view-hide.svg'
                                    }
                                />
                            </div>
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <div>
                                <DefaultInput
                                    type={passwordShown ? 'text' : 'password'}
                                    name="confirm_new_password"
                                    value={input.confirm_new_password}
                                    onChange={handleChange}
                                    placeholder="Confirm New Password"
                                    required={true}
                                    onClick={togglePasswordVisibility}
                                    icon="../../../../../images/password-icon.svg"
                                    error={errors.confirm_new_password}
                                    className={
                                        errors.confirm_new_password
                                            ? 'default-input error'
                                            : 'default-input'
                                    }
                                    righticon={
                                        passwordShown
                                            ? '../../../../../images/view.svg'
                                            : '../../../../../images/view-hide.svg'
                                    }
                                />
                            </div>
                        </Stack.Item>

                        {/* CODE FOR EMAIL */}
                        {user.email == '' ? (
                            <Stack.Item styles={stackItemStyles}>
                                <div>
                                    <DefaultInput
                                        type="email"
                                        name="email"
                                        value={input.email}
                                        onChange={handleChange}
                                        placeholder="Enter your email address"
                                        required
                                        icon="../../../../../images/email-icon.svg"
                                        error={errors.email}
                                        className={
                                            errors.email
                                                ? 'default-input error'
                                                : 'default-input'
                                        }
                                    />
                                </div>
                            </Stack.Item>
                        ) : null}
                        <div className="button-with-arrow">
                            <Link to="/auth/login">
                                <img
                                    src="../../../../../images/btn.svg "
                                    style={{ marginRight: 10, marginTop: 4 }}
                                />
                            </Link>
                            <button
                                className="save"
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                {loading ? (
                                    <Loader
                                        type="bubble-scale"
                                        bgColor={'#FFFFFF'}
                                        color={'#FFFFFF'}
                                        size={40}
                                    />
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogNewStyles}
            >
                <UpdateModal
                    text={'Password has been updated successfully.'}
                    onClick={updateChanges}
                />
            </Dialog>
            <Dialog
                hidden={hideDialog1}
                onDismiss={toggleHideDialog1}
                styles={dialogNewStyles}
            >
                <ErrorModal text={errorText} onClick={ErrorChanges} />
            </Dialog>
        </Stack>
    );
};

const mapStateToProps = (state) => ({
    user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchLogin: (data) =>
        dispatch({
            type: 'AUTH_LOGIN',
            data: data,
        }),
    dispatchLogout: () =>
        dispatch({
            type: 'AUTH_LOGOUT',
        }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountSetPassword);
