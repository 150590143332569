import React from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import { Stack } from '@fluentui/react/lib/Stack';
import { useParams } from 'react-router-dom';
import StaffAccordianTabs from '../../../common/StaffAccordianTabs';

// Styles definition

const stackStyles = {
    root: {
        padding: 30,
        width: '100%',
        float: 'left',
    },
};

const stackItemStyles = {
    root: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 30,
        border: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        borderRadius: 8,
    },
};

const AddStaffPage = () => {
    let { id } = useParams();

    return (
        <MainLayout>
            <Stack styles={stackStyles}>
                <Stack.Item styles={stackItemStyles}>
                    <Stack.Item>
                        <span className="ms-fontSize-24 ms-fontWeight-regular fontFamily line-height150 darkColor">
                           {id ? 'Edit Staff' : 'Add Staff'}
                        </span>
                    </Stack.Item>
                    <Stack.Item>
                        <StaffAccordianTabs id={id} />
                    </Stack.Item>
                </Stack.Item>
            </Stack>
        </MainLayout>
    );
};

export default AddStaffPage;
