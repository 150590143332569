import React from 'react';
import DefaultScreen from '../../../common/Default/DefaultScreen';
import MainLayout from '../../../layouts/main/MainLayout';
import ListOfPoll from './components/ListOfPoll';

const PollPage = () => {
    return (
        <MainLayout>
          <ListOfPoll />
        </MainLayout>
    );
};

export default PollPage;
