import React from 'react';
import MainLayout from '../../../../layouts/main/MainLayout';
import Table from '../../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';

// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 34,
    },
};

const ListEmailCampaignPage = () => {
    const history = useHistory();

    return (
        <MainLayout>
            <Table
                type={'campaign/email'}
                name={'Email Campaign'}
                tableName={'Email Campaigns'}
                allowImport={false}
                deleteIcon={true}
                allowExport={false}
                onItemClick={(item) => {
                    history.push(`/campaign/email/${item.id}`);
                }}
                onUnSubscribeClick={(item) => {
                    history.push(`/campaign/${item.id}/list/UnSubscribe`);
                }}
                onBounceClick={(item) => {
                    history.push(`/campaign/${item.id}/list/Bounced`);
                }}
                onFailedEmailClick={(item) => {
                    history.push(`/campaign/${item.id}/list/failed`);
                }}
                onClickedEmailClick={(item) => {
                    history.push(`/campaign/${item.id}/list/clicked`);
                }}
                 onOpenedEmailClick={(item) => {
                    history.push(`/campaign/${item.id}/list/opened`);
                }}
            />
        </MainLayout>
    );
};

export default ListEmailCampaignPage;


