import React, { useRef, useState } from 'react';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import EditProfileModal from '../../../../../Components/Modal/EditProfileModal';
import { Link } from 'react-router-dom';
import Api from '../../../../../../../common/api/api';
import FileDrop from '../../../../../Components/FileDrop';
import Initialising from '../../../../../../../common/controls/Initialising';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '620px !important',
    },
};

const EditProfileSection = ({ profile }) => {
    // Profile Edit Modal
    const inputFileRef = useRef();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const [showUpload, setShowUpload] = useState(false);
    const [showPic, setShowPic] = useState(false);

    const [uploading, setUploading] = useState(false);
    const [image, setImage] = useState(null);

    const handleFileChange = (files) => {
        let file = files[0];
        if (!file) {
            return;
        }
        setUploading(true);
        Api.getInstance()
            .uploadFile(
                {
                    extension: file.name.split('.').pop(),
                    'Content-Type': file.type,
                },
                file,
            )
            .then(({ key }) => {
                setImage(key);
            })
            .catch(console.log)
            .finally(() => {
                setUploading(false);
            });
    };

    const save = () => {
        Api.getInstance()
            .updateUserProfile({ image })
            .then((response) => {
                window.location.reload();
            })
            .catch(console.log);
    };

    return (
        <>
            <div className="profile-pic-box">
                {!image && !uploading && (
                    <div className="upload-profile-pic">
                        <span>
                            <FileDrop handleFileChange={handleFileChange} />
                        </span>
                    </div>
                )}
                {uploading && (
                    <div className="upload-profile-pic">
                        <Initialising />
                    </div>
                )}
                {image && (
                    <>
                        <div className="profile-save-section">
                            <div className="uploaded-image">
                                <img
                                    src={Api.getInstance().getImageUrl(image)}
                                    alt="UploadIcon"
                                />
                            </div>
                            <div className="profile-actions">
                                <button
                                    className="cancel"
                                    onClick={() => {
                                        setImage(null);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button className="save" onClick={save}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {/* Remove Profile Pic */}
                {showPic && (
                    <div className="profile-save-section">
                        <div className="remove-image">
                            <img
                                src="../../../../../../../images/uploaded.svg"
                                alt="UploadIcon"
                            />
                            <div className="edit-overlay">
                                <img
                                    src="../../../../../../../images/edit-overlay.svg"
                                    alt="EditOverlay"
                                />
                            </div>
                        </div>
                        <div className="profile-actions">
                            <Link>Remove Picture</Link>
                        </div>
                    </div>
                )}
            </div>
            <div className="edit-card-top new-edit-section">
                <div className="card-header-new">
                    <div className="card-header-col-left-new">
                        <div className="editprofile-pic">
                            <img
                                src={
                                    Api.getInstance().getImageUrl(
                                        profile.image,
                                    ) ||
                                    '../../../../../../../images/new-default-icon.png'
                                }
                                alt="ChitkaraLogo"
                            />
                        </div>
                        <div className="post-info-new">
                            <h4>{profile.name}</h4>
                            <span>{profile.batch_name}</span>
                            <p>{profile.address.permanent.city}</p>
                        </div>
                    </div>
                    
                </div>
                

                {/*  Profile Modal */}
                <Dialog
                    hidden={hideDialog}
                    onDismiss={toggleHideDialog}
                    styles={dialogStyles}
                    className={'test'}
                >
                    <EditProfileModal onClick={toggleHideDialog} />
                </Dialog>
            </div>
        </>
    );
};

export default EditProfileSection;
