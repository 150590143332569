import React from 'react';
import Api from '../api/api';
import { useBoolean } from '@fluentui/react-hooks';
import { Dialog } from '@fluentui/react/lib/Dialog';

const ContactUsModalView = (props) => {
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);
   

    const onSolve = () => {
        Api.getInstance()
            .solveQuery(props.item)
            .then((response) => {})
            .catch((e) => {
                setErrorText(e.error);
                toggleHideDialog2();
            })
            .finally(() => {
                window.location.reload();
            });
    };

    return (
        <div className="manage-column">
                
             <div className="modal-heading">
                <span>{props.text}</span>
                <div onClick={props.onCancel}>
                    <img src="../../../../images/edit-close.svg" alt="CloseIcon" />
                </div>
            </div>
            <h4>Education Detail</h4>
            <div className="delete-columns">
                <div>
                    <p>
                        <strong>Name</strong>: {props.item.name}
                    </p>
                </div>
                <div>   
                    <p>
                        <strong>Email</strong>: {props.item.email}
                    </p>
                </div>
                <div>
                    <p>
                        <strong>Subject</strong> : {props.item.subject}
                    </p>
                </div>
                <div className='modal-description'>
                    <span>
                          <strong> Description</strong>:
                    </span>
                    <p>
                      {props.item.description}
                    </p>
                </div>
            </div>
            <div className="column-buttons">
                <button onClick={props.onCancel} className="cancel-btn">
                    Cancel
                </button>
                <button onClick={onSolve} className="apply">
                   Marks as Solve
                </button>
            </div>
        </div>
    );
};

export default ContactUsModalView;
