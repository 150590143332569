import React, { useEffect, useState } from 'react';
import Api from '../../../../../../../common/api/api';
import Initialising from '../../../../../../../common/controls/Initialising';

const PostComments = ({ id }) => {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Api.getInstance()
            .getPostComments(id)
            .then((response) => {
                setComments(response);
            })
            .catch(console.log)
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return <Initialising />;
    }

    return (
        <div className="all-commnets">
            {comments.map((comment) => {
                return (
                    <div key={`comment-${comment.id}`} className="comments-col">
                        <div className="comment-info">
                            <img
                                src="../../../../../../images/comment1.svg"
                                alt=""
                            />
                            <div className="from-message">
                                <span>{comment.user.name}</span>
                                <p>UX/UI Expert</p>
                            </div>
                        </div>
                        <div className="comment-description">
                            <p>{comment.content}</p>
                        </div>
                        <div className="comments-actions">
                            <div className="comment-action-left">
                                <div className="like-count">
                                    <span>20K Likes</span>
                                </div>
                                <div
                                    className="comment-count"
                                    onClick={() => setShowThread(!showThread)}
                                >
                                    <div className="like-pic">
                                        <img
                                            src="../../../../../../images/thread-comment.svg"
                                            alt="Like"
                                        />
                                    </div>
                                    <span>2 Comment</span>
                                </div>
                            </div>
                            <div
                                className="comment-action-right"
                                onClick={() => setClickLike(!clickLike)}
                            >
                                <img
                                    src={
                                        clickLike
                                            ? '../../../../../../images/icons/after-like.svg'
                                            : '../../../../../../images/thread-like.svg'
                                    }
                                    alt="Like"
                                />
                            </div>
                        </div>

                        {/* Thread Start Div */}
                        {showThread && (
                            <div className="thread">
                                <div className="comments-col">
                                    <div className="comment-info">
                                        <img
                                            src="../../../../../../images/comment1.svg"
                                            alt=""
                                        />
                                        <div className="from-message">
                                            <span>Maude Hall</span>
                                            <p>UX/UI Expert</p>
                                        </div>
                                    </div>
                                    <div className="comment-description">
                                        <p>
                                            Thats a fantastic new app feature.
                                            You and your team did an excellent
                                            job of incorporating user testing
                                            feedback.
                                        </p>
                                    </div>
                                    <div className="comments-actions">
                                        <div className="comment-action-left">
                                            <div className="like-count">
                                                <span>20K Likes</span>
                                            </div>
                                            <div className="comment-count">
                                                <div className="like-pic">
                                                    <img
                                                        src="../../../../../../images/thread-comment.svg"
                                                        alt="Like"
                                                    />
                                                </div>
                                                <span>2 Comment</span>
                                            </div>
                                        </div>
                                        <div className="comment-action-right">
                                            <img
                                                src="../../../../../../images/thread-like.svg"
                                                alt="Like"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="comments-col">
                                    <div className="comment-info">
                                        <img
                                            src="../../../../../../images/comment1.svg"
                                            alt=""
                                        />
                                        <div className="from-message">
                                            <span>Maude Hall</span>
                                            <p>UX/UI Expert</p>
                                        </div>
                                    </div>
                                    <div className="comment-description">
                                        <p>
                                            Thats a fantastic new app feature.
                                            You and your team did an excellent
                                            job of incorporating user testing
                                            feedback.
                                        </p>
                                    </div>
                                    <div className="comments-actions">
                                        <div className="comment-action-left">
                                            <div className="like-count">
                                                <span>20K Likes</span>
                                            </div>
                                            <div className="comment-count">
                                                <div className="like-pic">
                                                    <img
                                                        src="../../../../../../images/thread-comment.svg"
                                                        alt="Like"
                                                    />
                                                </div>
                                                <span>2 Comment</span>
                                            </div>
                                        </div>
                                        <div className="comment-action-right">
                                            <img
                                                src="../../../../../../images/thread-like.svg"
                                                alt="Like"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

const ActionSection = ({ post: { id, total_likes, liked } }) => {
    const [isLiked, setIsLiked] = useState(liked);

    useEffect(() => {
        setIsLiked(liked);
    }, [liked]);

    useEffect(() => {
        setTotalLikes(totalLikes);
    }, [total_likes]);

    const [totalLikes, setTotalLikes] = useState(total_likes);

    const [clickLike, setClickLike] = useState(false);
    const [showComment, setShowComment] = useState(false);
    const [showThread, setShowThread] = useState(false);

    const handleLikeClick = () => {
        setTotalLikes(isLiked ? totalLikes - 1 : totalLikes + 1);
        setIsLiked(!isLiked);
        Api.getInstance()
            .likePost(id, isLiked ? 'unlike' : 'like')
            .then((response) => {
            })
            .then(console.log);
    };
    return (
        <div>
            <div className="post-actions">
                <div
                    className={isLiked ? 'after-like-action' : 'like-action'}
                    onClick={handleLikeClick}
                >
                    <div className="like-pic">
                        {isLiked
                            ?
                            <img
                                src="../../../../../../images/icons/after-like.svg"
                                alt="Like"
                            />
                            :
                            <img
                                src="../../../../../../images/icons/like.svg"
                                alt="Like"
                            />
                        }

                    </div>
                    <span style={{ paddingTop: 5 }}>
                        {totalLikes || ''} {totalLikes > 1 ? 'Likes' : 'Like'}
                    </span>
                </div>

                {/* <div
                    className="like-action"
                    onClick={() => setShowComment(!showComment)}
                    
                >
                    <div className="like-pic">
                        <img
                            src="../../../../../../images/icons/comment.svg"
                            alt="Like"
                        />
                    </div>
                    <span>2 Comment</span>
                </div> */}
                {/* <div className="like-action">
                    <div className="like-pic">
                        <img
                            src="../../../../../../images/icons/share.svg"
                            alt="Like"
                        />
                    </div>
                    <span>Share</span>
                </div> */}
            </div>

            {/* Show Comment */}

            {showComment && (
                <div
                    className="show-comment"
                    onClick={() => setShowComment(false)}
                >
                    <div className="comment-heading">
                        <span>Hide Comments</span>
                    </div>
                    <PostComments id={id} />
                </div>
            )}
        </div>
    );
};

export default ActionSection;
