import React from 'react';

const DefaultInput = (props) => {
    return (
        <>
            <div className={props.className}>
                <img src={props.icon} alt="Icon" />
                <input
                    type={props.type}
                    name={props.name}
                    onChange={props.onChange}
                    value={props.value}
                    placeholder={props.placeholder}
                />
                {props.righticon && (
                    <img
                        src={props.righticon}
                        onClick={props.onClick}
                        alt="Icon"
                        style={{ marginRight: 0 }}
                    />
                )}
            </div>
            {props.error && <span className="error">{props.error}</span>}
        </>
    );
};

export default DefaultInput;
