import React, { useEffect, useState } from 'react';

const ErrorModal = (props) => {
    
    return (
        <div className="manage-column">
             <div className="modal-icons">
            <div>
                <img src="../../../images/error-icon.svg"></img>
            </div>
            <div className="cross-icon">
                <img onClick={props.onClick} src="../../../images/cross-icon.svg"></img>
            </div>
            </div>
            <div className='modal-heading'>
               <span>Error</span> 
            </div>
            <div className="delete-columns">
                <p>{props.text}</p>
            </div>
            <div className="column-buttons">
                <button
                    className="delete"
                    onClick={props.onClick}
                >
                    Okay, Got it
                </button>
            </div>
        </div>
    );
};

export default ErrorModal;
