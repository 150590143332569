import React, { useEffect, useState } from 'react';
import MainLayout from '../../../layouts/main/MainLayout';
import Table from '../../../common/table/TableV3.js';
import { useHistory } from 'react-router-dom';
import Api from '../../../common/api/api';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import ViewNotificationModal from '../../../common/modals/ViewNotificationModal';
import NotificationDropDown from '../../../layouts/main/components/NotificationDropDown';

// Styles definition

const stackStyles = {
    root: {
        padding: 15,
        width: '100%',
        float: 'left',
    },
};

const stackItemStyles = {
    root: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 30,
    },
};

const dialogNewStyles = {
    main: {
        width: '100% !important',
        maxWidth: '467px !important',
        minHeight: '169px !important',
    },
};

const ListNotificationPage = () => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [audits, setAudits] = useState([]);
    const [intial, setInitial] = useState(0);
    const [end, setEnding] = useState(2);
    const [count, setCount] = useState(0);

    const [viewItem, setViewItem] = useState({
        from: '',
        to: '',
    });

    useEffect(() => {
        Api.getInstance()
            .getRowCount('audit', [])
            .then((response) => {
                setCount(response.total);
            });

        Api.getInstance()
            .getAuditLogs(0, 10)
            .then((response) => {
                setEnding(10);
                setAudits(response);
            });
    }, []);

    const getInitials = (name) => {
        const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
        return initials;
    };

    const showMore = () => {
        Api.getInstance()
            .getAuditLogs(end, end + 10)
            .then((response) => {
                setEnding(end + 10);
                setAudits(response);
            });
    };

    const history = useHistory();
    return (
        <MainLayout>
            <Stack styles={stackStyles}>
                <Stack.Item>
                    <Stack.Item>
                        <div className="notifications-list-page">
                            <div className="notify-heading">
                                <p>Notifications</p>
                            </div>
                            <div>
                                {Object.values(audits).map((value, index) => {
                                    if (value['action'] === 'Created') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    } else if (value['action'] === 'Deleted') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    } else if (value['action'] === 'update') {
                                        return (
                                            <NotificationDropDown
                                               initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                     else if (value['action'] === 'Imported') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                    else if (value['action'] === 'Liked') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                    else if (value['action'] === 'Disliked') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                    else if (value['action'] === 'Voted') {
                                        return (
                                            <NotificationDropDown
                                                initials = {getInitials(value['name'])}
                                                name={value['UserName']}
                                                entityType={value['entityType']}
                                                action={value['action']}
                                                entityData={value['entityData']}
                                                feildName={value['feildName']}
                                            />
                                        );
                                    }
                                })}
                            </div>
                            <div className="notify-show-more">
                                {end <= count && (
                                    <button onClick={showMore}>
                                        Show More
                                    </button>
                                )}
                            </div>
                        </div>
                    </Stack.Item>
                </Stack.Item>
            </Stack>
        </MainLayout>
    );
};

export default ListNotificationPage;
