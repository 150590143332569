import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../AlumniFrontEnd/Components/Header/Header';

const AccessDeniedPage = () => {
    return (
        <>
            <Header />
            <div className="not-found">
                <div className="container">
                    <div className="notfound-container">
                        <div className="not-found-left">
                            <h4>Oops.. </h4>
                            <span>Access denied</span>
                            <p>
                                You do not have the permission to access this
                                page.
                            </p>

                            <div className="go-home-button">
                                <Link to="/">
                                    <img
                                        src="../../../../images/left-arrow.svg"
                                        alt="ArrowIcon"
                                    />
                                    <span>Back to home</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccessDeniedPage;
