import React from 'react';
import Api from '../api/api';
import { useBoolean } from '@fluentui/react-hooks';
import { Dialog } from '@fluentui/react/lib/Dialog';

const ContactUsConfirmModal = (props) => {
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);

    return (
        <div className="manage-column">
            <div className="modal-icons">
                <div>
                    <img src="../../../images/Success.svg"></img>
                </div>
            </div>
            <div className="modal-heading">
                <span>{props.text}</span>
            </div>
            <div className="delete-columns">
               <p>Your query has been submitted successfully. Will solve your query soon.</p>
            </div>
            <div className="column-buttons">
                <button onClick={props.onClick} className="cancel-btn">
                    Okay
                </button>
            </div>
        </div>
    );
};

export default ContactUsConfirmModal;

