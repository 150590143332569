import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Link, useHistory } from 'react-router-dom';
import { DefaultButton } from '@fluentui/react/lib/Button';
// Styles definition
const stackStyles = {
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 35,
    },
};

const stackItemStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        childrenGap: 10,
    },
};

const stackDefaultStyles = {
    root: {
        height: 48,
        borderRadius: 4,
        background: 'transparent',
        color: '#2962FF',
        fontSize: 16,
        border: 1,
        borderColor: '#2962FF',
        borderStyle: 'solid',
        margin: 10,
        fontWeight: 'normal',
        width: 'auto',
    },
};

const DefaultDashboardScreen = ({
    Heading,
    Description,
    icon,
    watchdemotext,
    ViewHistoryText,
}) => {
    const history = useHistory();

    return (
        <Stack horizontal styles={stackStyles}>
            <Stack.Item grow={12}>
                {icon && (
                    <img src={icon} alt="Logo" style={{ marginBottom: 20 }} />
                )}
            </Stack.Item>
            <Stack.Item grow={12}>
                <span className="ms-fontSize-20 ms-fontWeight-bold darkLightColor line-height28 fontFamily ">
                    {Heading}
                </span>
            </Stack.Item>
            <div className="defalut-dash-description">
                <p className="ms-fontSize-16 ms-fontWeight-regular	ms-textAlignCenter line-height24 fontFamily greyLight">
                    {Description}
                </p>
            </div>
            <Stack.Item grow={12} styles={stackItemStyles}>
                <Link>{watchdemotext}</Link>
                <Link>{ViewHistoryText}</Link>
            </Stack.Item>
            <div className="buttons-groups">
                <DefaultButton
                    onClick={() => {
                        history.push('/alumni/add');
                    }}
                    text="Add Single Alumni"
                    styles={stackDefaultStyles}
                />
                <DefaultButton
                    text="Import CSV"
                    styles={stackDefaultStyles}
                    onClick={() => {
                        history.push('/alumni/import');
                    }}
                />
            </div>
        </Stack>
    );
};

export default DefaultDashboardScreen;
