import React from 'react';
import MainLayout from '../../Components/Layout/MainLayout';
import OtherProfileItems from './Components/OtherProfileItems';

const OtherProfile = () => {
    return (
        <MainLayout>
            <OtherProfileItems />
        </MainLayout>
    );
};

export default OtherProfile;
