import React from 'react'
import { Route } from 'react-router-dom';
import SwitchWithNotFound from '../hoc/SwitchWithNotFound';
import ListContactUsPage from '../views/main/ContactUs/ListContactUsPage';

function ContactUsRoutes ({ match: { url } }) {
 return (
        <SwitchWithNotFound>
            <Route path={`${url}/list`} exact component={ListContactUsPage} />
          
        </SwitchWithNotFound>
    );
}

export default ContactUsRoutes
