import React, { useState } from 'react';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import PersonalInfoModal from '../../../../../Components/Modal/PersonalInfoModal';
import Api from '../../../../../../../common/api/api';

const dialogStyles = {
    main: {
        width: '100% !important',
        maxWidth: '620px !important',
    },
};

const PersonalInformation = ({ profile, refresh }) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const onAdd = () => {
        setSelectedRecord({
            phone: '',
        });
        toggleHideDialog();
    };

    const actuallyUpdate = (updatedPersonalInfo) => {
        toggleHideDialog();
        Api.getInstance()
            .updatePersonalInformation(updatedPersonalInfo)
            .then(refresh)
            .catch(console.log);
    };

    return (
        <div className="branch-member-card">
            <div className="education-heading">
                <span>Personal Information</span>
            </div>
            <div className="education-list">
                {/* Loop Div */}
                <div className="member-list-col-new">
                    <div className="col-left">
                        <div className="member-info-new-details">
                            <h4>Contact Information</h4>
                            <span className="new-text">{profile.email}</span>
                        </div>
                    </div>
                </div>
                <div className="member-list-col-new">
                    <div className="col-left">
                        <div className="member-info-new-details">
                            <h4>Contact No</h4>
                            <span className="new-text">
                                {profile.phone}
                                <img
                                    className="edit-icon-contact"
                                    src="../../../../../../../images/edit-row.svg"
                                    alt="Icon"
                                    onClick={onAdd}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                {/* Loop Div */}
                <div className="member-list-col-new">
                    <div className="col-left">
                        <div className="member-info-new-details">
                            <h4>Correspondence Address</h4>
                            <RenderAddress
                                address={profile.address.correspondence}
                            />
                        </div>
                    </div>
                </div>
                <div className="member-list-col-new">
                    <div className="col-left">
                        <div className="member-info-new-details">
                            <h4>Permanent Address</h4>
                            <RenderAddress
                                address={profile.address.permanent}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/*  Personal Info Modal */}
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                styles={dialogStyles}
            >
                <PersonalInfoModal
                    updatedPersonalInformation={profile.phone}
                    onUpdate={actuallyUpdate}
                    onCancel={toggleHideDialog}
                />
            </Dialog>
        </div>
    );
};

const RenderAddress = ({ address }) => {
    const keys = Object.keys(address);
    if (keys.length === 0) {
        return '-';
    }
    let hasAddress = keys.reduce((acc, k) => {
        console.log({ acc, k });
        return !!address[k] || acc;
    }, false);
    if (!hasAddress) {
        return '-';
    }
    return (
        <>
            <span className="new-text">{address.address}</span>
            <span className="new-text">{address.city}</span>
            <span className="new-text">{address.state}</span>
            <span className="new-text">{address.country}</span>
            <span className="new-text">{address.postalCode}</span>
        </>
    );
};

export default PersonalInformation;
